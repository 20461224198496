import { memo } from "react";
import { Link } from 'react-router-dom';

import AuthenticationLayout from 'modules/Authentication/AuthenticationLayout';

import { FormControl, Button, RadioButton } from 'components';

import { Images } from 'assets';
import { locales, routes } from 'constants/index';
import { formSubmit } from 'utils';

import { TEntityTypeUI } from './EntityType.types';
import { RegisterEnum } from "../Register.types";

import '../register.scss';

const EntityTypeUI: TEntityTypeUI = ({
  data: {
    register
  },
  errors,
  handlers,
}) => {
  return (
    <AuthenticationLayout mainImage={Images.BusinessDeal}>
      <div className="register flex flex-justify-content-center flex-align-items-center">
        <div className="register__content flex flex-column flex-align-items-center">
          <div className="register__title">
            {locales.registration.createAccount}
          </div>

          <div className="register__description register__description--reset">
            {locales.registration.description}
          </div>

          <div className="mobile-icon">
            <div className="mobile-icon__svg mobile-icon__svg__register" />
          </div>

          <form onSubmit={formSubmit} className="register__form">
            <div className='register__form__title'>
              {locales.registration.whoAreYou}
            </div>

            <FormControl
              error={errors.entityType}
              className="register__field"
              elementClassName='flex'
              withBackground={false}
            >
              <RadioButton
                label={locales.general.school}
                isChecked={RegisterEnum.school in register}
                onChange={handlers.handleRadioChange(RegisterEnum.school)}
              />
            </FormControl>

            <FormControl
              error={errors.entityType}
              className="register__field"
              elementClassName='flex'
              withBackground={false}
            >
              <RadioButton
                label={locales.general.company}
                isChecked={RegisterEnum.company in register}
                onChange={handlers.handleRadioChange(RegisterEnum.company)}
              />
            </FormControl>
          </form>

          <div className="register__submit">
            <Button
              variant="primary"
              onClick={handlers.handleSubmit}
              isDisabled={!!errors.entityType}
            >
              {locales.general.next}
            </Button>
          </div>

          <div className="register__footer">
            {locales.registration.haveAnAccount}
            &nbsp;
            <Link
              to={routes.authentication.login}
              className="register__login-link"
            >
              {locales.general.login}
            </Link>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default memo(EntityTypeUI);
