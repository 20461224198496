import { OfferCard } from 'components';
import { TGrid } from './Grid.types';

import './grid.scss';
import {useNavigate} from "react-router-dom";
import {routes} from "../../constants";

export const Grid: TGrid = ({
  detailRoute = routes.offers.main,
  data,
}) => {
  const navigate = useNavigate();
  return (
    <div className="grid">
      <div className="grid__content">
        {data.map((offer, index) =>
          <div key={index} className="grid__item">
            <OfferCard data={offer} onClick={() => navigate(`${detailRoute}/${offer._id}`)} />
          </div>
        )}
      </div>
    </div>
  );
};
