import { memo } from "react";
import ClassNames from "classnames";

import { TBanner } from './Banner.types';

import './banner.scss';

const Banner: TBanner = ({
    children,
    className = '',
    variant = 'gray',
}) => {

    const classes = ClassNames({
        banner: true,
        [className]: !!className,
        [variant]: !!variant,
    });

    return (
        <div className={classes}>
            {children}
        </div>
    );
};

export default memo(Banner);
