import './mobile-block.scss';
import { locales } from 'constants/index';

export const MobileBlock = () => {
  return (
    <div className="mobile-block">
        <div className="mobile-block__display" >
            <div className="mobile-block__subcontent">
                <div className="mobile-block__subcontent__title">
                    {locales.mobileBlock.title}
                </div>
                <div className="mobile-block__subcontent__subtitle">
                    {locales.mobileBlock.subTitle}
                </div>

            </div>
            <div className="mobile-block__subcontent">
                <div className="mobile-block__subcontent__icon" />
            </div>

        </div>
        <div className="mobile-block__footer flex flex-justify-content-center flex-align-items-center">
            <div className="mobile-block__footer__icon" />
        </div>

    </div>
  );
};
