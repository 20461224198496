import classNames from 'classnames';
import { TextInput, FormControl, Button } from 'components';

import { locales, routes } from 'constants/index';
import { formSubmit } from 'utils';
import { Images } from 'assets';

import AuthenticationLayout from '../AuthenticationLayout';

import './reset-password.scss';

import { TResetPasswordUi } from './ResetPassword.types';

const ResetPasswordUi: TResetPasswordUi = ({
  isConfirmationVisible,
  data: { emailAddress },
  errors,
  handlers: { handleInputChange, handleInputBlur, handleSubmit },
}) => {
  const resetPasswordForm = (
    <>
      <div className="reset-password__title">
        {locales.forgotPassword.title}
      </div>

      <div className="reset-password__description reset-password__description--reset">
        {locales.forgotPassword.enterEmail}
      </div>

      <form onSubmit={formSubmit} className="reset-password__form">
        <FormControl
          id="emailAddress"
          label={locales.general.email}
          error={errors.emailAddress}
          className="reset-password__field"
          isRequired
          withRef
        >
          <TextInput
            id="emailAddress"
            name="email"
            value={emailAddress}
            isError={errors.emailAddress?.length > 0}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
        </FormControl>
      </form>

      <div className="reset-password__submit">
        <Button
          variant="primary"
          onClick={handleSubmit}
        >
          {locales.general.send}
        </Button>
      </div>
    </>
  );

  const confirmationContent = (
    <>
      <div className="reset-password__title">
        {locales.forgotPassword.emailSent}
      </div>

      <div className="reset-password__description reset-password__description--confirmation">
        {locales.formatString(locales.forgotPassword.confirmation, {
          email: (
            <span className="reset-password__user-email">{emailAddress}</span>
          ),
        })}
      </div>

      <div className="reset-password__image"></div>

        <div className="reset-password__submit">
        <Button
          path={routes.authentication.login}
          variant="primary"
          isLink
        >
          {locales.authentication.returnToLogin}
        </Button>
      </div>
    </>
  );

  return (
    <AuthenticationLayout mainImage={Images.BusinessDeal} isLeftContentRounded>
      <div className="reset-password flex flex-justify-content-center flex-align-items-center">
        <div
          className={classNames({
            'reset-password__content flex flex-column': true,
            'reset-password__content--confirmation': isConfirmationVisible,
          })}
        >
          {isConfirmationVisible ? confirmationContent : resetPasswordForm}
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default ResetPasswordUi;
