enum ButtonVariantEnum {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger',
  gray = 'gray',
  light = 'light',
  link = 'link',
}

// TODO: Delete this after global ButtonVariantEnum use.
type TButtonVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'gray'
  | 'light'
  | 'link'
  ;

enum ButtonSizeEnum {
  extrasmall = 'extrasmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  tableLink = 'table-link',
}

// TODO: Delete this after global ButtonSizeEnum use.
type TButtonSize =
  | 'extrasmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'table-link'
  | 'link-icon'
  ;

export {
  ButtonVariantEnum,
  ButtonSizeEnum,
};

export type {
  TButtonVariant,
  TButtonSize,
}