import { baseApi } from 'utils';

import { verifyEmailApi } from 'modules/Authentication/api';

import {
  TFetchConfigApi,
  TFetchDegreesApi,
  TFetchTags,
  TFetchUserInfoApi,
  TUpdateUserInfoApi,
  TFetchAttachmentApi,
} from './api.types';

// TODO: UPDATE TO SCHOOL
export const fetchConfigApi: TFetchConfigApi = () => {
  return baseApi().get('/config/public');
};

export const fetchAttachmentApi: TFetchAttachmentApi = () => {
  return baseApi().get('/attachments/config');
};

export const fetchUserInfoApi: TFetchUserInfoApi = () => {
  return baseApi().get('/schools/user/info');
};

export const updateUserInfoApi: TUpdateUserInfoApi = (payload) => {
  return baseApi().patch('/schools/user/profile', payload);
};

export const fetchDegreesApi: TFetchDegreesApi = () => {
  return baseApi().get('/school/degrees/fetch');
};

export const fetchTagsApi: TFetchTags = () => {
  return baseApi().get('/school/tags/fetch');
};

export { verifyEmailApi };
