import { TStatisticCard } from './StatisticCard.types';

import './statistic-card.scss';

const StatisticCard: TStatisticCard = ({ title, value }) => {
  return (
    <div className="statistic-card flex flex-column">
      <div className="statistic-card__title">{title}</div>

      <div className="statistic-card__value">{value}</div>
    </div>
  );
};

export default StatisticCard;
