import { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';

import { useValidate } from 'hooks';
import { routes, locales } from 'constants/index';
import { setRegister } from 'modules/Authentication/store/actions';

import GeneralInfoUi from './GeneralInfo.ui';

import { IHandleChange, IStoreState } from './GeneralInfo.types';
import { RegisterEnum } from "../Register.types";


const generalInfoValidationSchema = {
  emailAddress: Joi.string()
    .email({ tlds: false })
    .required()
    .messages({
      'string.empty': locales.errors.required,
      'string.email': locales.errors.invalidEmail,
    }),
  password: Joi.string()
    .min(8)
    // .regex(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //   'password'
    // )
    .required()
    .messages({
      'string.empty': locales.errors.required,
      'string.min': locales.formatString(locales.errors.minLength, { charNum: 8 }).toString(),
      'string.pattern.name': locales.errors.passwordPolicy,
    }),
  passwordConfirmation: Joi.string()
    .required()
    .valid(Joi.ref('password'))
    .messages({
      'string.empty': locales.errors.required,
      'any.only': locales.errors.passwordMatch,
    }),
};

const GeneralInfo = () => {
  // * Hooks Init
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errors, setErrors, validate } = useValidate();

  //* Redux State
  const register = useSelector(({ auth: { register } }: IStoreState) => register, shallowEqual);

  //* Handlers
  const handleInputChange: IHandleChange = useCallback(({ target: { name, value } }) => {
    dispatch(setRegister({ [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  }, [dispatch]);

  const handleInputBlur: IHandleChange = useCallback(({ target: { name, value } }) => {
    let data = {};
    let schema = {};

    if (!value) return;

    if (name === RegisterEnum.passwordConfirmation) {
      data = {
        ...data,
        password: register.password,
      };
      schema = {
        password: generalInfoValidationSchema.password,
      };
    }

    validate({
      data: {
        ...data,
        [name]: value,
      },
      validationSchema: {
        ...schema,
        // @ts-ignore
        [name]: generalInfoValidationSchema[name],
      },
    });
  }, [register]);

  const handleIsAgreedChange = useCallback(() => {
    dispatch(setRegister({
      termsOfUse: !register.termsOfUse
    }));
  }, [dispatch, register]);

  const handleSubmit = useCallback(() => {
    const { emailAddress, password, passwordConfirmation } = register;

    const isFormValid = validate({
      data: { emailAddress, password, passwordConfirmation },
      validationSchema: generalInfoValidationSchema,
    });

    isFormValid &&
      navigate(
        `${routes.authentication.register.main}/${routes.authentication.register.personalInfo}`
      );
  }, [register]);

  //* Effects
  useEffect(() => {
    if (!(RegisterEnum.school in register)
      && !(RegisterEnum.company in register))
      navigate(
        `${routes.authentication.register.main}/${routes.authentication.register.entityType}`
      );
  }, [register, navigate]);

  //* Wrappers
  const data = useMemo(() => ({
    register,
  }), [
    register,
  ]);

  const handlers = useMemo(() => ({
    handleInputChange,
    handleInputBlur,
    handleIsAgreedChange,
    handleSubmit,
  }), [
    handleInputChange,
    handleInputBlur,
    handleIsAgreedChange,
    handleSubmit,
  ]);

  return <GeneralInfoUi
    data={data}
    errors={errors}
    handlers={handlers}
  />;
};

export default memo(GeneralInfo);
