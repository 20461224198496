import { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';

import PersonalInfoUi from './PersonalInfo.ui';

import { useValidate } from 'hooks';
import { routes, locales } from 'constants/index';

import { setRegister } from 'modules/Authentication/store/actions';

import { IHandleChange, IStoreState } from './PersonalInfo.types';

const validationSchema = {
  firstname: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  lastname: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
};

const PersonalInfo = () => {
  // * Hooks Init
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errors, setErrors, validate } = useValidate();

  //* Redux State
  const register = useSelector(({ auth: { register } }: IStoreState) => register, shallowEqual);

  //* Handlers
  const handleInputChange: IHandleChange = useCallback(({ target: { name, value } }) => {
    dispatch(setRegister({ [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  }, [dispatch]);

  const handleInputBlur: IHandleChange = useCallback(({ target: { name, value } }) => {
    if (!value) return;
    validate({
      data: {
        [name]: value,
      },
      validationSchema: {
        // @ts-ignore
        [name]: validationSchema[name],
      },
    });
  }, [register]);

  const handleSubmit = useCallback(() => {
    const { firstname, lastname } = register;

    const isFormValid = validate({
      data: { firstname, lastname },
      validationSchema: validationSchema,
    });

    isFormValid &&
      navigate(
        `${routes.authentication.register.main}/${routes.authentication.register.userInfo}`
      );
  }, [register]);

  //* Effects
  useEffect(() => {
    if (!register.emailAddress
      || !register.password
      || !register.passwordConfirmation)
      navigate(
        `${routes.authentication.register.main}/${routes.authentication.register.generalInfo}`
      );
  }, [register, navigate]);

  //* Wrappers
  const data = useMemo(() => ({
    register
  }), [
    register
  ]);

  const handlers = useMemo(() => ({
    handleInputChange,
    handleInputBlur,
    handleSubmit,
  }), [
    handleInputChange,
    handleInputBlur,
    handleSubmit,
  ]);

  return <PersonalInfoUi
    data={data}
    errors={errors}
    handlers={handlers}
  />;
};

export default memo(PersonalInfo);
