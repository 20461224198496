import { locales } from 'constants/index';

import { IError } from 'common';

const useExtractServerError = () => {
  const extractErrorMessage = (errorObject: unknown) => {
    const error = errorObject as IError;

    let message = error?.data?.message || locales.errors.general;

    if (error.status === 401 || error.status === 403) {
      message = locales.errors.authorization;
    }

    if (error.status === 409) {
      message = locales.errors.userExists;
    }

    return message;
  };

  return { extractErrorMessage };
};

export default useExtractServerError;
