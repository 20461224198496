import { useCallback, useState } from "react";
import { useDispatch, batch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  setOfferFormActiveStepAction,
  setOfferFormCompletedStepAction,
} from "modules/Offers/store/actions";
import { CreateOfferStepsEnum } from "modules/Offers/CreateOffer/enums";
import { routes } from "constants/index";

import CreateOfferPlansUi from "./CreateOfferPlans.ui";
import { TCreateOfferPlans } from "./CreateOfferPlans.types";

const CreateOfferPlans: TCreateOfferPlans = ({
  couponCode,
  subscriptionTypes,
  selectedSubscriptionType,
  handlers,
}) => {
  //* Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  //* Local State
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  //* Handlers
  const handleNextStepClick = useCallback(() => {
    if (!id)
      navigate(
        `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.checkout}`
      );
    else
      navigate(
        `${routes.offers.main}/${routes.offers.create.main}/${id}/${routes.offers.create.checkout}`
      );

    batch(() => {
      dispatch(setOfferFormActiveStepAction(CreateOfferStepsEnum.checkout));
      dispatch(
        setOfferFormCompletedStepAction({
          stepIndex: CreateOfferStepsEnum.plans,
          isCompleted: true,
        })
      );
    });
  }, []);

  const handleTabClick = (index: number) => setActiveTabIndex(index);

  return (
    <CreateOfferPlansUi
      activeTabIndex={activeTabIndex}
      subscriptionTypes={subscriptionTypes}
      selectedSubscriptionType={selectedSubscriptionType}
      couponCode={couponCode}
      handlers={{
        ...handlers,
        handleTabClick,
        handleNextStepClick,
      }}
    />
  );
};

export default CreateOfferPlans;
