interface IRegister {
    emailAddress: string;
    password: string;
    passwordConfirmation?: string;
    termsOfUse: boolean;

    firstname: string;
    lastname: string;

    city: string;
    postalCode: string;
}

interface IRegisterSchool extends IRegister {
    company?: never;
    school?: string;
    shortName?: string;
}

interface IRegisterCompany extends IRegister {
    school?: never;
    company?: string;
    phoneNumber?: string;
    siretNum?: string;
    webUrl?: string;
}

enum RegisterEnum {
    emailAddress = 'emailAddress',
    password = 'password',
    passwordConfirmation = 'passwordConfirmation',
    termsOfUse = 'termsOfUse',
    firstname = 'firstname',
    lastname = 'lastname',
    city = 'city',
    postalCode = 'postalCode',
    school = 'school',
    shortName = 'shortName',
    company = 'company',
    phoneNumber = 'phoneNumber',
    siretNum = 'siretNum',
    webUrl = 'webUrl',
}

type TRegister = IRegisterSchool | IRegisterCompany;

export {
    RegisterEnum,
};

export type {
    TRegister,
    IRegisterSchool,
    IRegisterCompany,
};
