import { memo, useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useExtractServerError, useToast } from "hooks";
import { locales } from "constants/index";

import { setIsLoading } from "modules/App/store/actions";
import { resetModal, setModalData, setModalType, setModalValues } from "components/Modals/store/actions";

import { fetchOffersOptionsApi, updateStatusApi } from "../../api";

import { ModalsTypesEnum } from "components/Modals/Modals.enums";

import type { IStoreState } from "store";
import type { IUpdateStatusPayload } from "../../api/api.types";
import type {
    TDetails,
    THandleDropdownChangeWithType,
    THandleSubmitInterviewDetails
} from "./Details.type";

import DetailsUI from "./Details.ui";
import { fetchDesignApi, fetchEmailTemplateMultiApi, fetchUserInfoApi } from "../../../Settings/api";
import { convertToDropdown } from "../../../../utils";
import { setProfileEmails } from "../../store/actions";

const Details: TDetails = () => {
    const modalType = ModalsTypesEnum.INTERVIEW_DETAILS;

    //* Hooks
    const toast = useToast();
    const dispatch = useDispatch();
    const { id: profileId = '', offerId = '' } = useParams();
    const { extractErrorMessage } = useExtractServerError();


    //* Redux State
    const userInfo = useSelector( ( { app: { userInfo } }: IStoreState ) => userInfo, shallowEqual );
    const studentInfo = useSelector(({ candidates: { newProfile: { studentInfo } } }: IStoreState) => studentInfo, shallowEqual);
    const emails = useSelector(({ candidates: { newProfile: { emails } } }: IStoreState) => emails, shallowEqual);
    const config = useSelector(({ app: { config } }: IStoreState) => config, shallowEqual);
    const modals = useSelector(({ modals }: IStoreState) => modals, shallowEqual);
    const pipes = useSelector(({ candidates: { pipes } }: IStoreState) => pipes, shallowEqual);


    //* API Actions
    const updateStatus = useCallback(async (payload: Partial<IUpdateStatusPayload>) => {
        try {
            dispatch(setIsLoading(true));

            await updateStatusApi({
                _id: profileId,
                ...payload,
            });

            toast({ type: 'success', message: locales.general.success });
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
        }
    }, [dispatch, profileId]);

    const fetchEmails = useCallback(async () => {
        try {
            setIsLoading(true);

            const {
                data: { data: emails }
            } = await fetchEmailTemplateMultiApi();

            dispatch(setProfileEmails(emails));

            setIsLoading(false);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            setIsLoading(false);
        }
    }, [dispatch, profileId]);

    const setVariables = useCallback(async () => {
        try {
            setIsLoading(true);

            const { data: { data: newUserInfo } } = await fetchUserInfoApi();
            const { data: { data: offersOptions } } = await fetchOffersOptionsApi();
            // const { data: { data: siteConfig } } = await fetchDesignApi();


            const firstAndLastName = `${newUserInfo?.name?.firstname?.trim() ?? ''} ${newUserInfo?.name?.lastname?.trim() ?? ''}`;

            const CRE_NAME = !!firstAndLastName.trim()
                ? firstAndLastName.trim()
                : userInfo?.info?.school ?? locales.general.school

            const variables = [
                {
                    label: "{{CRE_NAME}}",
                    value: CRE_NAME,
                },
                {
                    label: "{{OFFER_NAME}}",
                    value: offersOptions.find(element => element.key === offerId)?.label ?? '',
                },
                // {
                //     label: "{{LANDING_SCHOOL_BLOOM}}",
                //     value: siteConfig?.domain_name ?? '',
                // },
                {
                    label: "{{Prénom}}",
                    value: studentInfo?.name.firstname ?? '',
                },
                {
                    label: "{{Nom}}",
                    value: studentInfo?.name.lastname ?? '',
                },
                {
                    label: "{{SCHOOL_NAME}}",
                    value: newUserInfo?.info.school ?? "",
                },
            ];

            dispatch(setModalData(ModalsTypesEnum.VALIDATE_EMAIL, {
                fromEmail: newUserInfo.contactInfo.emailAddress,
                fromName: CRE_NAME,
                to: studentInfo?.contact_info.email_address ?? '',
                variables
            }));

            setIsLoading(false);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            setIsLoading(false);
        }
    }, [dispatch, profileId, studentInfo, userInfo]);


    //* Handlers
    const handleDropdownChangeWithType: THandleDropdownChangeWithType = useCallback((type) => async (option) => {
        if (Array.isArray(option)) return;

        const chosenEmail = emails.find(element => element._id === option.value) ?? {};

        await setVariables();

        dispatch(setModalType(ModalsTypesEnum.VALIDATE_EMAIL, true));
        dispatch(setModalValues(ModalsTypesEnum.VALIDATE_EMAIL, chosenEmail));

        // await updateStatus({ statut_appel: option.value });
    }, [emails, setVariables]);

    // const handleInterviewDetails: THandleInterviewDetails = useCallback(async () => {
    //     dispatch(setModalType(modalType, true));
    //
    //     if (!!studentInfo?.student_info?._id) return;
    //     toast({type: 'error', message: locales.errors.cantAddToyPareo});
    // },[dispatch, studentInfo]);

    const handleSubmitInterviewDetails: THandleSubmitInterviewDetails = useCallback(async () => {
        batch(() => {
            dispatch(resetModal(ModalsTypesEnum.VALIDATE_EMAIL));
            dispatch(setModalType(ModalsTypesEnum.VALIDATE_EMAIL, undefined));
        })
    },[dispatch]);


    useEffect(() => {
        fetchEmails();
    }, [ studentInfo ]);

    useEffect(() => {
        setVariables();
    }, [modals[modalType].isOpen, studentInfo]);


    const data = useMemo(() => ({
        skills: studentInfo?.info.skills ?? [],
        emails: convertToDropdown({ labelKey: "trigger_identifier", valueKey: "_id", data: emails }),
        config,
        modals,
    }), [
        studentInfo,
        config,
        modals,
    ]);

    const handlers = useMemo(() => ({
        handleDropdownChangeWithType,
        handleSubmitInterviewDetails,
    }), [
        handleDropdownChangeWithType,
        handleSubmitInterviewDetails,
    ]);

    return <DetailsUI data={data} handlers={handlers} />
};

export default memo(Details);
