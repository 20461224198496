import { enums } from 'constants/index';

import { authenticationActionTypes } from './action-types';

import { TAuthenticationAction, IAuthenticationGlobalState } from './store.types';

const initialState: IAuthenticationGlobalState = {
  accessToken: '',
  refreshToken: '',
  authStatus: enums.app.status.none,
  register: {
    emailAddress: '',
    password: '',
    termsOfUse: false,
    firstname: '',
    lastname: '',
    city: '',
    postalCode: '',
  }
};

const reducer = (
  state: IAuthenticationGlobalState = initialState,
  action: TAuthenticationAction
) => {
  switch (action.type) {
    case authenticationActionTypes.AUTHENTICATE_USER:
      return {
        ...state,
        authStatus: enums.app.status.authenticated,
        accessToken: action.accessToken,
        refreshToken: action.refreshToken,
      };

    case authenticationActionTypes.UNAUTHENTICATE_USER:
      return {
        ...state,
        authStatus: enums.app.status.unAuthenticated,
        accessToken: '',
        refreshToken: '',
      };

    case authenticationActionTypes.SET_REGISTER:
      return {
        ...state,
        register: {
          ...state.register,
          ...action.payload
        },
      };

    case authenticationActionTypes.RESET_REGISTER:
      return {
        ...state,
        register: initialState.register,
      };

    case authenticationActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
