import { memo } from "react";
import { DragDropContext } from '@hello-pangea/dnd';

import { TDraggableContainer } from './DraggableContainer.types';

const DraggableContainer: TDraggableContainer = ({
  children,
  onDragStart,
  onDragUpdate,
  onDragEnd,
}) => {
  return (
    <DragDropContext
      onDragStart={(...props) => {
        if (!onDragStart) return;
        onDragStart(...props);
      }}
      onDragUpdate={(...props) => {
        if (!onDragUpdate) return;
        onDragUpdate(...props);
      }}
      onDragEnd={(...props) => {
        if (!onDragEnd) return;
        onDragEnd(...props);
      }}
    >
      {children}
    </DragDropContext>
  );
};

export default memo(DraggableContainer);
