import { FormEvent } from "react";

import type { TComboboxItem } from "common";

import {
    IDropdownObject,
    IEditListObject,
    TConvertToDropdown,
    TConvertToEditList,
    TGuestToEditList,
} from "./helpers.types";

export const formSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
};

export const convertToDropdown: TConvertToDropdown = ({ data, labelKey = "label", valueKey = "value" }) => {
    let result: IDropdownObject[] = [];

    if (data.length === 0) return [];

    data.forEach((entry) => {
        result = [
            ...result,
            {
                label: entry[labelKey],
                value: entry[valueKey],
            },
        ];
    });

    return result;
};

export const convertToEditlist: TConvertToEditList = ({
    data,
    idKey = "id",
    titleKey = "title",
    descriptionKeys = [
        {
            label: "Description",
            key: "description",
        },
    ],
}) => {
    let result: IEditListObject[] = [];

    if (data.length === 0) return [];

    data.forEach((entry) => {
        result = [
            ...result,
            {
                id: entry[idKey],
                title: entry[titleKey],
                description: descriptionKeys.map(({ label, key }) => ({
                    label,
                    value: entry[key],
                })),
            },
        ];
    });

    return result;
};

export const guestToEditlist: TGuestToEditList = ({ data, roles }) => {
    let result: IEditListObject[] = [];

    if (data.length === 0) return [];

    data.forEach((entry) => {
        result = [
            ...result,
            {
                id: entry._id,
                title: roles.find((role) => role.value === `${entry.roleId}`)?.label ?? "",
                badge: entry.message_status,
                description: [
                    {
                        value: entry.contactInfo.emailAddress ?? "",
                    },
                ],
                isDisabled: entry.register_status,
            },
        ];
    });

    return result;
};

export const cleanObject = (obj: any) => {
    for (var propName in obj) {
        if (
            obj[propName] === null ||
            obj[propName] === undefined ||
            obj[propName] === "" ||
            obj[propName].length === 0
        ) {
            delete obj[propName];
        }
    }
    return obj;
};

export const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
};

export const removeHttpFromString = ( text: string ) => {
    return text.replace(/(((http|https):\/\/)|((mailto|tel):))/g, '');
};

export const prependHttpsToString = ( text: string ) => {
    return 'https://' + text
};

export function convertToComboboxItem<T> ( source: Array<T>, labelKey: keyof T, valueKey: keyof T ): TComboboxItem[] {
    return source.map<TComboboxItem> ( element => ( {
        label: element[ labelKey ],
        value: element[ valueKey ],
        original: element
    } ) );
};