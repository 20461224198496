import { memo } from "react";
import classNames from "classnames";

import { TOfferCard } from "./OfferCard.types";

import "./offer-card.scss";

const OfferCard: TOfferCard = ({ data, onClick }) => {
    const cardClassname = classNames({
        "offer-card": true,
        "offer-card-clickable": onClick,
    });

    const pillClassname = classNames({
        "offer-card__prop__items": true,
        "offer-card__prop__items-pill-pink": data.info?.type?.toLowerCase() === "stage",
        "offer-card__prop__items-pill-green": data.info?.type?.toLowerCase() !== "stage",
    });

    return (
        <div className={cardClassname} onClick={onClick}>
            <div className="offer-card__header">
                <div className="offer-card__title">{data.info.title}</div>
                <div className="offer-card__subtitle">{data.company.name}</div>
            </div>
            <div className="offer-card__props">
                <div className="offer-card__prop">
                    {data.info.type && (
                        <>
                            <div className="offer-card__prop__title">Contrat :</div>
                            <div className={pillClassname}>{data.info.type}</div>
                        </>
                    )}
                </div>
                <div className="offer-card__prop">
                    {data.info.city && (
                        <>
                            <div className="offer-card__prop__title">Lieu :</div>
                            <div className="offer-card__prop__items offer-card__prop__items-pill-gray">
                                {data.info.city} - {data.info.postal_code}
                            </div>
                        </>
                    )}
                </div>
                <div className="offer-card__prop">
                    {!data.info.pace && (
                        <>
                            <div className="offer-card__prop__title">Durée :</div>
                            <div className="offer-card__prop__items offer-card__prop__items-pill-gray">
                                {data.info.pace}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(OfferCard);
