import Joi from "joi";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { memo, useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { locales } from "constants/index";
import { useExtractServerError, useToast, useValidate } from "hooks";
import { IStoreState } from "store";

import { setIsLoading } from "modules/App/store/actions";
import { setModalData, setModalType, setModalValues } from "../store/actions";

import { interviewDetailsApi } from "./api";

import type {
    TInterviewDetailsModal,
    THandleTextInputChange,
    THandleTextInputBlur,
} from './InterviewDetailsModal.types';
import { InterviewDetailsModalEnums } from './InterviewDetailsModal.enums';
import { ModalsTypesEnum } from "../Modals.enums";
import { IInterviewDetailsApiPayload } from "./api/api.types";

import InterviewDetailsModalUI from "./InterviewDetailsModal.ui";


const validationSchema: Record<InterviewDetailsModalEnums, Joi.Schema> = {
    [InterviewDetailsModalEnums.date]: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    [InterviewDetailsModalEnums.hour]: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
};

const InterviewDetailsModal: TInterviewDetailsModal = ({
    onSubmit,
}) => {
    dayjs.extend(advancedFormat);
    const modalType = ModalsTypesEnum.INTERVIEW_DETAILS;

    //* Hooks Init
    const toast = useToast();
    const dispatch = useDispatch();
    const { extractErrorMessage } = useExtractServerError();
    const { errors, validate, setErrors } = useValidate();


    //* Redux State
    const modal = useSelector(({ modals: { [modalType]: modals } }: IStoreState) => modals, shallowEqual);


    //* Side Effects
    const initRedux = useCallback(() => {
        const { values } = modal;

        dispatch(setModalValues(modalType, {}));
    }, [modal.values])

    //* API Actions
    const sendInterviewDetails = useCallback(async () => {
        try {
            // const { values } = modal;
            // dispatch(setIsLoading(true));
            //
            //
            // const payload: IInterviewDetailsApiPayload = {
            //     date: values.date,
            //     hour: values.hour,
            // };
            //
            // const isValid = validate({ data: payload as any, validationSchema });
            //
            // if (!isValid) return batch(() => {
            //     dispatch(setIsLoading(false));
            // });
            //
            //
            // await interviewDetailsApi(payload);
            //
            // toast({ type: 'success', message: locales.modals.yPareoModal.success });
            //
            // batch(() => {
            //     dispatch(setIsLoading(false));
            // });

            onSubmit && onSubmit();
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, onSubmit, modal.values]);

    //* Handlers
    const handleTextInputChange: THandleTextInputChange = useCallback(({ target: { name, value } }) => {
        batch(() => {
            dispatch(setModalValues(modalType, { [name]: value }));
        });

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }, [dispatch]);

    const handleTextInputBlur: THandleTextInputBlur = useCallback(({ target: { name, value } }) => {
        if (!validationSchema[name as InterviewDetailsModalEnums]) return;
        validate({
            data: { [name]: value },
            validationSchema: { [name]: validationSchema[name as InterviewDetailsModalEnums] },
        });
    }, [dispatch, validate]);

    const handleSubmitClick = useCallback(async () => {
        await sendInterviewDetails();
    }, [dispatch, onSubmit, sendInterviewDetails]);

    const handleClose = useCallback(() => {
        dispatch(setModalType(modalType, false));
    }, [dispatch]);


    //* Effects
    useEffect(() => {
        initRedux();
    }, []);


    //* Wrappers
    const values = useMemo(() => modal.values, [modal.values]);

    const data = useMemo(() => modal.data, [modal.data]);

    const handlers = useMemo(() => ({
        handleClose,
        handleSubmitClick,
        handleTextInputChange,
        handleTextInputBlur,
    }), [
        handleClose,
        handleSubmitClick,
        handleTextInputChange,
        handleTextInputBlur,
    ]);

    if (!modal.isOpen) return null;

    return <InterviewDetailsModalUI
        values={values}
        data={data}
        handlers={handlers}
        errors={errors}
    />;
};

export default memo(InterviewDetailsModal);
