import { memo } from "react";
import classNames from 'classnames';

import { TBadge } from './Badge.types';

import styles from './badge.module.scss';

const _PRIMARY = '#ffffff';
const _SECONDARY = '#2EC1AC';

const Badge: TBadge = ({
    value = '',
    size = 'extrasmall',
    isOutline = false,
    numberOfLines = 'two',
    hideBall = false,
    style,
}) => {
    const classes = classNames({
        [styles.badge]: true,
        [`${styles.badge__size} ${styles[size]}`]: !!size,
        [`${styles.badge} ${styles.outline}`]: isOutline,
    });

    return <div className={classes} style={{
        backgroundColor: style?.secondary ?? _SECONDARY,
        color: style?.primary ?? _PRIMARY,
        borderColor: style?.primary ?? _PRIMARY
    }}>
        {!hideBall && <div className={styles.badge__ball} style={{ backgroundColor: style?.primary ?? _PRIMARY }}/>}
        <div className={`${ styles.badge__content } ${ styles[numberOfLines] }`}>{value}</div>
    </div>;
};

export default memo(Badge);