import { Render } from './Switch.types';

import { style } from './style';

import './switch.scss';

export const Switch: Render = ({
  id,
  label,
  name,
  isChecked,
  isInverted = false,
  onChange,
  extraClass = ''
}) => {
  return (
    <div className={style({ extraClass, isInverted })}>
      {label}{' '}
      <div className="switch__toggle">
        <input
          type="checkbox"
          className="switch__checkbox"
          checked={isChecked}
          name={name}
          onChange={onChange}
          id={id}
        />
        <label className="switch__label" htmlFor={id}>
          <span className="switch__inner" />
          <span className="switch__self" />
        </label>
      </div>
    </div>
  );
};
