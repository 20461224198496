"use client";

import { MultiSelect } from "@mantine/core";

import classNames from 'styles/sass/components/MultiSelect.module.scss';

export default MultiSelect.extend({
	defaultProps: {
		color: 'darkgreen',
		radius: 'md',
		size: 'md',
	},
	classNames,
})