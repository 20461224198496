import { memo } from "react";
import { Button, FormLayout, Tabs, TabPanel, Checkbox, FormControl, SearchBar } from 'components';

import { locales } from 'constants/index';

import { TCreateOfferRequiredSkillsUi } from './CreateOfferRequiredSkills.types';
import { SkillsTabEnum } from "./CreateOfferRequiredSkills.enums";

const skillsPerCategory: {
  [key: number]: {
    category_id?: string;
    excluded_categories: string[];
  }
} = {
  [SkillsTabEnum.required]: {
    excluded_categories: [
      '6606b64201ad56002170790d'
    ]
  },
  [SkillsTabEnum.softSkills]: {
    category_id: '6606b64201ad56002170790d',
    excluded_categories: [],
  },
}

const CreateOfferRequiredSkillsUi: TCreateOfferRequiredSkillsUi = ({
  publish,
  isUserSubscribed,
  errors,
  data: {
    searchString,
    types,
    skills,
    allSkills,
    skillCategories,
    chosenCategory,
  },
  handlers: {
    handleToggleSkill,
    handleSubmitDraft,
    handleRequiredSkillsSubmit,
    handleSearch,
    handleCategoryTabClick,
  },
}) => {

  return (
    <>
      <FormLayout
        banner={locales.offer.chooseRequiredSkills}
        className='create-offer__form__large'
      >
        <div className='flex flex-justify-content-center'>
          <SearchBar
            className='create-offer__form__medium'
            value={searchString}
            onChange={handleSearch}
            placeholder={locales.createOffer.research}
          />
        </div>

        {types.map((type, index) =>
          <div key={index} className="create-offer__skill__line">
            {allSkills.filter((skill) => !!skill[type])
              .map((skill, index) => {
                return <div key={index} className="create-offer__skill__pill">
                  {skill.label}
                  <div
                    onClick={() => handleToggleSkill(type)(skill._id)}
                    className="create-offer__skill__pill__icon"
                  />
                </div>
              })}
          </div>
        )}

        <Tabs activeTab={chosenCategory} onClick={handleCategoryTabClick}>
          {types.map((type, index) =>
            <TabPanel
              key={index.toString()}
              title={locales.offer[`${type}Skill`]}
              className="create-offer__skill__tab"
            >
              <FormControl
                label={""}
                withBackground={false}
                error={errors.required ?? ''}
              >
                <div className="create-offer__skill__container">
                  <div className="create-offer__skill__container__scroller">
                    <>
                    {skillCategories.map((category, index) => {
                      const filteredSkills = skills.filter((skill) => {
                        if (!!skillsPerCategory[chosenCategory].category_id) {
                          return skillsPerCategory[chosenCategory].category_id === category.id
                            ? skill.category?.id === category.id
                            : false;
                        }
                        if (!!skillsPerCategory[chosenCategory].excluded_categories?.length) {
                          return skill.category?.id === category.id && !skillsPerCategory[chosenCategory].excluded_categories.includes(category.id);
                        }
                        return skill.category?.id === category.id;
                      });
                      if (filteredSkills.length === 0) return <></>;
                      return <div key={index.toString()} className="create-offer__skill__set">
                        <div className="create-offer__skill__set__title__container">
                          <div className="create-offer__skill__set__title">{category.label}</div>
                        </div>
                        <div className="create-offer__skill__set__container">
                          {filteredSkills.map((skill, index) =>
                            <Checkbox
                              key={index}
                              id={`${skill._id}-${index}`}
                              label={skill.label}
                              className="create-offer__skill"
                              labelStyle="create-offer__skill__label"
                              isChecked={skill.required ?? false}
                              onChange={() => handleToggleSkill(type)(skill._id)}
                              isError={!!errors.required}
                            />
                          )}
                        </div>
                      </div>
                    })}
                    </>
                  </div>
                </div>
              </FormControl>
            </TabPanel>
          )}
        </Tabs>
      </FormLayout>

      <div className="create-offer__actions">
        {isUserSubscribed && (
          <Button
            variant='link'
            onClick={handleSubmitDraft}
          >
            {locales.createOffer.saveAsDraft}
          </Button>
        )}

        <Button
          variant="primary"
          onClick={handleRequiredSkillsSubmit}
        >
          { publish
            ? locales.general.publish
            : locales.general.submit
          }
        </Button>
      </div>
    </>
  );
};

export default memo(CreateOfferRequiredSkillsUi);
