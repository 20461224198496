import { memo } from "react";
import { ActionIcon, Button, Flex, Grid, GridCol, Group, Paper, Stack, TextInput, Tooltip, } from "@mantine/core";
import { IconFileTypeCsv, IconFileTypePdf, IconFileZip, IconSearch, IconX } from '@tabler/icons-react';

import { PdfViewer, Table2 } from "components";
import { locales } from "constants/index";

import { COLUMNS } from "./tableColumns";
import { CVThequeFilter } from "./constants";
import { FilterInput, SelectFilter, MultiPillSelect } from "./components";
import { CVThequeFilterEnum } from "./enums";
import { TCVThequeUIProps, TCVThequeColumns, THandleShowCV } from './types';


const searchIcon = <IconSearch size={ 14 }/>;
const clearIcon = <IconX size={ 14 }/>;
const zipIcon = <IconFileZip />;
const csvIcon = <IconFileTypeCsv />;

const CVThequeUI = ( { loading, data: { form, students, activeFilters, selectedStudents, selectedStudentCV }, options, handlers }: TCVThequeUIProps ) => {

    const columns: TCVThequeColumns = [
        ...COLUMNS,
        {
            id: 'view-cv',
            header: undefined,
            accessorFn: row => row.candidateId,
            cell: info =>
                <ActionIcon
                    disabled={ !info.getValue<string>() }
                    onClick={ handlers.handleShowCV( info.getValue<string>() ) }
                >
                    <IconFileTypePdf/>
                </ActionIcon>,
            size: 0,
            minSize: 75,
            enableSorting: false,
        },
    ];


    return (
        <Stack p='md' h='100%'>
            <Paper withBorder bg='#F9F9FC' p='sm'>
                <Stack gap='sm'>
                    <Grid>
                        <GridCol span={ { base: 12, sm: 6 } } component='form' onSubmit={ handlers.onSubmit }>
                            <TextInput
                                { ...form.getInputProps( 'search_string' ) }
                                defaultValue={ form.values[ 'search_string' ] }
                                onChange={ handlers.setFieldValue( 'search_string' ) }
                                leftSection={ searchIcon }
                                rightSection={
                                    !!form.values[ 'search_string' ] &&
                                    <ActionIcon c='dimmed' onClick={ handlers.onClearClick( 'search_string' ) }>
                                        <IconX/>
                                    </ActionIcon>
                                }
                                placeholder={ 'Search ...' }
                            />
                        </GridCol>
                        <GridCol span={ { base: 12, sm: 6 } }>
                            <Group gap={ 0 } justify='flex-end' wrap='nowrap'>

                                <Tooltip
                                    disabled={ selectedStudents.length < 10 }
                                    label={ locales.pages.cvTheque.sections.filters.action.exportZIPlimit }
                                >
                                    <Button
                                        disabled={ !selectedStudents.length || selectedStudents.length > 10 }
                                        data-disabled={ !selectedStudents.length || selectedStudents.length > 10 }
                                        leftSection={ zipIcon }
                                        onClick={ handlers.onExportClick }
                                        variant='subtle'
                                        loading={ loading.exportCVs }
                                    >
                                        { locales.pages.cvTheque.sections.filters.action.exportZIP }
                                    </Button>
                                </Tooltip>

                            </Group>
                        </GridCol>
                    </Grid>


                    {/*<Flex align='center' wrap='wrap' gap='sm'>*/}
                    {/*    { activeFilters.map( ( _, index ) =>*/}
                    {/*        <Fragment key={ index }>*/}
                    {/*            <FilterInput options={ [] }/>*/}
                    {/*        </Fragment>*/}
                    {/*    ) }*/}


                    {/*    <FilterInput options={ options[ CVThequeFilterEnum.OFFERS ] }/>*/}
                    {/*    /!*<FilterInput options={ options[ CVThequeFilterEnum.MONTHS ] }/>*!/*/}
                    {/*    /!*<FilterInput options={ options[ CVThequeFilterEnum.RANGE ] }/>*!/*/}
                    {/*    /!*<FilterInput options={ options[ CVThequeFilterEnum.WORK_FIELD ] }/>*!/*/}
                    {/*    /!*<FilterInput options={ options[ CVThequeFilterEnum.STUDY_LEVEL ] }/>*!/*/}
                    {/*    /!*<FilterInput options={ options[ CVThequeFilterEnum.PIPE_STATUS ] }/>*!/*/}

                    {/*    <SelectFilter*/}
                    {/*        values={ activeFilters }*/}
                    {/*        options={ CVThequeFilter }*/}
                    {/*        onOptionSubmit={ handlers.onAddFilter }*/}
                    {/*    />*/}
                    {/*</Flex>*/}
                </Stack>
            </Paper>

            <Table2
                columns={ columns }
                data={ students }
                enableRowSelection
                enableMultiRowSelection
                handleSelectionChange={ handlers.handleSelectionChange }
                rowId='candidateId'
            />


            {!!selectedStudentCV && (
                <PdfViewer
                    url={selectedStudentCV}
                    isVisible={!!selectedStudentCV}
                    onClose={ handlers.handleCloseCv }
                    isFixedPosition
                />
            )}
        </Stack>
    );
};
export default memo( CVThequeUI );
