import { useId } from 'react';
import classNames from 'classnames';

import { TStepper } from './Stepper.types';

import './stepper.scss';

const Stepper: TStepper = ({
  steps,
  activeIndex,
  className = '',
  onStepClick = () => () => {},
}) => {
  const stepId = useId();
  return (
    <div
      className={classNames({
        'stepper flex flex-justify-content-center': true,
        [className]: className.length > 0,
      })}
    >
      {steps.map(({ title, isCompleted }, index) => (
        <div
          key={stepId + index}
          className={classNames({
            'stepper__step flex flex-column flex-align-items-center': true,
            'stepper__step--active': activeIndex === index,
            'stepper__step--completed': isCompleted,
          })}
        >
          {index !== 0 && (
            <div
              className={classNames({
                'stepper__step-connecter': true,
                'stepper__step-connecter--active': activeIndex >= index,
              })}
            />
          )}
          <div
            className="stepper__step-circle flex flex-align-items-center flex-justify-content-center"
            onClick={onStepClick(index)}
          >
            <div className="stepper__step-inner-circle"></div>
          </div>

          <div className="stepper__step-title">{title}</div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
