import { useState } from 'react';
import Joi from 'joi';

import { useToast, useExtractServerError, useValidate } from 'hooks';
import { locales } from 'constants/index';

import { sendPasswordVerificationApi } from '../api';

import ResetPasswordUi from './ResetPassword.ui';

import { IHandleChange } from './ResetPassword.types';

const ResetPassword = () => {
  // * Hooks Init
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();
  const { errors, setErrors, validate } = useValidate();

  const validationSchema = {
    emailAddress: Joi.string()
      .email({
        tlds: false,
      })
      .required()
      .messages({
        'string.empty': locales.errors.required,
        'string.email': locales.errors.invalidEmail,
      }),
  };

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  //* Handlers
  const handleInputChange: IHandleChange = ({ target: { name, value } }) => {
    setEmailAddress(value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleInputBlur: IHandleChange = ({ target: { name, value } }) => {
    const trimmed = value.trim();
    setEmailAddress(trimmed);
    if (trimmed.length > 0)
      validate({
        data: {
          emailAddress: trimmed,
        },
        validationSchema: {
          emailAddress: validationSchema.emailAddress,
        },
      });
  };

  const handleSubmit = () => {
    const trimmed = emailAddress.trim();
    setEmailAddress(trimmed);
    const isFormValid = validate({ data: { emailAddress: trimmed }, validationSchema });

    if (!isFormValid) return;

    sendPasswordVerification();
  };

  //* API Actions
  const sendPasswordVerification = async () => {
    try {
      setIsLoading(true);

      await sendPasswordVerificationApi({
        emailAddress,
      });

      setIsConfirmationVisible(true);

      setIsLoading(false);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      setIsLoading(false);
    }
  };

  //* Wrappers
  const data = { emailAddress };

  const handlers = {
    handleInputChange,
    handleInputBlur,
    handleSubmit,
  };

  return (
    <ResetPasswordUi
      isLoading={isLoading}
      isConfirmationVisible={isConfirmationVisible}
      data={data}
      errors={errors}
      handlers={handlers}
    />
  );
};

export default ResetPassword;
