import React, { memo, useMemo } from "react";
import { components, ValueContainerProps } from "react-select";
import { IDropdownObject } from "common";
import SingleValue from "../SingleValue";

const ValueContainer = ({ children, ...props }: ValueContainerProps<IDropdownObject, false>) => {
    let childrenToRender = useMemo(() => React.Children.toArray(children).filter(
        (child: any) => ['.1', '.$placeholder',].indexOf(child.key) >= 0,
    ), [children]);

    const Input = childrenToRender.find((child: any) => child.key === '.1') as React.ReactElement | undefined;

    return (
        <components.ValueContainer {...props} isMulti={false}>
            {childrenToRender}
            {!Input?.props.value && <SingleValue {...{children}}/>}
        </components.ValueContainer>
    );
};

export default memo(ValueContainer);