import classNames from 'classnames';

import { TRadioButton } from './RadioButton.types';

import './radio-button.scss';

const RadioButton: TRadioButton = ({
  id,
  name,
  label,
  error = '',
  isChecked,
  onChange,
  isDisabled,
  value,
  className = '',
}) => {
  const labelStyles = classNames({
    'radio-button__label': true,
    'radio-button__label--disabled': isDisabled,
  });

  const radioCheckStyles = classNames({
    'radio-button__check': true,
    'radio-button__check--error': !!error,
    'radio-button__check--disabled': isDisabled,
  });

  return (
    <div
      className={classNames({
        'radio-button flex align-items-center flex-column': true,
        [className]: className.length > 0,
      })}
    >
      <label htmlFor={id} className={labelStyles}>
        {label}

        <input
          id={id}
          type="radio"
          name={name}
          value={value}
          checked={isChecked}
          disabled={isDisabled}
          className="radio-button__input"
          onChange={onChange}
        />

        <span className={radioCheckStyles}></span>
      </label>

      {!!error && <div className="radio-button__error">{error}</div>}
    </div>
  );
};

export default RadioButton;
