import { archivesActionTypes } from './action-types';

import {
  TResetStateAction,
  TSetArchivesAction,
  TSetArchivesSelectedAction,
} from './store.types';

export const setArchives: TSetArchivesAction = (payload) => ({
  type: archivesActionTypes.SET_ARCHIVES,
  payload
});

export const setArchivesSelected: TSetArchivesSelectedAction = (payload) => ({
  type: archivesActionTypes.SET_ARCHIVES_SELECTED,
  payload
});

export const resetStateSettings: TResetStateAction = () => {
  return {
    type: archivesActionTypes.RESET,
  };
};
