import { Affix, Transition, Text, Checkbox, Button, Paper, Flex, Tooltip } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";

import { locales } from "constants/index";

import type { ExportAffixProps } from "./types";
import { memo } from "react";

const ExportAffixComponent = ({
    mounted,
    checked,
    indeterminate,
    selectedCandidatesLength,
    onChange,
    onClick,
    isLoading,
}: ExportAffixProps) => {
    return (
        <Affix position={{ bottom: 20, left: "50%" }} style={{ transform: "translateX(-50%)" }}>
            <Transition transition="slide-up" mounted={mounted}>
                {(transitionStyles) => (
                    <Paper bd="1px solid" p="sm" radius="md" style={transitionStyles}>
                        <Flex gap="sm" align="center">
                            <Checkbox checked={checked} indeterminate={indeterminate} onChange={onChange} />

                            <Text size="lg" span>
                                <Text size="lg" fw={700} span>
                                    {`${selectedCandidatesLength} ${
                                        selectedCandidatesLength > 1
                                            ? locales.student.someProfiles.toLowerCase()
                                            : locales.student.aProfile.toLowerCase()
                                    } `}
                                </Text>
                                {locales.student.selected.toLowerCase()}
                            </Text>

                            <IconArrowRight />

                            <Text size="lg" td="underline" fw={700}>
                                {locales.student.export}
                            </Text>

                            <Tooltip disabled={!(selectedCandidatesLength > 10)} label={locales.student.exportError}>
                                <Button
                                    disabled={selectedCandidatesLength > 10}
                                    data-disabled={selectedCandidatesLength > 10}
                                    loading={isLoading}
                                    variant="transparent"
                                    size="compact-md"
                                    onClick={onClick}
                                >
                                    {locales.student.zipPdf}
                                </Button>
                            </Tooltip>
                        </Flex>
                    </Paper>
                )}
            </Transition>
        </Affix>
    );
};

export const ExportAffix = memo(ExportAffixComponent);
