import { memo } from "react";

import { Modal, Button, RichTextEditor, TextInput, FormControl } from 'components';

import { locales } from 'constants/index';

import { ValidateEmailModalEnums } from "./ValidateEmailModal.enums";
import { TValidateEmailModalUI } from './ValidateEmailModal.types';

import styles from './vem.module.scss';


const ValidateEmailModalUI: TValidateEmailModalUI = ({
    values,
    data,
    handlers,
    errors,
    reactQuillRef
}) => {
    return (<>
        <Modal
            className={styles['vem__modal']}
            bodyClassName={styles["vem"]}
            title={locales.modals.validateEmail.title}
            onClose={handlers.handleClose}
            actionClassName={styles["vem__actionGroup"]}
            actionButtons={
                <Button
                    variant="secondary"
                    onClick={handlers.handleSubmitClick}
                >
                    {locales.modals.validateEmail.submit}
                </Button>
            }
        >
            <FormControl
                label={locales.communication.object}
                className={styles.vem__fc}
                withRef
            >
                <TextInput
                    id={ValidateEmailModalEnums.subject}
                    name={ValidateEmailModalEnums.subject}
                    value={values[ValidateEmailModalEnums.subject]}
                    // isError={!!errors[SettingsCommunicationEnum.subject]}
                    onChange={handlers.handleTextInputChange}
                    onBlur={handlers.handleTextInputBlur}
                />
            </FormControl>

            <RichTextEditor
                ref={reactQuillRef}
                id='mbout-aboutUs'
                className={styles.vem__richText}
                value={values[ValidateEmailModalEnums.body] ?? ''}
                placeholder={locales.settings.customWebsite.presentations.aboutUs}
                onChange={handlers.handleRichTextChange}
                readOnly={false}
            />
        </Modal>
    </>);
};

export default memo(ValidateEmailModalUI);
