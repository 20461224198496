import { RadioButton } from 'components';

import { TPaymentPlan } from './PaymentPlan.types';

import './payment-plan.scss';
import classNames from 'classnames';

const PaymentPlan: TPaymentPlan = ({
  isSelected = false,
  isRecommended = false,
  name,
  price,
  currency = '€',
  type,
  numberOfOffers,
  onClick,
}) => {
  return (
    <div
      role="presentation"
      className="payment-plan flex flex-column flex-align-items-center"
      onClick={onClick}
    >
      <RadioButton
        isChecked={isSelected}
        className="payment-plan__radio-button"
        onChange={() => {}}
      />

      <div
        className={classNames({
          'payment-plan__name': true,
          'payment-plan__name--recommended': isRecommended,
        })}
      >
        {name}
      </div>

      <div
        className={classNames({
          'payment-plan__price flex': true,
          'payment-plan__price--recommended': isRecommended,
        })}
      >
        {`${price}${currency}`}
        <span className="payment-plan__type">/{type}*</span>
      </div>

      <div className="payment-plan__number-of-offers">
        {numberOfOffers} offres
      </div>
    </div>
  );
};

export default PaymentPlan;
