import { locales } from "../locales";

export const routes = {
  main: "/",
  settings: {
    main: "/settings",
    myAccount: "my-account",
    customizeWebsite: "customize-website",
    archivedOffers: "archived-offers",
    communication: "communication",
    notifications: "notifications",
  },
  authentication: {
    login: "/login",
    register: {
      main: "/register",
      entityType: "entity-type",
      generalInfo: "general-info",
      personalInfo: "personal-info",
      userInfo: "user-info",
      confirmation: "confirmation",
    },
    forgotPassword: "/forgot-password",
    changePassword: "/change-password",
    guest: "guest",
    guestId: ":id",
    logout: "/logout",
  },
  offers: {
    main: "/offers",
    listing: "listing",
    drafts: "drafts",
    details: ":id",
    create: {
      main: "create",
      generalInfo: "general-information",
      skills: "skills",
      additionalInfo: "additional-information",
      plans: "plans",
      checkout: "checkout",
    },
  },
  listing: {
    main: "/listing",
    details: ":id",
  },
  CVTheque: {
    main: "/cv-theque",
  },
  classrooms: {
    main: '/classrooms',
  },
  students: {
    main: "/students",
    offer: "offer",
    profile: "offer/:offerId/:id",
  },
};

export const routePaths = {
  students: {
    pipe: "/students/offer/:offerId",
    offerPipe: "/students/offer/:offerId",
    profile: "/students/offer/:offerId/:id",
  },
}

export const breadcrumbRoutes = [
  {
    path: "/settings",
    name: locales.breadcrumbs.settings.settings,
  },
  {
    path: "/settings/my-account",
    name: locales.breadcrumbs.settings.myAccount,
  },
  {
    path: "/settings/customize-website",
    name: locales.breadcrumbs.settings.customizeWebsite,
  },
  {
    path: "/settings/archived-offers",
    name: locales.breadcrumbs.settings.archivedOffers,
  },
  {
    path: "/settings/communication",
    name: locales.breadcrumbs.settings.communication,
  },
  {
    path: "/settings/notifications",
    name: locales.breadcrumbs.settings.notifications,
  },
  {
    path: "/offers",
    name: locales.breadcrumbs.offers.offers,
  },
  {
    path: "/offers/listing",
    name: locales.breadcrumbs.dashboard.dashboard,
    isRoot: true,
  },
  {
    path: "/offers/drafts",
    name: locales.breadcrumbs.dashboard.draft,
  },
  {
    path: "/offers/:id",
    name: locales.breadcrumbs.offers.employment,
  },
  {
    path: "/offers/create",
    name: locales.breadcrumbs.createOffer.createOffer,
  },
  {
    path: "/offers/create/general-information",
    name: locales.breadcrumbs.createOffer.generalInfo,
  },
  {
    path: "/offers/create/skills",
    name: locales.breadcrumbs.createOffer.wantedSkills,
  },
  {
    path: "/offers/create/:id/general-information",
    name: locales.breadcrumbs.createOffer.generalInfo,
  },
  {
    path: "/offers/create/:id/skills",
    name: locales.breadcrumbs.createOffer.wantedSkills,
  },
  {
    path: "/offers/create/additional-information",
    name: locales.breadcrumbs.createOffer.createOffer,
  },
  {
    path: "/offers/create/plans",
    name: locales.breadcrumbs.createOffer.createOffer,
  },
  {
    path: "/offers/create/checkout",
    name: locales.breadcrumbs.createOffer.createOffer,
  },
  {
    path: "/listing",
    name: locales.breadcrumbs.offers.offers
  },
  {
    path: "/listing/:id",
    name: locales.breadcrumbs.offers.offersDesc
  },
  {
    path: '/classrooms',
    name: locales.breadcrumbs.candidateFollowUp.classroom
  },
  {
    path: "/cv-theque",
    name: locales.breadcrumbs.studentFollowUp.CVTheque,
  },
  {
    path: "/students",
    name: locales.breadcrumbs.studentFollowUp.studentFollowUp,
  },
  {
    path: "/students/offer",
    name: locales.breadcrumbs.studentFollowUp.pipe,
  },
  {
    path: "/students/offer/:offerId/:id",
    name: locales.breadcrumbs.studentFollowUp.studentProfile,
  },
];
