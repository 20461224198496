import { memo } from "react";

import { Button, RichTextEditor } from "components";

import type { TCommentsUI } from "./Comments.type";

import styles from './comments.module.scss';
import { locales } from "../../../../constants";
import dayjs from "dayjs";

const CommentsUI: TCommentsUI = ({
    data: {
        isEditing,
        canSubmit,
        notes,
        edited,
    },
    handlers,
}) => {
    console.log(edited)
    return <div className={styles.comments}>
        {isEditing && <div className={styles.comments__editorContainer}>
            <RichTextEditor
                id={'selector'}
                value={edited?.description ?? ''}
                onChange={handlers.handleRichTextChange}
                readOnly={false}
                notRounded
            />

            <div className={styles.comments__editorActionGroup}>
                <Button variant='secondary' onClick={handlers.handleCancelNoteClick}>
                    {locales.general.cancel}
                </Button>
                <Button
                    variant='primary'
                    isDisabled={!canSubmit}
                    onClick={handlers.handleSubmitNoteClick}
                >
                    {locales.general.submit}
                </Button>
            </div>
        </div>}

        <div className={styles.comments__listContainer}>
            {/*{data.notes.map((value, index) =>*/}
            {/*    <div key={index} dangerouslySetInnerHTML={{ __html: value.description }} />*/}
            {/*)}*/}


            {notes?.map(({ _id: noteId, adminInfo, description, dateCreated,}: any) => (
                <div key={noteId} className={styles.reportContainer}>
                    <div className={styles.reportHeading}>
                        {locales.student.interviewConductor}&nbsp;
                        <span className={styles.reportHeadingBold}>
                            {`${adminInfo?.name?.firstname} ${adminInfo?.name?.lastname} le:`} {dayjs(dateCreated).locale('fr').format('dddd DD MMMM YYYY')}
                        </span>
                    </div>

                    <div
                        className={styles.reportBody}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </div>
            ))}
        </div>
    </div>;
};

export default memo(CommentsUI);
