import { TListingAction, listingActionTypes, IListingGlobalState } from '.';
import {classroomsActionTypes} from "../../Classrooms/store";

const initialState: IListingGlobalState = {
  list: [],
  gridView: false,
  offersFlick: true,
};

export const listingReducer = (
  state: IListingGlobalState = initialState,
  action: TListingAction
) => {
  switch (action.type) {
    case listingActionTypes.SET_OFFERS:
      return {
        ...state,
        list: action.offers,
      };

    case listingActionTypes.SET_GRID_VIEW:
      return {
        ...state,
        gridView: action.gridView,
      };

    case listingActionTypes.SET_OFFERS_FLICK:
      return {
        ...state,
        offersFlick: action.offersFlick,
      };

    case listingActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};
