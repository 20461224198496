import {memo, useMemo} from 'react';

import { TTableProfile } from './TableProfile.types';

import 'components/Table2/table.scss';
import './table-profile.scss';

const TableProfile: TTableProfile = ({
    profile
}) => {
    const initials = useMemo(() => {
        let initials = '';
        initials += profile.firstname && profile.firstname[0] ? profile.firstname[0] : '.';
        initials += profile.lastname && profile.lastname[0] ? profile.lastname[0] : '.';
        return initials;
    }, [profile.firstname, profile.lastname])

    return (
        <div className='td__action table-profile'>
            <div className='table-profile__imageContainer'>
                <div className='table-profile__image'>
                    {initials}
                </div>
            </div>

            <div className='table-profile__dataContainer'>
                {(profile.firstname || profile.lastname) && <div className='table-profile__text name'>
                    {profile.firstname} {profile.lastname}
                </div>}
                {profile.email && <div className='table-profile__text email'>
                    {profile.email}
                </div>}
                {profile.phone_number && <div className='table-profile__text phone'>
                    {profile.phone_number}
                </div>}
            </div>
        </div>
    );
};

export default memo(TableProfile);