import TableInfo from "./TableInfo";
import TableBadge from "./TableBadge";
import TableCheckbox from "./TableCheckbox";
import TableLink from "./TableLink";
import TableResume from "./TableResume";
import TableProfile from "./TableProfile";

function TableExt () {}

TableExt.Info = TableInfo;
TableExt.Badge = TableBadge;
TableExt.Checkbox = TableCheckbox;
TableExt.Link = TableLink;
TableExt.Resume = TableResume;
TableExt.Profile = TableProfile;

export default TableExt;