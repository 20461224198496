import { FC, ReactNode } from 'react';

enum TooltipPositionEnum {
  top = "top",
  topStart = "top-start",
  topEnd = "top-end",
  right = "right",
  rightStart = "right-start",
  rightEnd = "right-end",
  bottom = "bottom",
  bottomStart = "bottom-start",
  bottomEnd = "bottom-end",
  left = "left",
  leftStart = "left-start",
  leftEnd = "left-end",
}

interface IProps {
  children: ReactNode;
  className?: string;
  title?: string;
  content?: string;
  maxWidthPX?: number;
  position?: TooltipPositionEnum;
  show?: boolean;
}

type TTooltipWrapper = FC<IProps>;

export { TooltipPositionEnum };
export type { TTooltipWrapper };
