import React, { memo } from "react";

import { locales } from "constants/index";

import { AddToYPareoModal, Button } from "components";

import { ModalsTypesEnum } from "components/Modals/Modals.enums";

import type { TActionsUI } from "./Actions.type";

import './actions.scss';

const ActionsUI: TActionsUI = ({
    data: {
        modals,
    },
    handlers,
}) => {

    return (
        <React.Fragment>
            <div className='actions'>
                <Button
                    isDisabled
                    variant='light'
                    className="actions__action"
                >
                    <div className="actions__actionContent">
                        <div className="actions__actionIcon classroom" />
                        <div className="actions__actionText">
                            {locales.student.actions.addClassroom}
                        </div>
                    </div>

                    <div className="actions__actionIcon arrow" />
                </Button>

                <Button
                    isDisabled
                    variant='light'
                    className="actions__action"
                >
                    <div className="actions__actionContent">
                        <div className="actions__actionIcon send" />
                        <div className="actions__actionText">
                            {locales.student.actions.transferProfile}
                        </div>
                    </div>

                    <div className="actions__actionIcon arrow" />
                </Button>

                <Button
                    variant='light'
                    className="actions__action"
                    onClick={handlers.handleAddToYPareo}
                >
                    <div className="actions__actionContent">
                        <div className="actions__actionIcon folder-add" />
                        <div className="actions__actionText">
                            {locales.student.actions.addToYPareo}
                        </div>
                    </div>

                    <div className="actions__actionIcon arrow" />
                </Button>
            </div>


            {modals[ModalsTypesEnum.ADD_TO_YPAREO].isOpen && <AddToYPareoModal onSubmit={handlers.handleSubmitAddToYPareo}/>}
        </React.Fragment>
    );
};

export default memo(ActionsUI);
