import { baseApi } from "utils";

import type { TExportToZipAPI, TFetchMatchingSearchAPI, TFetchOffersAPI } from "./api.types";


export const fetchMatchingSearchAPI: TFetchMatchingSearchAPI = ( params ) => {
    return baseApi ().get ( '/matching/search', { params } );
};

export const fetchOffersAPI: TFetchOffersAPI = () => {
    return baseApi ().get ( '/offers/private' );
};

export const exportToZipAPI: TExportToZipAPI = ( payload ) => {
    return baseApi ().post ( '/matching/export', payload, { responseType: "blob" } );
};