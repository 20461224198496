import { Modal, Button, FormControl, TextArea, Loader } from 'components';

import { locales } from 'constants/index';

import { TContactSupportModalUi } from './ContactSupportModal.types';

import './contact-support-modal.scss';

const ContactSupportModal: TContactSupportModalUi = ({
  isLoading,
  message,
  handlers: { handleSubmit, handleClose, handleMessageChange },
}) => {
  return (
    <Modal
      title={locales.general.contactSupport}
      className="contact-support-modal"
      onClose={handleClose}
      actionButtons={
        <span className="flex flex-justify-content-center">
          <Button
            variant="primary"
            onClick={handleSubmit}
            isDisabled={!message}
          >
            {locales.general.sendMessage}
          </Button>
        </span>
      }
    >
      {isLoading && <Loader />}

      <div className="contact-support-modal__image" />

      <div className="contact-support-modal__description">
        {locales.general.youHaveAProblem}
      </div>

      <FormControl label={locales.general.message} withRef>
        <TextArea value={message} onChange={handleMessageChange} />
      </FormControl>
    </Modal>
  );
};

export default ContactSupportModal;
