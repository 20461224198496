import Joi from "joi";
import { memo, useCallback, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch, batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useValidate } from 'hooks';
import { locales, routes } from 'constants/index';
import { resetRegister, setRegister } from 'modules/Authentication/store/actions';

import EntityTypeUi from './EntityType.ui';

import { THandleRadioChange, IStoreState } from './EntityType.types';
import { RegisterEnum, TRegister } from "../Register.types";

const validationSchema = {
  entityType: Joi.object({
      school: Joi.string().allow(""),
      company: Joi.string().allow(""),
    })
    .xor('school', 'company')
    .messages({
      'object.missing': locales.errors.missing,
    }),
};


const EntityType = () => {
  // * Hooks Init
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { errors, setErrors, validate } = useValidate();

  //* Redux State
  const register = useSelector(({ auth: { register } }: IStoreState) => register, shallowEqual);

  //* Handlers
  const handleRadioChange: THandleRadioChange = useCallback((name) => () => {
    let object: Partial<TRegister> = {};

    if (name === RegisterEnum.school) {
      object = {
        school: '',
        shortName: '',
      };
    } else {
      object = {
        company: '',
        phoneNumber: '',
        siretNum: '',
        webUrl: '',
      };
    }

    batch(() => {
      dispatch(resetRegister());
      dispatch(setRegister({ ...object }))
    });
    setErrors((prevErrors) => ({ ...prevErrors, entityType: '' }));
  }, [dispatch]);

  const handleSubmit = useCallback(() => {
    const entityType = {
      school: register.school,
      company: register.company,
    }
    const isValid = validate({
      data: { entityType } as any,
      validationSchema,
    })
    if (!isValid) return;

    const nextURL =`${routes.authentication.register.main}/${routes.authentication.register.generalInfo}`;
    navigate(nextURL);
  }, [
    validate,
    register
  ]);

  //* Wrappers
  const data = useMemo(() => ({
    register,
  }), [
    register,
  ]);

  const handlers = useMemo(() => ({
    handleRadioChange,
    handleSubmit,
  }), [
    handleRadioChange,
    handleSubmit,
  ]);

  return <EntityTypeUi
    data={data}
    errors={errors}
    handlers={handlers}
  />;
};

export default memo(EntityType);
