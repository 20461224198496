import classNames from "classnames";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import 'dayjs/locale/fr';
import { Indicator } from '@mantine/core';

import { Filter, Dropdown, Grid, Table2 } from 'components';
import type { IConfigEntity, IDropdownObject } from "common";

import { enums, locales, routes } from 'constants/index';
import { columns, columnsWithoutStatus } from './tableColumns';

import LocationSelect from "./LocationSelect";

import { OfferStateEnum } from "./Listing.enum";
import type { TListingUI } from './Listing.types';

import './listing.scss';

const ListingUI: TListingUI = ( {
    gridView,
    options,
    data: {
        offers,
        filterOffers,
        config,
        userInfo,
        offersFlick,
    },
    handlers: {
        handleSwitchToList,
        handleSwitchToCard,
        handleFilterFilterChange,
        handleFilterFilterReset,
        handleRemoveSingleDomaine,
        handletoggleOffersFlick,
        handleOfferStateFiltering,
    },
} ) => {
    dayjs.extend( relativeTime );

    return (
        <div className="listing">

            <div className="page-title">{ locales.offers.title }</div>

            <div className="listing__filter">
                <div className="listing__filter__setters">
                    <div className="listing__filter__dropdowns">
                        <div className="listing__filter__item">
                            <Dropdown
                                placeholder={ "Domaine d'activité" }
                                isRounded
                                isMulti
                                onChange={ ( value ) => {
                                    handleFilterFilterChange( {
                                        ...filterOffers,
                                        domaines: value as unknown as IConfigEntity[],
                                    } )
                                } }
                                options={ config?.domaines as unknown as IDropdownObject[] || [] }
                                value={ filterOffers.domaines as unknown as IDropdownObject[] }
                            />
                        </div>

                        <div className="listing__filter__item">
                            <Dropdown
                                placeholder={ "Type de contrat" }
                                isRounded
                                isMulti
                                onChange={ ( value ) =>
                                    handleFilterFilterChange( {
                                        ...filterOffers,
                                        contracts: value as unknown as IConfigEntity[],
                                    } )
                                }
                                options={ config?.contracts as unknown as IDropdownObject[] || [] }
                                value={ filterOffers.contracts as unknown as IDropdownObject[] }
                            />
                        </div>

                        <div className="listing__filter__item">
                            <LocationSelect
                                defaultOptions={ config?.workPlaces || [] }
                                placeholder={ locales.filter.location }
                                selectedValue={ filterOffers.workPlaces }
                                handleChange={ ( value ) =>
                                    handleFilterFilterChange( {
                                        ...filterOffers,
                                        workPlaces: value
                                    } )
                                }
                                filters={ filterOffers }
                                setFilters={ handleFilterFilterChange }
                                isItemSearch={ false }
                            />
                        </div>


                        { offersFlick && <div className="listing__filter__item">
                            <Dropdown
                                placeholder={ "Etat" }
                                isRounded
                                onChange={ handleOfferStateFiltering }
                                options={ options.offerStates }
                                defaultValue={ options.offerStates.find(
                                    option => option.value === OfferStateEnum.APPROVED ) }
                                value={ filterOffers.offerState }
                            />
                        </div> }


                        <div
                            className="listing__filter__reset flex flex-justify-content-center flex-align-items-center"
                            onClick={ handleFilterFilterReset }
                        >
                            Réinitialiser
                        </div>

                    </div>
                </div>

                <div className="listing__filter__extenders">
                    { filterOffers && filterOffers.domaines && (
                        filterOffers.domaines.map( ( domaine, index ) =>
                            <div key={ index } className="listing__filter__extendees">
                                { domaine.label }
                                <div
                                    className="listing__filter__extendees__cross"
                                    onClick={ () => handleRemoveSingleDomaine( domaine ) }
                                />
                            </div>
                        )
                    ) }
                </div>
            </div>

            <div className="listing__table">
                <div className="listing__table__tabs">
                    <div className="listing__table__tabGroup">
                        <div onClick={ handletoggleOffersFlick }
                             className={ classNames( {
                                 listing__table__tab: true,
                                 active: offersFlick,
                             } ) }
                        >
                            { locales.offers.myOffers }
                        </div>
                        <div onClick={ handletoggleOffersFlick }
                             className={ classNames( {
                                 listing__table__tab: true,
                                 active: !offersFlick,
                             } ) }
                        >
                            { locales.offers.bloom }
                        </div>
                    </div>

                    <div className="listing__filter__view flex  flex-align-items-center">
                        <div
                            onClick={ handleSwitchToList }
                            className={ classNames( {
                                'listing__filter__view__icon listing__filter__view__icon__list': true,
                                'icon__list': !gridView,
                            } ) }
                        />
                        <div
                            onClick={ handleSwitchToCard }
                            className={ classNames( {
                                'listing__filter__view__icon listing__filter__view__icon__card': true,
                                'icon__card': gridView,
                            } ) }
                        />
                    </div>
                </div>

                <div className='listing__filterContainer'>
                    <Filter moduleName={ enums.moduleNames.listing }>
                        { gridView
                            ? <Grid detailRoute={ routes.listing.main } data={ offers }/>
                            : <Table2 data={ offers } columns={
                                offersFlick
                                    ? columns
                                    : columnsWithoutStatus
                            }/>
                        }
                    </Filter>
                </div>
            </div>
        </div>
    );
};

export default ListingUI;
