import { useEffect, useMemo, useCallback, memo } from 'react';
import { useDispatch, useSelector, shallowEqual, batch } from 'react-redux';

import { useToast, useExtractServerError } from 'hooks';
import { enums } from 'constants/index';

import { setFilterPagination } from "components/Filter/store/actions";
import { setIsLoading } from 'modules/App/store/actions';
import { setArchives, setArchivesSelected } from "./store/actions";

import { fetchOffersApi } from "../api";

import { TArchivedOffersFC, IStoreState } from './ArchivedOffers.types';

import ArchivedOffersUi from './ArchivedOffers.ui';


const ArchivedOffersFC: TArchivedOffersFC = () => {
	//* Hook Init
	const dispatch = useDispatch();
	const toast = useToast();
	const { extractErrorMessage } = useExtractServerError();


	//* Redux State
	const moduleName = enums.moduleNames.archives;
	const filter = useSelector( ( { filter: { [moduleName]: filter } }: IStoreState ) => filter, shallowEqual );
	const archives = useSelector( ( { archives }: IStoreState ) => archives, shallowEqual );


	//* Side Effects
	const fetchOffers = useCallback( async () => {
		try {
			dispatch( setIsLoading( true ) );

			const { limit, page } = filter.pagination;

			const {
				data: { data: offers, total },
			} = await fetchOffersApi( { limit, page, deleted: true } );

			const totalPages = limit ? Math.ceil( total / limit ) : 1;

			batch( () => {
				dispatch( setArchives( offers ) );
				dispatch(
					setFilterPagination( {
						moduleName: moduleName,
						payload: { limit, page, totalPages },
					} )
				);
				dispatch( setIsLoading( false ) );
			} );
		} catch ( error ) {
			const errorMessage = extractErrorMessage( error );
			toast( { type: 'error', message: errorMessage } );
			dispatch( setIsLoading( false ) );
		}
	}, [ dispatch, filter ] );


	//* Effects
	useEffect( () => {
		fetchOffers();
	}, [ filter.pagination.page ] );


	//* Wrappers
	const data = useMemo( () => ({
		offers: archives.list,
		selected: archives.selected,
	}), [
		archives,
	] );

	const handlers = useMemo( () => ({}), [] );

	return <ArchivedOffersUi data={data} handlers={handlers}/>;
};

export const ArchivedOffers = memo( ArchivedOffersFC );
