import { Link } from 'react-router-dom';

import { Modal } from 'components';

import { locales, routes } from 'constants/index';

import { TPaymentSuccessModal } from './PaymentSuccessModal.types';

import './payment-success-modal.scss';

const PaymentSuccessModal: TPaymentSuccessModal = ({ onClose }) => {
  return (
    <Modal
      title={locales.general.paymentSuccess}
      className="payment-success-modal"
      onClose={onClose}
      actionButtons={
        <span className="flex flex-justify-content-center">
          <Link
            to={`${routes.offers.main}/${routes.offers.listing}`}
            className="payment-success-modal__link"
          >
            {locales.general.returnToHomepage}
          </Link>
        </span>
      }
    >
      <div className="payment-success-modal__image" />

      <div className="payment-success-modal__description">
        {locales.general.paymentSuccessDescription}
      </div>
    </Modal>
  );
};

export default PaymentSuccessModal;
