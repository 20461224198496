import { useEffect } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { clearSession } from 'utils';
import { useToast, useExtractServerError } from 'hooks';
import { routes } from 'constants/index';

import { resetStateAuth, unAuthenticateUserAction } from '../store/actions';

import { resetStateApp } from "modules/App/store/actions";
import { resetStateClassrooms } from "modules/Classrooms/store";
import { resetStateListing } from "modules/Listing";
import { resetStateOffers } from "modules/Offers/store/actions";
import { resetStateSettings } from "modules/Settings/store/actions";
import { resetStateStudents } from "modules/Students/store/actions";

import { resetStateFilter } from "components/Filter/store/actions";
import { resetStateSidebar } from "components/Sidebar/store/actions";
import { resetStateModal } from "components/Modals/store/actions";

import { logoutApi } from '../api';

const Logout = () => {
  // * Hooks Init
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();

  const clearUserSession = async () => {
    try {
      await logoutApi();

      clearSession();

      batch(async () => {
        dispatch(unAuthenticateUserAction());


        // TODO: group this in the main reducer
        // Module redux
        dispatch(resetStateApp());
        dispatch(resetStateAuth());
        dispatch(resetStateClassrooms());
        dispatch(resetStateListing());
        dispatch(resetStateOffers());
        dispatch(resetStateSettings());
        dispatch(resetStateStudents());

        // Component redux
        dispatch(resetStateFilter());
        dispatch(resetStateModal());
        dispatch(resetStateSidebar());
      });

      navigate(routes.authentication.login);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
    }
  };

  useEffect(() => {
    clearUserSession();
  }, []);

  return null;
};

export default Logout;
