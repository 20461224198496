import { FC, MouseEventHandler } from 'react';

import { IAttachment } from "common";
import { DropzoneState } from "react-dropzone";

//* Parent
enum SourceTypeEnum {
  attachements = 'attachements',
  files = 'files',
}

interface ISourceData {
  sourceType: SourceTypeEnum;
  src: string;
}

interface IInheritedProps {
  id?: string;
  className?: string;
  isMultiple?: boolean;
  isDisabled?: boolean;
}

type THandleDeleteFile = (type: SourceTypeEnum, index: number) => MouseEventHandler<HTMLDivElement>
type THandleUploadFile = (files: File[]) => void;

type TDropzoneState = Pick<DropzoneState, 'isDragActive' | 'getRootProps' | 'getInputProps'>

export {
  SourceTypeEnum,
};

export type {
  ISourceData,
  THandleDeleteFile,
  THandleUploadFile,
  TDropzoneState,
};

//* FC
interface IProps extends IInheritedProps {
  files?: File[];
  attachments?: IAttachment[];

  maxSize?: number;
  minSize?: number;
  maxFiles?: number;
  fileTypes?: any;
  className?: string;
  description?: string;
  onUploadFile: (file: File[]) => void;
  onDeleteFile: (sourceType: SourceTypeEnum, index: number) => void;
}

type TLogoPicker = FC<IProps>;

export type { TLogoPicker };

//* UI
interface IPropsUI extends IInheritedProps {
  dropzoneState: TDropzoneState;
  data: {
    SRCs: ISourceData[];
  }
  handlers: {
    handleDeleteFile: THandleDeleteFile;
  }
  error: string;
}

type TLogoPickerUI = FC<IPropsUI>;

export type { TLogoPickerUI };
