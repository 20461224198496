import { SET_SIDEBAR_STATE, sidebarActionTypes } from './action-types';

import { TSetSidebarState, TResetStateAction } from './store.types';

export const setSidebarState: TSetSidebarState = (payload) => ({
    type: SET_SIDEBAR_STATE,
    payload,
});

export const resetStateSidebar: TResetStateAction = () => ({
    type: sidebarActionTypes.RESET
});
