import { memo } from "react";

import AuthenticationLayout from 'modules/Authentication/AuthenticationLayout';

import { TextInput, FormControl, Button } from 'components';

import { locales } from 'constants/index';
import { formSubmit } from 'utils';
import { Images } from 'assets';

import { TCompanyInfoUi } from './CompanyInfo.types';
import { RegisterEnum } from "../Register.types";

import '../register.scss';

const CompanyInfoUi: TCompanyInfoUi = ({
  data: { register },
  errors,
  handlers,
}) => {
  return (
    <>
      <AuthenticationLayout mainImage={Images.Company}>
        <div className="register flex flex-justify-content-center flex-align-items-center">
          <div className="register__content flex flex-column flex-align-items-center">
            <div className="register__title">
              {locales.registration.createAccount}
            </div>

            <div className="register__description">
              {locales.registration.additionalInfo}
            </div>

            <div className="mobile-icon">
              <div className="mobile-icon__svg mobile-icon__svg__company" />
            </div>

            <form
              onSubmit={formSubmit}
              className="register__form register__form--school"
            >
              <div className="register__sub-title">
                {locales.registration.schoolInfo}...
              </div>

              {RegisterEnum.school in register && <FormControl
                id={RegisterEnum.school}
                label={locales.general.school}
                error={errors[RegisterEnum.school]}
                className="register__field"
                withRef
              >
                <TextInput
                  id={RegisterEnum.school}
                  name={RegisterEnum.school}
                  value={register[RegisterEnum.school]}
                  isError={!!errors[RegisterEnum.school]}
                  onChange={handlers.handleInputChange}
                  onBlur={handlers.handleInputBlur}
                />
              </FormControl>}

              {RegisterEnum.company in register && <FormControl
                id={RegisterEnum.company}
                label={locales.general.company}
                error={errors[RegisterEnum.company]}
                className="register__field"
                withRef
              >
                <TextInput
                  id={RegisterEnum.company}
                  name={RegisterEnum.company}
                  value={register[RegisterEnum.company]}
                  isError={!!errors[RegisterEnum.company]}
                  onChange={handlers.handleInputChange}
                  onBlur={handlers.handleInputBlur}
                />
              </FormControl>}

              {RegisterEnum.shortName in register && <FormControl
                id={RegisterEnum.shortName}
                label={locales.registration.shortName}
                note={locales.registration.shortNameNote}
                error={errors[RegisterEnum.shortName]}
                className="register__field"
                withRef
              >
                <TextInput
                  id={RegisterEnum.shortName}
                  name={RegisterEnum.shortName}
                  value={register[RegisterEnum.shortName]}
                  isError={!!errors[RegisterEnum.shortName]}
                  onChange={handlers.handleInputChange}
                  onBlur={handlers.handleInputBlur}
                />
              </FormControl>}

              <FormControl
                id={RegisterEnum.city}
                label={locales.general.city}
                error={errors[RegisterEnum.city]}
                className="register__field"
                withRef
              >
                <TextInput
                  id={RegisterEnum.city}
                  name={RegisterEnum.city}
                  value={register[RegisterEnum.city]}
                  isError={!!errors[RegisterEnum.city]}
                  onChange={handlers.handleInputChange}
                  onBlur={handlers.handleInputBlur}
                />
              </FormControl>

              <FormControl
                id={RegisterEnum.postalCode}
                label={locales.registration.postalCode}
                error={errors[RegisterEnum.postalCode]}
                className="register__field"
                withRef
              >
                <TextInput
                  id={RegisterEnum.postalCode}
                  name={RegisterEnum.postalCode}
                  value={register[RegisterEnum.postalCode]}
                  isError={!!errors[RegisterEnum.postalCode]}
                  onChange={handlers.handleInputChange}
                  onBlur={handlers.handleInputBlur}
                />
              </FormControl>

              {RegisterEnum.webUrl in register && <FormControl
                id={RegisterEnum.webUrl}
                label={locales.registration.webUrl}
                error={errors[RegisterEnum.webUrl]}
                className="register__field"
                withRef
              >
                <TextInput
                  id={RegisterEnum.webUrl}
                  name={RegisterEnum.webUrl}
                  value={register[RegisterEnum.webUrl]}
                  isError={!!errors[RegisterEnum.webUrl]}
                  onChange={handlers.handleInputChange}
                  onBlur={handlers.handleInputBlur}
                />
              </FormControl>}

              {RegisterEnum.phoneNumber in register && <FormControl
                id={RegisterEnum.phoneNumber}
                label={locales.registration.phoneNumber}
                error={errors[RegisterEnum.phoneNumber]}
                className="register__field"
                withRef
              >
                <TextInput
                  id={RegisterEnum.phoneNumber}
                  name={RegisterEnum.phoneNumber}
                  value={register[RegisterEnum.phoneNumber]}
                  isError={!!errors[RegisterEnum.phoneNumber]}
                  onChange={handlers.handleInputChange}
                  onBlur={handlers.handleInputBlur}
                />
              </FormControl>}
            </form>

            <div className="register__submit">
              <Button
                variant="primary"
                onClick={handlers.handleSubmit}
              >
                {locales.registration.createAccount}
              </Button>
            </div>
          </div>
        </div>
      </AuthenticationLayout>
    </>
  );
};

export default memo(CompanyInfoUi);
