import {memo, useMemo} from "react";
import Classnames from 'classnames';

import { locales } from "constants/index";

import { TLogoPickerUI } from './LogoPicker.types';

import './logo-picker.scss';

const LogoPickerUI: TLogoPickerUI = ({
  id = '',
  className = '',
  isMultiple,
  dropzoneState,
  data: { SRCs },
  handlers,
  error,
}) => {
  const multipleMode = useMemo(() => (isMultiple || SRCs.length === 0),[isMultiple, SRCs ]);

  const ImageClassNames = Classnames({
    'logo-picker': true,
    'logo-picker__image': true,
    [className]: !!className,
  });

  return (
    <div id={id} className={ImageClassNames}
       {...(multipleMode && dropzoneState.getRootProps())}
    >
      {multipleMode && <input {...dropzoneState.getInputProps()} />}
      {SRCs.map((src, index) =>
        <div key={index} className='logo-picker__image__preview'>
          <img
            id="logo-preview"
            className='logo-picker__image__preview__image'
            alt="logo preview"
            src={src.src}
          />
          <div className="logo-picker__image__iconContainer trash" onClick={handlers.handleDeleteFile(src.sourceType, index)}>
            <div className="logo-picker__image__icon trash" />
          </div>
          <div className="logo-picker__image__iconContainer number">
            {index + 1}
          </div>
        </div>
      )}

      {multipleMode && <div className='logo-picker__image__preview upload__button flex flex-column'>
        <div className="logo-picker__image__iconContainer upload">
          <div className="logo-picker__image__icon upload"/>
        </div>
        <div className='logo-picker__image__preview upload__description'>
          {locales.general.addPhoto}
        </div>
      </div>}
    </div>
  );
};

export default memo(LogoPickerUI);
