import { memo } from "react";
import { useSelector } from "react-redux";

import { IStoreState } from "store";

import './thumb-loader.scss';
import classNames from "classnames";

export const ThumbLoader = () => {
  const loading = useSelector(({ app: { loading: { thumb } } }: IStoreState) => thumb);

  const className = classNames({
    "thumb-loader thumb-loader-bottom-right": true,
    "hide": !loading,
  });

  return (
    <div className={className}>
      <div className="thumb-loader__image"></div>
    </div>
  );
};

export const MemoizedThumbLoader = memo(ThumbLoader);
