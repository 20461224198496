import {setFilterPagination} from 'components/Filter/store/actions';

import {
  CLEAR_OFFER_FORM,
  SET_OFFER_FORM_ACTIVE_STEP,
  SET_OFFER_FORM_COMPLETED_STEP,
  SET_OFFER_FORM_STEP_VALUES,
  SET_OFFERS,
  SET_SKILL_CATEGORIES,
  SET_SKILLS,
  SET_SUBSCRIPTION_TYPES,
  SELECT_SUBSCRIPTION_TYPES,
  SET_IS_USER_SUBSCRIBED,
  SET_COUPON_CODE,
  SET_SUB_EDUCATION_LEVEL,
  offersActionTypes,
} from './action-types';

import {
  TClearOfferFormAction,
  TSetOfferFormActiveStepAction,
  TSetOfferFormCompletedStepAction,
  TSetOfferFormStepValuesAction,
  TSetOffersAction,
  TSetSkillCategoriesAction,
  TSetSkillsAction,
  TSetSubscriptionTypesAction,
  TSelectSubscriptionTypeAction,
  TSetIsUserSubscribedAction,
  TSetCouponCodeAction,
  TSetSubEducationLevelAction,
  TResetStateAction,
  TSetDraftAction,
  TSetDraftSelectedAction,
  TSetChosenCategoryAction,
} from './store.types';

export const setOffersAction: TSetOffersAction = (offers) => {
  return {
    type: SET_OFFERS,
    payload: offers,
  };
};

export const setOfferFormActiveStepAction: TSetOfferFormActiveStepAction = (
  activeStepIndex
) => {
  return {
    type: SET_OFFER_FORM_ACTIVE_STEP,
    payload: activeStepIndex,
  };
};

export const setOfferFormCompletedStepAction: TSetOfferFormCompletedStepAction =
  (activeStepIndex) => {
    return {
      type: SET_OFFER_FORM_COMPLETED_STEP,
      payload: activeStepIndex,
    };
  };

export const setOfferFormStepValuesAction: TSetOfferFormStepValuesAction = (
  formValues
) => {
  return {
    type: SET_OFFER_FORM_STEP_VALUES,
    payload: formValues,
  };
};

export const setChosenCategory: TSetChosenCategoryAction = (
  chosenCategory
) => {
  return {
    type: offersActionTypes.SET_CHOSEN_CATEGORY,
    payload: chosenCategory,
  };
};

export const setSkillCategoriesAction: TSetSkillCategoriesAction = (
  skillCategories
) => {
  return {
    type: SET_SKILL_CATEGORIES,
    payload: skillCategories,
  };
};

export const setSkillsAction: TSetSkillsAction = (
  skills
) => {
  return {
    type: SET_SKILLS,
    payload: skills,
  };
};

export const clearOfferFormAction: TClearOfferFormAction = () => {
  return {
    type: CLEAR_OFFER_FORM,
  };
};

export const setSubscriptionTypesAction: TSetSubscriptionTypesAction = (subscriptionTypes) => {
  return {
    type: SET_SUBSCRIPTION_TYPES,
    payload: subscriptionTypes,
  };
};

export const selectSubscriptionTypeAction: TSelectSubscriptionTypeAction = (subscriptionType) => {
  return {
    type: SELECT_SUBSCRIPTION_TYPES,
    payload: subscriptionType,
  };
};

export const setIsUserSubscribedAction: TSetIsUserSubscribedAction = (isUserSubscribed) => {
  return {
    type: SET_IS_USER_SUBSCRIBED,
    payload: isUserSubscribed,
  };
};

export const setCouponCodeAction: TSetCouponCodeAction = (couponCode) => {
  return {
    type: SET_COUPON_CODE,
    payload: couponCode,
  };
};

export const setSubDegreesAction: TSetSubEducationLevelAction = (subDegrees) => {
  return {
    type: SET_SUB_EDUCATION_LEVEL,
    payload: subDegrees,
  };
};

export const setDraft: TSetDraftAction = (payload) => {
  return {
    type: offersActionTypes.SET_DRAFT,
    payload,
  };
};

export const setDraftSelected: TSetDraftSelectedAction = (payload) => {
  return {
    type: offersActionTypes.SET_DRAFT_SELECTED,
    payload,
  };
};

export const resetStateOffers: TResetStateAction = () => {
  return {
    type: offersActionTypes.RESET,
  };
};


export { setFilterPagination };
