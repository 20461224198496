import type { IDropdownObject } from "common";

import { OfferStateEnum } from "./Listing.enum";


export const offerStates: IDropdownObject[] = [
    {
        value: OfferStateEnum.DRAFT,
        label: 'Brouillon',
    },
    {
        value: OfferStateEnum.DELETED,
        label: 'Archivé',
    },
    {
        value: OfferStateEnum.APPROVED,
        label: 'Publié',
    },
    {
        value: OfferStateEnum.WAITING,
        label: 'En attente',
    },
];