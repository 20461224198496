import { ChangeEvent } from "react";

interface IUser {
    _id: string;
    entityId: string;
    userType: string;
    roleId?: number;
    name: {
        firstname: string;
        lastname: string;
    };
    location?: {
        longitude?: string;
        latitude?: string;
    };
    info: {
        school: string;
        city: string;
        postalCode: number;
        role: string;
        profileUrl: string;
        betweenUrl?: string;
        work_fields: any[];
        short_name: string;
        lastUpdateDate: string;
        siren_number: string;
        siret_number: string;
    };
    contactInfo: {
        emailAddress: string;
        phoneNumber?: string;
        countryCode?: string;
    };
    verification?: {
        phoneVerified: boolean;
        emailVerified: boolean;
    };
    actions?: {
        blocked: boolean;
        approval: boolean;
    };
}

interface ICompanyInfo {
    actions: {
        approval: boolean;
        blocked: boolean;
    };
    contactInfo: {
        countryCode: null | string;
        emailAddress: string;
        phoneNumber: null | string;
    };
    customer_id: string;
    info: {
        city: string;
        company: string;
        lastUpdateDate: string;
        profileUrl: string;
        role: string;
        description: string;
    };
    location: {
        latitude: null | string;
        longitude: null | string;
    };
    name: {
        firstname: string;
        lastname: string;
    };
    verification: {
        emailVerified: boolean;
        phoneVerified: boolean;
    };
    _id: string;
}

interface ICommonOptionProperties {
    createdDate: string;
    _id: string;
    key: string;
    value: string;
}

interface IClass extends ICommonOptionProperties {
    userId: string;
}

interface IDegree extends ICommonOptionProperties {}

interface ITag extends ICommonOptionProperties {
    category: string;
    email: string;
}

interface IResume {
    awsId: string;
    classe: IClass;
    createdDate: string;
    degree: IDegree;
    _id: string;
    name: string;
    tags: ITag[];
    userId: string;
}

enum AttachmentsEnum {
    logo = "logo",
    publicity = "publicity",
}

interface IAttachment {
    _id: string;
    entityId: string;
    userId: string;
    awsId: string;
    name: string;
    type: AttachmentsEnum;
    createdDate: string;
    url: string;
}

interface IPagination {
    page: number;
    limit: number;
    totalPages: number;
}

interface IError {
    status: number;
    data: { message: string };
}

export type TComboboxItem<T = any> = {
    label: string | T[ keyof T];
    value: string | T[ keyof T];
    original: T;
};

interface IConfigEntity {
    key: string;
    label: string;
    value: string;
}

export type TLocationEntity = IConfigEntity & {
    lat: string;
    lng: string;
};

interface ISkillCategory {
    id: string;
    label: string;
    value: string;
}

interface ISkillEntity extends IConfigEntity {
    category: ISkillCategory;
    required?: boolean;
    bonus?: boolean;
}

interface ISkill extends Omit<IConfigEntity, "key"> {
    category: ISkillCategory;
    id: string;
    _id: string;
    createdDate: string;
    required?: boolean;
    bonus?: boolean;
    softSkills?: boolean;
}

interface ISubEducationLevel extends Omit<ISkillCategory, "label"> {
    _id: string;
    degreeId: string;
    id: string;
    key: string;
    value: string;
    createdDate: string;
}

interface IFlagEntity extends IConfigEntity {
    code: string;
    emoji: string;
    unicode: string;
}

interface IDropdownObject {
    label: string;
    value: string;
    [ key: string ]: any;
}

interface IEditListObject {
    id: string;
    title: string;
    badge?: string;
    description: {
        label?: string;
        value: string;
    }[];
    isDisabled?: boolean;
}

interface IMultiSelectDropdownOption {
    [key: string]: string;
}

interface IHandleChange {
    (event: InputEvent): void;
}

interface IHandleTextInoutChange {
    (event: ChangeEvent<HTMLInputElement>): void;
}

interface IHandleTextAreaChange {
    (event: TextAreaEvent): void;
}

interface IGuest {
    name: {
        firstname?: string | null;
        lastname?: string | null;
    };
    contactInfo: {
        emailAddress: string;
        phoneNumber?: string | null;
        countryCode?: string | null;
    };
    verification: {
        phoneVerified: boolean;
        emailVerified: boolean;
    };
    actions: {
        blocked: boolean;
        approval: boolean;
    };
    register_status: boolean;
    message_status: string;
    roleId: number;
    _id: string;
}

interface IConfig {
    checkout_url: string;
    contracts: IConfigEntity[];
    degrees: IConfigEntity[];
    domain: string;
    domaines: IConfigEntity[];
    flags: IFlagEntity[];
    languages: IConfigEntity[];
    months: IConfigEntity[];
    paces: IConfigEntity[];
    pipes: IConfigEntity[];
    ranges: IConfigEntity[];
    skills: ISkillEntity[];
    workPlaces: IConfigEntity[];
    statut_appel: IConfigEntity[];
    createdDate: string;
    emailExpiry: number;
    fileSize: number;
    maxFiles: number;
    otpAttempts: number;
    otpExpiry: number;
    filesType: string[];
}

interface IOffer {
    approved: boolean;
    candidate: {
        languages: string[];
        missions: string;
        profile: string;
        skills: {
            bonus: string[];
            important: string[];
            required: string[];
        };
    };
    company: {
        entityId: string;
        userId: string;
        logo: string;
        description: string;
        name: string;
    };
    created_date: string;
    deleted: boolean;
    draft: boolean;
    info: {
        comment: string;
        education_level: string[];
        keywords: string[];
        city: string;
        postal_code: string;
        pace: string[];
        title: string;
        type: string;
        start_date: string;
        work_fields: string[];
        work_places: string[];
        sub_education_level: string[];
        geoloc: {
            lat: number;
            lng: number;
        }
    };
    updated_date: string;
    userId: string;
    _id: string;
}

type TModuleName = "offers" | "drafts" | "listing" | "classrooms" | "selectCVs" | "archives";

type TOfferModulName = "offers" | "listing" | "archives";

type THandleDropdownChange = (value: IDropdownObject[] | IDropdownObject) => void;

type THandleMultiSelectDropdownChange = (
    selectedList: IMultiSelectDropdownOption[],
    selectedItem: IMultiSelectDropdownOption
) => void;

type InputEvent = ChangeEvent<HTMLInputElement>;

type TextAreaEvent = ChangeEvent<HTMLTextAreaElement>;

export { AttachmentsEnum };

export type {
    IHandleChange,
    IHandleTextInoutChange,
    IHandleTextAreaChange,
    IDropdownObject,
    IEditListObject,
    IPagination,
    THandleDropdownChange,
    IMultiSelectDropdownOption,
    THandleMultiSelectDropdownChange,
    TModuleName,
    TOfferModulName,
    IConfigEntity,
    IError,
    ISkillCategory,
    ISkillEntity,
    IFlagEntity,
    IAttachment,
    ISkill,
    ISubEducationLevel,
    IUser,
    IClass,
    IDegree,
    ITag,
    IResume,
    IConfig,
    IOffer,
    IGuest,
};
