import { memo } from "react";
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { locales } from 'constants/index';

import { TBackLink } from './BackLink.types';

import './back-link.scss';

const BackLink: TBackLink = ({ path, className = '', onClick = () => {} }) => {
  const classes = classNames({
    'back-link flex flex-inline flex-align-items-center': true,
    [className]: className.length > 0,
  });

  return (
    <Link to={path} className={classes} onClick={onClick}>
      <div className="offers-drafts__icon" />
      {locales.general.back}
    </Link>
  );
};

export default memo(BackLink);
