import { forwardRef, useState } from 'react';
import classNames from 'classnames';

import { TTextInput, IProps } from './TextInput.types';

import './text-input.scss';

const TextInput: TTextInput = ({
  id = '',
  name,
  type = 'text',
  value = '',
  placeholder = '',
  className = '',
  isDisabled = false,
  isAutoComplete = true,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  leftContent = '',
  rightContent = '',
  maxLength,
}, ref) => {
  const [isAutoFilled, setIsAutoFilled] = useState(false);

  const inputClassNames = classNames({
    'text-input': true,
    'text-input--left-content': leftContent,
    'text-input--right-content': rightContent,
    'text-input--has-value': isAutoFilled,
    'text-input--auto-filled': !!value,
    [className]: !!className,
  });

  const handleAutoFill = (e: any) => {
    setIsAutoFilled(e.animationName === 'onAutoFillStart');
  };

  return (
    <>
      {leftContent && (
          <div className="text-input__left-content flex">{leftContent}</div>
      )}

      <input
        ref={ref}
        id={id}
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        className={inputClassNames}
        disabled={isDisabled}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        // autoComplete="off"
        onAnimationStart={handleAutoFill}
        maxLength={maxLength}
        {...!isAutoComplete && {
          autoComplete: "one-time-code",
        }}
      />

      {rightContent && (
        <div className="text-input__right-content flex">{rightContent}</div>
      )}
    </>
  );
};

export default forwardRef<any, IProps>(TextInput);
