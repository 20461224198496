import { useState, useEffect, useCallback, FormEvent, useRef, ChangeEvent } from 'react';
import Joi from 'joi';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import ClassroomsUI from './Classrooms.ui';

import {
    useToast,
    useExtractServerError,
    useValidate,
} from 'hooks';

import { locales } from 'constants/index';

import {
    createClassesApi,
    removeClassApi,
    fetchResumesApi,
    createResumesApi,
    updateResumeTagApi,
    updateClassApi,
    generateResumeApi,
    fetchClassesApi,
    updateResumeNameApi,
    removeTagApi,
    removeResumesApi,
} from './api';

import {
    setClasses,
    setResumes,
    clearResumesAction,
    setDegreeIdAction,
    setClassIdAction,
    clearClassIdAction,
} from './store';

import {
    TClassrooms,
    IDropdownObject,
    IHandleChange,
    IStoreState,
    IClass,
    THandleDropdownChange,
    IPagination,
} from "./Classrooms.types";
import {setIsLoading} from "../App/store/actions";
import {convertToDropdown} from "../../utils";

const Classrooms: TClassrooms = () => {
    // * Hooks Init
    const dispatch = useDispatch();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();
    const { errors, validate } = useValidate();

    const classTextInputRef = useRef<HTMLInputElement>(null);
    const addResumesBodyRef = useRef<HTMLElement>(null);
    const resumeNameRef = useRef<HTMLInputElement>(null);

    const validationSchema = {
        newClass: Joi.string().required().messages({
            'string.empty': locales.errors.required,
        }),
    };

    //* Redux State
    const { config, degrees, classes, tags, userInfo } = useSelector(
        ({ app }: IStoreState) => app,
        shallowEqual
    );

    const {
        resumes,
        degreeId: selectedDegree,
        classId: selectedClass,
    } = useSelector(({ classrooms }: IStoreState) => classrooms, shallowEqual);

    //* Local State
    const [isUploading, setIsUploading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isAddNewResumesModalVisible, setIsAddNewResumesModalVisible] =
        useState(false);

    const [selectedTag, setSelectedTag] = useState('');

    const [uploadedResumes, setUploadedResumes] = useState<File[]>([]);
    const [isAddClassInputVisible, setIsAddClassInputVisible] = useState(false);
    const [newClass, setNewClass] = useState('');
    const [selectedResume, setSelectedResume] = useState('');
    const [isAddClassesModalVisible, setIsAddClassesModalVisible] =
        useState(false);
    const [isResumeNameInputVisible, setResumeNameInputVisible] = useState(false);
    const [isTagDropdownVisible, setTagDropdownVisible] = useState(false);
    const [isUploadSuccessModalVisible, setIsUploadSuccessModalVisible] =
        useState(false);
    const [isClassSettingsMenuVisible, setIsClassSettingsMenuVisible] =
        useState(false);
    const [selectedDegrees, setSelectedDegrees] = useState<
        IDropdownObject[]
    >([]);

    const [pagination, setPagination] = useState<IPagination>({
        page: 1,
        limit: 10,
        totalPages: 1,
    });
    const [totalPages, setTotalPages] = useState(1);
    const [resumeAwsId, setResumeAwsId] = useState('');
    const [resumeUrl, setResumeUrl] = useState('');

    const [newSelectedDegree, setNewSelectedDegree] = useState('');
    const [newSelectedClass, setNewSelectedClass] = useState('');
    const [resumesClasses, setResumesClasses] = useState<IClass[]>([]);
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
    const [isBeingEdited, setIsBeingEdited] = useState(false);
    const [selectedResumes, setSelectedResumes] = useState<string[]>([]);
    const [isDeleteResumesConfirmVisible, setIsDeleteResumesConfirmVisible] =
        useState(false);

    const [resumeNameValue, setResumeNameValue] = useState<string>();

    //* Handlers
    const handleNewClassChange: IHandleChange = ({ target: { value } }) => {
        setNewClass(value);
    };

    const handleSelectedClassChange = ({ value }: IDropdownObject) => {
        setNewSelectedClass(value);
    };

    const handleSelectedDegreeChange = ({ value }: IDropdownObject) => {
        setNewSelectedDegree(value);
    };

    const handleSelectedTagChange = ({ value }: IDropdownObject) => {
        setSelectedTag(value);
    };

    const handleChangeResumeName = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        setResumeNameValue(value);
    };

    const handleSaveTag = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setSelectedResume('');
        setSelectedTag('');

        if (selectedTag.length === 0) return;

        saveTag();
    };

    const handleAddClassDialogShow = () => {
        setIsAddClassesModalVisible(true);
    };

    const handleAddClassDialogHide = () => {
        setNewClass('');
        setSelectedDegrees([]);
        setIsAddClassesModalVisible(false);
    };

    const handleAddResumesModalShow = () => {
        setNewSelectedDegree(selectedDegree);
        setNewSelectedClass(selectedClass);
        setIsAddNewResumesModalVisible(true);
    };

    const handleAddResumesModalClose = () => {
        setIsAddNewResumesModalVisible(false);
        setNewSelectedDegree('');
        setNewSelectedClass('');
        setUploadedResumes([]);
    };

    const handleTagsDropdownShow = (resumeId: string) => () => {
        setTagDropdownVisible(true);
        setResumeNameInputVisible(false);
        setSelectedResume(resumeId);
    };

    const handleTagsDropdownHide = () => {
        setTagDropdownVisible(false);
        setSelectedResume('');
        setSelectedTag('');
    };

    const handleAddResumes = () => {
        createResumes(uploadedResumes);
    };

    const handleDegreeChange: THandleDropdownChange = (options) => {
        if (Array.isArray(options)) return;
        dispatch(setDegreeIdAction(options.value));
        selectedClass.length > 0 && dispatch(setClassIdAction(''));
        resumes.length > 0 && dispatch(setResumes([]));
    };

    const handleClassClick = (id: string) => () => {
        dispatch(setClassIdAction(id));
        setResumeNameInputVisible(false);
    };

    const handleNewClassInputShow = () => {
        setIsAddClassInputVisible(true);
    };

    const handleNewClassInputHide = () => {
        setIsAddClassInputVisible(false);
    };

    const handleUploadFile = (files: File[]) => {
        setUploadedResumes((prevFiles) => [...prevFiles, ...files]);
        if (!isAddNewResumesModalVisible) createResumes(files);

        if (addResumesBodyRef.current) {
            addResumesBodyRef.current.scrollTo({ top: 5000, behavior: 'smooth' });
        }
    };

    const handleGenerateResume = (awsId: string) => () => {
        setResumeAwsId(awsId);
    };

    const handleResumePageChange = ({ selected }: { selected: number }) => {
        setPagination((prevValue) => ({
            ...prevValue,
            page: selected + 1,
        }));
    };

    const handleEditResumeNameShow = (resumeId: string) => async () => {
        const resume = resumes.find(({ _id }) => _id === resumeId);

        if (!resume) return;

        setSelectedResume(resumeId);
        setTagDropdownVisible(false);
        await setResumeNameInputVisible(true);
        setResumeNameValue(resume.name);
    };

    const handleEditResumeNameHide = () => {
        setResumeNameInputVisible(false);
        updateResumeName();
    };

    const handleUploadSuccessModalHide = () => {
        setIsUploadSuccessModalVisible(false);
        setUploadedResumes([]);
        setResumesClasses([]);
        setNewSelectedClass('');
        setNewSelectedDegree('');
    };

    const handleClassSettingsMenuShow = () => {
        setIsClassSettingsMenuVisible((prevValue) => !prevValue);
    };

    const handleClassSettingsMenuHide = () => {
        setIsClassSettingsMenuVisible(false);
    };

    const handleSelectedDegreesChange: THandleDropdownChange = (selectedList) => {
        setSelectedDegrees(selectedList as IDropdownObject[]);
    };

    const handleEditClass = () => {
        setIsBeingEdited(true);
        const sameClass = classes.find((element) => element._id === selectedClass)?.value ?? "";
        setNewClass(sameClass);
    };

    const handleRemoveTag = (tag: string, resumeId: string) => () => {
        removeTag(tag, resumeId);
    };

    const handleRemoveClass = () => {
        setIsConfirmModalVisible(true);
    };

    const handleRemoveClassConfirm = (classId: string) => () => {
        removeClass(classId);
    };

    const handleRemoveClassCancel = () => {
        setIsConfirmModalVisible(false);
    };

    const handleSelectResume = (resumeId: string) => () => {
        if (selectedResumes.includes(resumeId))
            return setSelectedResumes((prevResumes) => [
                ...prevResumes.filter((id) => id !== resumeId),
            ]);

        setSelectedResumes((prevResumes) => [...prevResumes, resumeId]);
    };

    const handleRemoveAllResumes = () => {
        setIsDeleteResumesConfirmVisible(true);
        setSelectedResumes(resumes.map(({ _id }) => _id));
    };

    const handleRemoveSelectedResumes = () => {
        setIsDeleteResumesConfirmVisible(true);
    };

    const handleRemoveResumesConfirm = (isDeleteAll: boolean) => () => {
        if (isDeleteAll) {
            return removeAllResumes();
        }

        removeSelectedResumes();
    };

    const handleRemoveResumesCancel = () => {
        setIsDeleteResumesConfirmVisible(false);
    };

    const handleCloseCv = () => {
        setResumeUrl('');
    };

    const handleCloseUploadingPercentage = () => {
        setIsUploading(false);
    };


    //* API Actions
    const fetchClasses = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const {
                data: { data: classes },
            } = await fetchClassesApi(newSelectedDegree || selectedDegree);

            if (newSelectedDegree.length > 0) {
                setResumesClasses(classes);
            } else {
                dispatch(setClasses(classes));
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, selectedDegree, newSelectedDegree]);

    const updateResumeName = async () => {
        try {
            dispatch(setIsLoading(true));

            await updateResumeNameApi({
                name: resumeNameValue || '',
                id: selectedResume,
            });
            await fetchResumes();
            setSelectedResume('');
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    };

    const fetchResumes = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));
            const { limit } = pagination;
            const {
                data: { data: resumes, total },
            } = await fetchResumesApi({
                classe: selectedClass,
                degree: selectedDegree,
                ...pagination,
            });

            const totalPages = limit ? Math.ceil(total / limit) : 1;

            dispatch(setResumes(resumes));

            setPagination((prevValue) => ({
                ...prevValue,
                totalPages
            }));
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [pagination, selectedClass, selectedDegree, dispatch]);

    const handleNewClassSubmit = async () => {
        try {
            dispatch(setIsLoading(true));
            handleAddClassDialogHide();

            await createClassesApi({
                classe: newClass,
                degrees: selectedDegrees.map(({ value }) => value),
            });

            await fetchClasses();
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    };

    const removeClass = async (classId: string) => {
        try {
            dispatch(setIsLoading(true));

            await removeClassApi(classId, selectedDegree);
            await fetchClasses();

            dispatch(clearClassIdAction());
            dispatch(clearResumesAction());

            setSelectedResumes([]);
            setIsConfirmModalVisible(false);
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    };

    const handleUpdateClassSubmit = async (event: Event) => {
        try {
            event.preventDefault();

            const isValid = validate({
                data: { newClass },
                validationSchema,
            });

            if (!isValid) {
                setNewClass('');
                setIsAddClassesModalVisible(false);
                setIsBeingEdited(false);
                return;
            }

            dispatch(setIsLoading(true));

            await updateClassApi({ value: newClass, id: selectedClass });

            const {
                data: { data: classes },
            } = await fetchClassesApi(newSelectedDegree || selectedDegree);

            dispatch(setClasses(classes));

            setNewClass('');
            setIsAddClassesModalVisible(false);
            setIsBeingEdited(false);
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
            setNewClass('');
            setIsBeingEdited(false);
        }
    };

    const createResumes = async (resumes: File[]) => {
        try {
            setIsAddNewResumesModalVisible(false);
            setIsUploading(true);

            const formData = new FormData();
            formData.append('classe', newSelectedClass || selectedClass);
            formData.append('degree', newSelectedDegree || selectedDegree);

            resumes.forEach((resume) => {
                formData.append('pdf', resume);
            });

            await createResumesApi(
                formData,
                {
                    onUploadProgress: (progressEvent) => {
                        setUploadPercentage(Math.floor((progressEvent.loaded / progressEvent.total) * 100))
                    },
                }
            );

            await fetchResumes();

            setIsAddNewResumesModalVisible(false);
            setIsUploadSuccessModalVisible(true);
            setIsUploading(false);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            setUploadedResumes([]);
            setIsUploading(false);
        }
    };

    const saveTag = async () => {
        try {
            dispatch(setIsLoading(true));

            await updateResumeTagApi({ id: selectedResume, tag: selectedTag });
            await fetchResumes();

            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    };

    const removeTag = async (tag: string, resumeId: string) => {
        try {
            dispatch(setIsLoading(true));

            await removeTagApi(tag, resumeId);
            await fetchResumes();

            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    };

    const removeAllResumes = async () => {
        try {
            dispatch(setIsLoading(true));

            await removeResumesApi({ ids: selectedResumes });
            await fetchResumes();

            setSelectedResumes([]);
            setIsDeleteResumesConfirmVisible(false);
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    };

    const removeSelectedResumes = async () => {
        try {
            dispatch(setIsLoading(true));

            await removeResumesApi({ ids: selectedResumes });
            await fetchResumes();

            setSelectedResumes([]);
            setIsDeleteResumesConfirmVisible(false);
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    };

    const generateResume = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const {
                data: { data },
            } = await generateResumeApi({ filename: resumeAwsId });

            setResumeAwsId('');
            setResumeUrl(data);
            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [resumeAwsId]);

    //* Effects
    useEffect(() => {
        if (resumeAwsId.length > 0) generateResume();
    }, [resumeAwsId, generateResume]);

    useEffect(() => {
        if (selectedDegree.length > 0 || newSelectedDegree.length > 0) fetchClasses();
    }, [selectedDegree, newSelectedDegree]);

    useEffect(() => {
        if (selectedClass?.length > 0 && selectedDegree?.length > 0) fetchResumes();
    }, [selectedClass, selectedDegree, pagination.page]);

    //* Wrappers
    const data = {
        isUploading,
        uploadPercentage,
        classes,
        resumesClasses,
        selectedClass,
        newClass,
        degrees,
        tags,
        selectedDegree,
        uploadedResumes,
        resumes,
        selectedResume,
        selectedTag,
        newSelectedDegree,
        newSelectedClass,
        selectedDegrees,
        selectedResumes,
        resumeUrl,
        userInfo,
        isBeingEdited,
        classTextInputRef,
        resumeNameValue,
    };

    const options = {
        degrees: convertToDropdown({ data: degrees, labelKey: 'value', valueKey: '_id' }),
        tags: convertToDropdown({ data: tags, labelKey: 'value', valueKey: '_id' }),
        classes: convertToDropdown({ data: classes, labelKey: 'value', valueKey: '_id' }),
    }

    const handlers = {
        handleChangeResumeName,
        handleAddResumesModalShow,
        handleAddResumesModalClose,
        handleAddResumes,
        handleDegreeChange,
        handleClassClick,
        handleNewClassInputShow,
        handleNewClassInputHide,
        handleUploadFile,
        handleSelectedClassChange,
        handleSelectedDegreeChange,
        handleNewClassChange,
        handleNewClassSubmit,
        handleUpdateClassSubmit,
        handleTagsDropdownShow,
        handleTagsDropdownHide,
        handleSelectedTagChange,
        handleSaveTag,
        handleAddClassDialogShow,
        handleAddClassDialogHide,
        handleResumePageChange,
        handleGenerateResume,
        handleEditResumeNameShow,
        handleEditResumeNameHide,
        handleUploadSuccessModalHide,
        handleClassSettingsMenuShow,
        handleClassSettingsMenuHide,
        handleSelectedDegreesChange,
        handleEditClass,
        handleRemoveTag,
        handleRemoveClass,
        handleRemoveClassConfirm,
        handleRemoveClassCancel,
        handleSelectResume,
        handleRemoveAllResumes,
        handleRemoveSelectedResumes,
        handleRemoveResumesConfirm,
        handleRemoveResumesCancel,
        handleCloseCv,
        handleCloseUploadingPercentage,
    };

    return (
        <ClassroomsUI
            data={data}
            errors={errors}
            pagination={pagination}
            totalPages={pagination.totalPages}
            config={config}
            options={options}
            handlers={handlers}
            isAddNewResumesModalVisible={isAddNewResumesModalVisible}
            isAddClassInputVisible={isAddClassInputVisible}
            isAddClassesModalVisible={isAddClassesModalVisible}
            isResumeNameInputVisible={isResumeNameInputVisible}
            isTagDropdownVisible={isTagDropdownVisible}
            isUploadSuccessModalVisible={isUploadSuccessModalVisible}
            isClassSettingsMenuVisible={isClassSettingsMenuVisible}
            isConfirmModalVisible={isConfirmModalVisible}
            addResumesBodyRef={addResumesBodyRef}
            isDeleteResumesConfirmVisible={isDeleteResumesConfirmVisible}
        />
    );
};

export default Classrooms;