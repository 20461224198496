import { forwardRef, memo } from 'react';
import Select, { createFilter } from 'react-select';
import classNames from 'classnames';

import { style } from './style';
import { TDropdown, IProps } from './Dropdown.types';

import {
    Control,
    FlagOption,
    IndicatorsContainer,
    Option,
    SingleValue,
    ValueContainer,
} from "../Dropdown";

import './dropdown.scss';

const Dropdown: TDropdown = ( {
    id = '',
    name = '',
    defaultValue = null,
    value = null,
    placeholder = '',
    options,
    onChange,
    onBlur,
    isError = false,
    isSearchable,
    isDisabled = false,
    isRounded = false,
    isMulti = false,
    isLocation = false,
    isFlags = false,
    className = '',
    isFixedHeight = false,
    isWhite = false,
    isOptionDisabled = false,
    onInputChange,
}, ref ) => {

    const dropdownClasses = classNames( {
        'std-dropdown': true,
        'std-dropdown--has-value':
            ( !Array.isArray( value ) && !!value ) ||
            ( Array.isArray( value ) && value.length > 0 ),
        'gray': true,
        'fixedheight': isFixedHeight,
        'white': isWhite,
        [ className ]: className.length > 0,
    } );

    const onFocus = ( { target }: any ) => {
        target.parentNode.parentNode.parentNode.parentNode?.classList
        && target.parentNode.parentNode.parentNode.parentNode.classList.add(
            'std-dropdown--focused'
        );
        target.parentNode.parentNode.parentNode.parentNode.nextSibling?.classList
        && target.parentNode.parentNode.parentNode.parentNode.nextSibling.classList.add(
            'form-control__label--has-value'
        );
    };

    const onBlurLocal = ( { target }: any ) => {
        onBlur && onBlur();
        target.parentNode.parentNode.parentNode.parentNode?.classList
        && target.parentNode.parentNode.parentNode.parentNode.classList?.remove(
            'std-dropdown--focused'
        );
        target.parentNode.parentNode.parentNode.parentNode.nextSibling?.classList
        && target.parentNode.parentNode.parentNode.parentNode.nextSibling.classList?.remove(
            'form-control__label--has-value'
        );
    };

    return (
        <Select
            onFocus={ onFocus }
            onBlur={ onBlurLocal }
            ref={ ref }
            id={ id }
            name={ name } //@ts-ignore
            options={ options } //@ts-ignore
            defaultValue={ defaultValue }
            value={ value }
            styles={ style( { isError, isRounded } ) }
            placeholder={ placeholder }
            isDisabled={ isDisabled }
            isSearchable={ !isMulti }
            //@ts-ignore
            onChange={ onChange }
            //@ts-ignore
            isMulti={ isMulti }
            closeMenuOnSelect={ !isMulti }
            hideSelectedOptions={ !isMulti && !isLocation }
            filterOption={ createFilter( { ignoreAccents: false } ) }
            className={ dropdownClasses }
            classNamePrefix="std-dropdown"
            onInputChange={ onInputChange }
            openMenuOnFocus={ true }
            menuPortalTarget={ document.body }
            menuPosition={ 'absolute' }
            menuPlacement="bottom"
            isOptionDisabled={ () => isOptionDisabled }
            components={ {
                ...isMulti && {
                    Control,
                    IndicatorsContainer,
                    Option,
                    SingleValue,
                    ValueContainer,
                },
                ...isFlags && {
                    Option: FlagOption
                }
            } }
        />
    );
};

export default memo( forwardRef<any, IProps>( Dropdown ) );
