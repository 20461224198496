import { memo } from "react";
import classNames from "classnames";
import { Draggable } from "@hello-pangea/dnd";

import { TDraggableItem } from "./DraggableItem.types";

const DraggableItem: TDraggableItem = ({ id, index, children, className = "" }) => {
    return (
        <Draggable draggableId={id} key={id} index={index}>
            {(provided, snapshot) => {
                return (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        className={classNames({
                            "draggable-item": true,
                            "draggable-item--dragging": snapshot.isDragging,
                            [className]: className.length > 0,
                        })}
                    >
                        {children}
                    </div>
                );
            }}
        </Draggable>
    );
};

export default memo(DraggableItem);
