import { memo } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import './loader.scss';

import { IStoreState } from "../../store";

export const Loader = () => {
  const loading = useSelector(({ app: { loading: { normal } } }: IStoreState) => normal);

  const className = classNames({
    "loader flex flex-align-items-center flex-justify-content-center": true,
    "loader__hide": !loading,
  });

  return (
    <div className={className}>
      <div className="loader__image"></div>
    </div>
  );
};

export const MemoizedLoader = memo(Loader);