enum AddToYPareoModalEnums {
    student_id = 'student_id',
    firstname = 'firstname',
    lastname = 'lastname',
    city = 'city',
    zip_code = 'zip_code',
    email_address = 'email_address',
    phone_number = 'phone_number',
    gender_code = 'gender_code',
    nationality_code = 'nationality_code',
    handicapped = 'handicapped',
    date_of_birth = 'date_of_birth',
    force = 'force',
}

export {
    AddToYPareoModalEnums,
};