import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react";
import ReactQuill from 'react-quill';
import classNames from 'classnames';

import RichTextToolbar from './RichTextToolbar';

import {
  TRichTextEditor,
  IRichTextEditor,
  RichTextEditorSourcesEnum,
  TRichTextEditorOnChange
} from './RichTextEditor.types';

import 'react-quill/dist/quill.snow.css';
import './rich-text-editor.scss';

const RichTextEditor: TRichTextEditor = ({
  id = '',
  value,
  className = '',
  placeholder = '',
  limit,
  readOnly = true,
  onChange,
  onBlur,
  isDark = false,
  isBorderless = false,
  notRounded = false,
}, ref) => {
  //* Local state
  const [showEmoji, setShowEmoji] = useState<boolean>(false);


  //* Refs
  const richTextEditorRef = useRef<ReactQuill>(null);
  const richTextRef = useRef<HTMLDivElement>(null);


  //* Memos
  const modules = useMemo(() => ({
    toolbar: {
      container: `#react-quill-toolbar-${id}`,
    },
    clipboard: {
      matchVisual: false,
    }
  }), []);

  const formats = useMemo(() => [
    // "header",
    // "font",
    // "size",
    "bold",
    "italic",
    "underline",
    // "strike",
    // "blockquote",
    "list",
    "bullet",
    // "indent",
    "link",
    "image",
    // "color"
  ], []);


  //* Handlers
  const handleShowEmojiPicker = useCallback((show?: boolean) => {
    if (show !== undefined) return setShowEmoji(show);
    setShowEmoji(p => !p);
  }, [setShowEmoji]);

  const handleInsertEmoji = useCallback((emoji: string) => {
    if (!richTextEditorRef || !richTextEditorRef?.current ) return;
    const editor = richTextEditorRef.current.getEditor();
    if (!editor) return;
    const selection = editor?.getSelection(true).index ?? 0;
    editor.insertText(selection, emoji, RichTextEditorSourcesEnum.user);
  }, [richTextEditorRef]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      readOnly
      || !showEmoji
      || !event.target
      || !richTextRef.current
      || richTextRef.current.contains(event.target  as Node)
    ) return;

    handleShowEmojiPicker(false);
  }, [richTextRef, showEmoji, readOnly]);

  const handleChange: TRichTextEditorOnChange = useCallback(( content, delta, source, editor ) => {
      onChange( content, delta, source, editor );
      limit && richTextEditorRef && richTextEditorRef.current && richTextEditorRef.current.getEditor().deleteText( limit, richTextEditorRef.current.getEditor().getLength() )
  }, [ onChange, limit, richTextEditorRef ]);


  //* Effects
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [
    richTextRef,
    handleClickOutside
  ]);

  //* Prop memos
  const data = useMemo(() => ({
    showEmoji,
  }), [
    showEmoji
  ]);

  const handlers = useMemo(() => ({
    handleShowEmojiPicker,
    handleInsertEmoji,
  }), [
    handleShowEmojiPicker,
    handleInsertEmoji,
  ]);


  //* Classes
  const parentClasses = classNames({
    'rich-text': true,
    'notRounded': notRounded,
  });

  const classes = classNames({
    'rich-text-editor': true,
    'rich-text-editor--dark': isDark,
    'rich-text-editor--is-readonly': readOnly,
    'rich-text-editor--is-borderless': isBorderless,
    [className]: className.length > 0,
  });

  useImperativeHandle(ref, () => richTextEditorRef?.current as ReactQuill, []);

  return (
    <div ref={richTextRef} className={parentClasses}>
      <RichTextToolbar
        id={id}
        readOnly={readOnly}
        hasEmoji={true}
        data={data}
        handlers={handlers}
        isBorderless={isBorderless}
      />
      <ReactQuill
        modules={modules}
        formats={formats}
        id={id}
        value={value}
        ref={richTextEditorRef}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={handleChange}
        onBlur={onBlur}
        className={classes}
      />
    </div>
  );
};

export default forwardRef<ReactQuill, IRichTextEditor>(RichTextEditor);
