import { ValidateEmailModalEnums } from "../ValidateEmailModal/ValidateEmailModal.enums";
import { InterviewDetailsModalEnums } from "../InterviewDetailsModal/InterviewDetailsModal.enums";

import { modalsActionTypes } from "./action-types";

import { IModalsGlobalState, ModalsTypesEnum, TModalsAction } from "./store.types";

const initialState: IModalsGlobalState = {
  [ModalsTypesEnum.SELECT_CVS]: {
    values: {
      degree: '',
      classe: '',
      tag: '',
      CVs: [],
      awsId: '',
    },
    data: {
      CVs: [],
      awsURL: '',
    }
  },
  [ModalsTypesEnum.UPLOAD_SUCCESS]: {
    data: {
      CVs: [],
    },
    values: {
      CVs: [],
    },
  },
  [ModalsTypesEnum.ADD_TO_YPAREO]: {
    data: {
      options: {
        genderCodes: [],
        nationalityCodes: [],
      }
    },
    values: {
      student_id: '',
      firstname: '',
      lastname: '',
      city: '',
      zip_code: '',
      email_address: '',
      phone_number: '',
      gender_code: '',
      nationality_code: '',
      handicapped: false,
      date_of_birth: '',
    },
  },
  [ModalsTypesEnum.INTERVIEW_DETAILS]: {
    data: {},
    values: {
      [InterviewDetailsModalEnums.date]: '',
      [InterviewDetailsModalEnums.hour]: '',
    },
  },
  [ModalsTypesEnum.VALIDATE_EMAIL]: {
    data: {
      variables: []
    },
    values: {},
  },
};

const reducer = (state: IModalsGlobalState = initialState, action: TModalsAction) => {
  switch (action.type) {
    case modalsActionTypes.SET_MODAL_TYPE:
      return {
        ...state,
        [action.modalType]: {
          ...state[action.modalType],
          isOpen: action.payload
        }
      };

    case modalsActionTypes.SET_MODAL_VALUES:
      return {
        ...state,
        [action.modalType]: {
          ...state[action.modalType],
          values: {
            ...state[action.modalType].values,
            ...action.payload
          }
        }
      };

    case modalsActionTypes.SET_MODAL_DATA:
      return {
        ...state,
        [action.modalType]: {
          ...state[action.modalType],
          data: {
            ...state[action.modalType].data,
            ...action.payload
          }
        }
      };

    case modalsActionTypes.RESET_MODAL:
      return {
        ...state,
        [action.modalType]: initialState[action.modalType],
      };

    case modalsActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
