import { memo, useCallback, useEffect } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { useExtractServerError, useToast } from "hooks";
import type { IStoreState } from "store";

import { setIsLoading } from "modules/App/store/actions";

import { fetchHistoryApi } from "../../api";
import { setProfileHistory } from "../../store/actions";
import type { THistoryProps } from "./types";

import HistoryUI from "./History.ui";


const History = ( {}: THistoryProps ) => {
    ///* Hooks
    const toast = useToast();
    const dispatch = useDispatch();
    const { extractErrorMessage } = useExtractServerError();


    ///* Redux State
    const studentInfo = useSelector( ( { candidates: { studentInfo } }: IStoreState ) => studentInfo, shallowEqual );
    const history = useSelector( ( { candidates: { newProfile: { history : { list } } } }: IStoreState ) => list, shallowEqual );


    ///* Api Calls
    const fetchHistory = useCallback( async () => {
        try {
            if ( !studentInfo?.student_info?._id ) return;

            dispatch( setIsLoading( true ) );

            const { data: { data } } = await fetchHistoryApi( { userId: studentInfo?.student_info?._id } );

            batch( () => {
                dispatch( setProfileHistory( { list: data } ) )
                dispatch( setIsLoading( false ) );
            } );
        } catch ( error ) {
            const errorMessage = extractErrorMessage( error );
            toast( { type: 'error', message: errorMessage } );
            dispatch( setIsLoading( false ) );
        }
    }, [ dispatch, studentInfo?.student_info?._id ] )


    ///* Effects
    useEffect( () => {
        fetchHistory();
    }, [ studentInfo?.student_info?._id ] );


    ///* Wrappers
    const data = {
        history
    };


    return <HistoryUI data={data} />
};

export default memo( History );