import classNames from 'classnames';

import { Dropdown, Checkbox, TextInput, PdfViewer, Button } from 'components';

import { locales } from 'constants/index';

import { TResumesTable } from './Resumes.types';

import './resumes.scss';

export const ResumesTable: TResumesTable = ({
  resumes = [],
  tags,
  isSmallRow = false,
  isTagsVisible = true,
  isResumeFileVisible = true,
  className = '',
  onTagsDropdownShow,
  onGenerateResume,
  selectedResume,
  selectedResumes,
  selectedTag,
  isResumeNameInputVisible,
  withCheckbox = false,
  onSelectedTagChange = () => {},
  onSaveTag = () => {},
  onSelectResume,
  onEditResumeNameShow,
  onEditResumeNameHide,
  isTagDropdownVisible,
  onResumeNameBlur,
  onTagsDropdownBlur,
  isResumeNameEditable = true,
  onRemoveTag,
  resumeUrl,
  onCloseCv,
  resumeNameValue,
  handleChangeResumeName,
}) => {
  const mainClasses = classNames({
    'table table--resumes': true,
    [className]: className.length > 0,
  });
  const rowClasses = classNames({
    'table__row': true,
    'table__row--small': isSmallRow,
  });

  return (
    <div className={mainClasses}>
      {resumes?.map((resume, index) => {
        const id =
          '_id' in resume ? resume._id : new Date().getTime().toString();
        const resumeTags = 'tags' in resume ? resume.tags : [];
        const awsId = 'awsId' in resume ? resume.awsId : '';

        return (
          <div key={id + awsId + index} className={rowClasses}>
            <div className=" flex flex-align-items-center">
              <div className="table__check__container">
                <div className="table__check">
                  {withCheckbox && (
                    <Checkbox
                      isChecked={selectedResumes?.includes(id)}
                      onChange={onSelectResume && onSelectResume(id)}
                    />
                  )}
                </div>
              </div>

              <div className="table__icon table__icon--profile"></div>

              <div className={classNames({
                'table__resume-name': true,
                'table__resume-name-fullwidth': isResumeNameInputVisible && selectedResume === id,
              })} title={resume.name}>
              {isResumeNameInputVisible && selectedResume === id ? (
                <TextInput
                  value={resumeNameValue}
                  className="table__resume-name__input"
                  id="resumeName"
                  name="resumeName"
                  onChange={handleChangeResumeName}
                />
              ) : (
                resume.name
              )}
            </div>

              {isResumeNameEditable &&
                (isResumeNameInputVisible && selectedResume === id ? (
                  <div
                    className="table__edit-name"
                    role="presentation"
                    onClick={onEditResumeNameHide}
                  >
                    {locales.resumes.enregistrer}
                  </div>
                ) : (
                  <div
                    className="table__edit-name"
                    role="presentation"
                    onClick={onEditResumeNameShow && onEditResumeNameShow(id)}
                  >
                    {locales.resumes.edit}
                  </div>
                ))}

              <div className="table__actions flex flex-align-items-center">
              {(!resumeTags || resumeTags.length === 0) && isTagsVisible &&
                (selectedResume === id && isTagDropdownVisible ? (
                  <>
                    <form onSubmit={onSaveTag}>
                      <Dropdown
                        className="table__tags-dropdown"
                        value={tags.find(({ value }) => value === selectedTag)}
                        options={tags}
                        isSearchable={false}
                        onChange={onSelectedTagChange}
                        onBlur={onTagsDropdownBlur}
                      />
                    </form>
                    <div
                      className="table__add-tag"
                      role="presentation"
                      // @ts-ignore
                      onClick={onSaveTag}
                    >
                      {locales.resumes.enregistrer}
                    </div>
                  </>
                ) : (
                  <div
                    className="table__add-tag"
                    role="presentation"
                    onClick={onTagsDropdownShow && onTagsDropdownShow(id)}
                  >
                    {locales.resumes.addTag}
                  </div>
                ))}

              {isResumeFileVisible && (
                <div className="table__cv-section">
                  <Button
                    className='flex flex-align-items-center flex-justify-content-center'
                    variant="secondary"
                    size="extrasmall"
                    onClick={onGenerateResume && onGenerateResume(awsId)}
                  >
                    <span className="table__button-icon table__button-icon--eye-white" />
                    {locales.student.viewCv}
                  </Button>
                </div>
              )}

              {!!resumeUrl && !!onCloseCv && resumeUrl.length && (
                <PdfViewer url={resumeUrl} isVisible isFixedPosition onClose={onCloseCv} />
              )}
            </div>
          </div>

            {resumeTags && resumeTags.length > 0 && isTagsVisible && (
              <div className="table__resume-tag__container">
              <div>
                <div className="table__resume-tag__icon"/>
              </div>
              <div>
                {isTagsVisible && (
                  <div className="table__resume-tags flex">
                    {resumeTags.map(({ value, _id: tagId }) => (
                      <div
                        className="table__resume-tag flex flex-align-items-center"
                        role="presentation"
                      >
                        {value}
                        <div
                          className="table__resume-tag-icon"
                          onClick={onRemoveTag && onRemoveTag(tagId, id)}
                        />
                      </div>
                    ))}

                    {selectedResume === id && isTagDropdownVisible && (
                      <form onSubmit={onSaveTag}>
                        <Dropdown
                          className="table__tags-dropdown"
                          value={tags.find(({ value }) => value === selectedTag)}
                          options={tags}
                          isSearchable={false}
                          onChange={onSelectedTagChange}
                          onBlur={onTagsDropdownBlur}
                        />
                      </form>
                    )}
                    </div>
                )}
              </div>
              <div>
                {resumeTags && resumeTags.length > 0 && isTagsVisible &&
                  (selectedResume === id && isTagDropdownVisible ? (
                    <div
                      className="table__add-tag"
                      role="presentation"
                      // @ts-ignore
                      onClick={onSaveTag}
                    >
                      {locales.resumes.enregistrer}
                    </div>
                  ) : (
                    <div
                      className="table__add-tag"
                      role="presentation"
                      onClick={onTagsDropdownShow && onTagsDropdownShow(id)}
                    >
                      {locales.resumes.addTag}
                    </div>
                  ))}
              </div>
            </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
