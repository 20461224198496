import {
  CLEAR_SELECTED_CANDIDATES,
  SET_OFFERID,
  SET_OPTIONS_OFFERS,
  SET_PIPES,
  SET_PIPES_BY_COLUMN,
  SET_PROFILE,
  SET_SELECTED_CANDIDATES,
  SET_SELECTED_CV_URL,
  SET_STUDENT_INFO,
  TRIGGER_REFRESH_PIPES,
  SET_DRAGGED_ID,
  RESET_DRAGGED_ID,
  studentsActionTypes,
} from './action-types';

import { ICandidatesGlobalState, TCandidatesAction } from './store.types';

const initialState: ICandidatesGlobalState = {
  offerId: '',
  selectedCvUrl: '',
  selectedCandidates: [],
  triggerRefreshPipes: false,
  pipes: [],
  options: {
    offers: []
  },
  pipesByColumn: {},
  profile: {
    cvUrl: '',
    editMode: false,
    notes: [],
    review: '',
    reviews: [],
    date_appel: '',
    date_entretien: '',
    statut_appel: '',
    tabIndex: 1,
    actions: {
      canSendToYPareo: true
    },
  },
  newProfile: {
    cvUrl: '',
    emails: [],
    history: {
      list: [],
    },
    comments: {
      isEditing: false,
      canSubmit: false,
      list: [],
      edited: {},
    }
  }
};

const reducer = (
  state: ICandidatesGlobalState = initialState,
  action: TCandidatesAction
) => {
  switch (action.type) {
    case SET_PIPES:
      return {
        ...state,
        pipes: action.payload,
      };

    case SET_OPTIONS_OFFERS:
      return {
        ...state,
        options: {
          ...state.options,
          offers: action.payload,
        },
      };

    case SET_OFFERID:
      return {
        ...state,
        offerId: action.payload,
      };

    case SET_SELECTED_CV_URL:
      return {
        ...state,
        selectedCvUrl: action.payload,
      };

    case SET_PIPES_BY_COLUMN:
      return {
        ...state,
        pipesByColumn: action.payload,
      };

    case SET_SELECTED_CANDIDATES:
      return {
        ...state,
        selectedCandidates: action.payload,
      };

    case CLEAR_SELECTED_CANDIDATES:
      return {
        ...state,
        selectedCandidates: initialState.selectedCandidates,
      };

    case TRIGGER_REFRESH_PIPES:
      return {
        ...state,
        triggerRefreshPipes: !state.triggerRefreshPipes,
      };

    case SET_DRAGGED_ID:
      return {
        ...state,
        draggedId: action.payload,
      };

    case RESET_DRAGGED_ID:
      return {
        ...state,
        draggedId: undefined,
      };

    case SET_STUDENT_INFO:
      return {
        ...state,
        studentInfo: action.payload,
        newProfile: {
          ...state.newProfile,
          studentInfo: action.payload,
        }
      };

    case SET_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        }
      };

    case studentsActionTypes.SET_PROFILE_CV:
      return {
        ...state,
        newProfile: {
          ...state.newProfile,
          cvUrl: action.payload,
        }
      };

    case studentsActionTypes.SET_PROFILE_COMMENTS:
      return {
        ...state,
        newProfile: {
          ...state.newProfile,
          comments: {
            ...state.newProfile.comments,
            ...action.payload,
          },
        }
      };

    case studentsActionTypes.EDIT_PROFILE_COMMENTS:
      return {
        ...state,
        newProfile: {
          ...state.newProfile,
          comments: {
            ...state.newProfile.comments,
            edited: {
              ...state.newProfile.comments.edited,
              ...action.payload,
            }
          },
        }
      };

    case studentsActionTypes.SET_PROFILE_HISTORY:
      return {
        ...state,
        newProfile: {
          ...state.newProfile,
          history: action.payload,
        }
      };

    case studentsActionTypes.SET_PROFILE_EMAILS:
      return {
        ...state,
        newProfile: {
          ...state.newProfile,
          emails: action.payload,
        }
      };


    case studentsActionTypes.SET_PROFILE_ACTIONS:
      return {
        ...state,
        profile: {
          ...state.profile,
          actions: {
            ...state.profile.actions,
            ...action.payload,
          },
        }
      };

    case studentsActionTypes.RESET_PROFILE_ACTIONS:
      return {
        ...state,
        profile: {
          ...state.profile,
          actions: initialState.profile.actions
        }
      };

    case studentsActionTypes.RESET_PROFILE:
      return {
        ...state,
        profile: initialState.profile,
        newProfile: initialState.newProfile,
      };

    case studentsActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
