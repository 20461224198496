import { memo, useState } from "react";

import {
    FormControl,
    TextInput,
    Section,
    Card,
} from 'components';

import { Icons } from "assets";
import { locales } from 'constants/index';

import { TCompanyProfileUi } from './CompanyProfile.types';

import Users from "./Users";
import YPareo from "./YPareo";

import './company-profile.scss';
import '../settings.scss';

const CompanyProfileUi: TCompanyProfileUi = ({
  errors,
  data: { isSchool, myAccount, editMode },
  handlers,
}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <div className="settings__subModule">
            <Section title={locales.settings.myAccount.yourInfo.index}>
                <Card
                  title={locales.settings.myAccount.yourInfo.personnal}
                  subTitle={locales.settings.myAccount.yourInfo.personnalSub}
                  showAction={editMode.personalData}
                  actionHandler={handlers.handleSave('personalData')}
                >
                    <FormControl
                        id="firstname"
                        label={locales.settings.myAccount.yourInfo.firstname}
                        error={errors.firstname}
                        withRef
                    >
                        <TextInput
                            id="firstname"
                            name="firstname"
                            value={myAccount.personalData?.firstname}
                            onChange={handlers.handlePersonalDataChange('firstname')}
                            isError={!!errors.firstname}
                        />
                    </FormControl>
                    <FormControl
                        id="lastname"
                        label={locales.settings.myAccount.yourInfo.lastname}
                        error={errors.lastname}
                        withRef
                    >
                        <TextInput
                            id="lastname"
                            name="lastname"
                            value={myAccount.personalData?.lastname}
                            onChange={handlers.handlePersonalDataChange('lastname')}
                            isError={!!errors.lastname}
                        />
                    </FormControl>
                </Card>
                <Card isDisabled
                  title={locales.settings.myAccount.yourInfo.login}
                  subTitle={locales.settings.myAccount.yourInfo.loginSub}
                >
                    <FormControl
                        id="email_address"
                        label={locales.settings.myAccount.yourInfo.email}
                        error={errors.email_address}
                        withRef
                    >
                        <TextInput
                            id="email_address"
                            name="email_address"
                            value={myAccount.loginData?.email_address}
                            onChange={handlers.handleLoginDataChange('email_address')}
                            isError={!!errors.email_address}
                        />
                    </FormControl>
                    <FormControl
                        id="password"
                        elementClassName='flex'
                        label={locales.settings.myAccount.yourInfo.password}
                        error={errors.password}
                        withRef
                    >
                        <TextInput
                            id="password"
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={myAccount.loginData?.password}
                            onChange={handlers.handleLoginDataChange('password')}
                            isError={!!errors.password}
                            rightContent={
                                <img
                                    alt="eye"
                                    src={showPassword ? Icons.EyeGray : Icons.EyeClosed}
                                    className="company-profile__password_icon"
                                    onClick={() => setShowPassword(p => !p)}
                                />
                            }
                        />
                    </FormControl>
                    {/*<FormControl*/}
                    {/*    id="confirm_password"*/}
                    {/*    label={locales.settings.myAccount.yourInfo.confirmPassword}*/}
                    {/*    error={errors.confirm_password}*/}
                    {/*    withRef*/}
                    {/*>*/}
                    {/*    <TextInput*/}
                    {/*        id="confirm_password"*/}
                    {/*        name="confirm_password"*/}
                    {/*        type={showPassword ? 'text' : 'password'}*/}
                    {/*        value={myAccount.loginData?.confirm_password}*/}
                    {/*        onChange={handlers.handleLoginDataChange('confirm_password')}*/}
                    {/*        isError={!!errors.confirm_password}*/}
                    {/*    />*/}
                    {/*</FormControl>*/}
                </Card>
            </Section>

            <Users />

            { isSchool && <YPareo/> }

            { isSchool && <Section isColumns title={ locales.settings.myAccount.yourEstablishment.index }>
                <Card
                    title={ locales.settings.myAccount.yourEstablishment.establishment }
                    subTitle={ locales.settings.myAccount.yourEstablishment.establishmentSub }
                    isColumns
                    showAction={ editMode.yourEstablishment }
                    actionHandler={ handlers.handleSave( 'yourEstablishment' ) }
                >
                    <Section>
                        <Card
                            isRounded={ false }
                            hasShadow={ false }
                            hasPadding={ false }
                            isColumns={ false }
                        >
                            <FormControl
                                id="school"
                                label={ locales.settings.myAccount.yourEstablishment.schoolName }
                                error={ errors.school }
                                className='settings__fc-columns'
                                elementClassName='settings__fc-input'
                                withRef
                            >
                                <TextInput
                                    id="school"
                                    name="school"
                                    value={ myAccount.yourEstablishment?.school }
                                    onChange={ handlers.handleYourEstablishmentChange( 'school' ) }
                                    isError={ !!errors.school }
                                />
                            </FormControl>
                            <FormControl
                                id="role"
                                label={ locales.settings.myAccount.yourEstablishment.yourRole }
                                error={ errors.role }
                                className='settings__fc-columns'
                                elementClassName='settings__fc-input'
                                withRef
                            >
                                <TextInput
                                    id="role"
                                    name="role"
                                    value={ myAccount.yourEstablishment?.role }
                                    onChange={ handlers.handleYourEstablishmentChange( 'role' ) }
                                    isError={ !!errors.role }
                                />
                            </FormControl>
                            <FormControl
                                id="short_name"
                                label={ locales.settings.myAccount.yourEstablishment.shortName }
                                error={ errors.short_name }
                                className='settings__fc-columns'
                                elementClassName='settings__fc-input'
                                withRef
                            >
                                <TextInput
                                    id="short_name"
                                    name="short_name"
                                    value={ myAccount.yourEstablishment?.short_name }
                                    onChange={ handlers.handleYourEstablishmentChange( 'short_name' ) }
                                    isError={ !!errors.siret_number }
                                />
                            </FormControl>
                            <FormControl
                                id="siret_number"
                                label={ locales.settings.myAccount.yourEstablishment.numberSIRET }
                                error={ errors.siret_number }
                                className='settings__fc-columns'
                                elementClassName='settings__fc-input'
                                withRef
                            >
                                <TextInput
                                    id="siret_number"
                                    name="siret_number"
                                    value={ myAccount.yourEstablishment?.siret_number }
                                    onChange={ handlers.handleYourEstablishmentChange( 'siret_number' ) }
                                    isError={ !!errors.siret_number }
                                />
                            </FormControl>
                            <FormControl
                                id="city"
                                label={ locales.settings.myAccount.yourEstablishment.city }
                                error={ errors.city }
                                className='settings__fc-columns'
                                elementClassName='settings__fc-input'
                                withRef
                            >
                                <TextInput
                                    id="city"
                                    name="city"
                                    value={ myAccount.yourEstablishment?.city }
                                    onChange={ handlers.handleYourEstablishmentChange( 'city' ) }
                                    isError={ !!errors.city }
                                />
                            </FormControl>
                            <FormControl
                                id="foundation_year"
                                label={ locales.settings.myAccount.yourEstablishment.creationDate }
                                error={ errors.foundation_year }
                                className='settings__fc-columns'
                                elementClassName='settings__fc-input'
                                withRef
                            >
                                <TextInput
                                    id="foundation_year"
                                    name="foundation_year"
                                    value={ myAccount.yourEstablishment?.foundation_year }
                                    onChange={ handlers.handleYourEstablishmentChange( 'foundation_year' ) }
                                    isError={ !!errors.foundation_year }
                                />
                            </FormControl>
                            <FormControl
                                id="siren_number"
                                label={ locales.settings.myAccount.yourEstablishment.numberSIREN }
                                error={ errors.siren_number }
                                className='settings__fc-columns'
                                elementClassName='settings__fc-input'
                                withRef
                            >
                                <TextInput
                                    id="siren_number"
                                    name="siren_number"
                                    value={ myAccount.yourEstablishment?.siren_number }
                                    onChange={ handlers.handleYourEstablishmentChange( 'siren_number' ) }
                                    isError={ !!errors.siren_number }
                                />
                            </FormControl>
                        </Card>
                    </Section>
                </Card>
            </Section> }
        </div>
    );
};

export default memo(CompanyProfileUi);
