import { memo } from 'react';

import { Checkbox } from "components";

import { TTableCheckbox } from './TableCheckbox.types';

import 'components/Table2/table.scss';

const TableCheckbox: TTableCheckbox = (props) => {

    return (
        <div className='td__action start'>
            <Checkbox {...props} />
        </div>
    );
};

export default memo(TableCheckbox);