import { memo, useCallback, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { breadcrumbRoutes } from "constants/index";

import { ICrumb, TBreadcrumb } from './Breadcrumb.types';

import BreadcrumbUI from './Breadcrumb.ui';


const Breadcrumb: TBreadcrumb = ({}) => {
    const { pathname } = useLocation();

    const getCrumbs = useCallback((): ICrumb[] => {
        const filteredCrumbs: ICrumb[] = breadcrumbRoutes
            .filter(crumb => pathname.startsWith(crumb.path) || matchPath(crumb.path, pathname));

        const rootPath = [...filteredCrumbs].find(crumb => !!crumb.isRoot);
        if (rootPath) return [rootPath];

        const matchedCrumbs = [...filteredCrumbs].filter(crumb => matchPath(crumb.path, pathname));
        if (matchedCrumbs.length <= 1) return filteredCrumbs;

        const paramFilteredCrumbs = filteredCrumbs.filter(crumb => !crumb.path.includes(':'));
        return paramFilteredCrumbs;
    }, [pathname]);

    //* Memos
    const crumbs = useMemo(() => getCrumbs(), [getCrumbs]);

    //* Wrappers
    const data = useMemo(() => ({
        crumbs,
    }), [
        crumbs,
    ]);

    return (
        <BreadcrumbUI data={data} />
    );
};

export default memo(Breadcrumb);
