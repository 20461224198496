import { TResumesAction, classroomsActionTypes, IClassroomsGlobalState } from '.';

const initialState: IClassroomsGlobalState = {
  resumes: [],
  degreeId: '',
  classId: '',
};

export const classroomsReducer = (
  state: IClassroomsGlobalState = initialState,
  action: TResumesAction
) => {
  switch (action.type) {
    case classroomsActionTypes.SET_RESUMES:
      return {
        ...state,
        resumes: action.resumes,
      };

    case classroomsActionTypes.CLEAR_RESUMES:
      return {
        ...state,
        resumes: initialState.resumes,
      };

    case classroomsActionTypes.SET_DEGREE_ID:
      return {
        ...state,
        degreeId: action.degreeId,
      };

    case classroomsActionTypes.SET_CLASS_ID:
      return {
        ...state,
        classId: action.classId,
      };

    case classroomsActionTypes.CLEAR_CLASS_ID:
      return {
        ...state,
        classId: initialState.classId,
      };

    case classroomsActionTypes.CLEAR_DEGREE_ID:
      return {
        ...state,
        degreeId: initialState.degreeId,
      };

    case classroomsActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};
