import { memo } from 'react';

import { Button } from "components";

import { TTableLink } from './TableLink.types';

import 'components/Table2/table.scss';

const TableLink: TTableLink = ({
    children,
    path,
    isLink,
    onClick,
    isDisabled,
}) => {

    return (
        <div className='td__action end'>
            <Button
                size='table-link'
                variant='link'
                path={path}
                isLink={isLink}
                onClick={onClick}
                children={children}
                isDisabled={isDisabled}
            />
        </div>
    );
};

export default memo(TableLink);