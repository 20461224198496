import { baseApi } from 'utils';

import { TFetchBloomOffersApi, TFetchCitiesApi, TFetchOffersApi } from './api.types';


export const fetchCitiesApi: TFetchCitiesApi = (name: string) => {
  return baseApi().get(
      `https://geo.api.gouv.fr/communes?nom=${name}&fields=nom,centre,code,codesPostaux,codeDepartement,codeRegion,population&format=json&geometry=centre&boost=population&limit=5`,
  );
};

export const fetchCurrentLocationApi: any = (lat: any, long:any) => {
  return baseApi().get(
      `geolocation/detection?lat=${lat}&long=${long}`
  );
};

export const fetchOffersApi: TFetchOffersApi = (params) => {
  return baseApi().get('/offers/private', { params });
};

export const fetchBloomOffersApi: TFetchBloomOffersApi = (params) => {
  // return baseApi().get('/offers/companies/public', { params });
  return baseApi().get('/offers/private', { params });
};
