import { Modal, Button } from 'components';

import { TErrorModal } from './ErrorModal.types';

import './error-modal.scss';

const ErrorModal: TErrorModal = ({
  title,
  description,
  applyLabel,
  onClose,
}) => {
  return (
    <Modal
      title={title}
      className="error-modal"
      onClose={onClose}
      actionButtons={
        <span className="flex flex-justify-content-center">
          <Button
            variant="danger"
            onClick={onClose}
          >
            {applyLabel}
          </Button>
        </span>
      }
    >
      <div className="error-modal__description">{description}</div>
    </Modal>
  );
};

export default ErrorModal;
