import { memo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip'
import classNames from 'classnames';

import { TElement, TooltipPositionEnum, TTooltip } from './Tooltip.types';

import 'react-tooltip/dist/react-tooltip.css'
import './tooltip.scss';

const Element: TElement = ({ content, activeAnchor }) => {
    if (!content) return null;
    const place = activeAnchor?.getAttribute('data-tooltip-place') ?? TooltipPositionEnum.right;
    const maxWidth = parseInt(activeAnchor?.getAttribute('data-tooltip-max-width') ?? '300');
    const { title, content: body } = JSON.parse(content);

    const classes = classNames({
        'content__wrapper': true,
        'content__top': place === ( TooltipPositionEnum.top || TooltipPositionEnum.topEnd || TooltipPositionEnum.topStart ),
        'content__bottom': place === ( TooltipPositionEnum.bottom || TooltipPositionEnum.bottomEnd || TooltipPositionEnum.bottomStart ),
        'content__left': place === ( TooltipPositionEnum.left || TooltipPositionEnum.leftEnd || TooltipPositionEnum.leftStart ),
        'content__right': place === ( TooltipPositionEnum.right || TooltipPositionEnum.rightEnd || TooltipPositionEnum.rightStart ),
    });

    return <div className={classes} style={{ maxWidth }}>
        <div className={classNames({'content__title': true, 'hide': !title})} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={classNames({'content__body': true, 'hide': !body})} dangerouslySetInnerHTML={{ __html: body }} />
    </div>
}

const Tooltip: TTooltip = () => {
  return (
    <ReactTooltip
      id='tooltip'
      positionStrategy={'absolute'}
      className='react-tooltip'
      classNameArrow='react-tooltip-arrow'
      render={(props) => Element(props)}
    />
  );
};

export default memo(Tooltip);