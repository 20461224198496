enum actionTypes {
    AUTHENTICATE_USER = 'AUTHENTICATE_USER',
    UNAUTHENTICATE_USER = 'UNAUTHENTICATE_USER',
    SET_REGISTRATION_INFO = 'SET_REGISTRATION_INFO',
    CLEAR_REGISTRATION_INFO = 'CLEAR_REGISTRATION_INFO',
    SET_REGISTER = 'SET_REGISTER',
    RESET_REGISTER = 'RESET_REGISTER',
    RESET = 'RESET',
}

export { actionTypes as authenticationActionTypes };
