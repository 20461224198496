import { useEffect, useCallback } from 'react';

export const useOutsideClick = ({ elementRef, setVisible }: any) => {
  const handleClickOutside = useCallback(
    (event: any) => {
      if (elementRef.current && !elementRef?.current.contains(event.target)) {
        setVisible(false);
      }
    },
    [elementRef, setVisible]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, elementRef]);
};
