import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { locales } from "constants/index";
import { useExtractServerError, useToast } from "hooks";
import { convertToDropdown, IDropdownObject } from "utils";

import NotificationsUI from './Notifications.ui';

import { TNotifications } from './Notifications.types';

const Notifications: TNotifications = () => {
  //* Hooks
  const { extractErrorMessage } = useExtractServerError();
  const dispatch = useDispatch();
  const toast = useToast();

  //* Redux State


  //* Local State


  //* Side Effects


  //* Handlers


  //* Effects


  //* Component
  const data = useMemo(() => ({}), []);

  const handlers = useMemo(() => ({}), []);

  return (
    <NotificationsUI
      data={data}
      handlers={handlers}
    />
  );
};
export default memo(Notifications);
