"use client";

import { createTheme } from "@mantine/core";

import ActionIcon from "./ActionIcon";
import Autocomplete from "./Autocomplete";
import Badge from "./Badge";
import Burger from "./Burger";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Combobox from "./Combobox";
import ComboboxDropdown from "./ComboboxDropdown";
import Container from "./Container";
import Drawer from "./Drawer";
import Dropzone from "./Dropzone";
import Flex from "./Flex";
import Indicator from "./Indicator";
import InputLabel from "./InputLabel";
import Modal from "./Modal";
import MultiSelect from "./MultiSelect";
import Pagination from "./Pagination";
import Paper from "./Paper";
import ScrollArea from "./ScrollArea";
import Select from "./Select";
import Skeleton from "./Skeleton";
import Stack from "./Stack";
import Text from "./Text";
import TextInput from "./TextInput";
import Title from "./Title";
import TypographyStylesProvider from "./TypographyStylesProvider";

export default createTheme({
	components: {
		ActionIcon,
		Autocomplete,
		Badge,
		Burger,
		Button,
		Checkbox,
        Combobox,
        ComboboxDropdown,
		Container,
		Drawer,
		Dropzone,
        Flex,
        Indicator,
        InputLabel,
        Modal,
        MultiSelect,
        Pagination,
        Paper,
        ScrollArea,
		Select,
		Skeleton,
		Stack,
		Text,
		TextInput,
		Title,
		TypographyStylesProvider,
	}
});
