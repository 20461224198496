import { memo, useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { useExtractServerError, useToast } from "hooks";
import { convertToDropdown } from "utils";
import { IStoreState } from "store";
import { THandleDropdownChange } from "common";

import { resetModal, setModalData, setModalType, setModalValues } from "../store/actions";
import { setFilterPagination } from "components/Filter/store/actions";
import { setClasses, setIsLoading } from "modules/App/store/actions";

import { fetchClassesApi, fetchResumesApi, generateResumeApi } from "modules/Classrooms/api";

import {
    TSelectCvsModal,
    TOnChangeDropdown,
    THandleShowCVPreview,
    THandleSelectionChange,
} from './SelectCvsModal.types';
import { ModalsTypesEnum } from "../Modals.enums";

import SelectCvsModalUI from "./SelectCvsModal.ui";

const SelectCvsModal: TSelectCvsModal = ({
    onSubmit,
}) => {
    const modalType = ModalsTypesEnum.SELECT_CVS;
    const moduleName = ModalsTypesEnum.SELECT_CVS;

    //* Hooks Init
    const toast = useToast();
    const dispatch = useDispatch();
    const { extractErrorMessage } = useExtractServerError();

    //* Redux State
    const app = useSelector(({ app }: IStoreState) => app, shallowEqual);
    const modals = useSelector(({ modals: { [modalType]: modals } }: IStoreState) => modals, shallowEqual);
    const pagination = useSelector(({ filter: { [moduleName]: { pagination } } }: IStoreState) => pagination, shallowEqual);

    //* API Actions
    const fetchClasses = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const {
                data: { data: classes },
            } = await fetchClassesApi(modals.values.degree);

            dispatch(setClasses(classes));

            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, modals.values.degree]);

    const fetchResumes = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));
            const {
                data: { data: resumes, total },
            } = await fetchResumesApi({
                classe: modals.values.classe,
                degree: modals.values.degree,
                ...pagination,
            });

            const totalPages = pagination.limit ? Math.ceil(total / pagination.limit) : 1;

            batch(() => {
                dispatch(setModalData(modalType, { CVs: resumes }));
                dispatch(setFilterPagination({
                    moduleName,
                    payload: { ...pagination, totalPages },
                }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, pagination, modals.values]);

    const fetchCVPDF = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const { data: { data } } = await generateResumeApi({ filename: modals.values.awsId });

            batch(() => {
                dispatch(setModalValues(modalType, { awsId: '' }));
                dispatch(setModalData(modalType, { awsURL: data }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [modals.values.awsId]);

    //* Handlers
    const handleClose = useCallback(() => {
        batch(() => {
            dispatch(setModalType(modalType, undefined));
            dispatch(resetModal(modalType));
        });
        dispatch(setModalType(modalType, undefined));
    }, []);

    const handleChangeDropdown: TOnChangeDropdown = useCallback((name) => (values) => {
        if (Array.isArray(values)) return;
        dispatch(setModalValues(modalType, {
            [name]: values.value,
        }));
    }, [dispatch]);

    const handleChangeDegree: THandleDropdownChange = useCallback((values) => {
        if (Array.isArray(values)) return;
        dispatch(setModalValues(modalType, {
            degree: values.value,
            classe: '',
        }));
    }, [dispatch]);

    const handleShowCVPreview: THandleShowCVPreview = useCallback((awsId) => () => {
        dispatch(setModalValues(modalType, { awsId }));
    }, [dispatch]);

    const handleSelectionChange: THandleSelectionChange = useCallback((selectedRows) => {
        dispatch(setModalValues(modalType, { CVs: Object.keys(selectedRows) }));
    }, [dispatch]);

    const handleClosePDF = useCallback(() => {
        dispatch(setModalData(modalType, { awsURL: '' }));
    }, [dispatch]);

    const handleSubmit = useCallback(() => {
        onSubmit()
    }, [onSubmit, modals.values.CVs]);

    //* Effects
    useEffect(() => {
        if (!modals.values.degree) return;
        fetchClasses();
        dispatch(setModalData(modalType, { CVs: [] }));
    }, [modals.values.degree]);

    useEffect(() => {
        if (!modals.values.degree) return;
        if (!modals.values.classe) return;
        fetchResumes();
    }, [
        modals.values.degree,
        modals.values.classe,
        pagination.page
    ]);

    useEffect(() => {
        if (!modals.values.awsId) return;
        fetchCVPDF();
    }, [modals.values.awsId]);

    useEffect(() => {
        if (!!modals.isOpen) return;
        batch(() => {
            dispatch(resetModal(modalType));
        });
    }, [modals.isOpen]);

    //* Wrappers
    const values = useMemo(() => modals.values, [modals.values]);

    const options = useMemo(() => ({
        degrees: convertToDropdown({ data: app.degrees, labelKey: 'value', valueKey: '_id' }),
        classes: convertToDropdown({ data: app.classes, labelKey: 'value', valueKey: '_id' }),
        tags: convertToDropdown({ data: app.tags, labelKey: 'value', valueKey: '_id' }),
    }), [app]);

    const data = useMemo(() => modals.data, [modals.data]);

    const handlers = useMemo(() => ({
        handleClose,
        handleChangeDropdown,
        handleChangeDegree,
        handleShowCVPreview,
        handleSelectionChange,
        handleClosePDF,
        handleSubmit,
    }), [
        handleClose,
        handleChangeDropdown,
        handleChangeDegree,
        handleShowCVPreview,
        handleSelectionChange,
        handleClosePDF,
        handleSubmit,
    ]);

    if (!modals.isOpen) return null;

    return <SelectCvsModalUI
        values={values}
        options={options}
        data={data}
        handlers={handlers}
    />;
};

export default memo(SelectCvsModal);
