export default function useGetNextPreviousItems() {
  return function getNextPreviousItems(items: string[], currentItem: any) {
    let previousId = '';
    let nextId = '';

    const currentItemIndex = items.findIndex((item) => item === currentItem);

    if (items.length === 1) {
      return {
        previousId: '-1',
        nextId: '-1',
      };
    }

    if (currentItemIndex === 0) {
      previousId = "-1";
      nextId = items[currentItemIndex + 1];
    } else if (currentItemIndex === items.length - 1) {
      previousId = items[currentItemIndex - 1];
      nextId = '-1';
    } else {
      previousId = items[currentItemIndex - 1];
      nextId = items[currentItemIndex + 1];
    }

    return {
      previousId,
      nextId,
    };
  };
}
