import { memo, useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { guestToEditlist } from "utils";
import { useExtractServerError, useToast, useValidate } from "hooks";
import { locales } from "constants/index";

import { THandleGuestChange, THandleGuestDropdown, TUsers } from './Users.types';
import {
    IStoreState,
    THandleEditListHandler,
    THandleModalClose,
    THandleResendInvite,
    THandleSave
} from "modules/Settings/settings.types";

import { setIsLoading } from "modules/App/store/actions";
import { setModals, setMyAccount } from "modules/Settings/store/actions";

import {
    createInviteApi,
    deleteInviteApi,
    fetchInvitesApi,
    updateInviteApi
} from "modules/Settings/api";

import roles from "./roles";

import UsersUI from "./Users.ui";


const Users: TUsers = () => {
    //* Hooks
    const dispatch = useDispatch();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();
    const { errors, validate, setErrors } = useValidate();

    //* Redux State
    const settings = useSelector(({ settings }: IStoreState) => settings, shallowEqual);


    //* Side Effects
    const fetchInvites = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const { data: { data: guests } } = await fetchInvitesApi();

            batch(() => {
                dispatch(setMyAccount({ guests }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, settings.myAccount]);

    const createGuest = useCallback(async () => {
        try {
            if ( !settings.modals.guests.data?.contactInfo?.emailAddress ||
            !settings.modals.guests.data?.roleId) return;

            dispatch(setIsLoading(true));

            await createInviteApi({
                email: settings.modals.guests.data?.contactInfo?.emailAddress,
                role_id: settings.modals.guests.data.roleId,
            });

            batch(() => {
                dispatch(setModals({ guests: { edit: false, data: null }}));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, settings.modals.guests.data]);

    const updateGuest = useCallback(async () => {
        try {
            if ( !settings.modals.guests.data?._id ||
            !settings.modals.guests.data?.roleId) return;

            dispatch(setIsLoading(true));

            await updateInviteApi({
                user_id: settings.modals.guests.data._id,
                role_id: settings.modals.guests.data.roleId,
            });

            batch(() => {
                dispatch(setModals({ guests: { edit: false, data: null }}));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, settings.modals.guests.data]);

    const deleteGuest = useCallback(async () => {
        try {
            if ( !settings.modals.guests.data?._id ) return;

            dispatch(setIsLoading(true));

            await deleteInviteApi({
                user_id: settings.modals.guests.data._id,
            });

            batch(() => {
                dispatch(setModals({ guests: { delete: false, data: null }}));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, settings.modals.guests.data]);

    const reinviteGuest = useCallback(async (id: string) => {
        try {
            const guest = settings.myAccount.guests.find(guest => guest._id === id);
            if (!guest) return;

            dispatch(setIsLoading(true));

            await createInviteApi({
                email: guest.contactInfo.emailAddress,
                role_id: guest.roleId,
            });

            dispatch(setIsLoading(false));
            toast({ type: 'success', message: locales.general.success });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, settings.modals.guests.data]);


    //* Handlers
    const handleCreateEditList = useCallback(() => {
        dispatch(setModals({ guests: {
          create: true,
          data: {},
        }}));
    }, []);

    const handleEditList: THandleEditListHandler = useCallback((type) => (id) => () => {
        dispatch(setModals({ guests: {
          [type]: true,
          data: settings.myAccount.guests.find((element) => element._id === id),
        }}));
    }, [settings.myAccount.guests]);

    const handleResendInvite: THandleResendInvite = useCallback((id) => async () => {
        await reinviteGuest(id);
    }, [reinviteGuest]);

    const handleModalClose: THandleModalClose = useCallback((type) => () => {
        dispatch(setModals({ guests: { [type]: false, data: null } }));
    }, [dispatch, settings.modals]);

    const handleGuestChange: THandleGuestChange = useCallback(() => ({ target: { value } }) => {
        dispatch(setModals({ guests: {
            ...settings.modals.guests,
            data: {
                ...settings.modals.guests.data,
                contactInfo: {
                    emailAddress: value
                }
            }
        }}));
    }, [dispatch, settings.modals.guests]);

    const handleGuestDropdown: THandleGuestDropdown = useCallback((type) => ({value} ) => {
        if (Array.isArray(value)) return;
        dispatch(setModals({ guests: {
            ...settings.modals.guests,
            data: {
                ...settings.modals.guests.data,
                [type]: parseInt(value),
            }
        }}));
    }, [dispatch, settings.modals.guests]);


    const handleSave: THandleSave = useCallback((type) => async () => {
        switch (type) {
            case "guest":
                await createGuest();
                break;

            case "guestUpdate":
                await updateGuest();
                break;

            case "guestDelete":
                await deleteGuest();
                break;

            default:
                break;
        }

        await fetchInvites();
    }, [createGuest, updateGuest, deleteGuest, fetchInvites]);

    //* Effects
    useEffect(() => {
        fetchInvites();
    }, []);


    //* Component
    const data = useMemo(() => ({
        guests: guestToEditlist({
            data: settings.myAccount.guests,
            roles: roles
        }),
    }), [
        settings.myAccount.guests,
    ]);

    const options = useMemo(() => ({
        roles
    }), [
        roles
    ]);

    const handlers = useMemo(() => ({
        handleGuestChange,
        handleGuestDropdown,
        handleSave,
        editHandlers: {
            handleCreate: handleCreateEditList,
            handleEdit: handleEditList('edit'),
            handleDelete: handleEditList('delete'),
            customHandlers: [
                {
                    handler: handleResendInvite,
                    label: locales.settings.myAccount.users.reinvite,
                }
            ]
        },
        handleModalClose
    }), [
        handleGuestChange,
        handleGuestDropdown,
        handleSave,
        handleEditList,
        handleModalClose,
    ]);

    const modals = useMemo(() => ({
        ...settings.modals.guests
    }), [
        settings.modals.guests
    ]);

    return <UsersUI
        data={data}
        errors={errors}
        options={options}
        modals={modals}
        handlers={handlers}
    />;
};

export default memo(Users);
