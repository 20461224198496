import { memo, useMemo } from "react";
import { BackLink, Loader, Stepper } from "components";

import { locales, routes } from "constants/index";
import { formSubmit } from "utils";

import CreateOfferGeneralInformation from "./CreateOfferGeneralInformation";
import CreateOfferSkillRequired from "./CreateOfferRequiredSkills";
import CreateOfferAdditionalInformation from "./CreateOfferAdditionalInformation";
import CreateOfferPlans from "./CreateOfferPlans";
import CreateOfferPayment from "./CreateOfferPayment";

import { TCreateOfferUi } from "./CreateOffer.types";

import { CreateOfferStepsEnum } from "./enums";

import "./create-offer.scss";

const CreateOfferUi: TCreateOfferUi = ({
  isLoading,
  data: {
    isUserSubscribed,
    completedSteps,
    activeStepIndex,
    couponCode,
    subscriptionTypes,
    selectedSubscriptionType,
  },
  handlers,
}) => {
  let activeStep;

  const skills = useMemo(() =>
    <CreateOfferSkillRequired
      publish={true}
      isUserSubscribed={isUserSubscribed}
      handlePublishOffer={handlers.handlePublishOffer}
      handleSaveDraft={handlers.handleSaveOfferAsDraft}
    />
  , [
    isUserSubscribed,
    handlers.handlePublishOffer,
    handlers.handleSaveOfferAsDraft,
  ]);

  const additionalInformation = useMemo(() =>
    <CreateOfferAdditionalInformation
      isUserSubscribed={isUserSubscribed}
      handlePublishOffer={handlers.handlePublishOffer}
      handleSaveDraft={handlers.handleSaveOfferAsDraft}
    />
  , [
    isUserSubscribed,
    handlers.handlePublishOffer,
    handlers.handleSaveOfferAsDraft,
  ]);

  const plans = useMemo(() =>
    <CreateOfferPlans
      couponCode={couponCode}
      subscriptionTypes={subscriptionTypes}
      selectedSubscriptionType={selectedSubscriptionType}
      handlers={handlers}
    />
  , [
    couponCode,
    subscriptionTypes,
    selectedSubscriptionType,
    handlers,
  ]);

  const checkout = useMemo(() =>
    <CreateOfferPayment
      couponCode={couponCode}
      subscriptionTypes={subscriptionTypes}
      selectedSubscriptionType={selectedSubscriptionType}
    />
  , [
    couponCode,
    subscriptionTypes,
    selectedSubscriptionType,
  ]);

  const general = useMemo(() =>
    <CreateOfferGeneralInformation
      isUserSubscribed={isUserSubscribed}
      handleSaveDraft={handlers.handleSaveOfferAsDraft}
    />
  , [
    isUserSubscribed,
    handlers.handleSaveOfferAsDraft,
  ]);


  switch (activeStepIndex) {
    case CreateOfferStepsEnum.skills:
      activeStep = skills
      break;

    case CreateOfferStepsEnum.additionalInformation:
      activeStep = additionalInformation
      break;

    case CreateOfferStepsEnum.plans:
      activeStep = plans
      break;

    case CreateOfferStepsEnum.checkout:
      activeStep = checkout;
      break;

    default:
      activeStep = general;
      break;
  }

  const steps = useMemo(() => [
    {
      title: locales.createOffer.generalInformation,
      isCompleted: completedSteps[CreateOfferStepsEnum.generalInformation],
      url: `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.generalInfo}`,
    },
    {
      title: locales.createOffer.requiredSkills,
      isCompleted: completedSteps[CreateOfferStepsEnum.skills],
      url: `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.skills}`,
    }
  ], [completedSteps, isUserSubscribed]);

  const nextSteps = useMemo(() => [
    {
      title: locales.createOffer.additionalInformation,
      isCompleted: completedSteps[CreateOfferStepsEnum.additionalInformation],
      url: `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.additionalInfo}`,
    },
    {
      title: locales.createOffer.plans,
      isCompleted: completedSteps[CreateOfferStepsEnum.plans],
      url: `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.plans}`,
    },
    {
      title: locales.createOffer.payment,
      isCompleted: completedSteps[CreateOfferStepsEnum.checkout],
      url: `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.checkout}`,
    }
  ], [completedSteps]);

  if (!isUserSubscribed) steps.push(...nextSteps);

  return (
    <div className="create-offer">
      {isLoading && <Loader />}
      <div className="create-offer__header">
        <div className="create-offer__links_bar">
          <BackLink
            className='create-offer__link'
            path={activeStepIndex > 0
              ? steps[activeStepIndex - 1].url
              :`${routes.offers.main}/${routes.offers.listing}`
            }
          />
        </div>

        <div className="page-title">{locales.offers.createOffer}</div>
      </div>

      <Stepper
        activeIndex={activeStepIndex}
        steps={steps}
        className="create-offer__stepper"
        onStepClick={handlers.handleStepClick}
      />

      <form
        action=""
        className="create-offer__form"
        onSubmit={formSubmit}
        children={activeStep}
      />
    </div>
  );
};

export default memo(CreateOfferUi);
