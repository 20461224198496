"use client";

import { TypographyStylesProvider } from "@mantine/core";

import classNames from 'styles/sass/components/TypographyStylesProvider.module.scss';


export default TypographyStylesProvider.extend({
	defaultProps: {
		m: 0,
		p: 0,
	},
	classNames
})