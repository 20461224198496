import React, { ChangeEvent, FC } from 'react';
import { IEmail, ITrigger } from "../settings.types";
import { IDropdownObject } from "../../../common";
import { Delta, Sources } from "quill";
import ReactQuill, { UnprivilegedEditor } from "react-quill";
import { RichTextEditorSourcesEnum } from "components/Forms/RichTextEditor/RichTextEditor.types";

export enum SettingsCommunicationEnum {
  trigger_identifier = 'trigger_identifier',
  subject = 'subject',
}


export type THandleTextInputChange = (event: ChangeEvent<HTMLInputElement>) => void;
export type THandleTextInputBlur = (event: ChangeEvent<HTMLInputElement>) => void;

interface ISettingsCommunicationUiProps {
  data: {
    emails: IDropdownObject[];
    chosenVariables: string[];
    inEdition: boolean;
    inCreation: boolean;
    canReset: boolean;
    email: Partial<IEmail>;
  }
  handlers: {
    handleResetText: () => void;

    handleEditEmail: () => void;
    handleCreateEmail: () => void;
    handleDeleteEmail: () => void;
    handleSaveEmail: () => void;
    handleCancelEmail: () => void;

    handleChooseTrigger: (value: IDropdownObject) => void;
    handleTextEditorChange: (content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor) => void;
    handleToggleSendActivated: () => void;
    handleAddVariable: (variable: string) => void;
    handleTextInputChange: THandleTextInputChange;
    handleTextInputBlur: THandleTextInputBlur;
  }
  reactQuillRef: React.MutableRefObject<ReactQuill|null>;
}

type TSettingsCommunicationUi = FC<ISettingsCommunicationUiProps>;

type TSettingsCommunication = FC;

export {
  RichTextEditorSourcesEnum
};

export type { TSettingsCommunication, TSettingsCommunicationUi, ITrigger, Delta, Sources, UnprivilegedEditor };
