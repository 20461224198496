import { TextInput, FormControl, Button } from 'components';

import { Icons, Images } from 'assets';
import { locales } from 'constants/index';
import { formSubmit } from 'utils';

import AuthenticationLayout from '../AuthenticationLayout';

import './change-password.scss';

import { TChangePasswordUi } from './ChangePassword.types';

const ChangePasswordUi: TChangePasswordUi = ({
  isPasswordVisible,
  isPasswordConfirmationVisible,
  data: { form },
  errors,
  handlers: {
    handleInputChange,
    handleInputBlur,
    handlePasswordVisibilityChange,
    handlePasswordConfirmationVisibilityChange,
    handleSubmit,
  },
}) => {
  return (
    <AuthenticationLayout mainImage={Images.Company} isLeftContentRounded>
      <div className="change-password flex flex-justify-content-center">
        <div className="change-password__content flex flex-column">
          <div className="change-password__title">
            {locales.changePassword.title}
          </div>

          <div className="change-password__description change-password__description--reset">
            {locales.changePassword.choosePassword}
          </div>

          <form onSubmit={formSubmit} className="change-password__form">
            <FormControl
              id="password"
              label={locales.changePassword.newPassword}
              error={errors.password}
              className="change-password__field"
              elementClassName='flex'
              withRef
            >
              <TextInput
                id="password"
                name="password"
                type={isPasswordVisible ? 'text' : 'password'}
                value={form.password}
                isError={errors.password?.length > 0}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                rightContent={
                  <img
                    src={isPasswordVisible ? Icons.EyeGray : Icons.EyeClosed}
                    alt="eye"
                    className="register__password-icon"
                    onClick={handlePasswordVisibilityChange}
                  />
                }
              />
            </FormControl>

            <FormControl
              id="passwordConfirmation"
              label={locales.changePassword.confirmPassword}
              error={errors.passwordConfirmation}
              className="change-password__field"
              elementClassName='flex'
              withRef
            >
              <TextInput
                id="passwordConfirmation"
                name="passwordConfirmation"
                type={isPasswordConfirmationVisible ? 'text' : 'password'}
                value={form.passwordConfirmation}
                isError={errors.passwordConfirmation?.length > 0}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                rightContent={
                  <img
                    src={isPasswordVisible ? Icons.EyeGray : Icons.EyeClosed}
                    alt="eye"
                    className="register__password-icon"
                    onClick={handlePasswordConfirmationVisibilityChange}
                  />
                }
              />
            </FormControl>
          </form>

          <div className='change-password__submit flex flex-justify-content-center'>
            <Button
              variant="primary"
              onClick={handleSubmit}
            >
              {locales.changePassword.submit}
            </Button>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default ChangePasswordUi;
