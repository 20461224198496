import { memo, useState, useEffect, useCallback, useMemo, ChangeEvent } from 'react';
import { useSelector, shallowEqual, useDispatch, batch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Joi from 'joi';

import { useExtractServerError, useToast, useValidate } from 'hooks';
import { locales, routes } from 'constants/index';
import {
  setChosenCategory,
  setOfferFormCompletedStepAction,
  setOfferFormStepValuesAction,
  setSkillCategoriesAction,
  setSkillsAction,
} from 'modules/Offers/store/actions';
import { CreateOfferStepsEnum } from 'modules/Offers/CreateOffer/enums';

import CreateOfferRequiredSkillsUi from './CreateOfferRequiredSkills.ui';

import {
  TCreateOfferRequiredSkills,
  IStoreState,
  TRequiredBonus,
  ISkill,
} from './CreateOfferRequiredSkills.types';
import { fetchSkillsApi, fetchSkillsCategoriesApi} from "../../api";
import { CreateOfferRequiredSkillsEnum, SkillsTabEnum } from "./CreateOfferRequiredSkills.enums";

const validationSchema = {
  required: Joi.array().min(1).required().messages({
    'array.min': locales.formatString(locales.errors.array.minOne, { num: 1 }).toString(),
  }),
  softSkills: Joi.array().min(0).messages({
    'array.min': locales.errors.required,
  }),
  bonus: Joi.array().min(0).messages({
    'array.min': locales.errors.required,
  }),
};

const skillsPerCategory: {
  [key: number]: {
    category_id?: string;
    excluded_categories?: string[];
  }
} = {
  [SkillsTabEnum.required]: {
    excluded_categories: [
      '6606b64201ad56002170790d'
    ]
  },
  [SkillsTabEnum.softSkills]: {
    category_id: '6606b64201ad56002170790d',
    excluded_categories: [],
  },
}

const CreateOfferRequiredSkills: TCreateOfferRequiredSkills = ({
  publish = false,
  isUserSubscribed,
  handlePublishOffer,
  handleSaveDraft,
}) => {
  //* Hooks
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { errors, setErrors, validate } = useValidate();

  //* Redux State
  const {
    form: { values: offerForm,},
    skillCategories: reduxSkillCategories,
    skills: reduxSkills,
    chosenCategory,
  } = useSelector(({ offers }: IStoreState) => offers, shallowEqual);

  //* Memos
  const { required, bonus } =
    useMemo(() => ({ required: offerForm.required, bonus: offerForm.bonus }), [offerForm]);
  const allSkills = useMemo(() => reduxSkills, [reduxSkills]);
  const skillCategories = useMemo(() => reduxSkillCategories, [reduxSkillCategories]);

  //* Local State
  const [searchString, setSearchString] = useState<string>('');
  const [skills, setSkills] = useState<ISkill[]>([]);

  //* Side Effects
  const fetchSkillsCategories = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await fetchSkillsCategoriesApi();

      dispatch(setSkillCategoriesAction(data));

    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: "error", message: errorMessage });
    }
  }, []);

  const fetchSkills = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await fetchSkillsApi({
        category_exists: true,
        search_string: searchString,
        ...searchString && skillsPerCategory[chosenCategory]
      });

      setSkills(data.map(skill => ({
        ...skill,
        required: required.includes(skill._id),
        bonus: bonus.includes(skill._id),
      })));

      if (searchString !== '') return;
      dispatch(setSkillsAction(data.map(skill => ({
        ...skill,
        required: required.includes(skill._id),
        bonus: bonus.includes(skill._id),
      }))));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: "error", message: errorMessage });
    }
  }, [searchString, required, bonus, chosenCategory]);

  //* Handlers
  const handleSearch = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchString(target.value);
  }, [offerForm]);

  const handleToggleSkill = useCallback((ignoreType: TRequiredBonus) => (skillKey: string) => {
    const type = 'required';
    setSkills(skills => skills.map(skill => skill._id === skillKey ? { ...skill, [type]: !skill[type] } : skill));

    setErrors((prevErrors) => ({ ...prevErrors, [type]: '' }));

    batch(() => {
      dispatch(setSkillsAction(
        allSkills.map(skill => skill._id === skillKey ? { ...skill, [type]: !skill[type] } : skill)
      ));
      dispatch(setOfferFormStepValuesAction({
        [type]: [
          ...allSkills.filter(skill => {
            if (skill._id === skillKey) return !skill[type];
            return skill[type] === true;
          }).map(item => item._id)
        ],
      }));
    })
  }, [allSkills, skills, offerForm]);

  const handleRequiredSkillsSubmit = useCallback(async () => {
    const data = { required, bonus };

    const isFormValid = validate({ data, validationSchema });
    if (!isFormValid) return;

    // --- DISABLED STRIPE
    //
    // if (!id)
    //   navigate(`${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.additionalInfo}`);
    // else
    //   navigate(`${routes.offers.main}/${routes.offers.create.main}/${id}/${routes.offers.create.additionalInfo}`);

    navigate(`${routes.offers.main}/${routes.offers.listing}`);

    batch(() => {
      dispatch(setOfferFormStepValuesAction(data));
      dispatch(setOfferFormCompletedStepAction({
        stepIndex: CreateOfferStepsEnum.skills,
        isCompleted: true,
      }));
    });

    publish && handlePublishOffer({...offerForm, ...data});
  }, [id, offerForm, required, bonus]);

  const handleSubmitDraft = useCallback(() => {
    const required = allSkills.filter((skill) => !!skill.required).map(skill => skill._id);
    const bonus = allSkills.filter((skill) => !!skill.bonus).map(skill => skill._id);
    handleSaveDraft({
      ...offerForm,
      required,
      bonus,
    })
  }, [allSkills]);

  const handleCategoryTabClick = useCallback((index: SkillsTabEnum) => {
      dispatch(setChosenCategory(index));
  }, [dispatch]);

  //* Effects
  useEffect(() => {
    fetchSkillsCategories();
  }, []);

  useEffect(() => {
    fetchSkills();
  }, [searchString, chosenCategory]);

  const types: TRequiredBonus[] = useMemo(() => [
    CreateOfferRequiredSkillsEnum.required,
    CreateOfferRequiredSkillsEnum.softSkills,
  ], []);

  const data = useMemo(() => ({
    types,
    skills,
    allSkills,
    skillCategories,
    searchString,
    chosenCategory,
  }), [
    types,
    skills,
    allSkills,
    skillCategories,
    searchString,
    chosenCategory,
  ]);

  const handlers = useMemo(() => ({
    handleToggleSkill,
    handleSubmitDraft,
    handleRequiredSkillsSubmit,
    handleSearch,
    handleCategoryTabClick,
  }), [
    handleToggleSkill,
    handleSubmitDraft,
    handleRequiredSkillsSubmit,
    handleSearch,
    handleCategoryTabClick,
  ]);

  return (
    <CreateOfferRequiredSkillsUi
      publish={publish}
      isUserSubscribed={isUserSubscribed}
      errors={errors}
      data={data}
      handlers={handlers}
    />
  );
};

export default memo(CreateOfferRequiredSkills);
