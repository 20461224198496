import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import { TDropzone } from './Dropzone.types';

import './dropzone.scss';

const Dropzone: TDropzone = ({
  isMultiple = true,
  isDisabled = false,
  maxSize = 1000000,
  minSize = 1000,
  maxFiles = 5,
  className = '',
  fileTypes = { 'image/png': ['.png'], 'image/jpeg': [',jpeg', '.jpg'] },
  onUploadFile,
  description = '',
}) => {
  const [error, setError] = useState<string>('');
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        setError('');
        onUploadFile(acceptedFiles);
      }
    },
    [onUploadFile]
  );
  const onDropRejected = useCallback((files: any) => {
    setError(files[0].errors[0].message);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    multiple: isMultiple,
    disabled: isDisabled,
    maxSize,
    minSize,
    accept: fileTypes,
    maxFiles,
  });

  return (
    <div
      className={classNames({
        'drop-zone': true,
        disabled: isDisabled,
        [className]: className.length > 0,
      })}
    >
      <div
        className={classNames({
          'drop-zone__area flex flex-align-items-center flex-justify-content-center': true,
          disabled: isDisabled,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className="drop-zone__text flex flex-column flex-align-items-center">
          <div className="drop-zone__add" />

          {isDragActive ? 'Upload you cv...' : description}
        </div>
      </div>

      {error.length > 0 && <div className="drop-zone__error">{error}</div>}
    </div>
  );
};

export default Dropzone;
