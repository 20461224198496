import {
  PaymentPlan,
  Tabs,
  TabPanel,
  Button,
  FormControl,
  TextInput,
  FormLayout,
} from "components";

import { locales } from "constants/index";

import { TCreateOfferPlansUi } from "./CreateOfferPlans.types";

const CreateOfferPlansUi: TCreateOfferPlansUi = ({
  subscriptionTypes,
  selectedSubscriptionType,
  activeTabIndex,
  couponCode,
  handlers: {
    handleSubscriptionTypeClick,
    handleTabClick,
    handleNextStepClick,
    handleCouponChange,
  },
}) => {
  const numberOfOffers = [2, 5, 10];
  return (
    <>
      <FormLayout title={locales.createOffer.plans}>
        <Tabs activeTab={activeTabIndex} onClick={handleTabClick}>
          <TabPanel title={`1 ${locales.general.month}`}>
            <div className="flex flex-justify-content-center">
              {subscriptionTypes
                .filter(
                  ({ recurring: { interval, interval_count } }) =>
                    interval === "month" && interval_count === 1
                )
                .map(({ id, default_price, name, unit_amount }, index) => (
                  <PaymentPlan
                    key={id}
                    name={name}
                    price={(unit_amount / 100).toString()}
                    type={locales.general.month}
                    isRecommended={name.toLowerCase().includes("pro")}
                    numberOfOffers={numberOfOffers[index]}
                    isSelected={selectedSubscriptionType === default_price}
                    onClick={handleSubscriptionTypeClick(default_price)}
                  />
                ))}
            </div>
          </TabPanel>

          <TabPanel title={`3 ${locales.general.month}`}>
            <div className="flex flex-justify-content-center">
              {subscriptionTypes
                .filter(
                  ({ recurring: { interval, interval_count } }) =>
                    interval === "month" && interval_count === 3
                )
                .map(({ id, default_price, name, unit_amount }, index) => (
                  <PaymentPlan
                    key={id}
                    name={name}
                    price={(unit_amount / 100).toString()}
                    type={`3 ${locales.general.month}`}
                    isRecommended={name.toLowerCase().includes("pro")}
                    numberOfOffers={numberOfOffers[index]}
                    isSelected={selectedSubscriptionType === default_price}
                    onClick={handleSubscriptionTypeClick(default_price)}
                  />
                ))}
            </div>
          </TabPanel>

          <TabPanel title={`12 ${locales.general.month}`}>
            <div className="flex flex-justify-content-center">
              {subscriptionTypes
                .filter(
                  ({ recurring: { interval, interval_count } }) =>
                    interval === "year" && interval_count === 1
                )
                .map(({ id, default_price, name, unit_amount }, index) => (
                  <PaymentPlan
                    key={id}
                    name={name}
                    price={(unit_amount / 100).toString()}
                    type={locales.general.year}
                    isRecommended={name.toLowerCase().includes("pro")}
                    numberOfOffers={numberOfOffers[index]}
                    isSelected={selectedSubscriptionType === default_price}
                    onClick={handleSubscriptionTypeClick(default_price)}
                  />
                ))}
            </div>
          </TabPanel>
        </Tabs>
      </FormLayout>

      <FormLayout title={"Coupon code"}>
        <div className="create-offer__coupon-wrapper flex">
          <FormControl label={"Coupon code"} className="create-offer__coupon" withRef>
            <TextInput onChange={handleCouponChange} value={couponCode} />
          </FormControl>
        </div>
      </FormLayout>

      <div className="create-offer__actions">
        <Button
          variant="primary"
          onClick={handleNextStepClick}
          isDisabled={selectedSubscriptionType.length === 0}
        >
          {locales.general.next}
        </Button>
      </div>
    </>
  );
};

export default CreateOfferPlansUi;
