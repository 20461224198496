enum ContractEnums {
    STAGE = "Stage",
    PRO = "Contrat pro",
    APPRENTISSAGE = "Apprentissage",
    PEU_IMPORTE = "Peu importe",
    CDI = "CDI",
}

enum PublicationStatusEnums {
    PUBLISHED = 'PUBLISHED',
    ARCHIVED = 'ARCHIVED',
    DRAFT = 'DRAFT',
    AWAITING_APPROVAL = 'AWAITING_APPROVAL',
}

export {
    ContractEnums,
    PublicationStatusEnums,
}