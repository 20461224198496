import { rem } from "@mantine/core";

import type { IconProps } from "assets/tsxIcons/types";


export const BusinessUserCurriculum = ( { size, style, ...others }: IconProps ) => (
    <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={ { width: rem( size ), height: rem( size ), ...style } }
        { ...others }
    >
        <path
            d="M22.9802 20.5846C23.1794 17.3882 23.2518 14.1852 23.1975 10.9831C23.1857 9.5345 22.7041 8.1288 21.8251 6.97729C20.4805 5.24643 19.2585 4.02443 17.4144 2.6C16.8234 2.14713 16.103 1.89527 15.3585 1.88129C14.5724 1.86463 13.7862 1.85658 12.9999 1.85714C10.4798 1.85714 8.44623 1.93143 6.34394 2.07814C5.47924 2.13635 4.66521 2.507 4.05358 3.12102C3.44196 3.73503 3.07449 4.55051 3.01966 5.41543C2.86257 7.94056 2.78453 10.47 2.78566 13C2.78566 15.6 2.86551 18.1369 3.01966 20.5846C3.13109 22.3749 4.55366 23.7993 6.34394 23.9219C8.44623 24.0686 10.4798 24.1429 12.9999 24.1429C15.5201 24.1429 17.5537 24.0686 19.6559 23.9219C20.5206 23.8637 21.3347 23.493 21.9463 22.879C22.5579 22.265 22.9254 21.4495 22.9802 20.5846Z"
            fill="#CDEBE7"/>
        <path
            d="M22.9802 20.5846C23.1794 17.3882 23.2518 14.1852 23.1975 10.9831C23.1857 9.5345 22.7041 8.1288 21.8251 6.97729C20.4805 5.24643 19.2585 4.02443 17.4144 2.6C16.8234 2.14713 16.1029 1.89527 15.3585 1.88129C14.5724 1.86463 13.7862 1.85658 12.9999 1.85714C10.4798 1.85714 8.44623 1.93143 6.34394 2.07814C5.47924 2.13635 4.66521 2.507 4.05358 3.12102C3.44196 3.73503 3.07449 4.55051 3.01966 5.41543C2.86257 7.94056 2.78453 10.47 2.78566 13C2.78566 15.6 2.86551 18.1369 3.01966 20.5846C3.13109 22.3749 4.55366 23.7993 6.34394 23.9219C8.44623 24.0686 10.4798 24.1429 12.9999 24.1429C15.5201 24.1429 17.5537 24.0686 19.6559 23.9219C20.5206 23.8637 21.3347 23.493 21.9463 22.879C22.5579 22.265 22.9254 21.4495 22.9802 20.5846Z"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.71729 16.276H17.7933" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.71729 20.1166H14.079" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M9.93568 10.2997C11.5142 10.2997 12.9257 11.1429 12.9257 12.2312H6.94568C6.94568 11.1429 8.35711 10.2997 9.93568 10.2997Z"
            fill="#CDEBE7"/>
        <path
            d="M12.9257 12.233C12.9257 11.1429 11.5142 10.3016 9.93568 10.3016C8.35711 10.3016 6.94568 11.1429 6.94568 12.233"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M9.93569 10.2997C10.5514 10.2997 11.1418 10.0551 11.5772 9.61979C12.0125 9.18443 12.2571 8.59397 12.2571 7.97829C12.2571 7.36261 12.0125 6.77214 11.5772 6.33679C11.1418 5.90144 10.5514 5.65686 9.93569 5.65686C9.32001 5.65686 8.72954 5.90144 8.29419 6.33679C7.85884 6.77214 7.61426 7.36261 7.61426 7.97829C7.61426 8.59397 7.85884 9.18443 8.29419 9.61979C8.72954 10.0551 9.32001 10.2997 9.93569 10.2997Z"
            fill="#CDEBE7"/>
        <path
            d="M9.93569 10.2997C10.5514 10.2997 11.1418 10.0551 11.5772 9.61979C12.0125 9.18443 12.2571 8.59397 12.2571 7.97829C12.2571 7.36261 12.0125 6.77214 11.5772 6.33679C11.1418 5.90144 10.5514 5.65686 9.93569 5.65686C9.32001 5.65686 8.72954 5.90144 8.29419 6.33679C7.85884 6.77214 7.61426 7.36261 7.61426 7.97829C7.61426 8.59397 7.85884 9.18443 8.29419 9.61979C8.72954 10.0551 9.32001 10.2997 9.93569 10.2997Z"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);