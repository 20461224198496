import { SET_FILTER_PAGINATION, SET_FILTER_FILTER, RESET_FILTER_FILTER, filterActionTypes } from './action-types';

import { IFilterGlobalState, TFilterActions } from './store.types';

const initialState: IFilterGlobalState = {
  offers: {
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 1,
    },
    filter: {},
  },
  drafts: {
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 1,
    },
    filter: {},
  },
  listing: {
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 1,
    },
    filter: {},
  },
  classrooms: {
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 1,
    },
    filter: {},
  },
  selectCVs: {
    pagination: {
      page: 1,
      limit: 6,
      totalPages: 1,
    },
    filter: {},
  },
  archives: {
    pagination: {
      page: 1,
      limit: 10,
      totalPages: 1,
    },
    filter: {},
  },
};

const reducer = (
  state: IFilterGlobalState = initialState,
  action: TFilterActions
) => {
  switch (action.type) {
    case SET_FILTER_PAGINATION:
      return {
        ...state,
        [action.moduleName]: {
          ...state[action.moduleName],
          pagination: {
            ...state[action.moduleName].pagination,
            ...action.payload,
          },
        },
      };

    case SET_FILTER_FILTER:
      return {
        ...state,
        [action.moduleName]: {
          ...state[action.moduleName],
          filter: {
            ...state[action.moduleName].filter,
            ...action.payload,
          },
        },
      };

    case RESET_FILTER_FILTER:
      return {
        ...state,
        [action.moduleName]: {
          ...state[action.moduleName],
          filter: initialState[action.moduleName].filter,
        },
      };

    case filterActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
