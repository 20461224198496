import { memo } from 'react';

import { Button } from "components";

import { locales } from "constants/index";

import { TTableResume } from './TableResume.types';

import 'components/Table2/table.scss';
import './table-resume.scss';

const TableResume: TTableResume = ({
    onClick,
}) => {

    return (
        <div className='td__action end'>
            <Button
                className='table-resume'
                variant="secondary"
                size="extrasmall"
                onClick={onClick}
            >
                <span className='table-resume-icon eye' />
                <span className='table-resume-text'>{locales.student.viewCv}</span>
            </Button>
        </div>
    );
};

export default memo(TableResume);