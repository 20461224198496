import {
  Modal,
  FormControl,
  TextInput,
  Button,
  Dropdown,
} from 'components';

import { locales } from 'constants/index';

import { TAddClassModal } from './AddClassModal.types';

import styles from './add-class-modal.module.scss';
import styles2 from '../AddCvsModal/add-cvs-modal.module.scss';

export const AddClassModal: TAddClassModal = ({
  newClass,
  degrees,
  selectedDegrees,
  onAddClass,
  onClassChange,
  onSelectedDegreesChange,
  onClose,
}) => {
  return (
    <Modal
      title={locales.resumes.addClass}
      className={styles["add-class-modal"]}
      onClose={onClose}
      actionClassName={styles2["add-cvs-modal__actions"]}
      actionButtons={
        <Button
          variant="primary"
          onClick={onAddClass}
          isDisabled={newClass.length === 0 || selectedDegrees.length === 0}
        >
          {locales.resumes.addClass}
        </Button>
      }
    >
      <div className={styles2["add-cvs-modal__content"]}>
        <div className={styles2["add-cvs-modal__dropdowns"]}>
          <div className={styles2["add-cvs-modal__dropdown"]}>
            <FormControl id="class" className={styles2["add-cvs-modal__form-control"]} label={locales.general.class} withRef>
              <TextInput id="class" className={styles["field-input"]} value={newClass} onChange={onClassChange} />
            </FormControl>
          </div>
          <div className={styles2["add-cvs-modal__dropdown"]}>
            <FormControl id="degrees" className={styles2["add-cvs-modal__form-control"]} withRef>
              <Dropdown
                isFixedHeight
                id="degree"
                placeholder={locales.resumes.durationOfTraining}
                options={degrees}
                value={selectedDegrees}
                onChange={onSelectedDegreesChange}
                isMulti
              />
            </FormControl>
          </div>
        </div>
      </div>
    </Modal>
  );
};
