import { baseApi } from 'utils';

import {
  TInterviewDetailsApi,
  TSendEmailApi,
} from './api.types';

export const interviewDetailsApi: TInterviewDetailsApi = (payload) => {
  return baseApi().post('/school/ypareo/apprenant', payload);
}

export const sendEmailApi: TSendEmailApi = (payload) => {
  return baseApi().post('/notification/email/candidate', payload);
}
