import { useState, useEffect } from 'react';

import { useToast, useExtractServerError } from 'hooks';

import AttachmentsLibraryUi from './AttachmentsLibrary.ui';
import { fetchAttachmentsApi, uploadAttachmentApi } from './api';

import { TAttachmentsLibrary, IAttachment } from './AttachmentsLibrary.types';

const AttachmentsLibrary: TAttachmentsLibrary = ({
  onSelectAttachments,
  onUploadAttachments,
  allowUpload = true,
  isMultiSelection = true,
  enableSelect = true,
}) => {
  //* Hooks
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [selectedAttachments, setSelectedAttachments] = useState<IAttachment[]>(
    []
  );

  //* Handlers
  const handleUploadAttachments = (attachments: File[]) => {
    onUploadAttachments && onUploadAttachments(attachments);

    uploadAttachments(attachments);
  };

  const handleSelectAttachments = (attachment: IAttachment) => () => {
    if (!enableSelect) return;

    if (!isMultiSelection) {
      onSelectAttachments && onSelectAttachments([attachment]);
      return setSelectedAttachments([attachment]);
    }

    const prevSelectedAttachments = selectedAttachments;
    const selectedAttachment = prevSelectedAttachments.find(
      (selectedAttachment) => selectedAttachment._id === attachment._id
    );

    if (selectedAttachment) {
      const filteredAttachments = prevSelectedAttachments.filter(
        ({ _id: id }) => id !== attachment._id
      );

      onSelectAttachments && onSelectAttachments(filteredAttachments);

      return setSelectedAttachments(filteredAttachments);
    }

    onSelectAttachments &&
      onSelectAttachments([...prevSelectedAttachments, attachment]);
    setSelectedAttachments((prevValue) => [...prevValue, attachment]);
  };

  //* Side Effects
  const fetchAttachments = async () => {
    try {
      setIsLoading(true);

      const {
        data: { data },
      } = await fetchAttachmentsApi();

      setAttachments(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      setIsLoading(false);
    }
  };

  const uploadAttachments = async (attachments: File[]) => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      attachments.forEach((attachment) => {
        formData.append('attachments', attachment);
      });

      await uploadAttachmentApi(formData);
      await fetchAttachments();
    } catch (error) {
      setIsLoading(false);

      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAttachments();
  }, []);

  const data = {
    attachments,
    selectedAttachments,
  };

  const handlers = {
    handleUploadAttachments,
    handleSelectAttachments,
  };

  return (
    <AttachmentsLibraryUi
      isLoading={isLoading}
      allowUpload={allowUpload}
      enableSelect={enableSelect}
      data={data}
      handlers={handlers}
    />
  );
};

export default AttachmentsLibrary;
