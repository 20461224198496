import { useCallback, useEffect, useMemo, useRef } from "react";
import { useExtractServerError, useToast } from "../../../hooks";
import { batch, useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";

import {
  createEmailTemplateApi,
  deleteEmailTemplateApi,
  fetchEmailTemplateMultiApi,
  updateEmailTemplateApi
} from "../api";
import {
  setChosenIdentifier,
  setInEdition,
  setCanReset,
  setEmails,
  setEmail,
  resetEmail,
  setInCreation
} from '../store/actions';

import SettingsCommunicationUi from './SettingsCommunication.ui';

import { TSettingsCommunication, Delta, Sources, RichTextEditorSourcesEnum, UnprivilegedEditor, THandleTextInputChange, THandleTextInputBlur } from './SettingsCommunication.types';
import { IEmail, IStoreState } from "../settings.types";
import { convertToDropdown, IDropdownObject } from "../../../utils";
import { setIsLoading } from "../../App/store/actions";

const SettingsCommunication: TSettingsCommunication = () => {
  //* Hooks
  const { extractErrorMessage } = useExtractServerError();
  const dispatch = useDispatch();
  const toast = useToast();
  const reactQuillRef = useRef<ReactQuill|null>(null);

  //* Redux State
  const {
    emails,
    email,
    chosenVariables,
    canReset,
    inEdition,
    inCreation,
  } = useSelector(({ settings : { communication }}: IStoreState) => communication);


  //* Side Effects
  const fetchCommunicationInfo = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data: emails }
      } = await fetchEmailTemplateMultiApi();
      dispatch(setEmails(emails));

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch])

  const submitEmail = useCallback(async (isCreating = false) => {
    try {
      dispatch(setIsLoading(true));
      if (!email) return dispatch(setIsLoading(false));

      const data = {
        id: email._id,
        trigger_identifier: email?.trigger_identifier,
        body: email?.body,
        subject: email?.subject,
        active: email?.active,
      }

      if (isCreating) {
        await createEmailTemplateApi(data);
      } else {
        await updateEmailTemplateApi(data);
      }

      batch(() => {
        dispatch(resetEmail());
        dispatch(setCanReset(false));
        dispatch(setInCreation(false));
        dispatch(setInEdition(false));
        dispatch(setIsLoading(false));
      });

      await fetchCommunicationInfo();
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [fetchCommunicationInfo, email])

  const deleteEmail = useCallback(async () => {
    try {
      if (!email.trigger_identifier) return;
      dispatch(setIsLoading(true));

      await deleteEmailTemplateApi({
        trigger_identifier: email.trigger_identifier,
      })

      batch(() => {
        dispatch(resetEmail());
        dispatch(setInCreation(false));
        dispatch(setInEdition(false));
        dispatch(setIsLoading(false));
      });

      await fetchCommunicationInfo();
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, fetchCommunicationInfo, email])


  //* Handlers
  const handleEditEmail = useCallback(() => {
    dispatch(setInEdition(true));
  }, [dispatch]);

  const handleCreateEmail = useCallback(() => {
    dispatch(resetEmail());
    dispatch(setInCreation(true));
    dispatch(setInEdition(true));
  }, [dispatch]);

  const handleDeleteEmail = useCallback(() => {
    deleteEmail();
  }, [deleteEmail]);

  const handleCancelEmail = useCallback(() => {
    batch(() => {
      dispatch(resetEmail());
      dispatch(setInEdition(false));
      dispatch(setInCreation(false));
    });
  }, [dispatch]);

  const handleSaveEmail = useCallback(() => {
    if (inEdition) submitEmail(inCreation);
    dispatch(setInEdition(false));
    dispatch(setInCreation(false));
  }, [dispatch, inEdition, inCreation, submitEmail]);


  const handleChooseTrigger = useCallback(({value}: IDropdownObject) => {
    dispatch(setEmail(emails.find(email => email._id === value) ?? {}))
    dispatch(setChosenIdentifier(value));
    dispatch(setCanReset(false));
  }, [emails]);

  const handleToggleSendActivated = useCallback(() => {
    if (!email || !inEdition) return;
    dispatch(setEmail({ active: !email?.active }));
  }, [email, inEdition])

  const handleResetText = useCallback(() => {
    batch(() => {
      dispatch(setEmail(emails.find(element => element._id === email._id) ?? {}));
      dispatch(setCanReset(false));
    });

    if (!reactQuillRef) return;
    const editor = reactQuillRef.current?.getEditor();
    if (!editor) return;
    editor.setSelection(0, 0);
  }, [emails, email]);

  const handleTextEditorChange = useCallback((content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor) => {
    if (!email || source === RichTextEditorSourcesEnum.api) return;
    if (source === RichTextEditorSourcesEnum.user && email && !inCreation) dispatch(setCanReset(true));
    dispatch(setEmail({ body: content }));
  }, [email, inCreation]);

  const handleAddVariable = useCallback((variable: string) => {
    if (!reactQuillRef) return;
    const editor = reactQuillRef.current?.getEditor();
    if (!editor) return;
    const selection = editor?.getSelection(true).index ?? 0;
    const text = ` ${variable}`;
    editor.insertText(selection, text, RichTextEditorSourcesEnum.user);
    // editor.formatText(selection + 3, text.length - 5, 'bold', true, RichTextEditorSourcesEnum.user);
    // editor.formatText(selection + 3, text.length - 5, 'italic', true, RichTextEditorSourcesEnum.user);
    // editor.formatText(selection + 3, text.length - 5, 'color', '#12564b', RichTextEditorSourcesEnum.user);
    editor.setSelection(selection + text.length, 0);

    !inCreation && (dispatch(setCanReset(true)));
  }, [reactQuillRef, inCreation]);

  const handleTextInputChange: THandleTextInputChange = useCallback(({ target: { name, value } }) => {
    dispatch(setEmail({ ...email, [name]: value }));
    // setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  }, [dispatch]);

  const handleTextInputBlur: THandleTextInputBlur = useCallback(({ target: { name, value } }) => {
    // if (!validationSchema[name as AddToYPareoModalEnums]) return;
    // validate({
    //   data: { [name]: value },
    //   validationSchema: { [name]: validationSchema[name as AddToYPareoModalEnums] },
    // });
  }, [dispatch, ]);


  //* Effects
  useEffect(() => {
    fetchCommunicationInfo();
  }, []);


  //* Component
  const data = useMemo(() => ({
    emails: convertToDropdown({ labelKey: "trigger_identifier", valueKey: "_id", data: emails }),
    chosenVariables,
    inEdition,
    inCreation,
    canReset,
    email,
  }), [
    emails,
    chosenVariables,
    inEdition,
    inCreation,
    canReset,
    email,
  ]);

  const handlers = useMemo(() => ({
    handleResetText,

    handleEditEmail,
    handleCreateEmail,
    handleDeleteEmail,
    handleSaveEmail,
    handleCancelEmail,

    handleChooseTrigger,
    handleTextEditorChange,
    handleToggleSendActivated,
    handleAddVariable,
    handleTextInputChange,
    handleTextInputBlur,
  }), [
    handleResetText,

    handleEditEmail,
    handleCreateEmail,
    handleDeleteEmail,
    handleSaveEmail,
    handleCancelEmail,

    handleChooseTrigger,
    handleTextEditorChange,
    handleToggleSendActivated,
    handleAddVariable,
    handleTextInputChange,
    handleTextInputBlur,
  ]);

  return (
    <SettingsCommunicationUi
      data={data}
      handlers={handlers}
      reactQuillRef={reactQuillRef}
    />
  );
};
export default SettingsCommunication;
