import { memo } from "react";
import classNames from "classnames";
import { Droppable } from "@hello-pangea/dnd";
import { Box, ScrollArea } from "@mantine/core";

import { TDraggableColumn } from "./DraggableColumn.types";

import "./draggable-column.scss";

const DraggableColumn: TDraggableColumn = ({ id, children, className = "" }) => {
    return (
        <Droppable droppableId={id} isCombineEnabled>
            {(provided, snapshot) => {
                return (
                    <ScrollArea
                        classNames={{ viewport: "draggable-column__scroll-area" }}
                        component={Box}
                        h="calc(100% - 5rem)"
                        scrollbars="y"
                        offsetScrollbars
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classNames({
                            "draggable-column": true,
                            "draggable-column--dragging-over": snapshot.isDraggingOver,
                            [className]: className.length > 0,
                        })}
                    >
                        {children}

                        {provided.placeholder}
                    </ScrollArea>
                );
            }}
        </Droppable>
    );
};

export default memo(DraggableColumn);
