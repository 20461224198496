import Joi from "joi";
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { HexColorPicker } from "react-colorful";
import ClassNames from 'classnames';

import { TextInput } from "components";

import { locales } from "constants/index";
import { useValidate } from "hooks";

import { TColorPicker } from './ColorPicker.types';
import { IHandleTextInoutChange } from 'common';

import './color-picker.scss';

const validationSchema = {
  color: Joi.string()
    .required()
    .pattern(/^#[A-Fa-f0-9]{6}$/)
    .min(7)
    .max(7)
    .messages({
      'string.empty': locales.errors.required,
      'string.alphanum': locales.errors.hex,
      'string.pattern.base': locales.errors.colorPattern,
      'string.min': locales.formatString(locales.errors.minLength, { charNum: 7 }).toString(),
      'string.max': locales.formatString(locales.errors.maxLength, { charNum: 7 }).toString(),
    }),
};


const ColorPicker: TColorPicker = ({
  className = '',
  onChange,
  color = '#FFFFFF',
}) => {
  //* Hooks
  const { errors, validate, setErrors } = useValidate();

  //* Local State
  const [open, setOpen] = useState<boolean>(false);
  const [tempColor, setTempColor] = useState<string>(color);

  //* Refs
  const pickerRef = useRef<HTMLDivElement>(null);

  //* Handlers
  const change = (newColor: string) => {
    onChange(newColor);
  }

  const handleTempColorChange: IHandleTextInoutChange = useCallback(({target: { value }}) => {
    if (!value.startsWith('#')) value = `#${value}`;
    setTempColor(value);
    const isValid = validate({ data: { color: value }, validationSchema });
    if (!isValid) return;
    setErrors((prevErrors) => ({ ...prevErrors, color: '' }));
    onChange(value);
  }, [validate, tempColor]);

  const handleShowColorPicker = useCallback((show?: boolean) => {
    if (show !== undefined) return setOpen(show);
    setOpen(p => !p);
  }, [setOpen]);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (!event.target
        || !pickerRef.current
        || pickerRef.current.contains(event.target  as Node)
    ) return;

    handleShowColorPicker(false);
    setTempColor(color);
    setErrors((prevErrors) => ({ ...prevErrors, color: '' }));
  }, [pickerRef, open, color]);

  //* Effects
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [
    pickerRef,
    handleClickOutside
  ]);

  useEffect(() => {
    setTempColor(color);
  }, [color]);

  //* Classes
  const classNames = ClassNames({
    'color-picker': true,
    [className]: !!className,
  });

  const pickerClassNames = ClassNames({
    'color-picker__popover': true,
    'hide': !open,
  });

  return (
    <div className={classNames} ref={pickerRef}>
      <div
        className='color-picker__content'
        onClick={() => handleShowColorPicker()}
      >
        <div className='color-picker__thumbnail' style={{ backgroundColor: color }} />
        <div className='color-picker__color'>
          <TextInput
            value={tempColor}
            maxLength={7}
            onChange={handleTempColorChange}
            isSmall
            className={ClassNames({
              'color-picker__fc__textInput': true,
              'colorError': !!errors.color,
            })}
          />
        </div>
      </div>
      <div className={pickerClassNames}>
        <HexColorPicker onChange={change} color={color} />
      </div>
    </div>
  );
};

export default memo(ColorPicker);
