import { IArchivesGlobalState, TArchivesAction } from './store.types';
import { archivesActionTypes } from "./action-types";

const initialState: IArchivesGlobalState = {
  list: [],
  selected: [],
};

export const archivesReducer = (state: IArchivesGlobalState = initialState, action: TArchivesAction) => {
  switch (action.type) {
    case archivesActionTypes.SET_ARCHIVES:
      return {
        ...state,
        list: action.payload,
      };

    case archivesActionTypes.SET_ARCHIVES_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };

    case archivesActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};
