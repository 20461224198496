import LocalizedStrings from "react-localization";

const locales = new LocalizedStrings({
    fr: {
        general: {
            new: "Nouveau",
            showMore: "Plus",
            showLess: "Moins",
            next: "suivant",
            email: "Email",
            password: "Mot de passe",
            appTitle: "Bloom alternance",
            appDescription: "Diffusez, qualifiez et recrutez efficacement!",
            lastName: "Nom",
            firstName: "Prénom",
            company: "Entreprise",
            school: "École",
            city: "Ville",
            role: "Rôle",
            send: "Envoyer",
            login: "Se connecter",
            logout: "Se déconnecter",
            back: "Retour",
            level: "Niveau",
            promotions: "Promotions",
            class: "Classe",
            delete: "Supprimer",
            create: "Créer",
            cancel: "Annuler",
            edit: "Modifier",
            save: "Enregistrer",
            candidateFollowUp: "Suivi candidats",
            settings: "Paramètres",
            description: "Description",
            notApplicable: "N/A",
            notifications: "Notifications",
            previous: "Précèdent",
            nextEng: "Next",
            page: "Page",
            of: "of",
            ok: "Ok",
            error: "Erreur",
            skills: "Skills",
            github: "Github",
            instagram: "Instagram",
            facebook: "Facebook",
            linkedin: "Linkedin",
            behance: "Behance",
            others: "Autres",
            cv: "CV",
            copyValueToClipboard: "{string} a été copié avec succès!",
            submit: "enregistrer",
            contactSupport: "Contacter le support",
            youHaveAProblem: "Vous avez un problème ? N’hésitez pas à nous envoyé un message ici.",
            sendMessage: "Envoyer le message",
            message: "Message",
            returnToHomepage: "Retourner à l’accueil",
            workField: "Domaine de formation",
            basic: "Basique",
            pro: "Pro",
            enterprise: "Enterprise",
            month: "mois",
            year: "année",
            optional: "optionnelles",
            generalInformation: "Informations générales",
            registrationNumber: "N° de Siret",
            addPhoto: "Ajouter une photo",
            dropzoneDescription:
                "Vous pouvez glisser et déposer vos photos ou cliquez ici pour chercher dans la bibliothèque",
            paymentSuccess: "paiement validé",
            paymentSuccessDescription: "Le paiement à été effectué avec succès !",
            success: "Opération effectuée avec succès !",
            filter: "Filtre",
            loading: "Chargement ...",
            noOptions: "Aucune option",
            publish: "Publier",
            status: "Statut",
            postalCode: "Code postal",
            phoneNumber: "Téléphone",
            or: "ou",
            other: "Autre",
        },
        errors: {
            general: "Quelque chose a mal tourné!",
            authorization: "Vous n'êtes pas autorisés à effectuer cette opération.",
            required: "Champ obligatoire.",
            invalidEmail: "Courriel non valide.",
            invalidDate: "Date non valide.",
            minLength: "Le nombre de caractères doit être au minimum de {charNum}.",
            maxLength: "Le nombre de caractères doit être au maximum de {charNum}.",
            alphanum: "Le champ ne doit contenir que des charactères alphanumériques (ex: a-z 0-9)",
            hex: "Le champ ne doit contenir que des charactères héxadécimaux (a-z 0-9)",
            pattern: {
                date: "Le champ doit suivre le modèle suivant: 01/01/2001",
            },
            colorPattern: "Le champ ne doit commencer avec #",
            passwordPolicy: "Mot de passe invalide.",
            passwordMatch: "Le mot de passe et la confirmation du mot de passe doivent correspondre.",
            userExists: "L'utilisateur existe déjà.",
            noTrigger: "Aucun modèle de mail n'a été trouvé.",
            missing: "Veuillez choisir une option.",
            cantAddToyPareo: "Ce candidat ne peut pas être ajouté à yPareo.",
            number: {
                base: "Le champ doit contenir un nombre.",
                min: "Le nombre doit être plus grand que {num}.",
                max: "Le nombre doit être plus petit que {num}.",
            },
            array: {
                min: "Le champ doit être contenir au minumum {num} éléments.",
                minOne: "Le champ doit être contenir au minumum {num} élément.",
                max: "Le champ doit être contenir au maximum de {num} éléments.",
                maxOne: "Le champ doit être contenir au maximum {num} élément.",
            },
        },
        authentication: {
            returnToLogin: "Retourner à la page de connexion",
        },
        login: {
            connect: "Je me connecte",
            description: "Diffusez, qualifiez et recrutez efficacement !",
            forgotPassword: "Mot de passe oublié",
            stayConnected: "Rester connecté",
            login: "Connexion",
            new: "Vous êtes nouveau ici?",
            register: "Inscrivez-vous ici",
        },
        guest: {
            guest: "Invité",
            description: "Veuillez remplir quelques informations ci-dessous :",
        },
        registration: {
            createAccount: "Je crée mon compte",
            description: "Inscrivez-vous pour accéder à toutes la cvthéque en un clic",
            additionalInfo: "Veuillez remplir quelques informations supplémentaires",
            schoolInfo: "Parlez nous de votre entreprise",
            passwordPolicy: "8 caractères - 1 majuscule - 1 minuscule  - 1 chiffre - 1 caractère spécial",
            passwordConfirmation: "Confirmation du mot de passe",
            getStarted: "Faisons connaissance",
            registrationNumber: "N° de Siret",
            webUrl: "Site web",
            phoneNumber: "Téléphone",
            postalCode: "Code postal",
            terms: "J’accepte les conditions générales d’utilisation de Bloom Alternance.",
            haveAnAccount: "Vous avez déja un compte?",
            confirmation: "Félicitations, Vous êtes inscrit",
            confirmationMessage:
                "Vous pouvez désormais multi diffuser vos annonces pour recruter efficacement. Vous allez recevoir un email de confirmation à l’adresse suivante: {email}",
            resend: "Renvoyez le lien d'activation",
            finish: "Terminer",
            whoAreYou: "Qui êtes-vous ?",
            shortName: "Diminutif",
            shortNameNote: "Ex : école européenne des métiers de l’internet -> EEMI",
        },
        forgotPassword: {
            title: "Mot de passe oublié?",
            enterEmail: "Renseignez votre email et recevez un lien pour réinitialiser votre mot de passe.",
            emailSent: "Email envoyé",
            confirmation:
                "Un email de réinitialisation de mot de passe vient d’être envoyé à l’adresse suivante: {email}",
            toLogin: "Se connecter",
        },
        changePassword: {
            title: "Je réinitialise mon mot de passe",
            choosePassword: "Veuillez choisir un nouveau mot de passe et le confirmer.",
            newPassword: "Nouveau mot de passe",
            confirmPassword: "Confirmer le mot de passe",
            submit: "Enregistrer",
        },
        offers: {
            title: "Offres",
            latestOffer: "Dernières publications",
            seeAd: "Voir l’annonce",
            seeOffer: "Voir l’offre",
            addOffer: "Ajouter une offre",
            drafts: "brouillons",
            activeOffers: "Offres actives",
            candidates: "Candidats",
            onHold: "en attente",
            createOffer: "créer une offre",
            completeAdd: "compléter l’annonce",
            awaitingValidation: "En attente de validation",
            deleteDrafts: "Archiver les brouillons",
            removeOffer: "Archiver une offre",
            deleteOfferConfirmation: "Les {numberOfDeletedOffers} offres ont bien été archivées.",
            myOffers: "Mes offres",
            bloom: "Offres Bloom",
            published: "Publié",
            draft: "Brouillon",
            archived: "Archivé",
            awaitingApproval: "En attente",
            errors: {
                atLeastOneOffer: "Veuillez sélectionner au moins une offre",
            },
        },
        offer: {
            title: "Offres de stage / alternance",
            whoAreThey: "Qui sont-ils ?",
            post: "Poste",
            requiredProfile: "Profil recherché”",
            theCompany: "L’entreprise",
            apply: "Postuler",
            chooseResumes: "Choisissez des CV",
            chooseResumesDescription: "Choisissez les CV de vos étudiants qui souhaitent postuler à cette offre  ",
            addResume: "Ajouter CV",
            selectProfiles: "Sélectionner ces profils",
            successMessage: "Vos {totalProfiles} profils ont bien été envoyé à l’entreprise {companyName}",
            contractType: "Type de contrat",
            languages: "Langues",
            studeLevel: "Niveaux d’études",
            remoteWork: "Télétravail",
            publication: "Publication",
            researchedSkills: "Compétences recherchées",
            skillsDescription:
                "Ces données sont a destination de l’équipe de Talent Acquisition de Bloom ainsi que notre algorithme de matching sur mesure. Personnalisez le processus de sélection de candidat en indiquant 3 compétences requises, importantes et éventuellement bonus.",
            chooseRequiredSkills:
                "Choisissez les compétences <b>requises</b> pour ce poste. Cela permettra de trouver des profils qui vous correspondent.",
            chooseBonusSkills:
                "Choisissez les compétences <b>bonus</b> pour ce poste. Cela permettra de trouver des profils qui vous correspondent.",
            required: "Requises",
            important: "Importantes",
            bonus: "Bonus",
            requiredSkill: "Compétences requises",
            importantSkill: "Compétences importantes",
            softSkillsSkill: "Soft skills",
            bonusSkill: "Compétences bonus",
            missions: "Mission",
            profile: "Profil",
            viewCandidates: "Voir les candidats",
            archiveOffer: "Archiver cette offre",
            confirmArchive: "Oui je l'archive",
            confirmArchiveSubTitle: "Souhaitez-vous vraiment archiver cette offre ?",
            successArchive: "Offre archivée",
            successArchivePlural: "Offres archivées",
            successArchiveSubTitle: "Votre offre à bien été archivée.",
            successArchiveSubTitlePlural: "Vos offres ont bien été archivées.",
            successArchiveSubTitle2:
                "Vous pouvez retrouver et republier l’offre dans vos paramètres “offres archivées”.",
            successArchiveSubTitle2Plural:
                "Vous pouvez retrouver et republier vos offres dans vos paramètres “offres archivées”.",
            cancelArchive: "Non je ne l'archive pas",
            deleteOffer: "Supprimer cette offre",
            confirmDelete: "Oui je l'archive",
            cancelDelete: "Non je ne l'archive pas",
            previousOffer: "Offre précédente",
            nextOffer: "Offre suivante",
            republish: "Republier l'offre",
            duplicate: "Dupliquer l'offre",
            tooltip: {
                missions:
                    "Profitez de cette partie pour expliquer aux candidats ce qu’<b>ils feront au quotidien !</b> Essayez de pas mettre trop de choses non plus certains très bons candidats ne postulent pas parfois car ils ne savent pas faire certaines missions qui sont finalement pas essentiel !",
                contractType:
                    "Précisez si c’est un <b>stage</b>, une <b>alternance</b> (le type d’alternance), ou un <b>CDI</b>",
                studeLevel:
                    "Indiquez le niveau d’étude que <b>vous désirez</b> chez le candidat (si vous êtes flexible vous pouvez en sélectionner plusieurs).",
                remoteWork:
                    "Faites savoir aux candidats leurs <b>options de lieu de travail.</b> Vous pouvez sélectionner plusieurs options.",
                languages: "Indiquez les langues que le candidat doit maîtriser pour le poste.",
            },
        },
        createOffer: {
            generalInformation: "Informations générales",
            generalInformationDescription: "Tous ces champs sont obligatoires.",
            additionalInformation: "Informations complémentaires",
            plans: "Plans",
            payment: "Paiement",
            iban: "IBAN",
            location: "Localisation",
            title: "Intitulé du poste",
            workField: "Domaine de formation",
            profile: "Profil",
            keyWords: "Mots-clés",
            duration: "Rythme / Durée",
            comments: "Commentaires",
            publishOffer: "Publier l’offre",
            saveAsDraft: "Enregistrer comme brouillon",
            research: "Rechercher",
            description: "Descriptif entreprise",
            requiredSkills: "Compétences recherchées",
            additionalInformationTitle: "Compétences complémentaires",
            additionalInformationDescription:
                "Ces informations sont optionnelles mais peuvent nous aider à mieux comprendre vos besoins.",
            optional: "optionnelles",
            pay: "Payer",
            positionStartDate: "Date de début de prise de poste",
            keywordsNote: "Appuyer sur la touche “entrée” à chacun des mots-clés choisis.",
            noCustomerIdNote: "Please contact support for more information about posting an offer",
            subEducationLevel: "CAP ...",
            tooltip: {
                title: "Ici remplissez l’<b>intitulé du poste que vous mettez en ligne</b>. Restez <b>général</b> pour inclure un maximum de personne. <i>C’est le bon endroit pour préciser si vous cherchez un alternant, un stagiaire ou un CDI !</i>",
                description:
                    "C’est l’occasion d’en <b>dire plus sur vous</b> aux candidats, n’hésitez pas à adapter cette partie en fonction du <b>type de position pour laquelle vous recrutez</b>. <i>Dans la même entreprise les sales ne cherchent pas forcément la même chose que les devs</i>.",
                profile:
                    "Ici décrivez ce que vous cherchez chez votre <b>candidat idéal</b>. <i>Mettez les éléments principaux pour ne pas vous fermer trop de portes. Vous pouvez parler de hard skills comme de soft skills ou encore du rythme d’alternance que vous préférez</i>.",
                location: "Précisez la <b>ville dans laquelle</b> le candidat travaillera.",
                workField:
                    "Précisez dans quel domaine vous voulez que votre <b>candidat ait une appétence</b>. Vous pouvez en mettre plusieurs. Par exemple si vous êtes dans le recrutement et que vous cherchez quelqu’un en marketing vous pouvez indiquer les deux.",
                keywordsNote:
                    "Précisez les mots-clés que vous voulez voir dans le CV (ça peut être des langues, soft skills, hard skills, diplôme etc)",
                duration:
                    "Précisez le rythme d’alternance ou la durée de stage que vous cherchez ! Ça vous permettra de filtrer les candidats qui correspondent au besoin de votre entreprise.",
                positionStartDate: "Indiquez la date à laquelle vous voulez que le contrat commence.",
                comments: "Si vous avez des infos supplémentaires à donner faîtes le ici",
            },
        },
        student: {
            profile: "Profil étudiant",
            profilePicture: "Photo de profil",
            workField: "Domaine de formation",
            extraInformation: "Informations supplémentaires",
            mail: "Mail",
            interviewReport: "Compte rendu",
            viewCv: "Voir CV",
            takeNotes: "Prise de note",
            interviewConductor: "Entretien réalisé par",
            requiredContract: "Contrat recherché",
            additionalInformation: "Informations supplémentaires",
            educationLevel: "Niveau de formation",
            addNote: "Ajouter une note",
            newNote: "Nouvelle note",
            select: "Sélectionner",
            archive: "Archiver",
            interview: "Entretien",
            previousProfile: "Profil précédent",
            nextProfile: "Profil suivant",
            createNewProfile: "Création d'un profil étudiant",
            aProfile: "Profil",
            someProfiles: "Profils",
            selected: "Sélectionnés",
            export: "Exporter au format :",
            exportError: "10 candidats maximum pour l'export",
            zipPdf: "ZIP/PDF",
            customOffers: {
                spontaneous: "Candidatures spontanées",
            },
            details: {
                callStatus: "Statut de l’appel :",
            },
            history: {
                history: "Historique",
            },
            notes: {
                notes: "Prises de notes",
            },
            pipe: {
                match: "Match",
            },
            actions: {
                addClassroom: "Ajouter classroom",
                transferProfile: "Transférer le profil",
                addToYPareo: "Ajouter à Yparéo",

        toCallback: 'A rappeler',
        interview: 'Entretien',
        accept: 'Accepter',
        refuse: 'Refuser',

                seeLess: "Voir moins",
                seeMore: "Voir plus",
            },
        },
        settings: {
            companyProfile: "Profil entreprise",
            companyPhotos: "Photos de l’entreprise",
            billing: "Facturation",
            settings: "Paramètres",
            sourcing: "Sourcing",
            classrooms: "Classrooms",
            candidateFollowUp: "Suivi candidats",
            cvTheque: "CV Thèque",
            publish: "Publier offre",
            offers: "Offres d'emploi",
            home: "Accueil",
            customizeWebsite: "Personnalisation site vitrine",
            archivedOffers: "Offres archivées",
            communication: "Communication",
            notifications: "Notifications",
            notification: {
                all: "Recevoir toutes les notifications",
                recommandation: "Je veux être averti lorsque je reçois une recommandation",
                offreApply: "Je veux être averti lorsqu’un candidat à postuler à une offre",
                email: "Email",
                sms: "SMS",
            },
            myAccount: {
                index: "Mon compte",
                yourInfo: {
                    index: "Vos informations",
                    personnal: "Données personnelles",
                    personnalSub: "A qui nous nous adressons ? ",
                    login: "Informations de connexion",
                    loginSub: "N’oubliez pas ces infos ! ",

                    firstname: "Prénom",
                    lastname: "Nom",
                    email: "Adresse mail",
                    password: "Mot de passe",
                    confirmPassword: "Confirmation du mot de passe",
                },
                users: {
                    user: "Utilisateur",
                    users: "Utilisateurs",
                    add: "Ajouter un utilisateur",
                    update: "Modifier un utilisateur",
                    delete: "Supprimer un utilisateur",
                    invite: "Inviter un utilisateur",
                    reinvite: "Réinviter",
                    role: "Rôle",
                },
                yPareo: {
                    yPareo: "YPareo",
                    yPareoSub: "Connectez votre compte YPareo à Bloom !",
                    domain: "Domaine",
                    token: "Token",
                    description: "Description",
                    idSite: "Identification site",
                    idFormationSouhait1: "Identification formation",
                },
                yourEstablishment: {
                    index: "Votre établissement",
                    establishment: "Établissement",
                    establishmentSub: "Dites-en plus sur votre établissement ! ",

                    schoolName: "Nom de l’école",
                    yourRole: "Votre rôle",
                    numberSIRET: "N° siret",

                    city: "Ville",
                    creationDate: "Date de création",
                    numberUAI: "Numero UAI",
                    numberSIREN: "N° siren",
                    shortName: "Diminutif",
                },
            },
            customWebsite: {
                index: "Personnalisation site vitrine",
                domain: {
                    domain: "Domaine",
                    warning: "Le domaine ne peut pas être modifié",
                },
                design: {
                    index: "Design",
                    color: "Couleurs",
                    colorSub: "Choisissez vos couleurs pour personnaliser votre site vitrine.",
                    colorPrimary: "Couleur principale :",
                    colorSecondary: "Couleur secondaire :",
                    logo: "Logo",
                    logoSub: "La taille du logo ne doit pas dépasser 5 mo.",
                    logoDescription:
                        "Vous pouvez glisser et déposer votre logo ou cliquez ici pour chercher dans la bibliothèque",
                },
                presentations: {
                    index: "Présentations",
                    aboutUs: "A propos",
                    aboutUsSub: "Décrivez votre entreprise de manière simple et conscise.",
                    figures: "Quelques chiffres",
                    figuresSub: "Afin d’aider à situer l’étudiant.",
                    joinUs: "Rejoignez-nous",
                    joinUsSub: "Afin d’aider à situer l’étudiant.",
                    publicity: "Photos",
                    publicitySub: "Accompagnez votre profil de photos pour illustrer votre école",
                    footerDescription: "Pied de page",
                    footerDescriptionSub: "Ce texte s'affichera en pied de page.",
                    socials: "Réseaux sociaux",
                    socialsSub:
                        "Ces liens s’afficheront dans la couverture du site vitrine. N’oubliez pas de les activer.",

                    foundationYear: "Année de création (YYYY)",
                    numberOfEmployees: "Effectifs",
                    numberOfCollaborators: "Collaborateurs",
                    insertion_rate: "Taux d'insertion",
                    success_rate: "Taux de réussite",

                    instagram: "Instagram",
                    discord: "Discord",
                    tiktok: "TikTok",
                    x: "X",
                    youtube: "YouTube",
                    email: "Email",
                    linkedin: "LinkedIn",
                    website: "Site web",
                    facebook: "Facebook",
                },
                course: {
                    index: "Formation",
                    courses: "Formations",
                    courseSub: "Dites-en plus sur la formation ! ",
                    add: "Ajouter une formation",
                    confirmDelete: "Etes vous sur de vouloir supprimer cette formation ?",
                    update: "Modifier la formation",
                    diplomaName: "Nom du diplôme",
                    startDate: "Prochaine rentrée",
                    startFrom: "À partir de",
                    admissionLevel: "Niveau d’admission",
                    courseLocation: "Lieux de formation",
                },
                contributors: {
                    index: "Intervenants",
                    profiles: "Profils intervenants",
                    profilesSub: "Dites-en plus sur les formateurs ! ",

          addContributor: 'Ajouter un intervenant',
          seeMore: 'Voir Plus',
        },
        delete: {
          delete: 'Supprimer le site',
          deleteSub: 'Supprimer le site',
        }
      }
    },
    mobileBlock: {
      title: "Pour une version du site optimale...",
      subTitle: "Si vous souhaitez une navigation optimale, veuillez utiliser le site sur un ordinateur.",
    },
    communication: {
      trigger_identifier: 'Identifiant',
      object: "Sujet",
      sendEmail: "Envoyer un email",
      activate: "J’active l’envoi de cette communication",
      disclaimer: "Le mail s'envoi lorsque vous déplacez un candidat dans la colonne entretien.",
      reset: "Revenir au texte de base ",
      warning: "Attention, n’enlevez pas les mots entre crochets car le message de s’adaptera pas avec le prénom du candidat ni votre nom d’entreprise.",
      headText: 'Personnalisez votre modèle de mail. N’oubliez pas de le personnaliser avec les bonnes variations.',
      labelText: 'Ex : {{Prénom}} pour personnaliser automatiquement le prénom d’un étudiant.',
      actions: {
        createTemplate: 'Créer un modèle d’email',
        chooseTemplate: 'Choisissez un de nos modèles à personnaliser',
      }
    },
    resumes: {
      title: "CV étudiants",
      degrees: "Niveau d’étude",
      addNewCv: "Ajouter CV",
      editSpecialties: "Modifier vos spécialités",
      edit: "modifier",
      addTag: "Ajouter un tag",
      noCVMessage: "Vous n‘avez aucun CV dans cette spécialité. Faites glisser et déposer vos fichiers ici.",
      addResumes: "Ajouter des CV",
      addDegreePlaceholder: "ex : e-business",
      addCVs: "Ajouter ces CV",
      addCVsDescription: "Ajouter les CV de vos étudiants sur la plateforme ",
      uploadCvsDropzoneDescription: "Faites glisser et déposer vos fichiers ici. (format : {fileTypes} - poids: {maxFileSize}mo)",
      successMessage: "Vos CV ont bien été ajouté à la promotion “{category}” ",
      enregistrer: "Enregistrer",
      addClass: "Ajouter une classe",
      durationOfTraining: "Durée de la formation",
      selectClass: "Sélectionner la classe à afficher",
      editClass: "Modifier la classe",
      deleteClass: "Supprimer la classe",
      deleteClassConfirmMessage: "Vous êtes sûr de vouloir supprimer",
      deleteSelectedResumes: "Supprimer les CV sélectionnés",
      deleteResume: "Supprimer le CV",
      deleteResumeConfirmMessage: "Êtes-vous sûr de vouloir supprimer ce CV?",
      deleteAllResumes: "Supprimer tous les CV",
      deleteAllResumesConfirmMessage: "Êtes-vous sûr de vouloir supprimer ces CV?",
      addedSuccessfully: 'CV ajouté(s)',
      addedSuccessfullyDescription: 'Vos profils ont bien été envoyé!',
    },
    classroom: {
      title: "Classroom",
    },
    dropzone: {
      uploadFiles:
          "Vous n‘avez aucun CV dans cette spécialité. Faites glisser et déposer vos fichiers ici.",
    },
    filter: {
      location: "Localisation",
      contract: "Type de contrat",
      domaine: "Domaine d'activité",
    },
    modals: {
      yPareoModal : {
        title: 'Exporter un candidat vers YParéo',
        subTitle: 'Afin d’exporter vers YParéo, vous devez indiquer les informations ci-dessous',
        firstname: 'Prénom',
        lastname: 'Nom',
        city: 'Ville',
        postalCode: 'Code postal',
        phoneNumber: 'Tel',
        genderCode: 'Code civilité',
        citizenshipCode: 'Code nationalité',
        knownHandicap: 'Handicap reconnu',
        dateOfBirth: 'Date de naissance',
        exportToYPareo: 'Exporter  le candidat dans YParéo',
        force: 'Forcer l\'envoie',
        success: 'Candidat envoyé à yPareo avec succès !'
      },
      interwiewDetails: {
        title: 'Entretien programmé physique',
        submit: 'submit',
        date: "Date de l’entretien",
        hour: 'Heure',
      },
      validateEmail: {
        title: 'Validation email',
        submit: "Envoyer l'email",
        success: 'Email envoyé avec succès !',
        missingEmail: 'Nous avons du mal à récupérer l\'email du candidat. Veuillez rafraichir la page.'
      }
    },
    breadcrumbs: {
      dashboard: {
        dashboard: 'Tableau de bord',
        draft: 'Brouillon',
      },
      createOffer: {
        createOffer: 'Créer une offre',
        generalInfo: 'Informations générales',
        wantedSkills: 'Compétences recherchées',
      },
      offers: {
        offers: 'Offres',
        offersDesc: 'Offres d’emploi  : stage & alternance',
        employment: 'Offres d’emploi',
      },
      candidateFollowUp: {
        candidateFollowUp: 'Suivi candidat',
        pipe: 'Pipe',
        candidateProfile: 'Profil candidat',
        classroom: 'Classroom',
      },
      studentFollowUp: {
        CVTheque: 'CV Thèque',
        studentFollowUp: 'Suivi étudiant',
        studentProfile: 'Profil étudiant',
        pipe: 'Pipe',
      },
      settings: {
        settings: 'Paramètres',
        myAccount: 'Mon compte',
        customizeWebsite: 'Personnalisation site vitrine',
        notifications: 'Notifications',
        archivedOffers: 'Offres archivées',
        communication: 'Communication',
      },
    },
    pages: {
        offer: {
            sections: {
                heading: {
                    goBack: "Retour",
                    bloomAlternance: "BLOOM ALTERNANCE",
                    applyToOffer: "Postuler maintenant",
                },
                details: {
                    titles: {
                        whoAreWe: "Qui sont-ils ?",
                        missions: "Quelles sont les missions ?",
                        profile: "Quel est le profil demandé ?",
                        skills: "Quelles sont les compétences recherchées ?",
                    },
                    labels: {
                        required: "Requises",
                        important: "Importantes",
                        bonus: "Bonus",
                    },
                },
            },
        },
        cvTheque: {
            sections: {
                filters: {
                    action: {
                        exportZIP: "Exporter en ZIP",
                        exportZIPlimit: "10 éléments maximum",
                    }
                },
                table: {
                    head: {
                        name: "Nom",
                        offer: "Offre",
                        status: "Statut",
                        addedDate: "Date d’ajout",
                    },
                    row: {
                        noOffer: "( Offre non disponible )",
                    },
                },
            },
        },
    }
  },
});

export { locales };
