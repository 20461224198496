import { memo } from "react";
import { NavLink } from 'react-router-dom';
import classNames from "classnames";

import { routes, locales } from 'constants/index';

import { TSidebarUI } from './Sidebar.types';

import './sidebar.scss';

const SidebarUI: TSidebarUI = ({
    openSidebar,
    data: {
        checkoutUrl = '',
        profile: {
            title = '',
            subTitle = '',
            imageUrl = '',
        }
    },
    handlers: {
        toggleSidebarState,
    }
}) => {
    const className = classNames({
        "sidebar flex flex-column flex-justify-content-space-between": true,
        "sidebar__closed": !openSidebar,
    });

    return (
        <div
            className={className}
            onMouseEnter={toggleSidebarState(true)}
            onMouseLeave={toggleSidebarState(false)}
        >
            <div className="sidebar__header">
                <div className="sidebar__header__user">
                    <img onClick={toggleSidebarState()} src={imageUrl} alt="profile" className="sidebar__header__user-image" />
                    <div className="sidebar__header__user-info sidebar__to-hide">
                        <div className="text-ellipsis sidebar__header__user-catchphrase">Welcome,</div>
                        <div className="text-ellipsis sidebar__header__user-title">{title}</div>
                        <div className="text-ellipsis sidebar__header__user-subtitle">{subTitle}</div>
                    </div>
                </div>
            </div>

            <div className="sidebar__content">
                <ul className="sidebar__menu">
                    <li className="sidebar__menu-item">
                        <NavLink
                            to={`${routes.offers.main}/${routes.offers.listing}`}
                            className="sidebar__link flex flex-align-items-center"
                        >
                            <div className="sidebar__link-icon sidebar__link-icon--home" />
                            <span className="text-ellipsis sidebar__to-hide">{locales.settings.home}</span>
                        </NavLink>
                    </li>

                    <li className="sidebar__menu-item">
                        <NavLink
                            to={`${routes.listing.main}`}
                            className="sidebar__link flex flex-align-items-center"
                        >
                            <div className="sidebar__link-icon sidebar__link-icon--offers" />
                            <span className="text-ellipsis sidebar__to-hide">{locales.settings.offers}</span>
                        </NavLink>
                    </li>

                    <li className="sidebar__menu-item">
                        <NavLink
                            to={`${routes.offers.main}/${routes.offers.create.main}`}
                            className="sidebar__link flex flex-align-items-center"
                        >
                            <div className="sidebar__link-icon sidebar__link-icon--publish" />
                            <span className="text-ellipsis sidebar__to-hide">{locales.settings.publish}</span>
                        </NavLink>
                    </li>

                    <li className="sidebar__menu-item">
                        <NavLink
                            to={`${routes.classrooms.main}`}
                            className="sidebar__link flex flex-align-items-center"
                        >
                            <div className="sidebar__link-icon sidebar__link-icon--classrooms" />
                            <span className="text-ellipsis sidebar__to-hide">{locales.settings.classrooms}</span>
                        </NavLink>
                    </li>

                    <li className="sidebar__menu-item">
                        <NavLink
                            to={`${routes.students.main}/${routes.students.offer}`}
                            className="sidebar__link flex flex-align-items-center"
                        >
                            <div className="sidebar__link-icon sidebar__link-icon--candidate-followup" />
                            <span className="text-ellipsis sidebar__to-hide">{locales.settings.candidateFollowUp}</span>
                        </NavLink>
                    </li>

                    <li className="sidebar__menu-item">
                        <NavLink
                            to={`${routes.CVTheque.main}`}
                            className="sidebar__link flex flex-align-items-center"
                        >
                            <div className="sidebar__link-icon sidebar__link-icon--cv-theque" />
                            <span className="text-ellipsis sidebar__to-hide">{locales.settings.cvTheque}</span>
                        </NavLink>
                    </li>

                    {/*<li className="sidebar__menu-item">*/}
                    {/*    <NavLink*/}
                    {/*        to={checkoutUrl}*/}
                    {/*        className="sidebar__link flex flex-align-items-center"*/}
                    {/*    >*/}
                    {/*        <div className="sidebar__link-icon sidebar__link-icon--sourcing" />*/}
                    {/*        <span className="text-ellipsis sidebar__to-hide">{locales.settings.sourcing}</span>*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}

                    <li className="sidebar__menu-item">
                        <NavLink
                            to={`${routes.settings.main}`}
                            className="sidebar__link flex flex-align-items-center"
                        >
                            <div className="sidebar__link-icon sidebar__link-icon--settings" />
                            <span className="text-ellipsis sidebar__to-hide">{locales.settings.settings}</span>
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className="sidebar__footer">
                <div className="sidebar__footer__logout">
                    <NavLink
                        to={routes.authentication.logout}
                        className="sidebar__footer__logout__link flex flex-align-items-center flex-justify-content-center"
                    >
                        <div className="sidebar__link-icon sidebar__link-icon--logout"></div>
                        <span className="text-ellipsis sidebar__to-hide">{locales.general.logout}</span>
                    </NavLink>
                </div>
                <div className="sidebar__footer__logout">
                    <div className="sidebar__footer__logo"></div>
                </div>
            </div>
        </div>
    );
};

export default memo(SidebarUI);
