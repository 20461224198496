import Cookies from 'js-cookie';

import { TSetAccessToken, TRefreshAccessToken } from './local-store.types';

export const getLocale = () => {
  return sessionStorage.getItem('locale');
};

export const setLocale = (locale: string) => {
  sessionStorage.setItem('locale', locale);
};

export const getAccessToken = () => {
  return Cookies.get('accessToken');
};

export const setAccessToken: TSetAccessToken = ({
  accessToken,
  expires = 5,
}) => {
  Cookies.set('accessToken', accessToken, { expires });
};

export const getRefreshToken = () => {
  return Cookies.get('refreshToken');
};

export const setRefreshToken: TRefreshAccessToken = ({
  refreshToken,
  expires,
}) => {
  Cookies.set('refreshToken', refreshToken, { expires });
};

export const clearSession = () => {
  sessionStorage.clear();
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
};

export const setStripeSecretKey = (secretKey: string) => {
  sessionStorage.setItem('secretKey', secretKey);
};

export const getStripeSecretKey = () => {
  return sessionStorage.getItem('secretKey');
};
