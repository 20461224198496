export enum CreateOfferRequiredSkillsEnum {
  required = 'required',
  bonus = 'bonus',
  softSkills = 'softSkills',
}

export enum SkillsTabEnum {
  required = 0,
  softSkills = 1,
}


export {};