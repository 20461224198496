import { forwardRef, useEffect } from "react";
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

import { locales } from 'constants/index';
import { TextInput } from "components";

import { TClasses } from './Classes.types';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import './classes.scss';

SwiperCore.use([Navigation]);

export const Classes: TClasses = ({
  classes,
  newClass,
  selectedClass,
  className = '',
  onClick,
  onAddClassDialogShow,
  onSettingsMenuShow,
  onSettingsMenuHide,
  isClassSettingsMenuVisible = false,
  isAddClassVisible,
  onRemoveClass,
  onEditClass,
  isBeingEdited,
  handleUpdateClassSubmit,
  handleNewClassChange,
  classTextInputRef,
}) => {
  const mainClasses = classNames({
    'flex-justify-content-end classes__empty': !classes || classes.length === 0,
    'classes flex flex-align-items-center flex-justify-content-space-between': true,
    [className]: className.length > 0,
  });

  const AutoFocusTextInput = forwardRef((props: any, ref: any) => {
    const { value } = props;
    useEffect(() => {
      if (ref.current) {
        ref.current.focus();
      }
    }, [ref]);
    return (<TextInput
      ref={ref}
      className="classes__class__input"
      id="resumeName"
      name="resumeName"
      placeholder={value}
      value={newClass}
      onChange={handleNewClassChange}
      onBlur={handleUpdateClassSubmit as any}
      onKeyDown={(event: KeyboardEvent) => {
        if (event.key === "Enter") handleUpdateClassSubmit(event)
      }}
    />);
  })

  useEffect(() => {
    window.addEventListener('click', onSettingsMenuHide);
  }, [])

  return (
    <div className={mainClasses}>
      <div className="classes__tabs">
        <div>
          {classes && classes.length > 0 && (
            <Swiper
              draggable={false}
              slidesPerView={'auto'}
              className="classes__carousel"
              navigation
            >
              {classes.map(({ key, value, _id: id }) => (<>
                <SwiperSlide
                  key={id}
                  className={classNames({
                    'classes__class': true,
                    'classes__class--active': selectedClass === id,
                  })}
                  onClick={onClick(id)}
                >
                  {isBeingEdited && selectedClass === id
                    ? <AutoFocusTextInput value={value} ref={classTextInputRef}/>
                    : value}
                </SwiperSlide>
              </>))}
            </Swiper>
          )}
        </div>
        <div className="classes__icons">
          {isAddClassVisible && (
            <div className="classes__icon classes__icon--add"
              role="presentation"
              onClick={onAddClassDialogShow}
            />
          )}
        </div>
      </div>
      {classes && classes.length > 0 && <div className="classes__icons">
        {classes.length > 0 && selectedClass.length > 0 && (
          <div
            className="classes__icon classes__icon--settings"
            role="presentation"
            onClick={(e) => {
              e.stopPropagation();
              onSettingsMenuShow();
            }}
          >
            {isClassSettingsMenuVisible && (
              <div className="classes__settings">
                <div
                  className="classes__settings__item"
                  role="presentation"
                  onClick={onEditClass}
                >
                  {locales.resumes.editClass}
                </div>
                <div
                  className="classes__settings__item classes__settings__delete"
                  role="presentation"
                  onClick={onRemoveClass}
                >
                  {locales.resumes.deleteClass}
                </div>
              </div>
            )}
          </div>
        )}
      </div>}
    </div>
  );
};
