enum actionTypes {
  SET_RESUMES = 'SET_RESUMES',
  SET_SELECTED_CLASSES = 'SET_SELECTED_CLASSES',
  CLEAR_RESUMES = 'CLEAR_RESUMES',
  SET_DEGREE_ID = 'SET_DEGREE_ID',
  CLEAR_DEGREE_ID = 'CLEAR_DEGREE_ID',
  SET_CLASS_ID = 'SET_CLASS_ID',
  CLEAR_CLASS_ID = 'CLEAR_CLASS_ID',
  RESET = 'RESET',
}

export { actionTypes as classroomsActionTypes };
