import { memo } from "react";
import ClassNames from "classnames";

import {
  FormControl,
  Dropdown,
  RichTextEditor,
  Button,
  Switch, TextInput,
} from 'components';

import { locales } from 'constants/index';

import { SettingsCommunicationEnum, TSettingsCommunicationUi } from './SettingsCommunication.types';

import './settings-communication.scss';
import '../settings.scss';

const SettingsCommunicationUi: TSettingsCommunicationUi = ({
  data : {
    emails,
    chosenVariables,
    inEdition,
    inCreation,
    canReset,
    email,
  },
  handlers: {
    handleResetText,

    handleEditEmail,
    handleCreateEmail,
    handleDeleteEmail,
    handleSaveEmail,
    handleCancelEmail,

    handleChooseTrigger,
    handleTextEditorChange,
    handleToggleSendActivated,
    handleAddVariable,
    handleTextInputChange,
    handleTextInputBlur,
  },
  reactQuillRef,
}) => {
  console.log("inCreation => ", inCreation);
  console.log("inEdition => ", inEdition);
  return (
    <div className="settings__subModule">

      {(!inEdition && !inCreation) && <div className='settings-communication__header'>
        <div className='settings-communication__headerLabel'>
          <p>{locales.communication.headText}</p>
          <label>{locales.communication.labelText}</label>
        </div>
        <div className='settings-communication__headerActions'>
          <Button variant='secondary' className='settings-communication__headerAction' onClick={handleCreateEmail}>
            <span className='settings-communication__headerAction__Text'>
              {locales.communication.actions.createTemplate}
            </span>
            <span className='settings-communication__headerAction__Icon edit'/>
          </Button>
          <p>{locales.general.or}</p>
          <Button variant='link' onClick={handleEditEmail}>
            {locales.communication.actions.chooseTemplate}
          </Button>
        </div>
        <hr className='settings-communication__headerHr'/>
      </div>}

      {(inEdition || inCreation) && <>
        <div className="settings-communication-dropdown">
          {!inCreation && <FormControl className='settings-communication__form-field' withRef>
            <Dropdown
                placeholder={locales.student.select}
                options={emails}
                value={emails.find((item) => item.value === email?._id)}
                onChange={handleChooseTrigger}
            />
          </FormControl>}

          {inCreation && <FormControl
              label={locales.communication.trigger_identifier}
              className='settings-communication__form-field'
              withRef
          >
            <TextInput
                id={SettingsCommunicationEnum.trigger_identifier}
                name={SettingsCommunicationEnum.trigger_identifier}
                value={email[SettingsCommunicationEnum.trigger_identifier]}
                // isError={!!errors[SettingsCommunicationEnum.subject]}
                onChange={handleTextInputChange}
                onBlur={handleTextInputBlur}
            />
          </FormControl>}
        </div>

        <div className={ClassNames({
          'settings-communication-editor-container': true,
          'settings-communication-editor-container__is-readonly': !inEdition,
        })}>
          {email && (inEdition || inCreation) && chosenVariables.length > 0 &&
              <div className="settings-communication-editor-variables">
                {chosenVariables.map((chosenVariable, index) =>
                    <div
                        key={index}
                        className="settings-communication-editor-variable"
                        onClick={() => handleAddVariable(chosenVariable)}
                        children={chosenVariable}
                    />
                )}
              </div>}

          {email && <div className="settings-communication-editor-header">
            {!inEdition
                ? <div className="settings-communication-editor-disclaimer">
                  {email && email.subject}
                </div>
                : <div className="settings-communication-dropdown">
                  <FormControl
                      label={locales.communication.object}
                      className='settings-communication__form-field'
                      withRef
                  >
                    <TextInput
                        id={SettingsCommunicationEnum.subject}
                        name={SettingsCommunicationEnum.subject}
                        value={email[SettingsCommunicationEnum.subject]}
                        // isError={!!errors[SettingsCommunicationEnum.subject]}
                        onChange={handleTextInputChange}
                        onBlur={handleTextInputBlur}
                    />
                  </FormControl>
                </div>
            }

            <div className="settings-communication-editor-reset">
              {canReset &&
                  <Button
                      variant="link"
                      onClick={handleResetText}
                  >
                    {locales.communication.reset}
                  </Button>}
            </div>
          </div>}

          <div className='settings-communication-editor'>
            <RichTextEditor
                id='rte-description'
                ref={reactQuillRef}
                isDark
                placeholder={locales.createOffer.description}
                value={email?.body ?? ''}
                readOnly={!inEdition}
                onChange={handleTextEditorChange}
                className='settings-communication-editor-rich-text'
            />
          </div>
        </div>

        {email && inEdition && <div className="settings-communication-editor-warning">
          <div className="settings-communication-editor-icon-warning"/>
          {locales.communication.warning}
        </div>}

        <div className="settings-communication-activate-container">
          <Switch
              id="sendActivated"
              name="sendActivated"
              label={locales.communication.activate}
              isChecked={email?.active ?? false}
              isInverted
              extraClass=""
              onChange={handleToggleSendActivated}
          />
        </div>

        <div className="settings-communication-submit-container">
          {inEdition && !!email._id && <Button
              variant="danger"
              onClick={handleDeleteEmail}
          >
            {locales.general.delete}
          </Button>}

          {(inEdition || inCreation) && <Button
              variant="secondary"
              isDisabled={!email}
              onClick={handleCancelEmail}
          >
            {locales.general.cancel}
          </Button>}

          {!inEdition && <Button
              variant="primary"
              isDisabled={!email}
              onClick={handleCreateEmail}
          >
            {locales.general.create}
          </Button>}

          {!inEdition && <Button
              variant="primary"
              isDisabled={!email || !email._id}
              onClick={handleEditEmail}
          >
            {locales.general.edit}
          </Button>}

          {(inCreation || inEdition) && <Button
              variant="primary"
              isDisabled={!email || !email.trigger_identifier}
              onClick={handleSaveEmail}
          >
            {locales.general.save}
          </Button>}
        </div>
      </>}
    </div>
  );
};

export default memo(SettingsCommunicationUi);
