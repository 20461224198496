import { memo, useCallback, useMemo, useState } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useExtractServerError, useToast } from "hooks";
import { routes, locales } from "constants/index";
import { copyToClipboard } from "utils";
import { exportCvApi, updatePipeApi } from "modules/Students/api";
import {
    setPipes,
    setSelectedCvUrl,
    setSelectedCandidates,
    setPipesByColumn,
    clearSelectedCandidates,
    triggerRefreshPipes,
    setDraggedId,
    resetDraggedId,
} from "modules/Students/store/actions";

import type { IStoreState, TPipes, IPipesByColumn, DropResult, DragStart, TToggleSelectAll } from "./Pipes.types";
import PipesUi from "./Pipes.ui";

import "./pipes.scss";

const Pipes: TPipes = () => {
    // * Hooks Init
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const params = useParams();
    const { extractErrorMessage } = useExtractServerError();

    //* Redux State
    const columns = useSelector(({ app: { config } }: IStoreState) => config.pipes, shallowEqual);
    const candidate = useSelector(({ candidates }: IStoreState) => candidates, shallowEqual);

    //* Local State
    const [isLoading, setIsLoading] = useState(false);

    //* Memos
    const pipeColumns = useMemo(() => columns, [columns]);
    const pipes = useMemo(() => candidate.pipes, [candidate.pipes]);
    const candidatesIds = useMemo(() => candidate.pipes.map((item) => item._id), [candidate.pipes]);
    const offerId = useMemo(() => candidate.offerId || params.offerId || "", [candidate.offerId, params.offerId]);
    const pipesByColumn = useMemo(() => candidate.pipesByColumn, [candidate.pipesByColumn]);
    const selectedCandidates = useMemo(() => candidate.selectedCandidates, [candidate.selectedCandidates]);

    //* API
    const updatePipe = useCallback(async (ids: string[], pipeInfoId: string, afterId?: string, beforeId?: string) => {
        await updatePipeApi({ ids, pipeInfoId, afterId, beforeId });
    }, []);

    const exportCandidates = useCallback(
        async (ids: string[]) => {
            try {
                setIsLoading(true);
                const { data } = await exportCvApi({ candidatesIds: ids, excel: false });
                setIsLoading(false);
                let url = window.URL.createObjectURL(data);
                let link = document.createElement("a");

                link.href = url;
                link.setAttribute("download", "");
                document.body.appendChild(link);
                link.click();

                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                const errorMessage = extractErrorMessage(error);
                toast({ type: "error", message: errorMessage });
            }
        },
        [selectedCandidates]
    );

    //* Handlers
    const handleDragEnd = useCallback(
        async ({ destination, source, draggableId }: DropResult) => {
            dispatch(resetDraggedId());
            if (!destination) return;
            if (destination.droppableId === source.droppableId && destination.index === source.index) return;

            try {
                const destinationItems = pipesByColumn[destination.droppableId];
                const selectedIds =
                    selectedCandidates.length > 0
                        ? selectedCandidates.includes(draggableId)
                            ? selectedCandidates
                            : [draggableId, ...selectedCandidates]
                        : [draggableId];
                const newPipesWithUpdatedColumnIds =
                    destination.droppableId === source.droppableId
                        ? pipes
                        : pipes.map((item) =>
                              selectedIds.includes(item._id) ? { ...item, pipeInfoId: destination.droppableId } : item
                          );
                const selectedItems = newPipesWithUpdatedColumnIds.filter((item) => selectedIds.includes(item._id));
                const restOfTheItems = newPipesWithUpdatedColumnIds.filter((item) => !selectedIds.includes(item._id));

                let after_id: string | undefined, before_id: string | undefined;
                if (source.droppableId === destination.droppableId) {
                    if (destination.index > 0) after_id = destinationItems[destination.index]._id;
                    if (destination.index !== 0 && destination.index < source.index)
                        after_id = destinationItems[destination.index - 1]._id;

                    if (destination.index < source.index) before_id = destinationItems[destination.index]._id;
                    else if (destination.index < destinationItems.length - 1)
                        before_id = destinationItems[destination.index + 1]._id;
                } else {
                    if (destination.index > 0 && destination.index <= destinationItems.length)
                        after_id = destinationItems[destination.index - 1]._id;

                    if (destination.index < destinationItems.length)
                        before_id = destinationItems[destination.index]._id;
                }

                let index = after_id ? restOfTheItems.findIndex((item) => item._id === after_id) + 1 : 0;
                restOfTheItems.splice(index, 0, ...selectedItems);

                const newPipesByColumn: IPipesByColumn = {};
                pipeColumns.map((column) => {
                    newPipesByColumn[column.value] = restOfTheItems.filter((item) => item.pipeInfoId === column.value);
                });

                batch(() => {
                    dispatch(setPipesByColumn(newPipesByColumn));
                    dispatch(setPipes(restOfTheItems));
                    dispatch(clearSelectedCandidates());
                });

                await updatePipe(selectedIds, destination.droppableId, after_id, before_id);

                dispatch(triggerRefreshPipes());
            } catch (error) {
                if (!destination) return;

                const newPipes = pipes.map((item) =>
                    item._id === draggableId ? { ...item, pipeInfoId: source.droppableId } : item
                );

                const newPipesByColumn: IPipesByColumn = {};
                pipeColumns.map((column) => {
                    newPipesByColumn[column.value] = newPipes.filter((item) => item.pipeInfoId === column.value);
                });

                batch(() => {
                    dispatch(setPipesByColumn(newPipesByColumn));
                    dispatch(setPipes(newPipes));
                    dispatch(clearSelectedCandidates());
                    dispatch(triggerRefreshPipes());
                });
                const errorMessage = extractErrorMessage(error);
                toast({ type: "error", message: errorMessage });
            }
        },
        [selectedCandidates, pipes]
    );

    const handleCandidateClick = useCallback(
        (applicationId: string) => () => {
            navigate(`${routes.students.main}/${routes.students.offer}/${offerId}/${applicationId}`);
        },
        [offerId]
    );

    const handleSelectCandidate = useCallback(
        (candidateId: string) => () => {
            if (selectedCandidates.includes(candidateId)) {
                dispatch(setSelectedCandidates(selectedCandidates.filter((item) => item !== candidateId)));
            } else {
                dispatch(setSelectedCandidates([...selectedCandidates, candidateId]));
            }
        },
        [selectedCandidates]
    );

    const handleToggleSelectAll: TToggleSelectAll = useCallback(
        ({ currentTarget: { checked } }) => {
            if (checked) {
                const concatCandidatesIds = selectedCandidates.concat(candidatesIds);
                const selectedIdsInColumn = [...new Set(concatCandidatesIds)];
                dispatch(setSelectedCandidates(selectedIdsInColumn));
            } else {
                dispatch(setSelectedCandidates(selectedCandidates.filter((item) => !candidatesIds.includes(item))));
            }
        },
        [selectedCandidates, candidatesIds]
    );

    const handleExportCandidatesClick = useCallback(async () => {
        await exportCandidates(selectedCandidates);
    }, [selectedCandidates]);

    const handleCopySocialMedia = useCallback(
        (value: string) => (event: any) => {
            event.stopPropagation();

            copyToClipboard(value);

            toast({
                type: "success",
                message: locales.formatString(locales.general.copyValueToClipboard, { string: value }).toString(),
            });
        },
        []
    );

    const handleViewCv = useCallback(
        (cvUrl: string) => (event: any) => {
            event.stopPropagation();
            dispatch(setSelectedCvUrl(cvUrl));
        },
        []
    );

    const handleDragStart = useCallback(
        ({ draggableId }: DragStart) => {
            if (!selectedCandidates.includes(draggableId)) handleSelectCandidate(draggableId)();
            dispatch(setDraggedId(draggableId));
        },
        [dispatch, handleSelectCandidate, selectedCandidates]
    );

    //* Wrappers
    const data = useMemo(
        () => ({
            pipes,
            pipeColumns,
            selectedCandidates,
        }),
        [pipeColumns, selectedCandidates]
    );

    const handlers = useMemo(
        () => ({
            handleDragEnd,
            handleDragStart,
            handleCandidateClick,
            handleSelectCandidate,
            handleCopySocialMedia,
            handleViewCv,
            handleToggleSelectAll,
            handleExportCandidatesClick,
        }),
        [
            handleDragEnd,
            handleDragStart,
            handleCandidateClick,
            handleSelectCandidate,
            handleCopySocialMedia,
            handleViewCv,
            handleToggleSelectAll,
            handleExportCandidatesClick,
        ]
    );

    return <PipesUi isLoading={isLoading} data={data} handlers={handlers} />;
};

export default memo(Pipes);
