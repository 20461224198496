import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual, batch } from 'react-redux';

import ListingUI from './Listing.ui';

import { useToast, useExtractServerError } from 'hooks';
import { enums } from 'constants/index';
import { fetchBloomOffersApi, fetchOffersApi } from './api';
import {
    setOffersAction,
    setFilterPagination,
    setGridViewAction,
    setFilterFilter,
    resetFilterFilter,
    setOffersFlick,
} from './store';
import { setIsLoading } from "../App/store/actions";

import { offerStates } from "./constants";
import { OfferStateEnum } from "./Listing.enum";
import type { IConfigEntity, IStoreState, THandleOfferStateFiltering } from './Listing.types';
import type { IFilterFilter } from "../../components/Filter/store/store.types";


export const Listing = () => {
    //* Hook Init
    const dispatch = useDispatch();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();

    //* Redux State
    const userInfo = useSelector( ( { app: { userInfo } }: IStoreState ) => userInfo, shallowEqual );
    const config = useSelector( ( { app: { config } }: IStoreState ) => config, shallowEqual );
    const filter = useSelector( ( { filter }: IStoreState ) => filter, shallowEqual );
    const filterOffers = useSelector( ( { filter: { listing: { filter } } }: IStoreState ) => filter, shallowEqual );
    const {
        list: offers,
        gridView,
        offersFlick
    } = useSelector( ( { listing }: IStoreState ) => listing, shallowEqual );

    //* Handlers
    const handlePaginationLimitChange = ( { limit }: { limit: number } ) => dispatch(
        setFilterPagination( {
            moduleName: enums.moduleNames.listing,
            payload: {
                limit: limit,
                page: 1,
                totalPages: 1,
            },
        } )
    );

    const handleResetPaginationPage = () => dispatch(
        setFilterPagination( {
            moduleName: enums.moduleNames.listing,
            payload: {
                ...filter[ enums.moduleNames.listing ].pagination,
                page: 1,
                totalPages: 1,
            },
        } )
    );

    const handleSwitchToList = () => {
        dispatch( setGridViewAction( false ) );
        handlePaginationLimitChange( { limit: 10 } );
    };
    const handleSwitchToCard = () => {
        dispatch( setGridViewAction( true ) );
        handlePaginationLimitChange( { limit: 16 } );
    }

    const handleFilterFilterChange = ( filterFilter: IFilterFilter ) => {
        dispatch( setFilterFilter( {
            moduleName: enums.moduleNames.listing,
            payload: filterFilter,
        } ) );
        handleResetPaginationPage();
    };

    const handletoggleOffersFlick = () => {
        dispatch( setOffersFlick( !offersFlick ) );
        handleResetPaginationPage();
    };

    const handleFilterFilterReset = () => {
        dispatch( resetFilterFilter( { moduleName: enums.moduleNames.listing } ) );
        if ( gridView ) handlePaginationLimitChange( { limit: 16 } );
        else handlePaginationLimitChange( { limit: 10 } );
    };

    const handleRemoveSingleDomaine = ( domaine: IConfigEntity ) => {
        if ( !filterOffers.domaines || filterOffers.domaines.length === 0 ) return;
        dispatch( setFilterFilter( {
            moduleName: enums.moduleNames.listing,
            payload: {
                ...filterOffers,
                domaines: filterOffers.domaines?.filter( item => item.key !== domaine.key )
            },
        } ) );
    }

    const handleOfferStateFiltering: THandleOfferStateFiltering = ( offerState ) => {
        dispatch( setFilterFilter( {
            moduleName: enums.moduleNames.listing,
            payload: {
                ...filterOffers,
                offerState
            },
        } ) );
    }

    //* API Actions
    const fetchOffers = async () => {
        try {
            dispatch( setIsLoading( true ) );

            const { limit, page } = filter[ enums.moduleNames.listing ].pagination;

            const offerStateFilter = filterOffers.offerState;

            const offerState = Object.assign(
                {},
                offerStateFilter?.value === OfferStateEnum.DRAFT
                    && { draft: true, deleted: false },
                offerStateFilter?.value === OfferStateEnum.DELETED
                    && { deleted: true },
                offerStateFilter?.value === OfferStateEnum.APPROVED
                    && { draft: false, deleted: false, approved: true },
                offerStateFilter?.value === OfferStateEnum.WAITING
                    && { draft: false, deleted: false, approved: false },
            )

            const payload = Object.assign(
                { limit, page },
                !offersFlick && { is_companies_offers: true, draft: false, deleted: false, approved: true },
                offersFlick && offerState,
                filterOffers.domaines && {
                    work_fields: filterOffers.domaines.map( ( option ) => option.value ),
                },
                filterOffers.contracts && {
                    type: filterOffers.contracts.map( ( option ) => option.value ),
                },
                filterOffers.ranges && {
                    range: "20",
                },
                filterOffers.workPlaces && {
                    postal_code: filterOffers.workPlaces.value,
                    city: filterOffers.workPlaces.label,
                    lat: filterOffers.workPlaces.lat,
                    lng: filterOffers.workPlaces.lng,
                },
                filterOffers.months && {
                    start_date: filterOffers.months.value,
                },
            );

            let apiCall = fetchBloomOffersApi;

            const {
                data: { data: offers, total },
            } = await apiCall( payload );

            const totalPages = limit ? Math.ceil( total / limit ) : 1;

            batch( () => {
                dispatch( setOffersAction( offers ) );
                dispatch(
                    setFilterPagination( {
                        moduleName: enums.moduleNames.listing,
                        payload: { limit, page, totalPages, },
                    } )
                );
                dispatch( setIsLoading( false ) );
            } );
        } catch ( error ) {
            console.log(error)
            const errorMessage = extractErrorMessage( error );
            toast( { type: 'error', message: errorMessage } );
            dispatch( setIsLoading( false ) );
        }
    };

    //* Wrappers
    useEffect( () => {
        fetchOffers();
    }, [ filter.listing.pagination.page, gridView, filterOffers, offersFlick ] );

    useEffect( () => {
        console.log()
    }, [] );


    //* Wrappers
    const options = {
        offerStates
    };

    const data = {
        offers,
        filterOffers,
        config,
        userInfo,
        offersFlick,
    };

    const handlers = {
        handleSwitchToList,
        handleSwitchToCard,
        handleFilterFilterChange,
        handleFilterFilterReset,
        handleRemoveSingleDomaine,
        handletoggleOffersFlick,
        handleOfferStateFiltering,
    };

    return (
        <ListingUI
            gridView={ gridView }
            options={ options }
            data={ data }
            handlers={ handlers }
        />
    );
};
