import {memo, useCallback, useEffect, useMemo} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';

import CompanyInfoUi from './CompanyInfo.ui';

import { useToast, useExtractServerError, useValidate } from 'hooks';
import { routes, locales } from 'constants/index';

import { setIsLoading } from "modules/App/store/actions";
import { setRegister } from "../../store/actions";

import { registerApi } from '../../api';

import { TCompanyInfo, IHandleChange, IStoreState } from './CompanyInfo.types';
import { RegisterEnum } from "../Register.types";

const validationSchema = {
  city: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  postalCode: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),

  school: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  shortName: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),

  company: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  webUrl: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  phoneNumber: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
};

const trunkValidationSchema = {
  city: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  postalCode: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
};

const schoolValidationSchema = {
  ...trunkValidationSchema,
  school: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  shortName: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
};

const companyValidationSchema = {
  ...trunkValidationSchema,
  company: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  webUrl: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  phoneNumber: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
};

const CompanyInfo: TCompanyInfo = () => {
  //* Hooks Init
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();
  const { errors, setErrors, validate } = useValidate();

  //* Redux State
  const register = useSelector(({ auth: { register } }: IStoreState) => register, shallowEqual);

  //* API Actions
  const registerUser = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      await registerApi(register);

      dispatch(setIsLoading(false));

      const url = `${routes.authentication.register.main}/${routes.authentication.register.confirmation}`;
      navigate(url);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, register]);

  const handleConfirm = useCallback(() => {
    navigate(routes.authentication.login);
  }, []);

  //* Handlers
  const handleInputChange: IHandleChange = useCallback(({ target: { name, value } }) => {
    dispatch(setRegister({ [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  }, [dispatch]);

  const handleInputBlur: IHandleChange = useCallback(({ target: { name, value } }) => {
    if (!value) return;
    validate({
      data: {
        [name]: value,
      },
      validationSchema: {
        // @ts-ignore
        [name]: validationSchema[name],
      },
    });
  }, [register]);

  const handleSubmit = useCallback(() => {
    let validation;

    if (RegisterEnum.school in register) validation = schoolValidationSchema;
    else validation = companyValidationSchema;

    const payload = Object.assign(
      {
          city: register[RegisterEnum.city],
          postalCode: register[RegisterEnum.postalCode],
        },
        RegisterEnum.school in register
        && RegisterEnum.shortName  in register && {
          school: register[RegisterEnum.school],
          shortName: register[RegisterEnum.shortName],
        },
        RegisterEnum.company in register
        && RegisterEnum.webUrl in register
        && RegisterEnum.phoneNumber in register && {
          company: register[RegisterEnum.company],
          webUrl: register[RegisterEnum.webUrl],
          phoneNumber: register[RegisterEnum.phoneNumber],
        }
      );

    const isFormValid = validate({
      // @ts-ignore
      data: payload,
      // @ts-ignore
      validationSchema: validation,
    });

    return isFormValid && registerUser();
  }, [register, registerUser]);

  //* Effects
  useEffect(() => {
    if (!register.firstname
      || !register.lastname)
      navigate(
        `${routes.authentication.register.main}/${routes.authentication.register.generalInfo}`
      );
  }, [register, navigate]);

  //* Wrappers
  const data = useMemo(() => ({
    register,
  }), [
    register
  ]);

  const handlers = useMemo(() => ({
    handleInputChange,
    handleInputBlur,
    handleSubmit,
    handleConfirm,
  }), [
    handleInputChange,
    handleInputBlur,
    handleSubmit,
    handleConfirm,
  ]);

  return <CompanyInfoUi
    data={data}
    errors={errors}
    handlers={handlers}
  />;
};

export default memo(CompanyInfo);
