import {
  classroomsActionTypes,
  TSetResumesAction,
  TClearResumesAction,
  TSetDegreeIdAction,
  TSetClassIdAction,
  TClearClassIdAction,
  TClearDegreeIdAction,
  TResetStateAction,
} from '.';

import { setClasses } from "modules/App/store/actions";

export const setResumes: TSetResumesAction = (resumes) => {
  return {
    type: classroomsActionTypes.SET_RESUMES,
    resumes,
  };
};

export const clearResumesAction: TClearResumesAction = () => {
  return {
    type: classroomsActionTypes.CLEAR_RESUMES,
  };
};

export const setDegreeIdAction: TSetDegreeIdAction = (degreeId) => {
  return {
    type: classroomsActionTypes.SET_DEGREE_ID,
    degreeId,
  };
};

export const clearDegreeIdAction: TClearDegreeIdAction = () => {
  return {
    type: classroomsActionTypes.CLEAR_DEGREE_ID,
  };
};

export const setClassIdAction: TSetClassIdAction = (classId) => {
  return {
    type: classroomsActionTypes.SET_CLASS_ID,
    classId,
  };
};

export const clearClassIdAction: TClearClassIdAction = () => {
  return {
    type: classroomsActionTypes.CLEAR_CLASS_ID,
  };
};

export const resetStateClassrooms: TResetStateAction = () => {
  return {
    type: classroomsActionTypes.RESET,
  };
};

export { setClasses };
