import { memo } from "react";
import { Link } from 'react-router-dom';

import { Modal } from 'components';

import { locales, routes } from 'constants/index';

import { TArchiveOfferModal } from './ArchiveOfferModal.types';

import './archive-offer-modal.scss';

const ArchiveOfferModal: TArchiveOfferModal = ({
  onClose,
  numberOfDeletedOffers = 1,
  isSingleOffer,
  actionButtons,
}) => {
  return (
    <Modal
      title={locales.offer.archiveOffer}
      className="archive-offer-modal"
      onClose={onClose}
      actionButtons={
        isSingleOffer ? (
          actionButtons
        ) : (
          <span className="flex flex-justify-content-center">
            <Link
              to={`${routes.offers.main}/${routes.offers.listing}`}
              className="archive-offer-modal__link"
            >
              {locales.general.returnToHomepage}
            </Link>
          </span>
        )
      }
    >
      <div className="archive-offer-modal__image" />

      <div className="archive-offer-modal__description">
        {isSingleOffer
          ? locales.offer.confirmArchiveSubTitle
          : locales.formatString(locales.offers.deleteOfferConfirmation, {
              numberOfDeletedOffers,
            })}
      </div>
    </Modal>
  );
};

export default memo(ArchiveOfferModal);
