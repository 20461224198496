import {
  listingActionTypes,
  TSetGridViewAction,
  TSetOffersAction,
  TSetOffersFlickAction,
  TResetStateAction,
} from '.';
import { setFilterPagination, setFilterFilter, resetFilterFilter } from "components/Filter/store/actions"

export const setOffersAction: TSetOffersAction = (offers) => {
  return {
    type: listingActionTypes.SET_OFFERS,
    offers,
  };
};

export const setGridViewAction: TSetGridViewAction = (gridView) => {
  return {
    type: listingActionTypes.SET_GRID_VIEW,
    gridView,
  };
};

export const setOffersFlick: TSetOffersFlickAction = (offersFlick) => {
  return {
    type: listingActionTypes.SET_OFFERS_FLICK,
    offersFlick,
  };
};

export const resetStateListing: TResetStateAction = () => {
  return {
    type: listingActionTypes.RESET,
  };
};

export { setFilterPagination, setFilterFilter, resetFilterFilter }
