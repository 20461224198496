import { memo, useMemo } from "react";

import {
    Modal,
    Button,
    Dropdown,
    FormControl,
    Filter,
    PdfViewer,
    Table2
} from 'components';
import TableExt from "components/Table2/components";

import { enums, locales } from 'constants/index';

import { TSelectCvsModalUI } from './SelectCvsModal.types';
import { ModalsFormEnum } from "../Modals.enums";

import { tableColumns } from "./tableColumns";

import './select-cvs-modal.scss';


const SelectCvsModalUI: TSelectCvsModalUI = ({
    values,
    options,
    data,
    handlers,
}) => {
    const columns = useMemo(() => [
        ...tableColumns,
        {
            id: 'info._id',
            accessorFn: row => row.awsId,
            cell: info => <TableExt.Resume
                onClick={handlers.handleShowCVPreview(info.getValue<string>())}
            />,
            size: 0,
            minSize: 110,
            enableSorting: false,
        },
    ], [tableColumns]);

    return (<>
        <Modal
            className='select-cvs-modal__modal'
            bodyClassName="select-cvs-modal"
            title={locales.offer.chooseResumes}
            description={locales.offer.chooseResumesDescription}
            onClose={handlers.handleClose}
            actionClassName="flex flex-justify-content-center"
            actionButtons={
                <Button
                    variant="primary"
                    onClick={handlers.handleSubmit}
                    isDisabled={!values.degree
                        || !values.classe
                        || values.CVs.length < 1
                    }
                >
                    {locales.resumes.addCVs}
                </Button>
            }
        >
            <div className="select-cvs-modal__dropdownGroup">
                <div className="select-cvs-modal__dropdown">
                    <FormControl
                        className='select-cvs-modal__fc'
                        id={ModalsFormEnum.degree}
                        label={locales.general.level}
                        withRef
                    >
                        <Dropdown
                            id={ModalsFormEnum.degree}
                            name={ModalsFormEnum.degree}
                            options={options.degrees}
                            value={options.degrees.find((item) => item.value === values[ModalsFormEnum.degree])}
                            onChange={handlers.handleChangeDegree}
                        />
                    </FormControl>
                </div>
                <div className="select-cvs-modal__dropdown">
                    <FormControl
                        className='select-cvs-modal__fc'
                        id={ModalsFormEnum.classe}
                        label={locales.general.promotions}
                        withRef
                    >
                        <Dropdown
                            id={ModalsFormEnum.classe}
                            name={ModalsFormEnum.classe}
                            options={options.classes}
                            value={options.classes.find((item) => item.value === values[ModalsFormEnum.classe])}
                            onChange={handlers.handleChangeDropdown(ModalsFormEnum.classe)}
                        />
                    </FormControl>
                </div>
            </div>

            <div className="select-cvs-modal__table">
                <Filter moduleName={enums.moduleNames.selectCVs}>
                    <Table2
                        data={data.CVs}
                        columns={columns}
                        withHeader={false}
                        rowId='_id'
                        enableRowSelection={true}
                        enableMultiRowSelection={true}
                        handleSelectionChange={handlers.handleSelectionChange}
                    />
                </Filter>
            </div>
        </Modal>

        {!!data.awsURL && !!handlers.handleClosePDF && (
            <PdfViewer url={data.awsURL} isVisible isFixedPosition onClose={handlers.handleClosePDF} />
        )}
    </>);
};

export default memo(SelectCvsModalUI);