import { memo, useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import type { IStoreState } from "store";
import { useExtractServerError, useGetNextPreviousItems, useToast } from "hooks";
import { routePaths } from "constants/index";

import { ModalsTypesEnum } from "components/Modals/Modals.enums";
import { resetModal, setModalData } from "components/Modals/store/actions";
import {
    resetProfile,
    resetProfileActions,
    setIsLoading,
    setPipes, setProfileComments,
    setProfileCV,
    setStudentInfo
} from "../store/actions";

import { fetchPipesApi, fetchStudentInfoApi } from "../api";

import type {
    TNewProfile,
    THandleProfileLinkClick,
    THandleAddNoteClick,
    THandleDownloadCvClick,
} from "./NewProfile.type";

import NewProfileUI from "./NewProfile.ui";

const NewProfile: TNewProfile = () => {
    const modalType = ModalsTypesEnum.ADD_TO_YPAREO;

    //* Hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const getNextPreviousItems = useGetNextPreviousItems();
    const { extractErrorMessage } = useExtractServerError();
    const { id = '', offerId = '' } = useParams();


    //* Redux State
    const userInfo = useSelector(({ app: { userInfo } }: IStoreState) => userInfo, shallowEqual);
    const pipes = useSelector(({ candidates: { pipes } }: IStoreState) => pipes, shallowEqual);
    const studentInfo = useSelector(({ candidates: { newProfile: { studentInfo } } }: IStoreState) => studentInfo, shallowEqual);
    const { cvUrl } = useSelector(({ candidates: { newProfile } }: IStoreState) => newProfile, shallowEqual);


    //* Memos
    const applicationsIds = useMemo(() => pipes
        .filter((pipe) => pipe.pipeInfoId === studentInfo?.pipe.pipeInfoId)
        .map((pipe) => pipe._id), [pipes, studentInfo]);

    const { nextId, previousId } = useMemo(() => getNextPreviousItems(applicationsIds, id), [applicationsIds, id]);


    //* Side Effects
    const dismount = useCallback(() => {
        dispatch(resetProfile());
        dispatch(resetModal(modalType));
    }, [dispatch, modalType])


    //* API Actions
    const fetchPipes = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const { data: { data: pipes } } =
                await fetchPipesApi({ offerId });

            batch(() => {
                dispatch(setPipes(pipes));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, offerId]);

    const fetchStudentInfo = useCallback(async () => {
        try {
            if (!id || id === '-1') return;

            batch(() => {
                dispatch(setIsLoading(true));
                dispatch(resetModal(modalType));
            });

            const { data: { user_info: studentInfo } } =
                await fetchStudentInfoApi({ applicationId: id });

            batch(() => {
                cvUrl !== studentInfo.cv_info?.url && dispatch(setProfileCV(studentInfo.cv_info?.url ?? ''));
                dispatch(setStudentInfo(studentInfo));
                dispatch(setModalData(modalType, { studentInfo }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, id, cvUrl]);

    const downloadCv = useCallback(async () => {
        try {
            if (!id || !studentInfo?.cv_info?.url) return;

            dispatch(setIsLoading(true));

            const anchor = document.createElement('a');
            anchor.href = studentInfo?.cv_info?.url;
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);

            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, id, studentInfo]);


    //* Handlers
    const handleProfileLinkClick: THandleProfileLinkClick = useCallback((profileId: string) => () => {
        dismount();
        if (!profileId || profileId === '-1') return navigate(generatePath(routePaths.students.offerPipe, { offerId }));
        navigate(generatePath(routePaths.students.profile, { offerId, id: profileId}));
    }, [dispatch, dismount, offerId]);

    const handleAddNoteClick: THandleAddNoteClick = useCallback(() => {
        dispatch(setProfileComments({
            isEditing: true,
        }))
    }, [dispatch]);

    const handleDownloadCvClick: THandleDownloadCvClick = useCallback(async () => await downloadCv(), [downloadCv]);


    //* Effects
    useEffect(() => {
        dispatch(resetProfileActions());
        Promise.all([
            fetchStudentInfo(),
            pipes.length === 0 && fetchPipes()
        ]);
    }, [id]);

    useEffect(() => {
        return () => dismount();
    }, []);


    //* Wrappers
    const data = useMemo(() => ({
        isSchool: userInfo?.userType === "school",
        nextId,
        previousId,
        cvUrl,
    }), [
        userInfo?.userType,
        nextId,
        previousId,
        cvUrl,
    ]);

    const handlers = useMemo(() => ({
        handleProfileLinkClick,
        handleAddNoteClick,
        handleDownloadCvClick,
    }), [
        handleProfileLinkClick,
        handleAddNoteClick,
        handleDownloadCvClick,
    ]);

    return <NewProfileUI data={data} handlers={handlers} />
};

export default memo(NewProfile);
