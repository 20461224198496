import {FC, MouseEventHandler} from 'react';

import ReactPDF from 'react-pdf';

enum ScaleEnum {
  BASE = 1,
  ZOOMED = 1.5,
}

type THandleLoadSuccess = ReactPDF.DocumentProps['onLoadSuccess'];
type THandleZoomClick = (type?: ScaleEnum) => MouseEventHandler<HTMLDivElement>
type THandleDownloadClick = MouseEventHandler<HTMLDivElement>

export {
  ScaleEnum,
};

export type {
  THandleLoadSuccess,
  THandleZoomClick,
  THandleDownloadClick,
};

interface IProps {
  url: string;
  onClose?: () => void;
  isVisible?: boolean;
  handleDownload?: () => void;
  isFixedPosition?: boolean;
}

type TPdfViewer = FC<IProps>;

export type { TPdfViewer };
