import { memo } from "react";
import { Box, Button, ScrollArea } from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";

import { locales } from "constants/index";
import { Dropdown, PdfViewer } from "components";

import { Pipes } from "./Pipe";
import { TStudentsDashboardUi } from "./StudentsDashboard.types";

import "./students-dashboard.scss";


const StudentsDashboardUi: TStudentsDashboardUi = ({
    data: { offerId, selectedCvUrl },
    options: { offersOptions },
    handlers: { handleOfferChange, handleTriggerMatching, handleCloseCv },
}) => {
    console.log( "StudentsDashboardUi" )

    return (
        <div className="students-dashboard flex flex-column">
            <div className="students-dashboard__filters">
                {/*{offerId !== CustomOffersEnum.spontaneous && <Button*/}
                {/*    variant='link'*/}
                {/*    onClick={handleTriggerMatching}*/}
                {/*    children={locales.student.pipe.match}*/}
                {/*/>}*/}
                <Button
                    radius="lg"
                    leftSection={<IconCirclePlus color="#2ec1ac" fill="#e3f5ff" stroke="1" />}
                    disabled={true}
                >
                    {locales.student.createNewProfile}
                </Button>
                <Dropdown
                    isWhite
                    value={offersOptions.find(({ value }) => offerId === value)}
                    options={offersOptions}
                    onChange={handleOfferChange}
                    className="students-dashboard__offers-dropdown"
                />
            </div>

            <div className="students-dashboard__columns__container">
                <ScrollArea
                    classNames={{ viewport: "students-dashboard__scroll-area" }}
                    h="100%"
                    scrollbars="x"
                    offsetScrollbars
                >
                    <Box className="students-dashboard__columns">
                        <Pipes />
                    </Box>
                </ScrollArea>
            </div>

            {selectedCvUrl.length > 0 && (
                <PdfViewer
                    url={selectedCvUrl}
                    isVisible={selectedCvUrl.length > 0}
                    onClose={handleCloseCv}
                    isFixedPosition
                />
            )}
        </div>
    );
};

export default memo(StudentsDashboardUi);
