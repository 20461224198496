import React, { forwardRef } from 'react';
import { Button, createPolymorphicComponent } from "@mantine/core";
import type { ButtonProps } from "@mantine/core";

import { TsxIcons } from "assets";
import theme from "styles/theme";

import classNames from "./OfferButton.module.scss";


export type OfferButtonProps = ButtonProps & {};

export const OfferButton = createPolymorphicComponent<'button', OfferButtonProps>(
    forwardRef<HTMLButtonElement, OfferButtonProps>((
        {
            c = theme.primaryColor,
            color = "white",
            variant = "white",
            radius = "md",
            rightSection = <TsxIcons.ArrowRight size={ 24 }/>,
            ...others
        },
        ref
    ) => (
        <Button
            {...others}
            ref={ref}
            className={ classNames.applyButton }
            c={ c }
            color={ color }
            variant={ variant }
            radius={ radius }
            rightSection={ rightSection }
        />
    ))
);