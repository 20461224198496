import { memo, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Pill } from "@mantine/core";
import classNames from "classnames";

import { DraggableItem, CandidateCard, Checkbox } from "components";
import { IStoreState } from "store";

import { TItem } from "./Item.types";

import { locales } from "constants/index";

import "./item.scss";

const Item: TItem = ({
    className = "",
    data: { item, index },
    handlers: { handleCandidateClick, handleSelectCandidate, handleCopySocialMedia, handleViewCv },
}) => {
    //* Redux State
    const reduxSelectedCandidates = useSelector(
        ({ candidates: { selectedCandidates } }: IStoreState) => selectedCandidates,
        shallowEqual
    );
    const reduxDraggedId = useSelector(({ candidates: { draggedId } }: IStoreState) => draggedId, shallowEqual);

    //* Memos
    const selectedCandidates = useMemo(() => reduxSelectedCandidates, [reduxSelectedCandidates]);
    const isSelected = useMemo(() => selectedCandidates.includes(item._id), [selectedCandidates]);
    const draggedId = useMemo(() => reduxDraggedId, [reduxDraggedId]);

    return (
        <DraggableItem
            id={item._id}
            index={index}
            className={classNames({
                "draggable-item": true,
                "select-mode": selectedCandidates.length !== 0,
                checked: isSelected && !!draggedId,
                [className]: !!className,
            })}
        >
            <div className="draggable-item__checkbox">
                <Checkbox isChecked={isSelected} onChange={handleSelectCandidate(item._id)} />
            </div>
            <div
                className={classNames({
                    "draggable-item__badge": true,
                    dragging: draggedId === item._id,
                })}
                children={reduxSelectedCandidates.length}
            />
            <CandidateCard
                isSelected={isSelected}
                data={{ item }}
                handlers={{
                    handleCandidateClick,
                    handleCopySocialMedia,
                    handleViewCv,
                }}
            />
        </DraggableItem>
    );
};

export default memo(Item);
