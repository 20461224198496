"use client";

import { Text } from "@mantine/core";

import classNames from 'styles/sass/components/Text.module.scss';


export default Text.extend({
	defaultProps: {},
	classNames,
});