import { ISettingsGlobalState, TSettingsAction } from './store.types';
import { settingsActionTypes } from "./action-types";
import { SettingRoutes } from '../enums';

const initialState: ISettingsGlobalState = {
  activeModule: SettingRoutes.MY_ACCOUNT,
  websiteEdit: true,
  myAccount: {
    guests: [],
    yPareo: {
      canEdit: true,
    },
  },
  websiteDesign: {
    colors: {
      primary: '#12564b',
      secondary: '#61CBB6',
    },
    logo: [],
    publicity: [],
    courses: [],
    toBeDeletedAttachments: [],
  },
  modals: {
    courses: {
      data: null,
    },
    guests: {
      data: null,
    },
  },
  uploads: {},
  contributors: {},
  editMode: {},
  communication: {
    triggers: [],
    emails: [],
    email: {},
    chosenIdentifier: null,
    chosenVariables: [
      "{{CRE_NAME}}",
      "{{OFFER_NAME}}",
      "{{LANDING_SCHOOL_BLOOM}}",
      "{{Prénom}}",
      "{{Nom}}",
      "{{SCHOOL_NAME}}",
    ],
    canReset: false,
    inEdition: false,
    inCreation: false,
  }
};

const reducer = (state: ISettingsGlobalState = initialState, action: TSettingsAction) => {
  switch (action.type) {
    case settingsActionTypes.SET_ACTIVE_MODULE:
      return {
        ...state,
        activeModule: action.payload,
      };

    case settingsActionTypes.SET_WEBSITE_EDIT:
      return {
        ...state,
        websiteEdit: action.payload,
      };



    case settingsActionTypes.SET_TRIGGERS:
      return {
        ...state,
        communication: {
          ...state.communication,
          triggers: action.payload,
        }
      };

    case settingsActionTypes.SET_EMAILS:
      return {
        ...state,
        communication: {
          ...state.communication,
          emails: action.payload,
        }
      };

    case settingsActionTypes.SET_EMAIL:
      return {
        ...state,
        communication: {
          ...state.communication,
          email: {
            ...state.communication.email,
            ...action.payload,
          }
        }
      };

    case settingsActionTypes.RESET_EMAIL:
      return {
        ...state,
        communication: {
          ...state.communication,
          email: initialState.communication.email,
        }
      };

    case settingsActionTypes.SET_CHOSEN_IDENTIFIER:
      return {
        ...state,
        communication: {
          ...state.communication,
          chosenIdentifier: action.payload,
        }
      };

    case settingsActionTypes.SET_USER_TRIGGER:
      return {
        ...state,
        communication: {
          ...state.communication,
          userTrigger: action.payload,
        }
      };

    case settingsActionTypes.SET_CHOSEN_VARIABLES:
      return {
        ...state,
        communication: {
          ...state.communication,
          chosenVariables: action.payload,
        }
      };

    case settingsActionTypes.RESET_CHOSEN_TRIGGER:
      return {
        ...state,
        communication: {
          ...state.communication,
          chosenIdentifier: null,
          chosenTrigger: null,
        }
      };

    case settingsActionTypes.SET_CAN_RESET:
      return {
        ...state,
        communication: {
          ...state.communication,
          canReset: action.payload,
        }
      };

    case settingsActionTypes.SET_IN_EDITION:
      return {
        ...state,
        communication: {
          ...state.communication,
          inEdition: action.payload,
        }
      };

    case settingsActionTypes.SET_IN_CREATION:
      return {
        ...state,
        communication: {
          ...state.communication,
          inCreation: action.payload,
        }
      };



    case settingsActionTypes.SET_EDIT_MODE:
      return {
        ...state,
        editMode: {
          ...state.editMode,
          [action.payload.type]: action.payload.state,
        }
      };

    case settingsActionTypes.SET_MY_ACCOUNT:
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          ...action.payload
        }
      };

    case settingsActionTypes.SET_YPAREO:
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          yPareo: {
            ...state.myAccount.yPareo,
            ...action.payload
          }
        }
      };

    case settingsActionTypes.RESET_YPAREO:
      return {
        ...state,
        myAccount: {
          ...state.myAccount,
          yPareo: initialState.myAccount.yPareo,
        }
      };

    case settingsActionTypes.SET_WEBSITE_DESIGN:
      return {
        ...state,
        websiteDesign: {
          ...state.websiteDesign,
          ...action.payload
        }
      };

    case settingsActionTypes.RESET_WEBSITE_DESIGN:
      return {
        ...state,
        websiteDesign: {},
      };

    case settingsActionTypes.SET_MODALS:
      return {
        ...state,
        modals: {
          ...state.modals,
          ...action.payload
        }
      };

    case settingsActionTypes.SET_UPLOAD_ATTACHMENT:
      return {
        ...state,
        uploads: {
          ...state.uploads,
          ...action.payload
        }
      };

    case settingsActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
