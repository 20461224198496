import classNames from "classnames";
import { locales } from 'constants/index';

import { TFormLayout } from './FormLayout.types';

import './form-layout.scss';

const FormLayout: TFormLayout = ({
  title = '',
  description = '',
  banner = '',
  className = '',
  isOptional = false,
  children,
}) => {
  const classes = classNames({
    'form-layout': true,
    [className]: !!className,
  });

  return (
    <div className={classes}>
      <div className="form-layout__content">
        {!!title &&<div className="form-layout__title">
          {title} {isOptional && <span>({locales.general.optional})</span>}
        </div>}

        {!!description && (
          <div className="form-layout__description">{description}</div>
        )}

        {!!banner && (
          <div className="form-layout__banner" dangerouslySetInnerHTML={{ __html: banner }} />
        )}

        {children}
      </div>
    </div>
  );
};

export default FormLayout;
