import { Link } from 'react-router-dom';

import { TextInput, FormControl, Button, Switch, Loader } from 'components';

import AuthenticationLayout from '../AuthenticationLayout';

import { locales, routes } from 'constants/index';
import { formSubmit } from 'utils';
import { Icons, Images } from 'assets';

import { TLoginUi } from './Login.types';

import './login.scss';

const LoginUi: TLoginUi = ({
  isLoading,
  data: { emailAddress, password, isRememberMe, isPasswordVisible },
  errors,
  handlers: {
    handleInputChange,
    handleInputBlur,
    handlePasswordVisibilityChange,
    handleRememberMeChange,
    handleSubmit,
  },
}) => {
  return (
    <AuthenticationLayout mainImage={Images.BusinessDeal} isLeftContentRounded>
      {isLoading && <Loader />}

      <div className="login flex flex-justify-content-center flex-align-items-center">
        <div className="login__content flex flex-column">
          <div className="login__title">{locales.login.connect}</div>

          <div className="login__description">{locales.login.description}</div>

          <form onSubmit={formSubmit} className="login__form">
            <FormControl
              id="emailAddress"
              label={locales.general.email}
              error={errors.emailAddress}
              className="login__field"
              isRequired
              withRef
            >
              <TextInput
                id="emailAddress"
                name="emailAddress"
                value={emailAddress}
                isError={errors.emailAddress?.length > 0}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="login__field-input"
              />
            </FormControl>

            <FormControl
              id="password"
              label={locales.general.password}
              error={errors.password}
              className="login__field"
              isRequired
              elementClassName='flex'
              withRef
            >
              <TextInput
                id="password"
                name="password"
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                isError={errors.password?.length > 0}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="login__field-input"
                rightContent={
                  <img
                    src={isPasswordVisible ? Icons.EyeGray : Icons.EyeClosed}
                    alt="eye"
                    className="login__password-icon"
                    onClick={handlePasswordVisibilityChange}
                  />
                }
              />
            </FormControl>
          </form>

          <Link
            to={routes.authentication.forgotPassword}
            className="login__forgot-password"
          >
            {locales.login.forgotPassword}
          </Link>

          <Switch
            id="rememberMe"
            name="rememberMe"
            label={locales.login.stayConnected}
            isChecked={isRememberMe}
            extraClass="login__remember-me flex-justify-content-end"
            onChange={handleRememberMeChange}
          />

          <div className="login__submit flex flex-justify-content-center">
            <Button
              variant="primary"
              onClick={handleSubmit}
            >
              {locales.login.login}
            </Button>
          </div>

          <div className="login__footer">
            {locales.login.new}
            &nbsp;
            <Link
              to={routes.authentication.register.main}
              className="login__register-link"
            >
              {locales.login.register}
            </Link>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default LoginUi;
