import {memo, useState} from "react";
import { Link } from 'react-router-dom';

import AuthenticationLayout from 'modules/Authentication/AuthenticationLayout';

import { TextInput, FormControl, Button, Checkbox } from 'components';

import { Icons, Images } from 'assets';
import { locales, routes } from 'constants/index';
import { formSubmit } from 'utils';

import { RegisterEnum } from "../Register.types";
import { TGeneralInfoUi } from './GeneralInfo.types';

import '../register.scss';

const GeneralInfoUi: TGeneralInfoUi = ({
  data: {
    register,
  },
  errors,
  handlers,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  return (
    <AuthenticationLayout mainImage={Images.BusinessDeal}>
      <div className="register flex flex-justify-content-center flex-align-items-center">
        <div className="register__content flex flex-column flex-align-items-center">
          <div className="register__title">
            {locales.registration.createAccount}
          </div>

          <div className="register__description register__description--reset">
            {locales.registration.description}
          </div>

          <div className="mobile-icon">
            <div className="mobile-icon__svg mobile-icon__svg__register" />
          </div>

          <form onSubmit={formSubmit} className="register__form">
            <FormControl
              id={RegisterEnum.emailAddress}
              label={`${locales.general.email}*`}
              error={errors[RegisterEnum.emailAddress]}
              className="register__field"
              withRef
            >
              <TextInput
                id={RegisterEnum.emailAddress}
                name={RegisterEnum.emailAddress}
                value={register[RegisterEnum.emailAddress]}
                isError={!!errors[RegisterEnum.emailAddress]}
                onChange={handlers.handleInputChange}
                onBlur={handlers.handleInputBlur}
              />
            </FormControl>

            <FormControl
              id={RegisterEnum.password}
              label={`${locales.general.password}*`}
              error={errors[RegisterEnum.password]}
              className="register__field"
              elementClassName='flex'
              withRef
            >
              <TextInput
                id={RegisterEnum.password}
                name={RegisterEnum.password}
                type={isPasswordVisible ? 'text' : 'password'}
                value={register[RegisterEnum.password]}
                isError={!!errors[RegisterEnum.password]}
                onChange={handlers.handleInputChange}
                onBlur={handlers.handleInputBlur}
                rightContent={
                  <img
                    src={isPasswordVisible ? Icons.EyeGray : Icons.EyeClosed}
                    alt="eye"
                    className="register__password-icon"
                    onClick={() => setIsPasswordVisible(p => !p)}
                  />
                }
              />
            </FormControl>

            <FormControl
              id={RegisterEnum.passwordConfirmation}
              label={`${locales.registration.passwordConfirmation}*`}
              error={errors[RegisterEnum.passwordConfirmation]}
              className="register__field"
              elementClassName='flex'
              withRef
            >
              <TextInput
                id={RegisterEnum.passwordConfirmation}
                name={RegisterEnum.passwordConfirmation}
                type={isPasswordVisible ? 'text' : 'password'}
                value={register[RegisterEnum.passwordConfirmation]}
                isError={!!errors[RegisterEnum.passwordConfirmation]}
                onChange={handlers.handleInputChange}
                onBlur={handlers.handleInputBlur}
                rightContent={
                  <img
                    src={isPasswordVisible ? Icons.EyeGray : Icons.EyeClosed}
                    alt="eye"
                    className="register__password-icon"
                    onClick={() => setIsPasswordVisible(p => !p)}
                  />
                }
              />
            </FormControl>

            <Checkbox
              label={locales.registration.terms}
              isChecked={register[RegisterEnum.termsOfUse]}
              className="register__terms"
              onChange={handlers.handleIsAgreedChange}
            />

          </form>

          <div className="register__submit">
            <Button
              variant="primary"
              onClick={handlers.handleSubmit}
              isDisabled={!register[RegisterEnum.termsOfUse]}
            >
              {locales.general.next}
            </Button>
          </div>

          <div className="register__footer">
            {locales.registration.haveAnAccount}
            &nbsp;
            <Link
              to={routes.authentication.login}
              className="register__login-link"
            >
              {locales.general.login}
            </Link>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default memo(GeneralInfoUi);
