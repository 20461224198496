enum ModalsFormEnum {
    degree = 'degree',
    classe = 'classe',
}

enum ModalsTypesEnum {
    SELECT_CVS = 'selectCVs',
    UPLOAD_SUCCESS = 'uploadSuccess',
    ADD_TO_YPAREO = 'addToYpareo',
    INTERVIEW_DETAILS = 'interviewDetails',
    VALIDATE_EMAIL = 'validateEmail',
}

export {
    ModalsFormEnum,
    ModalsTypesEnum,
};

