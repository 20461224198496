import { memo } from "react";
import { Image } from "@mantine/core";

import {
    LinkedInLogo,
    IndeedLogo,
    HelloWorkLogo,
    OuestFranceLogo,
    WizbiiLogo,
    MeteojobLogo,
    LetudiantLogo,
    MonsterLogo,
    ApecLogo,
    PmejobLogo,
    Icons,
} from "assets";


const JobboardLogoComponent = (props: { utmLabel?: string }) => {
    const { utmLabel } = props;
    let iconSrc;
    switch (utmLabel?.toLowerCase()) {
        case "linkedin":
            iconSrc = LinkedInLogo;
            break;
        case "indeed":
            iconSrc = IndeedLogo;
            break;
        case "hellowork":
            iconSrc = HelloWorkLogo;
            break;
        case "ouest-france":
            iconSrc = OuestFranceLogo;
            break;
        case "wizbii":
            iconSrc = WizbiiLogo;
            break;
        case "meteojob":
            iconSrc = MeteojobLogo;
            break;
        case "letudiant":
            iconSrc = LetudiantLogo;
            break;
        case "monster":
            iconSrc = MonsterLogo;
            break;
        case "apec":
            iconSrc = ApecLogo;
            break;
        case "pmejob":
            iconSrc = PmejobLogo;
            break;
        default:
            iconSrc = Icons.MainLogo;
    }
    return <Image h="auto" w="40%" fit="contain" src={iconSrc} />;
};

export const JobboardLogo = memo(JobboardLogoComponent);