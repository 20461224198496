import { useEffect, useCallback, useMemo, memo } from "react";
import { useSelector, useDispatch, batch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Router from "modules/Router";

import { MemoizedLoader, MemoizedThumbLoader, Toast, Tooltip } from "components";

import { useToast, useExtractServerError } from "hooks";
import {
    getAccessToken,
    getLocale,
    setLocale,
    setAccessToken,
    setRefreshToken,
} from "utils";

import { locales, routes, enums } from "constants/index";

import {
    fetchAttachmentApi,
    fetchConfigApi,
    fetchDegreesApi,
    fetchTagsApi,
    fetchUserInfoApi,
    verifyEmailApi,
} from "./api";

import {
    initAppAction,
    setConfigAction,
    authenticateUserAction,
    setUserInfoAction,
    setIsLoading,
    setDegrees,
    setTags,
} from "./store/actions";

import type { IStoreState } from "./App.types";

const App = () => {
    // * Hooks Init
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();
    const [ searchParams ] = useSearchParams();

    //* Local Storage
    const AccessToken = getAccessToken();
    const StoredLocale = getLocale();

    //* Redux State
    const Locale = useSelector( ( { app: { locale } }: IStoreState ) => locale );
    const AuthStatus = useSelector( ( { app: { authStatus } }: IStoreState ) => authStatus );

    //* Memos
    const accessToken = useMemo( () => AccessToken, [ AccessToken ] );
    const storedLocale = useMemo( () => StoredLocale, [ StoredLocale ] );
    const locale = useMemo( () => Locale, [ Locale ] );
    const authStatus = useMemo( () => AuthStatus, [ AuthStatus ] );

    //* Init
    const init = useCallback( () => {
        //* The following code creates a click event in order to bypass the Chromium autofill bug.
        const password = document.querySelector( "#password" ) as HTMLInputElement;
        if ( password ) password.value = "";

        const appLocale = storedLocale ?? locale;
        if ( !storedLocale ) {
            setLocale( appLocale );
            dispatch( initAppAction( appLocale ) );
        }
    }, [ document, dispatch, setLocale, locale, storedLocale ] );

    //* API Actions
    const load = useCallback( async () => {
        try {
            dispatch( setIsLoading( true ) );

            const [
                configData,
                attachmentData,
                userInfoData,
                degreesData,
                tagsData,
            ] = await Promise.all( [
                fetchConfigApi(),
                fetchAttachmentApi(),
                fetchUserInfoApi(),
                fetchDegreesApi(),
                fetchTagsApi(),
            ] );
            const {
                data: { success, ...config },
            } = configData;
            const {
                data: { data: attachment },
            } = attachmentData;
            const {
                data: { data: userInfo },
            } = userInfoData;
            const {
                data: { data: degrees },
            } = degreesData;
            const {
                data: { data: tags },
            } = tagsData;

            batch( () => {
                dispatch( setDegrees( degrees ) );
                dispatch( setTags( tags ) );
                dispatch( setConfigAction( { ...config, ...attachment } ) );
                dispatch( setUserInfoAction( userInfo ) );
                dispatch( setIsLoading( false ) );
            } );
        } catch ( error ) {
            const errorMessage = extractErrorMessage( error );
            toast( { type: "error", message: errorMessage } );
            dispatch( setIsLoading( false ) );
        }
    }, [ dispatch ] );

    const verifyEmail = useCallback(
        async ( id: string ) => {
            try {
                dispatch( setIsLoading( true ) );

                const {
                    data: { data },
                } = await verifyEmailApi( id );

                if ( data ) {
                    const {
                        token: accessToken,
                        refresh_token: refreshToken,
                        user,
                    } = data;

                    setAccessToken( { accessToken: accessToken } );
                    setRefreshToken( { refreshToken } );

                    batch( () => {
                        dispatch( authenticateUserAction( {
                            accessToken: accessToken,
                            refreshToken,
                        } ) );
                        dispatch( setUserInfoAction( user ) );
                    } );

                    navigate( `${ routes.listing.main }` );
                }

                dispatch( setIsLoading( false ) );
            } catch ( error ) {
                const errorMessage = extractErrorMessage( error );
                toast( { type: "error", message: errorMessage } );
                dispatch( setIsLoading( false ) );
            }
        },
        [ dispatch, accessToken, navigate ]
    );

    // Set App Locale
    locales.setLanguage( locale );

    //* Effects
    useEffect( () => {
        init();
    } );

    useEffect( () => {
        const guestId = searchParams.get( "guestId" );
        if ( guestId ) navigate( `${ routes.authentication.guest }/${ guestId }` );
    }, [ searchParams ] );

    useEffect( () => {
        const id = searchParams.get( "id" );
        if ( id ) verifyEmail( id );
    }, [ searchParams, verifyEmail ] );

    useEffect( () => {
        if ( accessToken ) load();
    }, [ accessToken, load ] );

    useEffect( () => {
        if ( authStatus === enums.app.status.authenticated && !accessToken ) {
            navigate( routes.authentication.logout );
        }
    }, [ authStatus, accessToken, navigate ] );

    return (
        <div className="page">
            <MemoizedLoader/>

            <MemoizedThumbLoader/>

            <Toast/>

            <Tooltip/>

            <Router isAuthenticated={ !!accessToken }/>
        </div>
    );
};

export default memo( App );
