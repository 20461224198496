import classNames from 'classnames';

import { locales } from 'constants/index';

import { TAuthenticationLayout } from './AuthenticationLayout.types';

import './authentication-layout.scss';

const AuthenticationLayout: TAuthenticationLayout = ({
  children,
  className = '',
  mainImage,
  isLeftContentRounded = false,
}) => {
  const classes = classNames({
    'authentication-layout flex': true,
    'authentication-layout--green': !isLeftContentRounded,
    [className]: className.length > 0,
  });

  const leftContentClasses = classNames({
    'authentication-layout__left-content':
      true,
      'authentication-layout__left-content--rounded': isLeftContentRounded,
      'authentication-layout__right-content--rounded': !isLeftContentRounded,
  });

  return (
    <div className="main-container">
      <div className={classes}>
          <div className={leftContentClasses}>
            <div className="authentication-layout__logo"></div>
            <img
              src={mainImage}
              className="authentication-layout__image"
              alt="people"
            />
            <div className="authentication-layout__title">
              {locales.general.appTitle}
            </div>
            <div className="authentication-layout__quote">
              “{locales.general.appDescription}“
            </div>
          </div>

          <div
            className={classNames({
              'authentication-layout__right-content': true,
              'authentication-layout__right-content--rounded':
                !isLeftContentRounded,
            })}
          >
              <div className="authentication-layout__line"></div>
              <div className="authentication-layout__outlet">
                  {children}
              </div>
          </div>
      </div>
      <div className="mobile__footer">
          <div className="mobile__footer__icon" />
      </div>
    </div>
  );
};

export default AuthenticationLayout;
