import { useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { convertToDropdown } from "utils";
import { useExtractServerError, useGetUserInfo, useToast } from "hooks";
import { locales, routes } from "constants/index";
import type { IOffer } from "common";

import { fetchOfferApi } from "../../Offers/api";
import { fetchMatchingApi, fetchOffersOptionsApi, fetchPipesApi } from "../api";
import {
    clearSelectedCandidates,
    setIsLoading,
    setOfferId,
    setOptionsOffers,
    setPipes,
    setPipesByColumn,
    setSelectedCvUrl,
} from "../store/actions";

import StudentsDashboardUi from "./StudentsDashboard.ui";
import {
    IDropdownObject,
    IPipesByColumn,
    IStoreState,
    THandleDropdownChange,
    TStudentsDashboard,
} from "./StudentsDashboard.types";
import { CustomOffersEnum } from "./StudentsDashboard.enums";

const StudentsDashboard: TStudentsDashboard = () => {
    console.log( "StudentsDashboard" )
    // * Hooks Init
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const params = useParams();
    const { extractErrorMessage } = useExtractServerError();
    const cancelToken = axios.CancelToken.source();

    //* Redux State
    const reduxUserInfo = useGetUserInfo();
    const columns = useSelector( ( { app: { config } }: IStoreState ) => config.pipes, shallowEqual );
    const candidate = useSelector( ( { candidates }: IStoreState ) => candidates, shallowEqual );
    const reduxPipesByColumns = useSelector(
        ( { candidates: { pipesByColumn } }: IStoreState ) => pipesByColumn,
        shallowEqual
    );

    //* Memos
    const userInfo = useMemo( () => reduxUserInfo, [ reduxUserInfo ] );

    const offerId = useMemo( () => params.offerId || candidate.offerId || "", [ candidate.offerId, params.offerId ] );

    const pipeColumns = useMemo( () => columns, [ columns ] );

    const pipes = useMemo( () => candidate.pipes, [ candidate.pipes ] );

    const offersOptions = useMemo( () => candidate.options.offers, [ candidate.options.offers ] );

    const selectedCvUrl = useMemo( () => candidate.selectedCvUrl, [ candidate.selectedCvUrl ] );

    const customOffers = useMemo(
        () => [
            {
                key: CustomOffersEnum.spontaneous,
                label: locales.student.customOffers.spontaneous,
                value: CustomOffersEnum.spontaneous,
            },
        ],
        []
    );


   //* Api Actions
    const fetchOffer = useCallback(
        async ( offerId?: string ): Promise<false | IOffer> => {
            try {
                if ( !offerId ) return false;

                const {
                    data: { data: offer },
                } = await fetchOfferApi( offerId );

                return offer;
            } catch ( error ) {
                console.log( error );
                return false;
            }
        },
        [ offerId ]
    );

    const fetchOffersOptions = useCallback( async () => {

        try {
            const {
                data: { data },
            } = await fetchOffersOptionsApi();

            const offerExists = await fetchOffer( offerId );

            let offerOptions = [ ...customOffers, ...data ];

            if ( !!offerExists && !data.find( ( e ) => e.value === offerId ) ) {
                offerOptions = [
                    {
                        key: offerId,
                        label: offerExists.info.title,
                        value: offerId,
                    },
                    ...offerOptions,
                ];
            }

            dispatch(
                setOptionsOffers(
                    convertToDropdown( {
                        data: offerOptions,
                    } )
                )
            );

            if ( offerExists ) {
                return dispatch( setIsLoading( false, "thumb" ) );
            }

            if ( data.length > 0 ) {
                const url = `${ routes.students.main }/${ routes.students.offer }/${ data[ 0 ].value }`;
                navigate( url );
            }
        } catch ( error ) {
            const errorMessage = extractErrorMessage( error );
            toast( { type: "error", message: errorMessage } );
        }

    }, [ offerId, customOffers ] );

    const fetchPipes = useCallback(
        async ( offerId: string, invisible?: boolean ) => {
            !invisible && dispatch( setIsLoading( true, "thumb" ) );

            try {
                const {
                    data: { data },
                } = await fetchPipesApi( {
                    ...( offerId !== CustomOffersEnum.spontaneous && { offerId } ),
                    ...( offerId === CustomOffersEnum.spontaneous && { spontaneous: true } ),
                    limit: 10000,
                    cancelToken: cancelToken.token,
                } );
                if ( data.length === pipes.length ) {
                    const oldIDs = JSON.stringify( data.map( ( e ) => [ e._id, e.pipeInfoId ] ) );
                    const newIDs = JSON.stringify( pipes.map( ( e ) => [ e._id, e.pipeInfoId ] ) );
                    if ( Object.keys( reduxPipesByColumns ).length > 0 && newIDs === oldIDs )
                        return !invisible && dispatch( setIsLoading( false, "thumb" ) );
                }

                // TODO: create a condition where the redux is only updated if the pipesByColumn are different.
                // TODO: check if you need a redux action that only updates specific columns
                // TODO: check if how this interacts with the memos you put in place in the Column & Pipe components.
                const pipesByColumn: IPipesByColumn = {};
                pipeColumns.map( ( column ) => {
                    pipesByColumn[ column.value ] = data.filter( ( item ) => item.pipeInfoId === column.value );
                } );
                batch( () => {
                    dispatch( setPipesByColumn( pipesByColumn ) );
                    dispatch( setPipes( data ) );
                } );
            } catch ( error ) {
                if ( axios.isCancel( error ) ) return;
                const errorMessage = extractErrorMessage( error );
                toast( { type: "error", message: errorMessage } );

                batch( () => {
                    dispatch( setPipesByColumn( {} ) );
                    dispatch( setPipes( [] ) );
                } );
            }

            !invisible && dispatch( setIsLoading( false, "thumb" ) );
        },
        [ pipes, pipeColumns, cancelToken, offerId ]
    );

    const fetchMatching = useCallback( async () => {
        dispatch( setIsLoading( true, "thumb" ) );

        try {
            const {
                data: { message },
            } = await fetchMatchingApi( {
                cancelToken: cancelToken.token,
                offer_id: offerId,
            } );

            toast( { type: "success", message: message } );

            await fetchPipes( offerId, true );
        } catch ( error ) {
            const errorMessage = extractErrorMessage( error );
            toast( { type: "error", message: errorMessage } );
        }

        dispatch( setIsLoading( false, "thumb" ) );
    }, [ dispatch, offerId, cancelToken ] );

    //* Handlers
    const handleOfferChange: THandleDropdownChange = useCallback( ( option ) => {
        const { value } = option as IDropdownObject;

        navigate( `${ routes.students.main }/${ routes.students.offer }/${ value }` );
        batch( () => {
            dispatch( setOfferId( value ) );
            dispatch( clearSelectedCandidates() );
        } );
    }, [] );

    const handleTriggerMatching = useCallback( async () => {
        await fetchMatching();
    }, [ fetchMatching ] );

    const handleCloseCv = useCallback( () => {
        dispatch( setSelectedCvUrl( "" ) );
    }, [] );

    //* Effects
    useEffect( () => {
        if ( userInfo ) fetchOffersOptions();
    }, [ userInfo ] );

    useEffect( () => {
        document.addEventListener( "keydown", ( e ) => {
            if ( e.key !== "Escape" ) return;
            e.preventDefault();
            dispatch( clearSelectedCandidates() );
        } );
    }, [] );

    useEffect( () => {
        if ( candidate.offerId !== offerId ) dispatch( setOfferId( offerId ) );
        if ( pipeColumns.length === 0 ) return;
        if ( !!offerId ) fetchPipes( offerId );
        return () => {
            cancelToken.cancel( "Request canceled." );
            dispatch( setIsLoading( false, "thumb" ) );
        };
    }, [ offerId, pipeColumns ] );

    useEffect( () => {
        if ( pipeColumns.length === 0 ) return;
        if ( !!offerId ) fetchPipes( offerId, true );
        return () => {
            cancelToken.cancel( "Request canceled." );
            dispatch( setIsLoading( false, "thumb" ) );
        };
    }, [ candidate.triggerRefreshPipes ] );

    //* Wrappers
    const data = useMemo(
        () => ( {
            offerId,
            selectedCvUrl,
        } ),
        [
            offerId,
            selectedCvUrl,
        ]
    );

    const options = useMemo(
        () => ( {
            offersOptions,
        } ),
        [ offersOptions ]
    );

    const handlers = useMemo(
        () => ( {
            handleOfferChange,
            handleTriggerMatching,
            handleCloseCv,
        } ),
        [ handleOfferChange, handleTriggerMatching, handleCloseCv ]
    );

    return <StudentsDashboardUi data={ data } options={ options } handlers={ handlers }/>;
};

export default StudentsDashboard;
