import { baseApi } from "utils";

import {
  TCreateClassesApi,
  TRemoveClassesApi,
  TUpdateClassApi,
  TCreateResumesApi,
  TUpdateResumeTag,
  TGenerateResumeApi,
  TUpdateResumeNameApi,
  TRemoveTagApi,
  TRemoveResumesApi,
  TFetchResumesApi,
  TFetchClassesApi,
} from "./api.types";

export const createClassesApi: TCreateClassesApi = (payload) => {
  return baseApi().post("/school/classes/degrees", payload);
};

export const removeClassApi: TRemoveClassesApi = (
  id: string,
  degreeId: string
) => {
  return baseApi().delete("/school/classes/remove", {
    params: { id, degree: degreeId },
  });
};

export const updateClassApi: TUpdateClassApi = (payload) => {
  return baseApi().put("/school/classes/update", payload);
};

export const createResumesApi: TCreateResumesApi = (payload, config) => {
  return baseApi().post("/cvs/upload", payload, config);
};

export const updateResumeTagApi: TUpdateResumeTag = (payload) => {
  return baseApi().put("/school/cvs/tag/update", payload);
};

export const generateResumeApi: TGenerateResumeApi = (payload) => {
  return baseApi().post("/attachments/sign", payload);
};

export const updateResumeNameApi: TUpdateResumeNameApi = (payload) => {
  return baseApi().put("/school/cvs/update", payload);
};

export const removeTagApi: TRemoveTagApi = (tag: string, id: string) => {
  return baseApi().delete("/school/cvs/tag/remove", {
    params: {
      tag,
      id,
    },
  });
};

export const removeResumesApi: TRemoveResumesApi = (payload) => {
  return baseApi().delete("/school/cvs/remove", { data: payload });
};


export const fetchResumesApi: TFetchResumesApi = (params) => {
  return baseApi().get('/school/cvs/fetch', { params });
};

export const fetchClassesApi: TFetchClassesApi = (degreeId, sourcing = null) => {
  return baseApi().get('/school/classes/degrees', {
    params: {
      degree: degreeId,
      sourcing: sourcing
    },
  });
};

