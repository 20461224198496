import { memo } from "react";
import {
    FormControl,
    TextInput,
    Dropdown,
    Chip,
    Label,
    Button,
    Loader,
    FormLayout,
    TooltipWrapper,
    RichTextEditor,
} from "components";

import { locales } from "constants/index";

import { TCreateOfferGeneralInformationUi } from "./CreateOfferGeneralInformation.types";

const CreateOfferGeneralInformationUi: TCreateOfferGeneralInformationUi = ({
    isLoading,
    isUserSubscribed,
    config,
    errors,
    options,
    data,
    handlers: {
        handleInputChange,
        handleDropdownChange,
        handleEducationLevelClick,
        handleWorkPlaceClick,
        handleLocationChange,
        handleLocationsDropdownChange,
        handleSubmitDraft,
        handleGeneralInformationSubmit,
        handleTextEditorChange,
        handleTextEditorBlur,
    },
}) => {
    const CAP = "65240cda6b836ac572b11940";
    return (
        <>
            {isLoading && <Loader />}

            <FormLayout
                title={locales.createOffer.generalInformation}
                description={locales.createOffer.generalInformationDescription}
                className="create-offer__form__medium"
            >
                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.offer.contractType}
                    content={locales.offer.tooltip.contractType}
                >
                    <FormControl
                        id="contractType"
                        label={locales.offer.contractType}
                        error={errors.contractType}
                        withRef
                    >
                        <Dropdown
                            id="contractType"
                            name="contractType"
                            options={options.contracts}
                            value={options.contracts.find((contract) => contract.value === data.contractType)}
                            onChange={handleDropdownChange("contractType")}
                            isError={errors.contractType?.length > 0}
                        />
                    </FormControl>
                </TooltipWrapper>

                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.createOffer.title}
                    content={locales.createOffer.tooltip.title}
                >
                    <FormControl
                        id="title"
                        label={locales.createOffer.title}
                        note="Caractères : max. 45"
                        error={errors.title}
                        withRef
                    >
                        <TextInput
                            id="title"
                            name="title"
                            maxLength={45}
                            isDark
                            value={data.title}
                            onChange={handleInputChange}
                            isError={errors.title?.length > 0}
                        />
                    </FormControl>
                </TooltipWrapper>

                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.createOffer.description}
                    content={locales.createOffer.tooltip.description}
                >
                    <FormControl
                        id="description"
                        error={errors.description}
                        note="Caractères : min. 200"
                        className="create-offer__form__text-editor"
                        withRef
                    >
                        <RichTextEditor
                            id={"description"}
                            placeholder={locales.createOffer.description}
                            value={data.description}
                            readOnly={false}
                            onChange={handleTextEditorChange("description")}
                            onBlur={handleTextEditorBlur("description")}
                            isBorderless
                        />
                    </FormControl>
                </TooltipWrapper>

                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.offer.missions}
                    content={locales.offer.tooltip.missions}
                >
                    <FormControl
                        id="missions"
                        error={errors.missions}
                        note="Caractères : min. 200"
                        className="create-offer__form__text-editor"
                        withRef
                    >
                        <RichTextEditor
                            id={"missions"}
                            placeholder={locales.offer.missions}
                            value={data.missions}
                            readOnly={false}
                            onChange={handleTextEditorChange("missions")}
                            onBlur={handleTextEditorBlur("missions")}
                            isBorderless
                        />
                    </FormControl>
                </TooltipWrapper>

                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.createOffer.profile}
                    content={locales.createOffer.tooltip.profile}
                >
                    <FormControl
                        id="profile"
                        error={errors.profile}
                        note="Caractères : min. 200"
                        className="create-offer__form__text-editor"
                        withRef
                    >
                        <RichTextEditor
                            id={"profile"}
                            placeholder={locales.createOffer.profile}
                            value={data.profile}
                            readOnly={false}
                            onChange={handleTextEditorChange("profile")}
                            onBlur={handleTextEditorBlur("profile")}
                            isBorderless
                        />
                    </FormControl>
                </TooltipWrapper>

                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.createOffer.location}
                    content={locales.createOffer.tooltip.location}
                >
                    <FormControl id="city" label={locales.createOffer.location} error={errors.postalCode} withRef>
                        <Dropdown
                            id="city"
                            name="city"
                            isLocation
                            options={options.cities}
                            value={
                                options.cities?.length > 0
                                    ? options.cities.find((city) => city.value === data.postalCode)
                                    : data.city?.length > 0 && data.postalCode?.length > 0
                                        ? {
                                              label: data.city,
                                              value: data.postalCode,
                                              lat: data.lat,
                                              lng: data.lng,
                                          }
                                        : undefined
                            }
                            onInputChange={handleLocationChange}
                            onChange={handleLocationsDropdownChange}
                            isError={errors.postalCode?.length > 0}
                        />
                    </FormControl>
                </TooltipWrapper>

                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.offer.studeLevel}
                    content={locales.offer.tooltip.studeLevel}
                >
                    {config.degrees?.length > 0 && (
                        <>
                            <Label isError={errors.educationLevel?.length > 0}>{locales.offer.studeLevel}</Label>

                            <div className="create-offer__tele-work">
                                {config.degrees.map(({ value, label }) => (
                                    <Chip
                                        key={value}
                                        onClick={handleEducationLevelClick(value)}
                                        isChecked={data.educationLevel.includes(value)}
                                    >
                                        {label}
                                    </Chip>
                                ))}
                            </div>

                            <div className="create-offer__form-field-error">{errors.educationLevel}</div>
                        </>
                    )}
                </TooltipWrapper>

                {data.educationLevel.includes(CAP) && (
                    <TooltipWrapper
                        className="create-offer__form-field"
                        title={locales.createOffer.subEducationLevel}
                        content={locales.offer.tooltip.studeLevel}
                    >
                        <FormControl
                            id="workFields"
                            label={locales.createOffer.subEducationLevel}
                            error={errors.subEducationLevel}
                            withRef
                        >
                            <Dropdown
                                id="workFields"
                                name="workFields"
                                options={options.subEducationLevel}
                                value={options.subEducationLevel.filter((level) =>
                                    data.subEducationLevel.includes(level.value)
                                )}
                                onChange={handleDropdownChange("subEducationLevel", true)}
                                isError={!!errors.subEducationLevel}
                            />
                        </FormControl>
                    </TooltipWrapper>
                )}

                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.createOffer.workField}
                    content={locales.createOffer.tooltip.workField}
                >
                    <FormControl
                        id="workFields"
                        label={locales.createOffer.workField}
                        error={errors.workFields}
                        withRef
                    >
                        <Dropdown
                            id="workFields"
                            name="workFields"
                            isMulti
                            options={options.workFields}
                            value={options.workFields.filter((workField) => data.workFields.includes(workField.value))}
                            onChange={handleDropdownChange("workFields")}
                            isError={errors.workFields?.length > 0}
                        />
                    </FormControl>
                </TooltipWrapper>

                <TooltipWrapper
                    className="create-offer__form-field"
                    title={locales.offer.remoteWork}
                    content={locales.offer.tooltip.remoteWork}
                >
                    <>
                        <Label isError={errors.workPlaces?.length > 0}>{locales.offer.remoteWork}</Label>

                        <div className="create-offer__tele-work">
                            {options.workPlaces.map(({ label, value }) => (
                                <Chip
                                    className="create-offer__tele-work_chip"
                                    key={value}
                                    onClick={handleWorkPlaceClick(value)}
                                    isChecked={data.workPlaces.includes(value)}
                                >
                                    {label}
                                </Chip>
                            ))}
                        </div>

                        <div className="create-offer__form-field-error">{errors.workPlaces}</div>
                    </>
                </TooltipWrapper>
            </FormLayout>

            <div className="create-offer__actions">
                {isUserSubscribed && (
                    <Button variant="link" onClick={handleSubmitDraft}>
                        {locales.createOffer.saveAsDraft}
                    </Button>
                )}

                <Button variant="primary" onClick={handleGeneralInformationSubmit}>
                    {locales.general.next}
                </Button>
            </div>
        </>
    );
};

export default memo(CreateOfferGeneralInformationUi);
