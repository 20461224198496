import { rem } from "@mantine/core";

import type { IconProps } from "assets/tsxIcons/types";


export const SearchUser = ( { size, style, ...others }: IconProps ) => (
    <svg
        width="27"
        height="28"
        viewBox="0 0 27 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={ { width: rem( size ), height: rem( size ), ...style } }
        { ...others }
    >
        <path
            d="M12.7536 24.9502C20.2404 24.9502 24.4504 20.7401 24.4504 13.2534C24.4504 5.76669 20.2404 1.55469 12.7536 1.55469C5.265 1.55469 1.05493 5.76669 1.05493 13.2534C1.05493 20.7401 5.26693 24.9502 12.7536 24.9502Z"
            fill="#CDEBE7"/>
        <path
            d="M6.19458 22.6324C6.5002 21.1264 7.31712 19.7723 8.50689 18.7998C9.69666 17.8272 11.1861 17.2959 12.7228 17.2959C14.2595 17.2959 15.7489 17.8272 16.9387 18.7998C18.1285 19.7723 18.9454 21.1264 19.251 22.6324V23.5099C17.4922 24.4587 15.3148 24.9505 12.7517 24.9505C10.1617 24.9505 7.96308 24.4471 6.19458 23.4771V22.6324Z"
            fill="white"/>
        <path
            d="M6.19458 22.6324C6.5002 21.1264 7.31712 19.7723 8.50689 18.7998C9.69666 17.8272 11.1861 17.2959 12.7228 17.2959C14.2595 17.2959 15.7489 17.8272 16.9387 18.7998C18.1285 19.7723 18.9454 21.1264 19.251 22.6324V23.5099C17.4922 24.4587 15.3148 24.9505 12.7517 24.9505C10.1617 24.9505 7.96308 24.4471 6.19458 23.4771V22.6324Z"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M12.7536 24.9502C20.2404 24.9502 24.4504 20.7401 24.4504 13.2534C24.4504 5.76669 20.2404 1.55469 12.7536 1.55469C5.265 1.55469 1.05493 5.76669 1.05493 13.2534C1.05493 20.7401 5.26693 24.9502 12.7536 24.9502Z"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M25.9451 26.4453L21.494 21.9941" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M12.7228 13.9994C15.2666 13.9994 16.6956 12.5684 16.6956 10.0246C16.6956 7.48083 15.2666 6.05176 12.7228 6.05176C10.1771 6.05176 8.74799 7.48083 8.74799 10.0246C8.74799 12.5703 10.1809 13.9994 12.7228 13.9994Z"
            fill="white"/>
        <path
            d="M12.7228 13.9994C15.2666 13.9994 16.6956 12.5684 16.6956 10.0246C16.6956 7.48083 15.2666 6.05176 12.7228 6.05176C10.1771 6.05176 8.74799 7.48083 8.74799 10.0246C8.74799 12.5703 10.1809 13.9994 12.7228 13.9994Z"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);