import { memo } from "react";
import { Routes, Route, Navigate } from 'react-router-dom';

import {
  GeneralInfo,
  PersonalInfo,
  CompanyInfo,
  Confirmation,
  Login,
  ResetPassword,
  ChangePassword,
  Guest,
  EntityType,
} from 'modules';

import { routes } from 'constants/index';

import { TUnauthorizedRoutes } from './Unauthorized.types';

const UnauthorizedRoutes: TUnauthorizedRoutes = () => {
  return (
    <Routes>
      <Route path={routes.authentication.register.main}>
        <Route
          path={routes.authentication.register.entityType}
          element={<EntityType />}
        />
        <Route
          path={routes.authentication.register.generalInfo}
          element={<GeneralInfo />}
        />
        <Route
          path={routes.authentication.register.personalInfo}
          element={<PersonalInfo />}
        />
        <Route
          path={routes.authentication.register.userInfo}
          element={<CompanyInfo />}
        />
        <Route
          path={routes.authentication.register.confirmation}
          element={<Confirmation />}
        />
        <Route
          path={routes.authentication.register.main}
          element={
            <Navigate
              replace
              to={`${routes.authentication.register.main}/${routes.authentication.register.entityType}`}
            />
          }
        />
      </Route>
      <Route path={routes.authentication.login} element={<Login />} />
      <Route
        path={routes.authentication.forgotPassword}
        element={<ResetPassword />}
      />
      <Route
        path={routes.authentication.changePassword}
        element={<ChangePassword />}
      />
      <Route path={routes.authentication.guest}>
        <Route path={routes.authentication.guestId} element={<Guest />} />
      </Route>

      <Route
        path="*"
        element={<Navigate replace to={routes.authentication.login} />}
      />
    </Routes>
  );
};

export default memo(UnauthorizedRoutes);
