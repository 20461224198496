import { useState } from 'react';
import Joi from 'joi';

import { TValidate } from './validation.types';

export const useValidate = () => {
  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const [isError, setIsError] = useState(false);
  const errorData: { [key: string]: string } = {};
  const validate: TValidate = ({ data, validationSchema }) => {
    const schema = Joi.object(validationSchema);

    if ('password' in validationSchema) {
      schema.with('password', 'passwordConfirmation');
    }

    const { error } = schema.validate(data, { abortEarly: false });

    if (error) {
      for (let item of error.details) {
        const name = item.path[0];
        const message = item.message;
        errorData[name] = message;
      }

      setErrors((prevErrors) => ({ ...prevErrors, ...errorData }));
      setIsError(true);

      return false;
    }
    return true;
  };

  return { errors, setErrors, isError, setIsError, validate };
};
