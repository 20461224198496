import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useToast, useExtractServerError } from 'hooks';
import { enums } from 'constants/index';
import { fetchOffersApi } from '../api';
import { setOffersAction, setFilterPagination } from '../store/actions';
import { setIsLoading } from 'modules/App/store/actions';

import OffersListingUi from './OffersListing.ui';

import { TOffersListing, IStoreState } from './OffersListing.types';

const OffersListing: TOffersListing = () => {
  //* Hook Init
  const dispatch = useDispatch();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();

  //* Redux State
  const moduleName = enums.moduleNames.offers;
  const filter = useSelector(({ filter: { [moduleName]: filter } }: IStoreState) => filter, shallowEqual);
  const offers = useSelector(({ offers: { list } }: IStoreState) => list, shallowEqual);

  //* Handlers
  const handleLinkClick = useCallback(() => {
    dispatch(
      setFilterPagination({
        moduleName: moduleName,
        payload: { ...filter.pagination, page: 1 },
      })
    );
  }, [dispatch, filter]);

  //* Side Effects
  const fetchOffers = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      const { limit, page } = filter.pagination;

      const {
        data: { data: offers, total },
      } = await fetchOffersApi({ limit, page  });

      let totalPages: number = Math.ceil(total / limit);

      dispatch(setOffersAction( offers ));

      dispatch(
        setFilterPagination({
          moduleName: moduleName,
          payload: { limit, page, totalPages },
        })
      );
      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, filter]);

  //* Effects
  useEffect(() => {
    fetchOffers();
  }, [filter.pagination.page]);

  //* Wrappers
  const data = useMemo(() => ({
    offers,
  }), [
    offers,
  ]);

  const handlers = useMemo(() => ({
    handleLinkClick,
  }), [
    handleLinkClick,
  ]);

  return (
    <OffersListingUi data={data} handlers={handlers} />
  );
};

export default OffersListing;
