import classNames from 'classnames';

import { TTabPanel } from './TabPanel.types';

import './tab-panel.scss';

export const TabPanel: TTabPanel = ({ children, className = '' }) => {
  const classes = classNames({
    'tab-panel': true,
    [className]: className.length > 0,
  });

  return <div className={classes}>{children}</div>;
};
