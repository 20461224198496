import { memo, useMemo } from "react";
import dayjs from "dayjs";
import classNames from "classnames";

import { Button, JobboardLogo } from "components";
import { locales } from "constants/index";

import { TCandidateCard } from "./CandidateCard.types";

import "./candidate-card.scss";

const CandidateCard: TCandidateCard = ({
    className = "",
    isSelected = false,
    data: {
        item: { _id, createdDate, candidateInfo, score, utm_source },
    },
    handlers: { handleCandidateClick, handleCopySocialMedia, handleViewCv },
}) => {
    //* Memos
    const formattedDate = useMemo(() => dayjs(createdDate).format("DD.MM.YY"), [createdDate]);

    const classes = classNames({
        "candidate-card flex flex-column": true,
        "candidate-card__selected": isSelected,
        [className]: !!className,
    });

    return (
        <div className={classes} onClick={handleCandidateClick(_id)}>
            <div className="candidate-card__body flex flex-column" role="presentation">
                <div className="candidate-card__header">
                    <div className="candidate-card__profile-picture">
                        {/* TODO: ASK JULIANO FOR THE IMAGE */}
                        {/*{candidateInfo?.info?.photo_url?.length > 0*/}
                        {/*    ? <img*/}
                        {/*        src={candidateInfo.info?.photo_url}*/}
                        {/*        alt="profile"*/}
                        {/*        className="candidate-card__image"*/}
                        {/*    />*/}
                        {/*    : */}
                        <div className="candidate-card__user-initials">
                            {candidateInfo?.name.firstname[0]}
                            {candidateInfo?.name.lastname[0]}
                        </div>
                        {/*}*/}
                    </div>

                    <div className="candidate-card__name__container text-ellipsis">
                        <div className="candidate-card__name text-ellipsis">
                            {candidateInfo?.name && candidateInfo?.name.firstname.toLowerCase()}
                        </div>

                        <div className="candidate-card__name text-ellipsis">
                            {candidateInfo?.name && candidateInfo?.name.lastname.toLowerCase()}
                        </div>
                    </div>

                    <div className="candidate-card__date_and_rating text-ellipsis">
                        <div className="candidate-card__date_and_rating__item text-ellipsis">{formattedDate}</div>

                        {score !== undefined && (
                            <div className="candidate-card__date_and_rating__item text-ellipsis">
                                <div className="candidate-card__icon-container__small">
                                    <div className="candidate-card__icon candidate-card__icon--star" />
                                </div>
                                {score}/100
                            </div>
                        )}
                    </div>
                </div>

                <div className="candidate-card__contacts">
                    {candidateInfo?.contact_info?.email_address && (
                        <div className="candidate-card__contact-info flex flex-wrap flex-align-items-center">
                            <div className="candidate-card__contact-value">
                                {candidateInfo?.contact_info.email_address.toLowerCase()}
                            </div>
                        </div>
                    )}

                    {candidateInfo?.contact_info?.phone_number && (
                        <div className="candidate-card__contact-info flex flex-align-items-center">
                            <div className="candidate-card__contact-value">
                                {candidateInfo?.contact_info.phone_number.toString()}
                            </div>
                        </div>
                    )}
                </div>

                <div className="candidate-card__social-media">
                    {/* TODO: ASK JULIANO FOR THE MEDIA URLS */}
                    {/*{candidateInfo?.info?.media_urls*/}
                    {/*&& (!!candidateInfo?.info?.media_urls?.behance*/}
                    {/*&& candidateInfo.info.media_urls.behance.includes("https")*/}
                    {/*    ? <a*/}
                    {/*        href={candidateInfo.info.media_urls.behance}*/}
                    {/*        target="_blank"*/}
                    {/*        rel="noreferrer"*/}
                    {/*        className="candidate-card__icon-container"*/}
                    {/*        onClick={(event: any) => event.stopPropagation()}*/}
                    {/*    >*/}
                    {/*      <div className="candidate-card__icon candidate-card__icon--behance"/>*/}
                    {/*    </a>*/}
                    {/*    : <div className="candidate-card__icon-container">*/}
                    {/*        <div*/}
                    {/*            className="candidate-card__icon candidate-card__icon--behance"*/}
                    {/*            onClick={handleCopySocialMedia(candidateInfo.info.media_urls.behance)}*/}
                    {/*        />*/}
                    {/*    </div>)*/}
                    {/*}*/}

                    {/*{candidateInfo?.info?.media_urls*/}
                    {/*&& (!!candidateInfo?.info?.media_urls?.github*/}
                    {/*&& candidateInfo.info.media_urls.github.includes("https")*/}
                    {/*    ? <a*/}
                    {/*        href={candidateInfo.info.media_urls.github}*/}
                    {/*        target="_blank"*/}
                    {/*        rel="noreferrer"*/}
                    {/*        className="candidate-card__icon-container"*/}
                    {/*        onClick={(event: any) => event.stopPropagation()}*/}
                    {/*    >*/}
                    {/*      <div className="candidate-card__icon candidate-card__icon--github"/>*/}
                    {/*    </a>*/}
                    {/*    : <div className="candidate-card__icon-container">*/}
                    {/*        <div*/}
                    {/*            className="candidate-card__icon candidate-card__icon--github"*/}
                    {/*            onClick={handleCopySocialMedia(candidateInfo.info.media_urls.github)}*/}
                    {/*        />*/}
                    {/*    </div>)*/}
                    {/*}*/}

                    {/*{candidateInfo?.info?.media_urls*/}
                    {/*&& (!!candidateInfo?.info?.media_urls?.linkedIn*/}
                    {/*&& candidateInfo.info.media_urls.linkedIn.includes("https")*/}
                    {/*    ? <a*/}
                    {/*        href={candidateInfo.info.media_urls.linkedIn}*/}
                    {/*        target="_blank"*/}
                    {/*        rel="noreferrer"*/}
                    {/*        className="candidate-card__icon-container"*/}
                    {/*        onClick={(event: any) => event.stopPropagation()}*/}
                    {/*    >*/}
                    {/*      <div className="candidate-card__icon candidate-card__icon--linkedin"/>*/}
                    {/*    </a>*/}
                    {/*    : <div className="candidate-card__icon-container">*/}
                    {/*        <div*/}
                    {/*            className="candidate-card__icon candidate-card__icon--linkedin"*/}
                    {/*            onClick={handleCopySocialMedia(candidateInfo.info.media_urls.linkedIn)}*/}
                    {/*        />*/}
                    {/*    </div>)*/}
                    {/*}*/}
                </div>
                <div className="candidate-card__footer">
                    <Button
                        className="flex flex-justify-content-center flex-align-items-center"
                        variant="secondary"
                        size="extrasmall"
                        onClick={handleViewCv(candidateInfo.cv_info.url)}
                        isDisabled={!candidateInfo?.cv_info?.url}
                    >
                        <span className="candidate-card__button-icon candidate-card__button-icon--eye-white" />
                        {locales.student.viewCv}
                    </Button>
                    {/*<div className='candidate-card__school'>*/}
                    {/*  <div className='candidate-card__school__name'>SKALE</div>*/}
                    {/*</div>*/}
                    <JobboardLogo utmLabel={utm_source} />
                </div>
            </div>
        </div>
    );
};

export default memo(CandidateCard);
