import { memo } from "react";

import AuthorizedRoutes from './Authorized';
import UnAuthorizedRoutes from './Unauthorized';

import { Render } from './Router.types';

const Router: Render = ({ isAuthenticated }) => {
  return isAuthenticated ? <AuthorizedRoutes /> : <UnAuthorizedRoutes />;
};

export default memo(Router);
