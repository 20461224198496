import ClassNames from "classnames";
import { memo } from "react";

import { Banner, Button, Card, FormControl, Section, TextInput } from "components";

import { locales } from "constants/index";

import type { TYPareoUI } from './YPareo.types';
import { YPareoActionEnums, YPareoEnums } from "./YPareo.enums";

import './ypareo.scss';

const YPareoUI: TYPareoUI = ({
    data,
    handlers,
    errors
}) => {
    return <>
        <Section title={locales.settings.myAccount.yPareo.yPareo}>
            <Card
                title={locales.settings.myAccount.yPareo.yPareo}
                subTitle={locales.settings.myAccount.yPareo.yPareoSub}
                showAction={data.showSubmit}
                actionHandler={handlers.handleSaveYPareo(YPareoActionEnums.CREATE)}
                showHeaderAction={!data.canEdit}
                headerAction={<Button
                    variant='danger'
                    children={locales.general.delete}
                    onClick={handlers.handleSaveYPareo(YPareoActionEnums.DELETE)}
                />}
            >
                {!data.canEdit && <>
                    <Banner className='ypareo__banner'>
                        {data[YPareoEnums.domain]}
                    </Banner>
                    <Banner className='ypareo__banner'>
                        <span className="ypareo__banner__text">{data[YPareoEnums.token] ?? '******'}</span>
                        <span
                            className={ClassNames({
                                ypareo__banner__icon: true,
                                eye: !data[YPareoEnums.token],
                                clipboard: !!data[YPareoEnums.token],
                            })}
                            onClick={!!data[YPareoEnums.token]
                                ? handlers.handleClipboardClick
                                : handlers.handleFetchTokenClick
                            }
                        />
                    </Banner>
                    <Banner className='ypareo__banner'>
                        {data[YPareoEnums.description]}
                    </Banner>
                    <Banner className='ypareo__banner'>
                        {data[YPareoEnums.idSite]}
                    </Banner>
                    <Banner className='ypareo__banner'>
                        {data[YPareoEnums.idFormationSouhait1]}
                    </Banner>
                </>}

                {data.canEdit && <>
                    <FormControl
                        id={YPareoEnums.domain}
                        label={locales.settings.myAccount.yPareo.domain}
                        error={errors[YPareoEnums.domain]}
                        withRef
                        isDisabled={!data.canEdit}
                    >
                        <TextInput
                            isDisabled={!data.canEdit}
                            id={YPareoEnums.domain}
                            name={YPareoEnums.domain}
                            value={data[YPareoEnums.domain]}
                            onChange={handlers.handleTextInputChange}
                            onBlur={handlers.handleTextInputBlur}
                            isError={!!errors[YPareoEnums.domain]}
                        />
                    </FormControl>

                    <FormControl
                        id={YPareoEnums.token}
                        label={locales.settings.myAccount.yPareo.token}
                        error={errors[YPareoEnums.token]}
                        withRef
                        isDisabled={!data.canEdit}
                    >
                        <TextInput
                            isDisabled={!data.canEdit}
                            id={YPareoEnums.token}
                            name={YPareoEnums.token}
                            value={data[YPareoEnums.token]}
                            onChange={handlers.handleTextInputChange}
                            onBlur={handlers.handleTextInputBlur}
                            isError={!!errors[YPareoEnums.token]}
                        />
                    </FormControl>

                    <FormControl
                        id={YPareoEnums.description}
                        label={locales.settings.myAccount.yPareo.description}
                        error={errors[YPareoEnums.description]}
                        withRef
                        isDisabled={!data.canEdit}
                    >
                        <TextInput
                            isDisabled={!data.canEdit}
                            id={YPareoEnums.description}
                            name={YPareoEnums.description}
                            value={data[YPareoEnums.description]}
                            onChange={handlers.handleTextInputChange}
                            onBlur={handlers.handleTextInputBlur}
                            isError={!!errors[YPareoEnums.description]}
                        />
                    </FormControl>

                    <FormControl
                        id={YPareoEnums.idSite}
                        label={locales.settings.myAccount.yPareo.idSite}
                        error={errors[YPareoEnums.idSite]}
                        withRef
                        isDisabled={!data.canEdit}
                    >
                        <TextInput
                            isDisabled={!data.canEdit}
                            id={YPareoEnums.idSite}
                            name={YPareoEnums.idSite}
                            value={data[YPareoEnums.idSite]}
                            onChange={handlers.handleTextInputChange}
                            onBlur={handlers.handleTextInputBlur}
                            isError={!!errors[YPareoEnums.idSite]}
                        />
                    </FormControl>

                    <FormControl
                        id={YPareoEnums.idFormationSouhait1}
                        label={locales.settings.myAccount.yPareo.idFormationSouhait1}
                        error={errors[YPareoEnums.idFormationSouhait1]}
                        withRef
                        isDisabled={!data.canEdit}
                    >
                        <TextInput
                            isDisabled={!data.canEdit}
                            id={YPareoEnums.idFormationSouhait1}
                            name={YPareoEnums.idFormationSouhait1}
                            value={data[YPareoEnums.idFormationSouhait1]}
                            onChange={handlers.handleTextInputChange}
                            onBlur={handlers.handleTextInputBlur}
                            isError={!!errors[YPareoEnums.idFormationSouhait1]}
                        />
                    </FormControl>
                </>}
            </Card>
        </Section>
    </>;
};

export default memo(YPareoUI);
