import { FC, useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import classNames from "classnames";

import { fetchCitiesApi, fetchCurrentLocationApi } from "./api";
import { style } from "components/Forms/Dropdown/style";
import { setFilterFilter } from "../../components/Filter/store/actions";

import { IOfferFilters } from "modules/Listing/Listing.types";
import { IConfigEntity, TLocationEntity } from "common";

import { Icons } from "../../assets";
import { locales } from "constants/index";

interface ILocationSelect {
    defaultOptions: IConfigEntity[] | undefined;
    placeholder: string;
    selectedValue?: any;
    handleChange: (value: TLocationEntity) => void;
    setFilters: (value: TLocationEntity | any) => void;
    filters: IOfferFilters | any;
    isItemSearch: boolean;
}

const LocationSelect: FC<ILocationSelect> = ({
    defaultOptions,
    isItemSearch,
    placeholder,
    selectedValue,
    handleChange,
    setFilters,
    filters,
}) => {
    const [currentUserPos, setCurrentUserPos] = useState<any>(undefined);
    const loadOptions = async (inputValue: string, callback: (options: any[]) => void) => {
        const { data } = await fetchCitiesApi(inputValue);
        callback(
            data.map((i) => ({
                label: i.nom,
                value: i.code,
                key: i.code,
                lat: i.centre.coordinates[1]?.toString() ?? "",
                lng: i.centre.coordinates[0]?.toString() ?? "",
            }))
        );
    };

    const fetchPostalData = async () => {
        if (!currentUserPos) {
            return;
        }
        const lat = currentUserPos.latitude;
        const lng = currentUserPos.longitude;
        const res = await fetchCurrentLocationApi(lat, lng);
        if (res.status === 200) {
            if (isItemSearch) {
                setFilterFilter({
                    moduleName: "offers",
                    payload: {
                        workPlaces: {
                            label: res.data.data.city,
                            key: res.data.data.postal_code,
                            value: res.data.data.postal_code,
                            lat,
                            lng,
                        },
                    },
                });
            } else {
                setFilters({
                    ...filters,
                    workPlaces: {
                        label: res.data.data.city,
                        key: res.data.data.postal_code,
                        value: res.data.data.postal_code,
                        lat,
                        lng,
                    },
                });
            }
        }
    };
    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setCurrentUserPos(position.coords);
        });
    };

    useEffect(() => {
        getCurrentLocation();
    }, []);

    const DropdownIndicator = (props: any) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <div className="tooltip" onClick={fetchPostalData}>
                        <img alt="#" src={Icons.Localisation} />
                        {!props.isFocused && <p className="tooltiptext">Autour de moi</p>}
                    </div>
                </components.DropdownIndicator>
            )
        );
    };

    const NoOptionsMessage = (props: any) => {
        return <components.NoOptionsMessage {...props}>{locales.general.noOptions}</components.NoOptionsMessage>;
    };

    return (
        <AsyncSelect
            styles={style({ isError: false, isRounded: false })}
            placeholder={placeholder}
            onChange={handleChange}
            className={classNames("location-select", "form_inputGroup-drop")}
            classNamePrefix="std-dropdown"
            cacheOptions
            isClearable
            // @ts-ignore
            loadOptions={loadOptions}
            value={selectedValue || ""}
            components={{ NoOptionsMessage, ...(currentUserPos ? { DropdownIndicator } : {}) }}
            // defaultOptions={defaultOptions}
        />
    );
};

export default LocationSelect;
