import classNames from 'classnames';

import { TTextArea } from './TextArea.types';

import './text-area.scss';

const TextArea: TTextArea = ({
  id = '',
  name,
  value = '',
  placeholder = '',
  className = '',
  isDisabled = false,
  onChange,
  onBlur,
  onFocus,
  rows = 5,
  textAreaRef,
}) => {
  const inputClassNames = classNames({
    'text-area': true,
    'text-area--has-value': value?.length > 0,
    [className]: className.length > 0,
  });

  return (
    <textarea
      ref={textAreaRef}
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      className={inputClassNames}
      disabled={isDisabled}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      rows={rows}
    />
  );
};

export default TextArea;
