import { MouseEventHandler } from "react";
import classNames from "classnames";

import { TTooltipWrapper, TooltipPositionEnum } from './TooltipWrapper.types';

import './tooltip-wrapper.scss';

export const TooltipWrapper: TTooltipWrapper = ({
  children,
  className = '',
  title = '',
  content = '',
  maxWidthPX = 340,
  position = TooltipPositionEnum.right,
  show = true,
}) => {
  const onMouseOver: MouseEventHandler<HTMLDivElement> = async (event) => {
    event.preventDefault();
    if (!event.currentTarget) return;
    const currentBounds = event.currentTarget.offsetLeft + event.currentTarget.offsetWidth + maxWidthPX + 10 > window.innerWidth;
    if (currentBounds) return event.currentTarget.setAttribute('data-tooltip-place', TooltipPositionEnum.bottom);
    event.currentTarget.setAttribute('data-tooltip-place', position);
  }

  const classes = classNames({
    'tooltip-wrapper': true,
    [className]: !!className,
  });

  const tooltipContent = JSON.stringify({title, content});
  return (
    <div
      id='tooltip-wrapper'
      onMouseOver={onMouseOver}
      className={classes}
      data-tooltip-id="tooltip"
      data-tooltip-content={show ? tooltipContent : undefined}
      data-tooltip-place={position}
      data-tooltip-max-width={maxWidthPX}
    >
      {children}
    </div>
  );
};
