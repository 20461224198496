import { memo } from "react";
import { components } from 'react-select';
import { Checkbox } from "components";

const Option = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    ...rest
}: any) => {

    // styles
    let bg = 'transparent';
    if (isFocused) bg = '#eee';

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
        >
            <Checkbox
                isChecked={isSelected}
                onChange={() => {}}
            />
            {children}
        </components.Option>
    );
};

export default memo(Option);