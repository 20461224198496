import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import 'dayjs/locale/fr';
import { memo } from "react";
import { Box, Text, Timeline, TimelineItem } from "@mantine/core";

import type { THistoryUIProps } from "./types";


dayjs.extend( relativeTime );
const HistoryUI = ( { data }: THistoryUIProps ) => {

    return (
        <Box py='md'>
            <Timeline bulletSize={ 24 } lineWidth={ 2 }>
                {
                    data.history.map( ( item, index ) =>
                        <TimelineItem title={ item.title } key={ index }>
                            <Text c="dimmed" size="sm">{ item.description }</Text>
                            <Text size="xs" mt={ 4 }>{ dayjs( item.createdDate ).locale( 'fr' ).fromNow() }</Text>
                        </TimelineItem>
                    )
                }
            </Timeline>
        </Box>
    );
};

export default memo( HistoryUI );
