import { Modal } from 'components';

import { TUploadPercentageModal } from './UploadPercentageModal.types';

import './upload-percentage-modal.scss'

export const UploadPercentageModal: TUploadPercentageModal = ({
  title,
  attachToBody,
  data: {
    uploadPercentage
  },
  onClose,
}) => {
  return (
    <Modal title={title} attachToBody={attachToBody} className="upload-percentage-modal" onClose={onClose}>
      <div className="perc">
        <div className="perc perc__track">
          <div className="perc perc__thumb" style={{ width: `${uploadPercentage}%` }}>{uploadPercentage}</div>
        </div>
      </div>
    </Modal>
  );
};
