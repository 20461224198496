import { enums } from 'constants/index';

import {
  INIT_APP,
  SET_CONFIG,
  SET_LOCALE,
  SET_USER_INFO,
  SET_DEGREES,
  SET_CLASSES,
  SET_TAGS,
  SET_IS_LOADING, appActionTypes,
} from './action-types';

import { TAppAction, IAppGlobalState } from './store.types';

const initialState: IAppGlobalState = {
  accessToken: '',
  refreshToken: '',
  authStatus: enums.app.status.none,
  locale: enums.locales.fr,
  userInfo: null,
  config: {
    checkout_url: '',
    contracts: [],
    degrees: [],
    domain: '',
    domaines: [],
    flags: [],
    languages: [],
    months: [],
    paces: [],
    pipes: [],
    ranges: [],
    skills: [],
    workPlaces: [],
    statut_appel: [],
    createdDate: '',
    emailExpiry: 0,
    fileSize: 0,
    maxFiles: 0,
    otpAttempts: 0,
    otpExpiry: 0,
    filesType: [],
  },
  loading: {
    normal: false,
    thumb: false,
  },
  degrees: [],
  classes: [],
  tags: [],
};

const reducer = (state: IAppGlobalState = initialState, action: TAppAction) => {
  switch (action.type) {
    case INIT_APP:
      return {
        ...state,
        authStatus: enums.app.status.loaded,
        locale: action.locale,
      };

    case SET_CONFIG:
      return {
        ...state,
        config: action.config,
      };

    case SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      };

    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };

    case SET_DEGREES:
      return {
        ...state,
        degrees: action.degrees,
      };

    case SET_CLASSES:
      return {
        ...state,
        classes: action.classes,
      };

    case SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };

    case SET_IS_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.type]: action.payload.isLoading
        },
      };

    case appActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
