import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual, batch } from 'react-redux';

import { useToast, useExtractServerError } from 'hooks';
import { enums } from 'constants/index';

import { fetchOffersApi, removeOfferApi } from '../api';

import { setIsLoading } from "modules/App/store/actions";
import { setOffersAction, setFilterPagination, setDraft, setDraftSelected } from '../store/actions';

import DraftsUi from './Drafts.ui';

import {
  TDrafts,
  IStoreState,
  THandleSelectionChange,
} from './Drafts.types';

const Drafts: TDrafts = () => {
  //* Hook Init
  const dispatch = useDispatch();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();

  //* Redux State
  const moduleName = enums.moduleNames.drafts;
  const filter = useSelector(({ filter: { [moduleName]: filter } }: IStoreState) => filter, shallowEqual);
  const draft = useSelector(({ offers: { draft } }: IStoreState) => draft, shallowEqual);

  //* Local State
  const [isOfferConfirmationModalVisible, setIsOfferConfirmationModalVisible] =
    useState(false);
  const [numberOfDeletedOffers, setNumberOfDeletedOffers] = useState(0);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);

  //* Handlers
  const handleSelectionChange: THandleSelectionChange = useCallback((selectedRows) => {
    dispatch(setDraftSelected(Object.keys(selectedRows)));
  }, [dispatch]);

  const handleErrorModalClose = () => {
    setIsErrorModalVisible(false);
  };

  const handleLinkBackClick = useCallback(() => {
    dispatch(
      setFilterPagination({
        moduleName: moduleName,
        payload: { ...filter.pagination, page: 1 },
      })
    );
  }, [dispatch, filter]);

  const handleDeleteOfferConfirmationModalClose = () => {
    setIsOfferConfirmationModalVisible(false);
  };

  const handleDeleteOffers = () => {
    if (!draft.selected.length) return setIsErrorModalVisible(true);

    deleteOffer();
  };

  //* Side Effects
  const fetchOffers = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      const { limit, page } = filter.pagination;

      const {
        data: { data: draftOffers, total: draftTotal },
      } = await fetchOffersApi({ limit, page, draft: true, deleted: false });

      let totalPages = Math.ceil( draftTotal / limit );

      batch(() => {
        dispatch(setOffersAction( draftOffers ));
        dispatch(setDraft( draftOffers ));
        dispatch(
          setFilterPagination({
            moduleName: moduleName,
            payload: { ...filter.pagination, totalPages },
          })
        );
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, filter]);

  const deleteOffer = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));
      setNumberOfDeletedOffers(draft.selected.length);

      await removeOfferApi(draft.selected);
      await fetchOffers();

      batch(() => {
        dispatch(setDraftSelected([]));
        dispatch(setIsLoading(false));
      });
      setIsOfferConfirmationModalVisible(true);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, draft.selected]);

  //* Effects
  useEffect(() => {
    fetchOffers();
  }, [filter.pagination.page]);

  //* Wrappers
  const data = useMemo(() => ({
    offers: draft.list,
    selected: draft.selected,
  }), [
    draft,
  ]);

  const handlers = useMemo(() => ({
    handleSelectionChange,
    handleDeleteOffers,
    handleErrorModalClose,
    handleLinkBackClick,
    handleDeleteOfferConfirmationModalClose,
  }), [
    handleSelectionChange,
    handleDeleteOffers,
    handleErrorModalClose,
    handleLinkBackClick,
    handleDeleteOfferConfirmationModalClose,
  ]);

  return (
    <DraftsUi
      isOfferConfirmationModalVisible={isOfferConfirmationModalVisible}
      numberOfDeletedOffers={numberOfDeletedOffers}
      data={data}
      handlers={handlers}
      isErrorModalVisible={isErrorModalVisible}
    />
  );
};

export default Drafts;
