import { IDropdownObject } from "modules/Settings/settings.types";

const roles: IDropdownObject[] = [
    {
        value: '1',
        label: 'Administrateur',
    },
    {
        value: '2',
        label: 'Chargé de relation entreprise',
    }
];

export default roles;