import {
  Filter,
  Button,
  BackLink,
  ErrorModal,
  Table2,
} from 'components';

import { locales, routes, enums } from 'constants/index';

import { DeleteOfferModal } from '../components';

import { columns } from './tableColumns';

import { TDraftsUi } from './Drafts.types';

import './drafts.scss';

const DraftsUi: TDraftsUi = ({
  isOfferConfirmationModalVisible,
  isErrorModalVisible,
  numberOfDeletedOffers,
  data: { offers, selected },
  handlers: {
    handleSelectionChange,
    handleDeleteOffers,
    handleErrorModalClose,
    handleLinkBackClick,
    handleDeleteOfferConfirmationModalClose,
  },
}) => {
  return (
    <div className="offers-drafts">
      <div className="offers-drafts__header">
        <div className="offers-drafts__links">
          <BackLink
            path={`${routes.offers.main}/${routes.offers.listing}`}
            onClick={handleLinkBackClick}
          />
        </div>
      </div>

      <div className='offers-drafts__filterContainer'>
        <Filter moduleName={enums.moduleNames.drafts}>
          <Table2
            data={offers}
            columns={columns}
            rowId='_id'
            enableRowSelection={true}
            enableMultiRowSelection={true}
            handleSelectionChange={handleSelectionChange}
          />
        </Filter>
      </div>

      <div className="offers-drafts__delete-offers">
        <Button
          variant="danger"
          onClick={handleDeleteOffers}
          isDisabled={!selected.length}
        >
          {locales.offers.deleteDrafts}
        </Button>
      </div>

      {isErrorModalVisible && (
        <ErrorModal
          title={locales.general.error}
          applyLabel={locales.general.ok}
          description={locales.offers.errors.atLeastOneOffer}
          onClose={handleErrorModalClose}
        />
      )}

      {isOfferConfirmationModalVisible && (
        <DeleteOfferModal
          numberOfDeletedOffers={numberOfDeletedOffers}
          onClose={handleDeleteOfferConfirmationModalClose}
        />
      )}
    </div>
  );
};

export default DraftsUi;
