import { useState } from 'react';
import Joi from 'joi';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useToast, useExtractServerError, useValidate } from 'hooks';
import { locales, routes } from 'constants/index';
import { setAccessToken, setRefreshToken } from 'utils';

import { validatePasswordVerificationApi } from '../api';
import { authenticateUserAction } from '../store/actions';

import ChangePasswordUi from './ChangePassword.ui';

import { IHandleChange } from './ChangePassword.types';

const ChangePassword = () => {
  // * Hooks Init
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();
  const { errors, setErrors, validate } = useValidate();
  const [searchParams] = useSearchParams();

  const validationSchema = {
    password: Joi.string()
      .required()
      .messages({
        'string.empty': locales.errors.required,
        'string.min': locales
          .formatString(locales.errors.minLength, {
            fieldName: locales.general.password,
          })
          .toString(),
        'string.pattern.name': locales.errors.passwordPolicy,
      }),
    passwordConfirmation: Joi.string()
      .required()
      .valid(Joi.ref('password'))
      .messages({
        'string.empty': locales.errors.required,
        'any.only': locales.errors.passwordMatch,
      }),
  };

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmationVisible, setSsPasswordConfirmationVisible] =
    useState(false);
  const [form, seForm] = useState({
    password: '',
    passwordConfirmation: '',
  });

  //* Handlers
  const handleInputChange: IHandleChange = ({ target: { name, value } }) => {
    seForm((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleInputBlur: IHandleChange = ({ target: { name, value } }) => {
    let data = {};
    let schema = {};

    if (value.length > 0) {
      if (name === 'passwordConfirmation') {
        data = {
          ...data,
          password: form.password,
        };
        schema = {
          password: validationSchema.password,
        };
      }
      validate({
        data: {
          ...data,
          [name]: value,
        },
        validationSchema: {
          ...schema,
          // @ts-ignore
          [name]: validationSchema[name],
        },
      });
    }
  };

  const handlePasswordVisibilityChange = (event: any) => {
    event.stopPropagation();
    setIsPasswordVisible((prevValue) => !prevValue);
  };

  const handlePasswordConfirmationVisibilityChange = (event: any) => {
    event.stopPropagation();
    setSsPasswordConfirmationVisible((prevValue) => !prevValue);
  };

  const handleSubmit = () => {
    const isFormValid = validate({
      data: { ...form },
      validationSchema,
    });

    if (!isFormValid) return;

    sendPasswordVerification();
  };

  //* API Actions
  const sendPasswordVerification = async () => {
    try {
      const id = searchParams.get('reset-password-id');

      if (!id) return;

      setIsLoading(true);

      const {
        data: { data },
      } = await validatePasswordVerificationApi({
        id,
        password: form.password,
      });

      if (!!data?.token && !!data?.refresh_token) {
        setAccessToken({ accessToken: data?.token });
        setRefreshToken({ refreshToken: data?.refresh_token });
        dispatch(
          authenticateUserAction({
            accessToken: data?.token,
            refreshToken: data?.refresh_token,
          })
        );
      }

      navigate(routes.offers.main);

      setIsLoading(false);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      setIsLoading(false);
    }
  };

  //* Wrappers
  const data = { form };

  const handlers = {
    handleInputChange,
    handleInputBlur,
    handlePasswordVisibilityChange,
    handlePasswordConfirmationVisibilityChange,
    handleSubmit,
  };

  return (
    <ChangePasswordUi
      isLoading={isLoading}
      isPasswordVisible={isPasswordVisible}
      isPasswordConfirmationVisible={isPasswordConfirmationVisible}
      data={data}
      errors={errors}
      handlers={handlers}
    />
  );
};

export default ChangePassword;
