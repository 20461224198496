import { memo, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { useToast, useExtractServerError } from 'hooks';

import { setIsLoading } from "modules/App/store/actions";
import { resetRegister } from 'modules/Authentication/store/actions';

import { sendEmailVerificationApi } from "../../api";

import ConfirmationUi from './Confirmation.ui';

import { routes } from 'constants/index';

import { TConfirmation, IStoreState } from './Confirmation.types';

const Confirmation: TConfirmation = () => {
  // * Hooks Init
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { extractErrorMessage } = useExtractServerError();
  const toast = useToast();

  //* Redux State
  const emailAddress = useSelector(({ auth: { register: { emailAddress } } }: IStoreState) => emailAddress);

  //* Handlers
  const handleConfirm = useCallback(() => {
    navigate(routes.authentication.login);
  }, []);

  const handleResend = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      await sendEmailVerificationApi({ emailAddress });

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [emailAddress]);

  useEffect(() => {
    return () => {
      dispatch(resetRegister());
    };
  }, []);

  //* Wrappers
  const data = useMemo(() => ({
    emailAddress,
  }), [
    emailAddress,
  ]);

  const handlers = useMemo(() => ({
    handleConfirm,
    handleResend
  }), [
    handleConfirm,
    handleResend,
  ]);

  return <ConfirmationUi data={data} handlers={handlers} />;
};

export default memo(Confirmation);
