"use client";

import { Title } from "@mantine/core";

export default Title.extend({
	defaultProps: {
		p: 0,
		m: 0,
		textWrap: 'balance',
	},
});