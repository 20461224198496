import { memo } from "react";
import ClassNames from "classnames";

import { Button } from "../Button";

import { locales } from "constants/index";

import { TCard } from './Card.types';

import './card.scss';

const Card: TCard = ({
    title = '',
    subTitle = '',
    cardClassName = '',
    className = '',
    showAction = false,
    showHeaderAction = false,
    actionLabel = locales.general.save,
    actionHandler,
    children,
    headerAction,
    isDisabled = false,
    isColumns = true,
    isRounded = true,
    hasShadow = true,
    hasPadding = true,
}) => {

    const classes = ClassNames({
        card: true,
        disabled: isDisabled,
        notRounded: !isRounded,
        notShadowed: !hasShadow,
        notPadded: !hasPadding,
        [cardClassName]: !!cardClassName,
    });

    const bodyClasses = ClassNames({
        card__body: true,
        columns: isColumns,
        [className]: !!className,
    });

    return (
        <div className={classes}>
            <div className='card__header'>
                {(title || subTitle) && <div className='card__headerContent'>
                    {title && <div className='card__title'>
                        {title}
                    </div>}

                    {subTitle && <div className='card__subTitle'>
                        {subTitle}
                    </div>}
                </div>}
                {showHeaderAction && !!headerAction && <div className='card__headerAction'>
                    {headerAction}
                </div>}
            </div>

            <div className={bodyClasses}>
                {children}
            </div>

            {showAction && actionHandler && <div className='card__action'>
                <Button
                    variant='secondary'
                    size='small'
                    onClick={actionHandler}
                    children={actionLabel}
                />
            </div>}

            {isDisabled && <div className='card__mask'/>}
        </div>
    );
};

export default memo(Card);
