import { baseApi } from 'utils';

import {
  TCreateApprenantApi,
  TFetchApprenantApi,
} from './api.types';

export const createApprenantApi: TCreateApprenantApi = (payload) => {
  return baseApi().post('/school/ypareo/apprenant', payload);
}

export const fetchApprenantApi: TFetchApprenantApi = (params) => {
  return baseApi().get('/school/ypareo/apprenant', { params });
}
