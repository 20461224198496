import ReactLoadingSkeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import { TSkeletonLoader } from './SkeletonLoader.types';

import 'react-loading-skeleton/dist/skeleton.css';
import './skeleton-loader.scss';

const SkeletonLoader: TSkeletonLoader = (props) => {
  const {
    count = 1,
    extraClasses = '',
    isCircle = false,
    height,
    width,
  } = props;

  return (
    <ReactLoadingSkeleton
      width={width}
      height={height}
      circle={isCircle}
      count={count}
      className="skeleton-loader__item"
      containerClassName={classNames({
        'skeleton-loader': true,
        [extraClasses]: extraClasses.length > 0,
      })}
    />
  );
};

export default SkeletonLoader;
