import { Pagination } from 'components';

import { TFilterUi } from './Filter.types';

import './filter.scss';

const FilterUi: TFilterUi = ({
  children,
  filter,
  handlers: { handlePageChange },
}) => {
  return (
    <div className="filter">
      <div className="filter__content"> {children}</div>

      <div className="filter__pagination">
        <Pagination
          selectedPage={filter.pagination.page}
          totalPages={filter.pagination.totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default FilterUi;
