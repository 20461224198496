import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Tab } from './Tab/Tab';

import { TTabs } from './Tabs.types';

import './tabs.scss';
import { locales } from "../../constants";
import { Indicator } from "@mantine/core";
import { TTabPanelProps } from "./TabPanel/TabPanel.types";

export const Tabs: TTabs = ({
  children,
  className = '',
  headerClassName = '',
  tabClassName = '',
  activeTab = 0,
  tabColor = 'ocean-green',
  onClick,
}) => {
  //* Local State
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  //* Handlers
  const handleActiveTabClick = (tabIndex: number) => () => {
    onClick && onClick(tabIndex);
    setActiveTabIndex(tabIndex);
  };

  const mainClasses = classNames({
    'tabs flex flex-column': true,
    [className]: className.length > 0,
  });

  const headerClasses = classNames({
    'tabs__header flex flex-inline': true,
    [headerClassName]: headerClassName.length > 0,
  });

  useEffect(() => {
    if (activeTab !== activeTabIndex && !!onClick) setActiveTabIndex(activeTab);
  }, [activeTab, activeTabIndex]);

  return (
    <div className={mainClasses}>
      <div className={headerClasses}>
        {Array.isArray(children) ? (
          children.filter(({ props: { title, children } }: { props: TTabPanelProps }, index) => !!children )
              .map(({ props: { title } }: { props: TTabPanelProps }, index) => (
            // <Indicator position="bottom-end" label={locales.general.new} disabled={!!index || index !== activeTabIndex}>
              <Tab
                key={title}
                title={title}
                onClick={handleActiveTabClick}
                index={index}
                className={tabClassName}
                tabColor={tabColor}
                isActive={index === activeTabIndex}
              />
            // </Indicator>
          ))
        ) : (
          <Tab
            key={children.props.title}
            title={children.props.title}
            onClick={handleActiveTabClick}
            index={1}
            className={tabClassName}
            tabColor={tabColor}
            isActive={true}
          />
        )}
      </div>

      {Array.isArray(children) ? children.filter(({ props: { title, children } }: { props: TTabPanelProps }, index) => !!children )[activeTabIndex] : children}
    </div>
  );
};
