import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { batch, shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useExtractServerError, useGetNextPreviousItems, useGetUserInfo, useToast } from 'hooks';
import { routes} from 'constants/index';

import { ModalsTypesEnum } from "components/Modals/Modals.enums";

import { setOfferFormStepValuesAction } from "../store/actions";
import { fetchOfferApi, postPipeApi, removeOfferApi, updateOfferApi } from '../api';

import { setIsLoading } from "modules/App/store/actions";
import { resetModal, setModalData, setModalType, setModalValues } from "components/Modals/store/actions";

import { IOffer, IStoreState, TOffer } from './Offer.types';

import OfferUI from './Offer.ui';

const Offer: TOffer = ({
  moduleName = 'offers',
  returnRoute = routes.offers.main,
  detailRoute = routes.offers.main,
}) => {
  const modalType = ModalsTypesEnum.SELECT_CVS;

  //* Hooks Init
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { extractErrorMessage } = useExtractServerError();
  const { id: offerId = '' } = useParams();
  const getNextPreviousItems = useGetNextPreviousItems();

  //* Redux State
  const config = useSelector(
    ({ app: { config } }: IStoreState) => config,
    shallowEqual
  );

  const offers = useSelector(
    ({ [moduleName]: { list } }: IStoreState) => list,
    shallowEqual
  );

  const modals = useSelector(({ modals: { [modalType]: modals } }: IStoreState) => modals, shallowEqual);

  const userInfo = useGetUserInfo();

  const offersIds = offers?.map((offer) => offer._id);

  const { nextId: nextOfferId, previousId: previousOfferId } =
    getNextPreviousItems(offersIds, offerId);

  //* Local State
  const [isArchiveOfferModalVisible, setIsArchiveOfferModalVisible] =
    useState(false);
  const [isArchiveSuccessModalVisible, setIsArchiveSuccessModalVisible] =
    useState(false);
  const [offer, setOffer] = useState({} as IOffer);

  //* Handlers
  const handleArchiveOfferClick = () => setIsArchiveOfferModalVisible(true);

  const handleArchiveOfferCancel = () => setIsArchiveOfferModalVisible(false);

  const handleArchiveOfferConfirm = () => archiveOffer();

  const handleArchiveSuccessConfirm = () => archiveSuccess();

  const handleAddResumesClick = useCallback(() => {
    dispatch(setModalType(modalType, true));
  }, [dispatch]);

  //* Side Effects
  const fetchOffer = async () => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data },
      } = await fetchOfferApi(offerId);

      setOffer(data);

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
      navigate(returnRoute);
    }
  };

  const archiveOffer = async () => {
    try {
      dispatch(setIsLoading(true));

      // TODO: this is related to the archive === draft isssue
      await removeOfferApi([offerId]);
      // await updateOfferApi({
      //   offer_id: offerId,
      //   draft: true,
      // });

      dispatch(setIsLoading(false));
      setIsArchiveOfferModalVisible(true);

      setIsArchiveSuccessModalVisible(true)
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  };

  const archiveSuccess = async () => {
    try {
      setIsArchiveSuccessModalVisible(false);

      navigate(returnRoute);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  };

  const applyToOffer = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      await postPipeApi({
        offerId,
        pipeInfoId: "62de933098c747f4cd8d6b79",
        cvIds: modals.values.CVs,
      })

      batch(() => {
        dispatch(resetModal(modalType));
        dispatch(setModalType(ModalsTypesEnum.UPLOAD_SUCCESS, true));
        dispatch(setModalValues(ModalsTypesEnum.UPLOAD_SUCCESS, { CVs: modals.values.CVs }));
        dispatch(setModalData(ModalsTypesEnum.UPLOAD_SUCCESS, { CVs: modals.data.CVs }));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, modals])

    ///* Handlers
    const handleDuplicateOfferClick = useCallback(() => {
        const redux = {
            title: offer?.info?.title ?? "",
            description: offer?.company?.description ?? "",
            contractType: offer?.info?.type ?? "",
            postalCode: offer?.info?.postal_code ?? "",
            city: offer?.info?.city ?? "",
            educationLevel: offer?.info?.education_level ?? [],
            workFields: offer?.info?.work_fields ?? [],
            workPlaces: offer?.info?.work_places ?? [],
            profile: offer?.candidate?.profile ?? "",
            missions: offer?.candidate?.missions ?? "",
            required: offer?.candidate?.skills?.required ?? [],
            bonus: offer?.candidate?.skills?.bonus ?? [],
            languages: offer?.candidate?.languages ?? [],
            keywords: offer?.info?.keywords ?? [],
            pace: offer?.info?.pace ?? [],
            startDate: offer?.info?.start_date ?? "",
            comment: offer?.info?.comment ?? "",
            subEducationLevel: offer?.info?.sub_education_level ?? [],
        }

        dispatch(setOfferFormStepValuesAction( redux ));
        navigate( '/offers/create' );
    }, [ offer ]);

  const handleOfferLinkClick = (id: string) => () => {
    if (id === '-1') return navigate(returnRoute);

    navigate(`${detailRoute}/${id}`);
  };

  const handleAddResumesSubmit = useCallback(() => {
    applyToOffer();
  }, [dispatch, applyToOffer]);

  const handleSuccessClose = useCallback(() => {
    dispatch(resetModal(ModalsTypesEnum.UPLOAD_SUCCESS));
  }, [dispatch]);

  //* Effects
  useEffect(() => {
    if (config.degrees?.length > 0) fetchOffer();
  }, [config.degrees, offerId]);

  //* Wrappers
  const data = {
    offer,
    config,
    userInfo,
    modals,
    returnRoute,
  };

  const handlers = {
    handleArchiveOfferClick,
    handleArchiveOfferCancel,
    handleArchiveOfferConfirm,
    handleOfferLinkClick,
    handleAddResumesClick,
    handleAddResumesSubmit,
    handleSuccessClose,
    handleArchiveSuccessConfirm,
    handleDuplicateOfferClick,
  };

  return (
    <OfferUI
      moduleName={moduleName}
      isArchiveOfferModalVisible={isArchiveOfferModalVisible}
      isArchiveSuccessModalVisible={isArchiveSuccessModalVisible}
      nextOfferId={nextOfferId}
      previousOfferId={previousOfferId}
      data={data}
      handlers={handlers}
    />
  );
};

export default Offer;
