import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/fr";

import { locales, routes } from "constants/index";

import TableExt from "components/Table2/components";

import { PublicationStatusEnums } from "../../components/Table2/components/TableBadge/TableBadge.enums";
import type { TDraftsColumn, TDraftsColumns } from "./Listing.types";

dayjs.extend(relativeTime);

const title: TDraftsColumn = {
    id: "info.title",
    header: locales.offer.post,
    accessorFn: (row) => row.info.title,
    size: 2,
};

const city: TDraftsColumn = {
    id: "info.city",
    header: locales.general.city,
    accessorFn: (row) => `${row.info.city} - ${row.info.postal_code}`,
    size: 1,
};

const type: TDraftsColumn = {
    id: "info.type",
    header: locales.offer.contractType,
    accessorFn: (row) => row.info.type,
    cell: (info) => <TableExt.Badge value={info.getValue<string>()} />,
    size: 1,
    minSize: 160,
};

const publication_status: TDraftsColumn = {
    id: "info.publication_status",
    header: locales.general.status,
    accessorFn: (row) => row.approved,
    cell: (info) => (
        <TableExt.Badge
            variant={(() => {
                if (info.row.original.approved && !info.row.original.draft && !info.row.original.deleted)
                    return PublicationStatusEnums.PUBLISHED;
                if (info.row.original.deleted) return PublicationStatusEnums.ARCHIVED;
                if (info.row.original.draft) return PublicationStatusEnums.DRAFT;
                if (!info.row.original.approved) return PublicationStatusEnums.AWAITING_APPROVAL;
                return undefined;
            })()}
        />
    ),
    size: 1,
    minSize: 140,
};

const updated_date: TDraftsColumn = {
    id: "info.updated_date",
    header: locales.offer.publication,
    accessorFn: (row) => row.updated_date,
    cell: (info) => dayjs(info.getValue<string>()).locale("fr").fromNow(),
    size: 1,
};

const _id: TDraftsColumn = {
    id: "info._id",
    header: undefined,
    accessorFn: (row) => row._id,
    cell: (info) => (
        <TableExt.Link
            isLink
            path={`${routes.listing.main}/${info.getValue<string>()}`}
            children={locales.offers.seeOffer}
        />
    ),
    size: 0,
    minSize: 130,
    enableSorting: false,
};

export const columns: TDraftsColumns = [title, city, type, publication_status, updated_date, _id];

export const columnsWithoutStatus: TDraftsColumns = [title, city, type, updated_date, _id];
