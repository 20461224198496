import Joi from "joi";
import { memo, useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { useExtractServerError, useToast, useValidate } from "hooks";
import { convertToDropdown, convertToEditlist, prependHttpsToString, removeHttpFromString } from "utils";

import { setIsLoading } from "modules/App/store/actions";
import {
  setEditMode,
  setModals,
  setUploadAttachment,
  setWebsiteDesign,
  setWebsiteEdit
} from "modules/Settings/store/actions";

import {
  TCustomizeWebsite,
  THandleColorsChange,
  THandleCourseChange,
  THandleCourseDropdown,
  THandleDeleteAttachment,
  THandleDeleteDomain,
  THandleDomainChange,
  THandleEditListHandler,
  THandleFiguresChange,
  THandleModalClose,
  THandleRichTextChange,
  THandleSocialsChange,
  THandleSocialsCheck,
  THandleTextAreaChange,
  THandleUploadAttachment,
  RichTextEditorSourcesEnum,
} from './CustomizeWebsite.types';
import { IDesign, IStoreState, THandleSave, TUpdateDesign } from "../settings.types";
import { SourceTypeEnum } from "components/Forms/LogoPicker/LogoPicker.types";

import { AttachmentsEnum } from "common";

import { locales } from "constants/index";

import {
  createCourseApi,
  createDesignApi,
  deleteAttachmentApi,
  deleteDesignApi,
  fetchAttachmentsApi,
  fetchCoursesApi,
  removeCourseApi,
  updateCourseApi,
  updateDesignApi,
  uploadAttachmentsApi,
} from "../api";

import CustomizeWebsiteUI from './CustomizeWebsite.ui';

const domainValidationSchema = {
  domain: Joi.string()
    .required()
    .alphanum()
    .min(1)
    .max(30)
    .messages({
      'string.empty': locales.errors.required,
      'string.min': locales.formatString(locales.errors.minLength, { charNum: 1 }).toString(),
      'string.max': locales.formatString(locales.errors.maxLength, { charNum: 30 }).toString(),
      'string.alphanum': locales.errors.alphanum,
    }),
};

const validationSchema = {
  aboutUs: Joi.string().allow(''),
  joinUs: Joi.string().allow(''),
  footerDescription: Joi.string().allow(''),
  work_force: Joi.number().allow('').min(1).max(100000)
    .messages({
      'number.base': locales.errors.number.base,
      'string.min': locales.formatString(locales.errors.number.min, { num: 1 }).toString(),
      'string.max': locales.formatString(locales.errors.number.max, { num: 100000 }).toString(),
    }),
  success_rate: Joi.number().allow('').min(0).max(100)
    .messages({
      'number.base': locales.errors.number.base,
      'number.min': locales.formatString(locales.errors.number.min, { num: 0 }).toString(),
      'number.max': locales.formatString(locales.errors.number.max, { num: 100 }).toString(),
    }),
  insertion_rate: Joi.number().allow('').min(0).max(100)
    .messages({
      'number.base': locales.errors.number.base,
      'number.min': locales.formatString(locales.errors.number.min, { num: 0 }).toString(),
      'number.max': locales.formatString(locales.errors.number.max, { num: 100 }).toString(),
    }),
  collaborators: Joi.string().allow(''),
  foundation_year: Joi.number().integer().allow('').min(1950).max(2030),
  // TODO: finish the validation
  // socials: Joi.array().items(
  //     Joi.object({
  //       _id: Joi.string().allow(''),
  //       type: Joi.string().required(),
  //       value: Joi.string().alphanum().allow('').required(),
  //       url: Joi.string().alphanum().allow('').required(),
  //       username: Joi.string().allow(''),
  //       active: Joi.boolean().required(),
  //     })
  // ).min(0).max(7),
}

const apiValidationSchema = {
  colors: Joi.object({
    primary: Joi.string().allow(''),
    secondary: Joi.string().allow(''),
  }),
  description: Joi.string().allow(''),
  why_us_desc: Joi.string().allow(''),
  footer_description: Joi.string().allow(''),
  work_force: Joi.number().allow('').min(1).max(100000),
  success_rate: Joi.number().allow('').min(0).max(100)
    .messages({
      'number.base': locales.errors.number.base,
      'number.min': locales.formatString(locales.errors.number.min, { num: 0 }).toString(),
      'number.max': locales.formatString(locales.errors.number.max, { num: 100 }).toString(),
    }),
  insertion_rate: Joi.number().allow('').min(0).max(100)
    .messages({
      'number.base': locales.errors.number.base,
      'number.min': locales.formatString(locales.errors.number.min, { num: 0 }).toString(),
      'number.max': locales.formatString(locales.errors.number.max, { num: 100 }).toString(),
    }),
  collaboration: Joi.string().allow(''),
  foundation_year: Joi.number().integer().allow('').min(1950).max(2030),
  social_media: Joi.array(),
};

const CustomizeWebsite: TCustomizeWebsite = () => {
  //* Hooks
  const { extractErrorMessage } = useExtractServerError();
  const dispatch = useDispatch();
  const toast = useToast();
  const { errors, validate, setErrors } = useValidate();

  //* Redux State
  const settings = useSelector(({ settings }: IStoreState) => settings, shallowEqual);
  const userInfo = useSelector(({ app: { userInfo } }: IStoreState) => userInfo, shallowEqual);
  const config = useSelector(({ app: { config } }: IStoreState) => config, shallowEqual);

  //* Side Effects
  const createWebsite = useCallback(async () => {
    try {
      const isFormValid = validate({
        data: { domain: settings.websiteDesign.domain },
        validationSchema: domainValidationSchema,
      });

      if (!isFormValid) return;
      setErrors((prevErrors) => ({ ...prevErrors, domain: '' }));

      dispatch(setIsLoading(true));

      const payload = {
        domain_name: `https://${settings.websiteDesign.domain}.${config.domain}/`,
        colors: settings.websiteDesign.colors,
      };

      await createDesignApi(payload);

      batch(() => {
        dispatch(setEditMode({ type: 'domain', state: false }));
        dispatch(setWebsiteEdit(true));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch,validate, setErrors, settings.websiteDesign, config.domain]);

  const updateDesign: TUpdateDesign = useCallback(async (type) => {
    try {
      const payload: Partial<IDesign> = Object.assign(
        {},
        type === 'colors' && settings.websiteDesign.colors &&
          { colors: settings.websiteDesign.colors },
        type === 'aboutUs' && settings.websiteDesign.aboutUs &&
          { description: settings.websiteDesign.aboutUs },
        type === 'joinUs' && settings.websiteDesign.joinUs &&
          { why_us_desc: settings.websiteDesign.joinUs },
        type === 'footerDescription' && settings.websiteDesign.footerDescription &&
          { footer_description: settings.websiteDesign.footerDescription },
        type === 'figures' && settings.websiteDesign.figures &&
          {
            ...(settings.websiteDesign.figures.work_force && {
              work_force: parseInt(settings.websiteDesign.figures.work_force),
            }),
            ...(settings.websiteDesign.figures.collaborators && {
              collaboration: settings.websiteDesign.figures.collaborators,
            }),
            ...(settings.websiteDesign.figures.foundation_year && {
              foundation_year: parseInt(settings.websiteDesign.figures.foundation_year),
            }),
            ...(settings.websiteDesign.figures.success_rate && {
              success_rate: parseInt(settings.websiteDesign.figures.success_rate),
            }),
            ...(settings.websiteDesign.figures.insertion_rate && {
              insertion_rate: parseInt(settings.websiteDesign.figures.insertion_rate),
            }),
          },
        type === 'socials' && settings.websiteDesign.socials &&
          { social_media: settings.websiteDesign.socials },
      );

      const isValid = validate({
        data: { ...payload } as any,
        validationSchema: apiValidationSchema,
      });

      if (!isValid) return;

      dispatch(setIsLoading(true));

      await updateDesignApi(payload);

      batch(() => {
        dispatch(setIsLoading(false));
        dispatch(setEditMode({ type, state: false }));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings, userInfo]);

  const fetchCourses = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      const { data: { data } } = await fetchCoursesApi({
        headers: {
          'Domain-Key': settings.websiteDesign.key,
        },
        params: {
          page: 1, limit: 100,
        }
      });

      batch(() => {
        dispatch(setWebsiteDesign({
          courses: data.map((course) => ({
            formation_id: course._id,
            title: course.info.title,
            description: course.info.description,
            type: '',
            start_date: course.info.start_date,
            end_date: '',
            city: course.info.city,
            postal_code: course.info.postal_code,
            degree: course.info.degree,
            education_level: course.info.education_level,
            sub_education_level: course.info.sub_education_level,
            work_fields: course.info.work_fields,
            work_places: course.info.work_places,
            keywords: course.info.keywords,
            pace: course.info.pace,
            comment: '',
            school_name: course.school.name,
            school_description: course.school.description,
          })),
        }));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings]);

  const createCourse = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      await createCourseApi({
        title: settings.modals.courses.data?.title ?? '',
        description: '',
        type: '',
        start_date: settings.modals.courses.data?.start_date ?? '',
        end_date: '',
        city: settings.modals.courses.data?.city ?? '',
        postal_code: '',
        degree: settings.modals.courses.data?.degree ?? [],
        education_level: [],
        sub_education_level: [],
        work_fields: [],
        work_places: [],
        keywords: [],
        pace: [],
        comment: '',
        school_name: '',
        school_description: '',
      });

      batch(() => {
        dispatch(setModals({ courses: { create: false, data: null } }));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings.modals.courses.data, userInfo]);

  const updateCourse = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      await updateCourseApi({
        ...settings.modals.courses.data
      });

      batch(() => {
        dispatch(setModals({ courses: { edit: false, data: null }}));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings.modals.courses.data, userInfo]);

  const deleteCourse = useCallback(async () => {
    try {
      if (!settings.modals.courses.data?.formation_id) return;
      dispatch(setIsLoading(true));

      await removeCourseApi({
        formation_id: settings.modals.courses.data?.formation_id
      });

      batch(() => {
        dispatch(setModals({ courses: { delete: false, data: null }}));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings.modals.courses.data, userInfo]);

  const deleteDesign = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      await deleteDesignApi();

      batch(() => {
        dispatch(setWebsiteEdit(false));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings, userInfo]);

  const fetchAttachment = useCallback(async (type: AttachmentsEnum) => {
    try {
      dispatch(setIsLoading(true));

      const { data: { data } } = await fetchAttachmentsApi({ type });

      dispatch(setWebsiteDesign({ [type]: data }));

      batch(() => {
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch]);

  const uploadAttachment = useCallback(async (type: AttachmentsEnum) => {
    try {
      dispatch(setIsLoading(true));

      const formData = new FormData();
      formData.append('type', type);
      settings.uploads[type]?.file.forEach((attachment) => formData.append('attachments', attachment));

      await uploadAttachmentsApi(formData);

      batch(() => {
        dispatch(setUploadAttachment({ [type]: undefined }));
        dispatch(setEditMode({ type, state: false }));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings.editMode, settings.uploads]);

  const deleteAttachment = useCallback(async (type: AttachmentsEnum, id: string) => {
    try {
      dispatch(setIsLoading(true));

      await deleteAttachmentApi({ id });
      await fetchAttachment(type);

      batch(() => {
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings.uploads]);

  //* Handlers
  const handleDomainChange: THandleDomainChange = useCallback(({ target: { value: domain } }) => {
    const isValid = validate({
      data: { domain },
      validationSchema: domainValidationSchema,
    });

    if (isValid) setErrors((prevErrors) => ({ ...prevErrors, domain: '' }));

    batch(() => {
      dispatch(setWebsiteDesign({
        domain: domain.trim().toLowerCase(),
      }))
      if (!settings.editMode.domain) dispatch(setEditMode({ type: 'domain', state: true }));
    });
  }, [dispatch, validate, setErrors, settings.editMode]);

  const handleColorsChange: THandleColorsChange = useCallback((type) => (color) => {
    batch(() => {
      dispatch(setWebsiteDesign({
        colors: {
          ...settings.websiteDesign.colors,
          [type]: color,
        }
      }));
      if (!settings.editMode.colors) dispatch(setEditMode({ type: 'colors', state: true }));
    });
  }, [dispatch, settings.websiteDesign, settings.editMode]);

  const handleTextAreaChange: THandleTextAreaChange = useCallback((type) => ({ target: { value } }) => {
    const isValid = validate({
      data: { [type]: value },
      validationSchema: validationSchema,
    });

    if (isValid) setErrors((prevErrors) => ({ ...prevErrors, [type]: '' }));

    batch(() => {
      dispatch(setWebsiteDesign({
        [type]: value,
      }));
      if (!settings.editMode[type]) dispatch(setEditMode({ type, state: true }));
    });
  }, [dispatch, validate, setErrors, settings.editMode]);

  const handleRichTextChange: THandleRichTextChange = useCallback((type) => (content, delta, source, editor) => {
    if (source !== RichTextEditorSourcesEnum.user) return;
    const isValid = validate({
      data: { [type]: content },
      validationSchema: validationSchema,
    });

    if (isValid) setErrors((prevErrors) => ({ ...prevErrors, [type]: '' }));

    batch(() => {
      dispatch(setWebsiteDesign({
        [type]: content,
      }));
      if (!settings.editMode[type]) dispatch(setEditMode({ type, state: true }));
    });
  }, [dispatch, validate, setErrors, settings.editMode]);

  const handleFiguresChange: THandleFiguresChange = useCallback((type) => ({ target: { value: figure } }) => {
    const isValid = validate({
      data: { [type]: figure },
      validationSchema: validationSchema,
    });

    if (isValid) setErrors((prevErrors) => ({ ...prevErrors, [type]: '' }));

    batch(() => {
      dispatch(setWebsiteDesign({
        figures: {
          ...settings.websiteDesign.figures,
          [type]: figure
        },
      }));
      if (!settings.editMode.figures) dispatch(setEditMode({ type: 'figures', state: true }));
    });
  }, [dispatch, settings.websiteDesign, settings.editMode]);

  const handleSocialsChange: THandleSocialsChange = useCallback((type) => ({ target: { value: social } }) => {
    const link = removeHttpFromString( social );

    const filteredSocials = settings.websiteDesign?.socials?.filter((media) => media.type !== type) ?? [];
    let chosenMedia = settings.websiteDesign?.socials?.find((media) => media.type === type);

    if (!chosenMedia) chosenMedia = {
      type,
      value: prependHttpsToString( link ),
      url: prependHttpsToString( link ),
      username: '',
      active: false
    };

    chosenMedia.value = prependHttpsToString( link );
    chosenMedia.url = prependHttpsToString( link );

    const socials = [...filteredSocials, chosenMedia];

    batch(() => {
      dispatch(setWebsiteDesign({ socials }));
      if (!settings.editMode.socials) dispatch(setEditMode({ type: 'socials', state: true }));
    });
  }, [dispatch, validate, setErrors, settings.websiteDesign, settings.editMode]);

  const handleSocialsCheck: THandleSocialsCheck = useCallback((type) => () => {
    const filteredSocials = settings.websiteDesign?.socials?.filter((media) => media.type !== type) ?? [];
    let chosenMedia = settings.websiteDesign?.socials?.find((media) => media.type === type);

    if (!chosenMedia) chosenMedia = {
      type,
      value: '',
      url: '',
      username: '',
      active: false
    };

    chosenMedia.active = !chosenMedia.active;

    const socials = [...filteredSocials, chosenMedia];

    batch(() => {
      dispatch(setWebsiteDesign({ socials }));
      if (!settings.editMode.socials) dispatch(setEditMode({ type: 'socials', state: true }));
    });
  }, [dispatch, settings.websiteDesign, settings.editMode]);

  const handleDeleteDomain: THandleDeleteDomain = useCallback(() => {
    deleteDesign();
  }, [deleteDesign]);

  const handleCourseChange: THandleCourseChange = useCallback((course) => ({ target: { value } }) => {
    dispatch(setModals({ courses: {
      ...settings.modals.courses,
      data: {
        ...settings.modals.courses.data,
        [course]: value
      }
    }}));
  }, [dispatch, settings.modals.courses]);

  const handleCourseDropdown: THandleCourseDropdown = useCallback((type) => (value ) => {
    if (!Array.isArray(value)) return;
    dispatch(setModals({ courses: {
      ...settings.modals.courses,
      data: {
        ...settings.modals.courses.data,
        [type]: value.map(element => element.value)
      }
    }}));
  }, [dispatch, settings.modals.courses]);

  const handleCreateCourse = useCallback(() => {
    dispatch(setModals({ courses: {
      create: true,
      data: {},
    }}));
  }, [settings.websiteDesign.courses]);

  const handleEditList: THandleEditListHandler = useCallback((type) => (id: string) => () => {
    dispatch(setModals({ courses: {
      [type]: true,
      data: settings.websiteDesign.courses.find((element) => element.formation_id === id),
    }}));
  }, [settings.websiteDesign.courses]);

  const handleModalClose: THandleModalClose = useCallback((type) => () => {
    dispatch(setModals({ courses: { [type]: false, data: null } }));
  }, [dispatch, settings.modals]);

  const handleDeleteAttachment : THandleDeleteAttachment = useCallback( (type) => (sourceType, index) => {
    try {
      batch(() => {
        if (sourceType === SourceTypeEnum.attachements && settings.websiteDesign[type]) {
          if (!settings.websiteDesign[type] || !settings.websiteDesign[type][index] || !settings.websiteDesign[type][index]._id) return;
          deleteAttachment(type, settings.websiteDesign[type][index]._id);
        } else {
          dispatch(setUploadAttachment({ [type]: { type, file: settings.uploads[type]?.file.filter(( _, i ) => i !== index - settings.websiteDesign[type].length )}}));
        }
      });
    } catch ( error ) {
      toast({ type: 'error', message: locales.errors.general });
    }
  }, [dispatch, deleteAttachment, settings.uploads, settings.websiteDesign, settings.editMode]);

  const handleUploadAttachment : THandleUploadAttachment = useCallback((type) => (file) => {
    batch(() => {
      dispatch(setUploadAttachment({ [type]: { type, file }}));
      if (!settings.editMode[type]) dispatch(setEditMode({ type, state: true }));
    });
  }, [dispatch, settings.uploads]);

  const handleSave: THandleSave = (type) => () => {
    switch (type) {
      case "domain":
        createWebsite();
        break;

      case "colors":
      case "aboutUs":
      case "figures":
      case "joinUs":
      case "footerDescription":
      case "socials":
        updateDesign(type);
        break;

      case "course":
        createCourse();
        break;

      case "courseUpdate":
        updateCourse();
        break;

      case "courseDelete":
        deleteCourse();
        break;

      case AttachmentsEnum.logo:
      case AttachmentsEnum.publicity:
        uploadAttachment(type as AttachmentsEnum);
        break;

      default:
        break;
    }
  }

  //* Effects
  useEffect(() => {
    fetchCourses();
  }, [
    settings.modals.courses.edit,
    settings.modals.courses.delete,
    settings.modals.courses.create
  ]);

  useEffect(() => {
    fetchAttachment(AttachmentsEnum.logo);
  }, [
    settings.uploads.logo,
  ]);

  useEffect(() => {
    fetchAttachment(AttachmentsEnum.publicity);
  }, [
    settings.uploads.publicity,
  ]);

  //* Component
  const data = useMemo(() => ({
    websiteEdit: settings.websiteEdit,
    websiteDesign: settings.websiteDesign,
    courses: convertToEditlist({
      data: settings.websiteDesign.courses,
      idKey: 'formation_id',
      titleKey: 'title',
      descriptionKeys: [
        { label: `${locales.settings.customWebsite.course.startDate} :`, key: 'start_date' },
        { label: `${locales.settings.customWebsite.course.courseLocation} :`, key: 'city' },
      ]
    }),
    uploads: settings.uploads,
    editMode: settings.editMode,
  }), [
    settings.websiteEdit,
    settings.websiteDesign,
    settings.editMode,
    settings.uploads,
  ]);

  const modals = useMemo(() => ({
    ...settings.modals.courses
  }), [
    settings.modals.courses
  ]);

  const options = useMemo(() => ({
    degrees: convertToDropdown({ data: config.degrees, labelKey: 'label', valueKey: 'value' }),
  }), [
    config.degrees,
  ]);

  const handlers = useMemo(() => ({
    handleDomainChange,
    handleColorsChange,
    handleTextAreaChange,
    handleRichTextChange,
    handleFiguresChange,
    handleSocialsChange,
    handleSocialsCheck,
    handleCourseChange,
    handleCourseDropdown,
    handleDeleteDomain,
    handleDeleteAttachment,
    handleUploadAttachment,
    handleSave,
    editHandlers: {
      handleCreate: handleCreateCourse,
      handleEdit: handleEditList('edit'),
      handleDelete: handleEditList('delete'),
    },
    handleModalClose,
  }), [
    handleDomainChange,
    handleColorsChange,
    handleTextAreaChange,
    handleRichTextChange,
    handleFiguresChange,
    handleSocialsChange,
    handleSocialsCheck,
    handleCourseChange,
    handleCourseDropdown,
    handleDeleteDomain,
    handleDeleteAttachment,
    handleUploadAttachment,
    handleSave,
    handleEditList,
    handleModalClose,
  ]);

  return (
    <CustomizeWebsiteUI
      errors={errors}
      data={data}
      modals={modals}
      options={options}
      handlers={handlers}
    />
  );
};
export default memo(CustomizeWebsite);
