import { memo } from "react";
import {
  NovuProvider,
  PopoverNotificationCenter,
  IMessage,
} from '@novu/notification-center';
import ClassNames from 'classnames';

import { ContactSupportModal, AttachmentsLibraryModal, Breadcrumb } from 'components';

import { THeaderUI } from './Header.types';

import './header.scss';

const HeaderUI: THeaderUI = ({
  isNotificationListVisible,
  isContactSupportModalVisible,
  isAttachmentsLibraryVisible,
  notificationsRef,
  data: { userInfo },
  handlers: {
    handleNotificationsClick,
    handleContactSupportClick,
    handleContactSupportClose,
    handleProfileImageClick,
    handleAttachmentsLibraryModalClose,
    handlerProfileImageUpdate,
    handleProfileImageSelect,
  },
}) => {

  function onNotificationClick(notification: IMessage) {
    if (notification?.cta?.data?.url) {
      window.location.href = notification.cta.data.url;
    }
  }

  return (
    <>
      <div className="header">
        <Breadcrumb />

        <div className="header__actions">
          <NovuProvider
            subscriberId={userInfo?._id}
            applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID || ''}
            i18n="fr"
          >
            <PopoverNotificationCenter
              onNotificationClick={onNotificationClick}
              colorScheme={'light'}
            >
              {({ unseenCount }) => (
                <div
                  className={ClassNames({
                    'header__action notification': true,
                    unseen: !!unseenCount && unseenCount > 0,
                  })}
                />
              )}
            </PopoverNotificationCenter>
          </NovuProvider>

          <div
            className="header__action support"
            onClick={handleContactSupportClick}
            role="presentation"
          />
        </div>
      </div>

      {isContactSupportModalVisible && (
        <ContactSupportModal onClose={handleContactSupportClose} />
      )}

      {isAttachmentsLibraryVisible && (
        <AttachmentsLibraryModal
          title={'Update your profile image'}
          applyLabel="Update image"
          onClose={handleAttachmentsLibraryModalClose}
          onApply={handlerProfileImageUpdate}
          isMultiSelection={false}
          onSelectAttachments={handleProfileImageSelect}
        />
      )}
    </>
  );
};

export default memo(HeaderUI);
