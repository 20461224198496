import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { MantineProvider } from "@mantine/core";

import App from "modules/App";

import store from "./store";
import theme from "styles/theme";

import reportWebVitals from "./reportWebVitals";

import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "./styles/_main.scss";


const DOM_NODE = (
    <Provider store={store}>
        <MantineProvider theme={theme} >
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </MantineProvider>
    </Provider>
);

const container = document.getElementById( "root" );

const root = createRoot( container! );

root.render( DOM_NODE );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
