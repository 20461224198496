import {memo} from "react";
import classNames from 'classnames';

import {FormControl, TextInput} from "../index";

import { TSearchBar } from './SearchBar.types';

import './search-bar.scss';

const SearchBar: TSearchBar = ({
  id = '',
  value = '',
  placeholder = '',
  error = '',
  className = '',
  isDisabled = false,
  onChange,
}) => {

  const classes = classNames({
    'search-bar__container': true,
    'search-bar__disabled': isDisabled,
    'search-bar_error': !!error,
    [className]: !!className,
  });

  return (
    <div id={id} className={classes}>
      <TextInput
        id='searchBar'
        name='searchBar'
        className='search-bar'
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        isError={!!error}
        isDisabled={isDisabled}
      />
      <div className='search-bar__icon' />
    </div>
  );
};

export default memo(SearchBar);
