import { useSelector, shallowEqual, useDispatch, batch } from 'react-redux';

import FilterUi from './Filter.ui';
import { setFilterPagination } from './store/actions';

import { TFilter, IStoreState } from './Filter.types';

const Filter: TFilter = ({ moduleName, children }) => {
  //* Hooks init
  const dispatch = useDispatch();

  //* Redux State
  const filter = useSelector(
    ({ filter }: IStoreState) => filter[moduleName],
    shallowEqual
  );

  //* Handlers
  const handlePageChange = ({ selected }: { selected: number }) => {
    dispatch(
      setFilterPagination({
        moduleName,
        payload: { ...filter.pagination, page: selected + 1 },
      })
    );
  };

  const handlers = {
    handlePageChange,
  };

  return (
    <FilterUi filter={filter} handlers={handlers}>
      {children}
    </FilterUi>
  );
};

export default Filter;
