import { Modal, AttachmentsLibrary, Button } from 'components';

import { TAttachmentsLibraryModal } from './AttachmentsLibraryModal.types';

import './attachments-library-modal.scss';

const AttachmentsLibraryModal: TAttachmentsLibraryModal = ({
  title,
  onClose,
  applyLabel = 'Ok',
  isMultiSelection = true,
  onSelectAttachments,
  onApply,
}) => {
  return (
    <Modal
      title={title}
      className="attachments-library-modal"
      onClose={onClose}
      actionButtons={
        <span className="flex flex-justify-content-center">
          <Button
            variant="primary"
            onClick={onApply}
          >
            {applyLabel}
          </Button>
        </span>
      }
    >
      <AttachmentsLibrary
        allowUpload={false}
        isMultiSelection={isMultiSelection}
        onSelectAttachments={onSelectAttachments}
      />
    </Modal>
  );
};

export default AttachmentsLibraryModal;
