"use client";

import { Badge } from "@mantine/core";

export default Badge.extend({
	defaultProps: {
		color: 'darkgreen',
	},
	vars: (theme, props) => {
		if ( props.variant ) {
			if (props.variant === 'skills-required') {
				return {
					root: {
						'--badge-bg': '#E4E4E4',
						'--badge-color': '#000000'
					},
				};
			} else if (props.variant === 'skills-important') {
				return {
					root: {
						'--badge-bg': '#E9FFEF',
						'--badge-color': '#409261'
					},
				};
			} else if (props.variant === 'skills-bonus') {
				return {
					root: {
						'--badge-bg': '#5AC0F561',
						'--badge-color': '#276EF1'
					},
				};
			} else if (props.variant === 'offer-card-badge') {
				return {
					root: {
						'--badge-bg': 'transparent',
						'--badge-color': '#141414B2'
					},
				};
			} else if (props.variant === 'offer-filter-number') {
				return {
					root: {
						padding: 'var(--mantine-spacing-xs)',
					},
				};
			}
		}

		if (props.children) {
			const childrenString = props.children.toString().toLowerCase();
			if (childrenString === 'apprentissage') {
				return {
					root: {
						'--badge-bg': '#e4e4e4',
						'--badge-color': 'rgb(63, 55, 72)'
					},
				};
			} else if (childrenString === 'stage') {
				return {
					root: {
						'--badge-bg': '#F7EDF7',
						'--badge-color': '#e900a8'
					},
				};
			} else if (childrenString === 'cdi') {
				return {
					root: {
						'--badge-bg': '#e9ffef',
						'--badge-color': 'rgb(64, 146, 97)'
					},
				};
			} else if (childrenString === 'peu importe') {
				return {
					root: {
						'--badge-bg': '#e9ffef',
						'--badge-color': 'rgb(46, 193, 172)'
					},
				};
			} else if (childrenString === 'contrat pro') {
				return {
					root: {
						'--badge-bg': '#e9ffef',
						'--badge-color': 'rgb(64, 146, 97)'
					},
				};
			} else if (childrenString === 'new post') {
				return {
					root: {
						'--badge-radius': 'var(--mantine-radius-sm)',
						'--badge-bg': '#7D5AE21A',
						'--badge-color': '#7D5AE2'
					},
				};
			}
		}

		return { root: {} };
	},
})