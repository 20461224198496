import { CVThequeActionEnum } from ".";
import type { TResetState, TSetStudents, TSetSelectedStudents, TSetSelectedStudentCV, TSetActiveFilters, TSetFilters, TEditFilterOptions } from ".";


export const setStudents: TSetStudents = ( students ) => ( {
    type: CVThequeActionEnum.SET_STUDENTS,
    payload: students
} );

export const setSelectedStudents: TSetSelectedStudents = ( selectedStudents ) => ( {
    type: CVThequeActionEnum.SET_SELECTED_STUDENTS,
    payload: selectedStudents
} );

export const setSelectedStudentCV: TSetSelectedStudentCV = ( selectedStudentCV ) => ( {
    type: CVThequeActionEnum.SET_SELECTED_STUDENT_CV,
    payload: selectedStudentCV
} );

export const setActiveFilters: TSetActiveFilters = ( activeFilters ) => ( {
    type: CVThequeActionEnum.SET_ACTIVE_FILTERS,
    payload: activeFilters
} );

export const setFilters: TSetFilters = ( filters ) => ( {
    type: CVThequeActionEnum.SET_FILTERS,
    payload: filters
} );

export const editFiltersOptions: TEditFilterOptions = ( filterOptions ) => ( {
    type: CVThequeActionEnum.EDIT_FILTER_OPTIONS,
    payload: filterOptions
} );

export const resetStateCVTheque: TResetState = () => ( {
    type: CVThequeActionEnum.RESET,
} );