import axios, { AxiosError } from 'axios';

import { enums } from 'constants/index';
import {
  getAccessToken,
  getRefreshToken,
  getLocale,
  setAccessToken,
  clearSession,
} from '../local-store';

import { Headers, IRefreshTokenApiResponse } from './base-api.types';

export const baseApi = () => {
  const accessToken = getAccessToken();
  const locale = getLocale();

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  let headers: Headers = {
    'Content-Type': 'application/json',
    language: locale ? locale : enums.locales.fr,
  };

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  axiosInstance.defaults.headers.common = { ...headers };

  axiosInstance.interceptors.request.use(
    (request) => request,
    (error) => {
      return Promise.reject(new Error(error.response));
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error && error.code === AxiosError.ERR_CANCELED) return Promise.reject(error);
      const err = error.response as {
        status: number;
      };

      if (err.status === 401) {
        await refreshTokenApi();
      }
      return Promise.reject(error.response);
    }
  );

  return axiosInstance;
};

const refreshTokenApi = async () => {
  try {
    const refreshToken = getRefreshToken();

    const {
      data: {
        data: { access_token: accessToken },
      },
    } = await baseApi().post<IRefreshTokenApiResponse>(
      '/users/auth/refresh',
      {
        refresh_token: refreshToken,
      }
    );

    setAccessToken({ accessToken });

    window.location.reload();
  } catch (error) {
    clearSession();
    window.location.href = '/logout';
  }
};
