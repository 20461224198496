import { memo, useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useExtractServerError, useToast } from "hooks";
import { routes } from "constants/index";

import { SettingRoutes } from "./enums";

import {
    setActiveModule,
    setMyAccount,
    setWebsiteDesign,
    setWebsiteEdit,
} from "./store/actions";
import { setIsLoading } from "../App/store/actions";

import { fetchAttachmentsApi, fetchCoursesApi, fetchDesignApi, } from "./api";

import { IStoreState, TSettings } from "./settings.types";
import { AttachmentsEnum } from "common";

import SettingsUI from "./Settings.ui";

const Settings: TSettings = () => {
    // console.log('Settings rendered!');

    //* Hook Init
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();
    const params = useParams();
    const Location = useLocation();

    //* Redux State
    const config = useSelector(({ app: { config } }: IStoreState) => config, shallowEqual);
    const reduxUserInfo = useSelector(({ app: { userInfo } }: IStoreState) => userInfo, shallowEqual);
    const reduxSettings = useSelector(({ settings }: IStoreState) => settings, shallowEqual);

    //* Memos
    const location = useMemo(() => Location, [Location]);
    const module = useMemo(() => params.module ?? '', [params.module]);
    const activeModule = useMemo(() => reduxSettings.activeModule, [reduxSettings.activeModule]);
    const userInfo =  useMemo(() => reduxUserInfo, [reduxUserInfo, module]);
    const settingsRoutes = useMemo(() =>
        Object.values<string>(routes.settings).filter(value =>
            value === routes.settings.myAccount
            || ( value === routes.settings.customizeWebsite && userInfo?.userType === "school" )
            || value === routes.settings.communication
        ),
        [reduxSettings.activeModule, userInfo?.userType]);

    console.log(settingsRoutes);
    const validModule =  useMemo(() => settingsRoutes.includes(module), [settingsRoutes, module]);
    const settings =  useMemo(() => reduxSettings, [reduxSettings, module]);

    //* API Actions
    const fetchSiteConfig = useCallback(async () => {
        try {
            if ( userInfo?.userType !== "school" ) return;

            dispatch(setIsLoading(true));

            const { data: { data: siteConfig } } = await fetchDesignApi();

            const { data: { data: logo } } = await fetchAttachmentsApi({
                type: AttachmentsEnum.logo
            });

            const { data: { data: publicity } } = await fetchAttachmentsApi({
                type: AttachmentsEnum.publicity
            });

            const { data: { data: courses } } = await fetchCoursesApi({
                headers: {
                    'Domain-Key': siteConfig?.key,
                },
                params: {
                    page: 1, limit: 100,
                }
            });

            if (!siteConfig) {
                return batch(() => {
                    dispatch(setWebsiteEdit(false));
                    dispatch(setIsLoading(false));
                })
            }

            batch(() => {
                dispatch(setWebsiteEdit(!!siteConfig.domain_name));
                // TODO: CHECK THIS double redux BUG
                // dispatch(setMyAccount({
                //     personalData: {
                //         ...userInfo?.name
                //     },
                //     loginData: {
                //         email_address: userInfo?.contactInfo.emailAddress,
                //     },
                //     yourEstablishment: {
                //         school: userInfo?.info.school,
                //         foundation_year: siteConfig.foundation_year?.toString(),
                //         siret_number: userInfo?.info.siret_number,
                //         siren_number: userInfo?.info.siren_number,
                //         short_name: userInfo?.info.short_name,
                //         city: userInfo?.info.city,
                //         role: userInfo?.info.role,
                //     }
                // }));
                dispatch(setWebsiteDesign({
                    key: siteConfig.key,
                    domain: siteConfig.domain_name,
                    colors: siteConfig.colors,
                    logo: logo,
                    aboutUs: siteConfig.description,
                    footerDescription: siteConfig.footer_description,
                    figures: {
                        foundation_year: siteConfig.foundation_year?.toString(),
                        work_force: siteConfig.work_force?.toString(),
                        collaborators: siteConfig.collaboration,
                        success_rate: siteConfig.success_rate,
                        insertion_rate: siteConfig.insertion_rate,
                    },
                    joinUs: siteConfig.why_us_desc,
                    publicity: publicity,
                    socials: siteConfig.social_media,
                }));
                dispatch(setWebsiteDesign({
                    courses: courses.map((course) => ({
                        formation_id: course._id,
                        title: course.info.title,
                        description: course.info.description,
                        type: '',
                        start_date: course.info.start_date,
                        end_date: '',
                        city: course.info.city,
                        postal_code: course.info.postal_code,
                        degree: course.info.degree,
                        education_level: course.info.education_level,
                        sub_education_level: course.info.sub_education_level,
                        work_fields: course.info.work_fields,
                        work_places: course.info.work_places,
                        keywords: course.info.keywords,
                        pace: course.info.pace,
                        comment: '',
                        school_name: course.school.name,
                        school_description: course.school.description,
                    })),
                }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, settings, userInfo]);

    //* Effects
    useEffect(() => {
        let newIndex;
        switch (module) {
            case routes.settings.customizeWebsite:
                newIndex = SettingRoutes.CUSTOMIZE_WEBSITE;
                break;

            case routes.settings.archivedOffers:
                newIndex = SettingRoutes.ARCHIVED_OFFERS;
                break;

            case routes.settings.communication:
                newIndex = SettingRoutes.COMMUNICATION;
                break;

            // case routes.settings.notifications:
            //     newIndex = SettingRoutes.NOTIFICATIONS;
            //     break;

            default:
                newIndex = SettingRoutes.MY_ACCOUNT;
                break;
        }
        if (activeModule === newIndex) return;
        dispatch(setActiveModule(newIndex));
    }, [module]);

    useEffect(() => {
        fetchSiteConfig();
    }, [activeModule]);

    useLayoutEffect(() => {
        if (validModule) return;
        navigate(`${routes.settings.main}/${routes.settings.myAccount}`, { replace: true });
    }, [validModule, location.pathname]);

    //* Wrappers
    const data = useMemo(() => ({
        isSchool: userInfo?.userType === "school",
        activeModule,
    }), [
        userInfo?.userType,
        activeModule,
    ]);

    const handlers = useMemo(() => ({}), []);

    return (
        <SettingsUI
            data={data}
            handlers={handlers}
        />
    );
};

export default memo(Settings);