import classNames from 'classnames';

import { Dropzone, SkeletonLoader } from 'components';

import { locales } from 'constants/index';

import { TAttachmentsLibraryUi } from './AttachmentsLibrary.types';

import './attachments-library.scss';

const AttachmentsLibraryUi: TAttachmentsLibraryUi = ({
  isLoading,
  allowUpload,
  enableSelect,
  data: { attachments, selectedAttachments },
  handlers: { handleSelectAttachments, handleUploadAttachments },
}) => {
  return (
    <div className="attachments-library">
      {allowUpload && (
        <Dropzone
          description={locales.general.dropzoneDescription}
          onUploadFile={handleUploadAttachments}
        />
      )}

      {attachments.length > 0 ? (
        <div className="row">
          <div className="attachments-library__attachments flex flex-wrap">
            {attachments.map((attachment) => (
              <div key={attachment._id} className="col-xs-12 col-md-3 col-lg-4">
                <img
                  src={attachment.url}
                  alt={attachment.name}
                  className={classNames({
                    'attachments-library__attachment': true,
                    'attachments-library__attachment--selectable': enableSelect,
                    'attachments-library__attachment--selected':
                      selectedAttachments.find(
                        (selectedAttachment) =>
                          attachment._id === selectedAttachment._id
                      ),
                  })}
                  onClick={handleSelectAttachments(attachment)}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        isLoading && (
          <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-4">
              <SkeletonLoader width={187} height={125} />
            </div>

            <div className="col-xs-12 col-md-6 col-lg-4">
              <SkeletonLoader width={187} height={125} />
            </div>

            <div className="col-xs-12 col-md-6 col-lg-4">
              <SkeletonLoader width={187} height={125} />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AttachmentsLibraryUi;
