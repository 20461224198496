import { memo, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

import { ISourceData, SourceTypeEnum, TDropzoneState, THandleDeleteFile, TLogoPicker } from './LogoPicker.types';

import './logo-picker.scss';
import LogoPickerUI from "./LogoPicker.ui";

const LogoPicker: TLogoPicker = ({
  id,
  className,
  files,
  attachments,

  isMultiple = true,
  isDisabled = false,
  maxSize = 5000000,
  minSize = 1000,
  maxFiles = 5,
  fileTypes = { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] },
  onUploadFile,
  onDeleteFile,
  description = '',
}) => {
  const [error, setError] = useState<string>('');

  //* Handlers
  const handleUploadFile = useCallback((acceptedFiles: File[]) => {
    if (!acceptedFiles || acceptedFiles.length === 0) return;
    setError('');
    onUploadFile(acceptedFiles);
  }, [onUploadFile]);

  const handleUploadFileRejected = useCallback((files: any) => {
    setError(files[0].errors[0].message);
  }, []);

  const handleDeleteFile: THandleDeleteFile = useCallback((sourceType, index) => (event) => {
    event.stopPropagation();
    onDeleteFile(sourceType, index);
  }, [onDeleteFile]);

  //* Memos
  const SRCs = useMemo(() => {
    let sources: ISourceData[] = []
    if (!!files && files.length > 0 || !!attachments && attachments.length > 0) {
      if (!!attachments && attachments.length > 0) sources = [...sources, ...attachments.map(attachment => ({
        sourceType: SourceTypeEnum.attachements,
        src: attachment?.url
      }))];
      if (!!files && files.length > 0) sources = [...sources, ...files.map(file => ({
        sourceType: SourceTypeEnum.files,
        src: URL.createObjectURL(file)
      }))];
    }
    return sources;
  }, [attachments, files])


  //* Main Hook
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleUploadFile,
    onDropRejected: handleUploadFileRejected,
    multiple: isMultiple,
    disabled: isDisabled,
    maxSize,
    minSize,
    accept: fileTypes,
    maxFiles,
  });


  //* Wrappers
  const dropzoneState: TDropzoneState = useMemo(() => ({
    getRootProps,
    getInputProps,
    isDragActive,
  }), [
    getRootProps,
    getInputProps,
    isDragActive,
  ]);

  const data = useMemo(() => ({
    SRCs,
  }), [
    SRCs,
  ]);

  const handlers = useMemo(() => ({
    handleDeleteFile,
  }), [
    handleDeleteFile,
  ]);

  return <LogoPickerUI
    id={id}
    className={className}
    isDisabled={isDisabled}
    isMultiple={isMultiple}
    dropzoneState={dropzoneState}
    data={data}
    handlers={handlers}
    error={error}
  />;
};

export default memo(LogoPicker);
