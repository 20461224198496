import cn from 'classnames';

import { TLabel } from './Label.types';

import './label.scss';

const Label: TLabel = ({
  id = '',
  className = '',
  children,
  isError = false,
  isDisabled = false,
  isRequired = false,
  onClick,
}) => {
  const classNames = cn({
    'label flex': true,
    'label--error': isError,
    'label--disabled': isDisabled,
    [className]: className.length > 0,
  });

  return (
    <label htmlFor={id} className={classNames} onClick={onClick}>
      {children}
      {isRequired && '*'}
    </label>
  );
};

export default Label;
