import { combineReducers } from 'redux';

import { appReducer } from 'modules/App/store';
import { authenticationReducer } from 'modules/Authentication/store';
import { offersReducer } from 'modules/Offers/store';
import { candidatesReducer } from 'modules/Students/store';
import { classroomsReducer } from 'modules/Classrooms/store';
import { filterReducer } from 'components/Filter/store';
import { settingsReducer } from 'modules/Settings/store';
import { sidebarReducer } from "components/Sidebar/store";
import { CVThequeReducer } from "modules/CVTheque/store";
import { listingReducer } from "modules/Listing";
import { archivesReducer } from "modules/Settings/ArchivedOffers";
import { modalsReducer } from "components/Modals/store";

const mainReducer = combineReducers({
  app: appReducer,
  auth: authenticationReducer,
  offers: offersReducer,
  candidates: candidatesReducer,
  classrooms: classroomsReducer,
  filter: filterReducer,
  settings: settingsReducer,
  sidebar: sidebarReducer,
  CVTheque: CVThequeReducer,
  listing: listingReducer,
  archives: archivesReducer,
  modals: modalsReducer,
});

export default mainReducer;
