import {
    ActionIcon,
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Group,
    Paper,
    Stack,
    Text,
    Title,
    Tooltip,
    TypographyStylesProvider,
    ScrollArea,
} from "@mantine/core";
import { Link } from 'react-router-dom';
import { IconArrowNarrowLeft, IconChevronLeft, IconChevronRight } from "@tabler/icons-react";

import { OfferButton, SelectCvsModal, UploadSuccessModal } from 'components';
import { locales, routes } from 'constants/index';
import { TsxIcons } from 'assets';

import { ArchiveOfferModal, ArchiveSuccessModal } from '../components';

import { TOfferUi } from './Offer.types';

import classNames from './Offer.module.scss';
import './offer.scss';


const leftIcon = <IconChevronLeft size='70%'/>
const rightIcon = <IconChevronRight size='70%'/>

const OfferUi: TOfferUi = ( {
    moduleName,
    isArchiveOfferModalVisible,
    isArchiveSuccessModalVisible,
    nextOfferId,
    previousOfferId,
    data: { offer, config, userInfo, modals, returnRoute },
    handlers,
} ) => {
    return (
        <ScrollArea h={ '100%' }>
            <Container py='md'>
                <Stack gap='xl'>
                    <Stack gap='xs'>
                        <Group>
                            <Button
                                component={ Link }
                                to={ returnRoute }
                                variant='subtle'
                                leftSection={ <IconArrowNarrowLeft/> }
                            >
                                { locales.pages.offer.sections.heading.goBack }
                            </Button>
                        </Group>

                        <Group gap='xs'>
                            <Tooltip label={ locales.offer.previousOffer }>
                                <ActionIcon
                                    variant='light'
                                    children={ leftIcon }
                                    onClick={ handlers.handleOfferLinkClick( previousOfferId ) }
                                />
                            </Tooltip>

                            <Paper flex={ 1 } className={ classNames.offerHeading }>
                                <Text size="sm" c='white'>
                                    {
                                        !!offer?.company?.name?.length
                                            ? offer?.company?.name
                                            : locales.pages.offer.sections.heading.bloomAlternance
                                    }
                                </Text>

                                <Group justify="space-between" mb="xs">
                                    <Title c={ 'white' } fw={ 500 } visibleFrom='sm'>
                                        { offer?.info?.title }
                                    </Title>
                                    <Title order={ 2 } c={ 'white' } fw={ 500 } hiddenFrom='sm'>
                                        { offer?.info?.title }
                                    </Title>
                                </Group>

                                <Text size={ "lg" } c='white'>
                                    { [
                                        offer?.info?.city,
                                        offer?.info?.postal_code,
                                        ...config?.degrees
                                            .filter( ( { key } ) => offer?.info?.education_level?.includes( key ) )
                                            .map( degree => degree?.label ),
                                        ...config?.domaines
                                            .filter( ( { key } ) => offer?.info?.work_fields?.includes( key ) )
                                            .map( degree => degree?.label ),
                                        ...config?.languages
                                            .filter( ( { key } ) => offer?.candidate?.languages?.includes( key ) )
                                            .map( degree => degree?.label ),
                                    ]?.join( ' • ' ) }
                                </Text>

                                <Badge>
                                    {
                                        config?.contracts?.find(
                                            (contract) => contract.value === offer?.info?.type
                                        )?.label
                                    }
                                </Badge>


                                <Group
                                    // ref={applyButtonFixedREF}
                                    justify='space-evenly'
                                    style={ {
                                        width: '100%',
                                        position: 'absolute',
                                        bottom: '-20px',
                                        left: 0,
                                        zIndex: 1,
                                    } }
                                >
                                    { userInfo?.entityId !== offer?.company?.entityId
                                        && <OfferButton
                                            leftSection={ <TsxIcons.BusinessUserCurriculum size={ 24 }/> }
                                            onClick={ handlers.handleAddResumesClick }
                                        >
                                            { locales.offer.addResume }
                                        </OfferButton>
                                    }

                                    { userInfo?.entityId === offer?.company?.entityId
                                        && <OfferButton
                                            onClick={ handlers.handleDuplicateOfferClick }
                                            leftSection={ <TsxIcons.DuplicateBox size={ 24 }/> }
                                        >
                                            { locales.offer.duplicate }
                                        </OfferButton>
                                    }

                                    { offer.deleted
                                        && userInfo?.entityId === offer?.company?.entityId
                                        && <OfferButton
                                            component={ Link }
                                            to={ `${ routes.offers.main }/${ routes.offers.create.main }/${ offer._id }/${ routes.offers.create.generalInfo }` }
                                            leftSection={ <TsxIcons.DuplicateBox size={ 24 }/> }
                                        >
                                            { locales.offer.republish }
                                        </OfferButton>
                                    }

                                    {moduleName !== 'archives'
                                        && userInfo?.entityId === offer?.company?.entityId
                                        && <OfferButton
                                            leftSection={ <TsxIcons.SearchUser size={ 24 }/> }
                                            component={ Link }
                                            to={`${routes.students.main}/${routes.students.offer}/${offer._id}`}
                                        >
                                            {locales.offer.viewCandidates}
                                        </OfferButton>
                                    }

                                    { !offer.deleted
                                        && userInfo?.entityId === offer?.company?.entityId
                                        && <OfferButton
                                            leftSection={ <TsxIcons.ArchiveBox size={ 24 }/> }
                                            onClick={ handlers.handleArchiveOfferClick }
                                        >
                                            { locales.offer.archiveOffer }
                                        </OfferButton>
                                    }
                                </Group>
                            </Paper>

                            <Tooltip label={ locales.offer.nextOffer }>
                                <ActionIcon
                                    variant='light'
                                    children={ rightIcon }
                                    onClick={ handlers.handleOfferLinkClick( nextOfferId ) }
                                />
                            </Tooltip>
                        </Group>

                    </Stack>

                    <Stack px='xl'>
                        { offer?.company?.description && <Stack>
                            <Title order={ 3 } c='darkgreen'>
                                { locales.pages.offer.sections.details.titles.whoAreWe }
                            </Title>
                            <Divider/>
                            <Text mod='data-div' component='div'>
                                <TypographyStylesProvider dangerouslySetInnerHTML={ { __html: offer?.company?.description } }/>
                            </Text>
                        </Stack> }

                        { offer?.candidate?.missions && <Stack>
                            <Title order={ 3 } c='darkgreen'>
                                { locales.pages.offer.sections.details.titles.missions }
                            </Title>
                            <Divider/>
                            <Text mod='data-div' component='div'>
                                <TypographyStylesProvider dangerouslySetInnerHTML={ { __html: offer?.candidate?.missions } }/>
                            </Text>
                        </Stack> }

                        { offer?.candidate?.profile && <Stack>
                            <Title order={ 3 } c='darkgreen'>
                                { locales.pages.offer.sections.details.titles.profile }
                            </Title>
                            <Divider/>
                            <Text mod='data-div' component='div'>
                                <TypographyStylesProvider dangerouslySetInnerHTML={ { __html: offer?.candidate?.profile } }/>
                            </Text>
                        </Stack> }

                        { ( !!offer?.candidate?.skills?.required?.length
                                || !!offer?.candidate?.skills?.important?.length
                                || !!offer?.candidate?.skills?.bonus?.length ) &&
                            <Stack>
                                <Title order={ 3 } c='darkgreen'>
                                    { locales.pages.offer.sections.details.titles.skills }
                                </Title>
                                <Divider/>
                                <TypographyStylesProvider>
                                    <Box>
                                        <Stack gap='md'>
                                            { !!offer?.candidate?.skills?.required?.length && <Stack gap='xs'>
                                                <Title order={ 3 }>
                                                    { locales.pages.offer.sections.details.labels.required }
                                                </Title>
                                                <Group>
                                                    {
                                                        config?.skills
                                                            .filter(
                                                                ( { key } ) => offer?.candidate?.skills?.required?.includes(
                                                                    key ) )
                                                            .map( ( skill, key ) => (
                                                                <Badge variant='skills-required'
                                                                       key={ key }>{ skill?.label }</Badge>
                                                            ) )
                                                    }
                                                </Group>
                                            </Stack> }

                                            { !!offer?.candidate?.skills?.important?.length && <Stack gap='xs'>
                                                <Title order={ 3 }>
                                                    { locales.pages.offer.sections.details.labels.important }
                                                </Title>
                                                <Group>
                                                    {
                                                        config?.skills
                                                            .filter(
                                                                ( { key } ) => offer?.candidate?.skills?.important?.includes(
                                                                    key ) )
                                                            .map( ( skill, key ) => (
                                                                <Badge variant='skills-important'
                                                                       key={ key }>{ skill?.label }</Badge>
                                                            ) )
                                                    }
                                                </Group>
                                            </Stack> }

                                            { !!offer?.candidate?.skills?.bonus?.length && <Stack gap='xs'>
                                                <Title order={ 3 }>
                                                    { locales.pages.offer.sections.details.labels.bonus }
                                                </Title>
                                                <Group>
                                                    {
                                                        config?.skills
                                                            .filter( ( { key } ) => offer?.candidate?.skills?.bonus?.includes(
                                                                key ) )
                                                            .map( ( skill, key ) => (
                                                                <Badge variant='skills-bonus'
                                                                       key={ key }>{ skill?.label }</Badge>
                                                            ) )
                                                    }
                                                </Group>
                                            </Stack> }
                                        </Stack>
                                    </Box>
                                </TypographyStylesProvider>
                            </Stack> }
                    </Stack>

                </Stack>
            </Container>

            { isArchiveOfferModalVisible && (
                <ArchiveOfferModal
                    isSingleOffer
                    onClose={ handlers.handleArchiveOfferCancel }
                    actionButtons={
                        <Group justify='center'>
                            <Button
                                variant="light"
                                onClick={ handlers.handleArchiveOfferCancel }
                            >
                                { locales.offer.cancelArchive }
                            </Button>

                            <Button
                                variant="primary"
                                onClick={ handlers.handleArchiveOfferConfirm }
                            >
                                { locales.offer.confirmArchive }
                            </Button>
                        </Group>
                    }
                />
            ) }

            { isArchiveSuccessModalVisible && (
                <ArchiveSuccessModal
                    isSingleOffer
                    onClose={ handlers.handleArchiveSuccessConfirm }
                />
            ) }

            <SelectCvsModal onSubmit={ handlers.handleAddResumesSubmit }/>

            <UploadSuccessModal
                title={ locales.resumes.addedSuccessfully }
                description={ locales.resumes.addedSuccessfullyDescription }
                onClose={ handlers.handleSuccessClose }
            />
        </ScrollArea>
    );
};

export default OfferUi;

