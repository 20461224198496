enum actionTypes {
    SET_ACTIVE_MODULE = 'SET_ACTIVE_MODULE',
    SET_WEBSITE_EDIT = 'SET_WEBSITE_EDIT',
    SET_TRIGGERS = 'SET_TRIGGERS',
    SET_EMAILS = 'SET_EMAILS',
    SET_EMAIL = 'SET_EMAIL',
    RESET_EMAIL = 'RESET_EMAIL',
    SET_CHOSEN_IDENTIFIER = 'SET_CHOSEN_IDENTIFIER',
    SET_USER_TRIGGER = 'SET_USER_TRIGGER',
    SET_CHOSEN_VARIABLES = 'SET_CHOSEN_VARIABLES',
    RESET_CHOSEN_TRIGGER = 'RESET_CHOSEN_TRIGGER',
    SET_CAN_RESET = 'SET_CAN_RESET',
    SET_IN_EDITION = 'SET_IN_EDITION',
    SET_IN_CREATION = 'SET_IN_CREATION',
    SET_EDIT_MODE = 'SET_EDIT_MODE',
    SET_MY_ACCOUNT = 'SET_MY_ACCOUNT',
    SET_YPAREO = 'SET_YPAREO',
    RESET_YPAREO = 'RESET_YPAREO',
    SET_WEBSITE_DESIGN = 'SET_WEBSITE_DESIGN',
    RESET_WEBSITE_DESIGN = 'RESET_WEBSITE_DESIGN',
    SET_MODALS = 'SET_MODALS',
    SET_UPLOAD_ATTACHMENT = 'SET_UPLOAD_ATTACHMENT',
    RESET = 'RESET',
}

export { actionTypes as settingsActionTypes };
