import { toast as reactToast } from 'react-toastify';

interface IToastOptions {
  message: string;
  type: 'info' | 'success' | 'warning' | 'error';
}

const useToast = () => {
  const toast = ({ type, message }: IToastOptions) => {
    reactToast.clearWaitingQueue();

    switch (type) {
      case 'info':
        reactToast.info(message);
        break;
      case 'success':
        reactToast.success(message);
        break;
      case 'warning':
        reactToast.warn(message);
        break;
      case 'error':
        reactToast.error(message);
        break;
      default:
        reactToast(message);
    }
  };

  return toast;
};

export default useToast;
