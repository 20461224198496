import { baseApi } from "utils";

import { postPipeApi } from "modules/Students/api";

import {
  TFetchOffersStatisticsApi,
  TFetchOffersApi,
  TFetchOfferApi,
  TPostOfferApi,
  TUpdateOfferApi,
  TFetchCitiesApi,
  TRemoveOfferApi,
  TCreateCheckoutApi,
  TCheckUserSubscriptionApi,
  TFetchSubscriptionsApi,
  TCheckoutApi,
  TFetchSkillsCategoriesApi,
  TFetchSkillsApi,
  TFetchSubEducationLevelApi,
} from "./api.types";

export const fetchOffersStatisticsApi: TFetchOffersStatisticsApi = () => {
  return baseApi().get("/offers/school/statistics");
};

export const fetchOffersApi: TFetchOffersApi = (params) => {
  return baseApi().get("/offers/private", { params });
};

export const fetchOfferApi: TFetchOfferApi = (offerId) => {
  return baseApi().get("/offers/single", { params: { offerId } });
};

export const postOfferApi: TPostOfferApi = (payload) => {
  return baseApi().post("/offers/school/create", payload);
};

export const updateOfferApi: TUpdateOfferApi = (payload) => {
  return baseApi().patch("/offers/school/update", payload);
};

export const fetchCheckoutAPI: TCheckoutApi = (params) => {
  return baseApi().get("/payment/checkout-portal", { params });
};

export const fetchCitiesApi: TFetchCitiesApi = (name: string) => {
  return baseApi().get(
    `https://geo.api.gouv.fr/communes?nom=${name}&fields=nom,centre,code,codesPostaux,codeDepartement,codeRegion,population&format=json&geometry=centre&boost=population&limit=5`
  );
};

export const removeOfferApi: TRemoveOfferApi = (payload) => {
  return baseApi().delete("/offers/school/archive", { params: { offer_ids: payload } });
};

export const createCheckoutApi: TCreateCheckoutApi = (payload) => {
  return baseApi().post("/payment/create-checkout-session", {
    customer_id: payload.customerId,
    lookup_key: payload.lookupKey,
    coupon_code: payload.couponCode,
  });
};

export const checkUserSubscriptionApi: TCheckUserSubscriptionApi = () => {
  return baseApi().get("/payment/subscription");
};

export const fetchSubscriptionsApi: TFetchSubscriptionsApi = () => {
  return baseApi().get("/payment/types");
};

// INFO: This endpoint gives permission to schools
export const fetchSkillsCategoriesApi: TFetchSkillsCategoriesApi = () => {
  return baseApi().get("/company/skills/categories");
};

// INFO: This endpoint gives permission to schools
export const fetchSkillsApi: TFetchSkillsApi = (params) => {
  return baseApi().get("/config/skills", { params });
};

export const fetchSubEducationLevelApi: TFetchSubEducationLevelApi = (degreeId) => {
  return baseApi().get('/school/subDegrees', { params : { degreeId } });
};

export {
  postPipeApi
}