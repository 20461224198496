import Joi from "joi";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { locales } from "constants/index";
import { useExtractServerError, useToast, useValidate } from "hooks";
import { IStoreState } from "store";

import { setIsLoading } from "modules/App/store/actions";
import { setModalData, setModalType, setModalValues } from "../store/actions";

import { interviewDetailsApi, sendEmailApi } from "./api";

import type {
    TValidateEmailModal,
    THandleRichTextChange,
    THandleTextInputChange,
    THandleTextInputBlur,
} from './ValidateEmailModal.types';
import { ValidateEmailModalEnums } from './ValidateEmailModal.enums';
import { ModalsTypesEnum } from "../Modals.enums";


import ValidateEmailModalUI from "./ValidateEmailModal.ui";
import ReactQuill from "react-quill";
import { fetchUserInfoApi } from "../../../modules/App/api";


const validationSchema: Record<ValidateEmailModalEnums, Joi.Schema> = {
    [ValidateEmailModalEnums.subject]: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    [ValidateEmailModalEnums.body]: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
};

const ValidateEmailModal: TValidateEmailModal = ({
    onSubmit,
}) => {
    dayjs.extend(advancedFormat);
    const modalType = ModalsTypesEnum.VALIDATE_EMAIL;

    //*Refs
    const reactQuillRef = useRef<ReactQuill|null>(null);

    //* Hooks Init
    const toast = useToast();
    const dispatch = useDispatch();
    const { extractErrorMessage } = useExtractServerError();
    const { errors, validate, setErrors } = useValidate();


    //* Redux State
    const modal = useSelector(({ modals: { [modalType]: modals } }: IStoreState) => modals, shallowEqual);


    //* Side Effects
    const initRedux = useCallback(() => {
        const { values } = modal;

        dispatch(setModalValues(modalType, {}));
    }, [modal.values])

    //* API Actions
    const sendEmail = useCallback(async () => {
        try {
            const { values, data } = modal;

            if (!data.fromEmail || !data.to) return toast({
                type: 'error',
                message: locales.modals.validateEmail.missingEmail
            });

            dispatch(setIsLoading(true));

            const payload = {
                receiver: data.to ?? '',
                subject: values.subject ?? '',
                htmlContent: values.body ?? '',
                senderName: data.fromName ?? '',
                senderEmail: data.fromEmail ?? '',
                replyTo: data.fromEmail ?? '',
            }

            await sendEmailApi(payload);

            toast({ type: 'success', message: locales.modals.validateEmail.success });

            batch(() => {
                dispatch(setIsLoading(false));
            });

            onSubmit && onSubmit();
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, onSubmit, modal.values]);

    //* Handlers
    const handleRichTextChange: THandleRichTextChange = useCallback((value) => {
        batch(() => {
            dispatch(setModalValues(modalType, { [ValidateEmailModalEnums.body]: value }));
        });

        setErrors((prevErrors) => ({ ...prevErrors, [ValidateEmailModalEnums.body]: '' }));
    }, [dispatch]);

    const handleSubmitClick = useCallback(async () => {
        await sendEmail();
    }, [dispatch, sendEmail]);

    const handleClose = useCallback(() => {
        dispatch(setModalType(modalType, false));
    }, [dispatch]);

    const handleReplaceTextVariables = useCallback(() => {
        if (!reactQuillRef || !reactQuillRef.current) return;
        // const editor = reactQuillRef.current?.getEditor();

        let subject = modal.values?.subject ?? '';
        let body = modal.values.body ?? '';

        for (const variable of modal.data.variables) {
            body = body.replaceAll(variable.label, variable.value);
            subject = subject.replaceAll(variable.label, variable.value);
        }

        dispatch(setModalValues(modalType, { subject, body }));
    }, [dispatch, reactQuillRef, modal]);

    const handleTextInputChange: THandleTextInputChange = useCallback(({ target: { name, value } } ) => {
        batch(() => {
            dispatch(setModalValues(modalType, { [name]: value }));
        });
    }, []);

    const handleTextInputBlur: THandleTextInputBlur = useCallback(({ target: { name, value } } ) => {

    }, []);


    //* Effects
    useEffect(() => {
        initRedux();
        handleReplaceTextVariables();
    }, []);

    useEffect(() => {
        if (!reactQuillRef || !reactQuillRef.current) return;
        handleReplaceTextVariables();
    }, [reactQuillRef]);


    //* Wrappers
    const values = useMemo(() => modal.values, [modal.values]);

    const data = useMemo(() => modal.data, [modal.data]);

    const handlers = useMemo(() => ({
        handleClose,
        handleSubmitClick,
        handleRichTextChange,
        handleTextInputChange,
        handleTextInputBlur,
    }), [
        handleClose,
        handleSubmitClick,
        handleRichTextChange,
        handleTextInputChange,
        handleTextInputBlur,
    ]);

    // if (!modal.isOpen) return null;

    return <ValidateEmailModalUI
        values={values}
        data={data}
        handlers={handlers}
        errors={errors}
        reactQuillRef={reactQuillRef}
    />;
};

export default memo(ValidateEmailModal);
