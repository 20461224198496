import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual, batch } from 'react-redux';
import Joi from 'joi';

import { useToast, useExtractServerError, useValidate } from 'hooks';
import { locales } from 'constants/index';

import { setIsLoading, setUserInfoAction } from 'modules/App/store/actions';
import { setEditMode, setMyAccount } from "modules/Settings/store/actions";

import { fetchUserInfoApi, updateUserInfoApi } from "modules/App/api";
import { updateDesignApi } from "../api";

import {
  IStoreState, THandleLoginDataChange,
  THandlePersonalDataChange,
  THandleYourEstablishmentChange
} from './CompanyProfile.types';

import { THandleSave, TUpdateDesign } from "../settings.types";

import CompanyProfileUi from './CompanyProfile.ui';

const personalDataValidationSchema = {
  firstname: Joi.string()
      .required()
      .max(100)
      .messages({ 'string.empty': locales.errors.required, }),
  lastname: Joi.string()
      .required()
      .max(100)
      .messages({ 'string.empty': locales.errors.required, }),
};

const loginDataValidationSchema = {
  email: Joi.string()
    .max(100)
    .messages({ 'string.empty': locales.errors.required, }),
  password: Joi.string()
    .max(100)
    .messages({ 'string.empty': locales.errors.required, }),
  confirm_password: Joi.string()
    .max(100)
    .messages({ 'string.empty': locales.errors.required, }),
};

const yourEstablishmentValidationSchema = {
  school: Joi.string()
      .required()
      .max(100)
      .messages({ 'string.empty': locales.errors.required, }),
  role: Joi.string()
      .allow('')
      .max(100),
  city: Joi.string()
      .allow('')
      .max(100),
  short_name: Joi.string()
      .allow('')
      .max(100),
  foundation_year: Joi.string()
      .allow('')
      .max(100),
  siret_number: Joi.string()
      .allow('')
      .max(100),
  siren_number: Joi.string()
      .allow('')
      .max(100),
};

const CompanyProfile = () => {
  //* Hooks
  const dispatch = useDispatch();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();
  const { errors, validate, setErrors } = useValidate();

  //* Redux State
  const userInfo = useSelector(({ app: { userInfo } }: IStoreState) => userInfo, shallowEqual);
  const settings = useSelector(({ settings }: IStoreState) => settings, shallowEqual);

  //* Side Effects
  const updateMyAccount: TUpdateDesign = useCallback(async (type) => {
    try {
      const payload = Object.assign(
        {},
        type === 'personalData' && settings.myAccount.personalData &&
          { ...settings.myAccount.personalData },
        type === 'yourEstablishment' && settings.myAccount.yourEstablishment &&
          { ...settings.myAccount.yourEstablishment },
      );

      const foundationYear = Object.assign(
        {},
        type === 'yourEstablishment' && settings.myAccount.yourEstablishment &&
          { foundation_year: settings.myAccount.yourEstablishment.foundation_year }
      )

      const validationSchema = Object.assign(
        {},
        type === 'personalData' && personalDataValidationSchema,
        type === "loginData" && loginDataValidationSchema,
        type === "yourEstablishment" && yourEstablishmentValidationSchema,
      )

      const isValid = validate({
        data: { ...payload } as any,
        validationSchema: { ...validationSchema } as any,
      });

      if (!isValid) return;

      dispatch(setIsLoading(true));

      await updateDesignApi(foundationYear as any);
      await updateUserInfoApi(payload as any);
      const userInfoData = await fetchUserInfoApi();
      const { data: { data: newUserInfo } } = userInfoData;

      batch(() => {
        dispatch(setUserInfoAction(newUserInfo));
        dispatch(setIsLoading(false));
        dispatch(setEditMode({ type, state: false }));
      });
      setErrors({});
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings.myAccount]);

  const fetchUserInfo = useCallback(async () => {
    try {
      dispatch(setIsLoading(true));

      const userInfoData = await fetchUserInfoApi();
      const { data: { data: newUserInfo } } = userInfoData;

      batch(() => {
        dispatch(setUserInfoAction(newUserInfo));
        dispatch(setIsLoading(false));
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  }, [dispatch, settings])

  //* Handlers
  const handlePersonalDataChange: THandlePersonalDataChange = useCallback((type) => ({ target: { value } }) => {
    batch(() => {
      dispatch(setMyAccount({
        personalData: {
          ...settings.myAccount.personalData,
          [type]: value,
        }
      }));
      if (!settings.editMode.personalData) dispatch(setEditMode({ type: 'personalData', state: true }));
    });
  }, [dispatch, settings.myAccount.personalData, settings.editMode]);

  const handleLoginDataChange: THandleLoginDataChange = useCallback((type) => ({ target: { value } }) => {
    batch(() => {
      dispatch(setMyAccount({
        loginData: {
          ...settings.myAccount.loginData,
          [type]: value,
        }
      }));
      if (!settings.editMode.loginData) dispatch(setEditMode({ type: 'loginData', state: true }));
    });
  }, [dispatch, settings.myAccount.loginData, settings.editMode]);

  const handleYourEstablishmentChange: THandleYourEstablishmentChange = useCallback((type) => ({ target: { value } }) => {
    batch(() => {
      dispatch(setMyAccount({
        yourEstablishment: {
          ...settings.myAccount.yourEstablishment,
          [type]: value,
        }
      }));
      if (!settings.editMode.yourEstablishment) dispatch(setEditMode({ type: 'yourEstablishment', state: true }));
    });
  }, [dispatch, settings.myAccount.yourEstablishment, settings.editMode]);

  const handleSave: THandleSave = useCallback((type) => () => {
    switch (type) {
      case "personalData":
      case "yourEstablishment":
        updateMyAccount(type);
        break;

      default:
        break;
    }
  }, [dispatch, updateMyAccount])

  //* Effects
  useEffect(() => {
    if (!settings) return;

    if (!userInfo?.name || !userInfo?.contactInfo || !userInfo?.info) {
      fetchUserInfo()
      return;
    }

    dispatch(setMyAccount({
      personalData: {
        ...settings.myAccount?.personalData,
        firstname: userInfo.name.firstname ?? "",
        lastname: userInfo.name.lastname ?? "",
      },
      loginData: {
        email_address: userInfo.contactInfo.emailAddress ?? "",
      },
      yourEstablishment: {
        ...settings.myAccount?.yourEstablishment,
        school: userInfo?.info.school ?? "",
        siret_number: userInfo?.info.siret_number ?? "",
        siren_number: userInfo?.info.siren_number ?? "",
        city: userInfo?.info.city ?? "",
        role: userInfo?.info.role ?? "",
      },
    }));
  }, [
    userInfo?.name,
    userInfo?.contactInfo,
    userInfo?.info
  ]);

  //* Component
  const data = useMemo(() => ({
    isSchool: userInfo?.userType === "school",
    myAccount: settings.myAccount,
    editMode: settings.editMode,
  }), [
    userInfo,
    settings.myAccount,
    settings.editMode,
  ]);

  const options = useMemo(() => ({}), []);

  const handlers = useMemo(() => ({
    handlePersonalDataChange,
    handleLoginDataChange,
    handleYourEstablishmentChange,
    handleSave,
  }), [
    handlePersonalDataChange,
    handleLoginDataChange,
    handleYourEstablishmentChange,
    handleSave,
  ]);

  return (
    <CompanyProfileUi
      errors={errors}
      data={data}
      options={options}
      handlers={handlers}
    />
  );
};

export default CompanyProfile;
