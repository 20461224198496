import { forwardRef } from "react";
import ClassNames from "classnames";
import { flexRender, Row } from "@tanstack/react-table";

import type { IPropsUI, TTableUI } from './Table.types';

import './table.scss';

const TableUI: TTableUI = ({
    table,
    rowVirtualizer,
    rows,
    withHeader,
}, ref) => {
    return <div className="table__container" ref={ref}>
        <table className="table">
            {withHeader && <thead className="thead">
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} className='tr'>
                        {headerGroup.headers.map(header => {
                            return (
                                <th
                                    key={header.id}
                                    className={ClassNames({
                                        th: true,
                                        clickable: header.column.getCanSort(),
                                    })}
                                    onClick={header.column.getToggleSortingHandler()}
                                    style={{
                                        flex: header.column.columnDef.size,
                                        minWidth: header.column.columnDef.minSize,
                                    }}
                                >
                                    <div className='th__content'>{flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}</div>
                                    {header.column.getCanSort() && <div className={ClassNames({
                                        th__filter: true,
                                        [header.column.getIsSorted() as string]: true
                                    })}/>}
                                </th>
                            )
                        })}
                    </tr>
                ))}
            </thead>}
            <tbody
                className="tbody"
                style={{ height: `${rowVirtualizer.getTotalSize()}px` }} // KEEP: tells scrollbar how big the table is
            >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
                const row = rows[virtualRow.index] as Row<any>
                return (
                    <tr
                        data-index={virtualRow.index} // KEEP: needed for dynamic row height measurement
                        ref={node => rowVirtualizer.measureElement(node)} // KEEP: measure dynamic row height
                        key={row.id}
                        className={ClassNames({
                            'tr tbody__tr': true,
                            contrast: (virtualRow.index + 1) % 2 === 0,
                        })}
                        style={{ transform: `translateY(${virtualRow.start}px)` }} // KEEP: this should always be a `style` as it changes on scroll
                    >
                        {row.getVisibleCells().map(cell => {
                            return (
                                <td
                                    key={cell.id}
                                    className='td'
                                    style={{
                                        flex: cell.column.columnDef.size,
                                        minWidth: cell.column.columnDef.minSize,
                                    }}
                                >
                                    <div className='td__content'>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext()
                                        )}
                                    </div>
                                </td>
                            )
                        })}
                    </tr>
                )
            })}
            </tbody>
        </table>
    </div>;
};

//WARN: do no memo this shit, the vitualization stops working if you do
export default forwardRef<HTMLDivElement, IPropsUI>(TableUI);

