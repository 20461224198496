import { baseApi } from 'utils';

import { TFetchAttachmentsApi, TUploadAttachmentsApi } from './api.types';

export const fetchAttachmentsApi: TFetchAttachmentsApi = () => {
  return baseApi().get('/attachments/private/fetch');
};

export const uploadAttachmentApi: TUploadAttachmentsApi = (payload) => {
  return baseApi().post('/attachments/private/upload', payload);
};
