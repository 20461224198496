"use client";

import { createTheme, DEFAULT_THEME, mergeMantineTheme } from "@mantine/core";

import shameTheme from "./shameTheme";
import colors from "./colors";
import typography from "./typography";
import components from "./components";

const themeMerger = createTheme({
	...shameTheme,
	...colors,
	...typography,
	...components,
});

const theme = mergeMantineTheme(
	DEFAULT_THEME,
	themeMerger,
);

export default theme;