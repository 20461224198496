import { generatePath, useNavigate, useParams } from "react-router-dom";
import { memo, useCallback, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { locales, routePaths } from "constants/index";
import { useExtractServerError, useGetNextPreviousItems, useToast } from "hooks";

import { setIsLoading } from "modules/App/store/actions";
import { resetProfile } from "modules/Students/store/actions";

import { updatePipeApi } from "../../api";

import type { IStoreState } from "store";
import type {
    THandleCandidateActionClick,
    THeader
} from "./Header.type";

import HeaderUI from "./Header.ui";

const Header: THeader = () => {
    //* Hooks
    const toast = useToast();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { extractErrorMessage } = useExtractServerError();
    const getNextPreviousItems = useGetNextPreviousItems();
    const { id: profileId = '', offerId = '' } = useParams();


    //* Redux State
    const pipes = useSelector(({ candidates: { pipes } }: IStoreState) => pipes, shallowEqual);
    const studentInfo = useSelector(({ candidates: { newProfile: { studentInfo } } }: IStoreState) => studentInfo, shallowEqual);
    const config = useSelector(({ app: { config } }: IStoreState) => config, shallowEqual);

    //* Memos
    const applicationsIds = useMemo(() => pipes
        .filter((pipe) => pipe.pipeInfoId === studentInfo?.pipe.pipeInfoId)
        .map((pipe) => pipe._id), [pipes, studentInfo]);

    const { nextId, previousId } = useMemo(() => getNextPreviousItems(applicationsIds, profileId), [applicationsIds, profileId]);


    // API Actions
    const updatePipe = useCallback(async (studentId: string, pipeInfoId: string) => {
        try {
            dispatch(setIsLoading(true));

            await updatePipeApi({ ids: [studentId], pipeInfoId });

            batch(() => {
                dispatch(resetProfile());
                dispatch(setIsLoading(false));
            });

            if (!nextId || nextId === '-1') {
                navigate(generatePath(routePaths.students.offerPipe, {offerId}));
            } else {
                navigate(generatePath(routePaths.students.profile, { offerId, id: nextId }));
            }

            toast({ type: 'success', message: locales.general.success })
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, offerId, nextId])



    //* Handlers
    const handleCandidateActionClick: THandleCandidateActionClick = useCallback((action) => async () => {
        if (!profileId) {
            navigate(generatePath(routePaths.students.offerPipe, { offerId }));
            toast({ type: 'error', message: locales.errors.general });
            return;
        }

        await updatePipe(pipes.find((pipe) => pipe._id === profileId)?._id || '', action);
    }, [studentInfo]);


    const data = useMemo(() => ({
        image: studentInfo?.info.photo_url ?? "",
        firstname: studentInfo?.name.firstname ?? "",
        lastname: studentInfo?.name.lastname ?? "",
        phoneNumber: (studentInfo?.contact_info.phone_number ?? "").toString() ?? "",
        emailAddress: studentInfo?.contact_info.email_address ?? "",
        city: studentInfo?.info.address.city ?? "",
        postalCode: (studentInfo?.info.address.postal_code ?? "").toString() ?? "",
        workFields: (studentInfo?.student_info.info?.work_fields ?? [])?.map((work_field_key) =>
            config.domaines.find(domaine => domaine.key === work_field_key)?.label ?? ''
        ) ?? [],
        socialMedia: !studentInfo?.info.media_urls ? []
            : Object.entries(studentInfo?.info.media_urls)
                .map((entry) => ({
                    label: entry[0],
                    key: entry[0],
                    value: entry[1],
                })),
    }), [
        studentInfo
    ]);

    const handlers = useMemo(() => ({
        handleCandidateActionClick,
    }), [
        handleCandidateActionClick,
    ]);

    return <HeaderUI data={data} handlers={handlers} />
};

export default memo(Header);
