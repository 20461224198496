import { memo } from "react";
import ClassNames from "classnames";

import { locales } from "constants/index";
import { Icons } from "assets";

import { Button } from "components";

import type { THeaderUI } from "./Header.type";
import { HeaderActionsEnum } from "./Header.enum";

import './header.scss';

const HeaderUI: THeaderUI = ({
    data: {
        image,
        firstname,
        lastname,
        phoneNumber,
        emailAddress,
        city,
        postalCode,
        workFields,
        socialMedia,
    },
    handlers: {
        handleCandidateActionClick,
    }
}) => {

    return <div className='np-header'>
        <div className='np-header__content'>

            <div className='np-header__userContainer'>
                <div className='np-header__userPhotoContainer'>
                    {!!image
                        ? <img
                            className='np-header__userPhoto'
                            src={!!image ? image : Icons.ManAvatar}
                            alt={locales.student.profilePicture}
                        />
                        : <div className='np-header__userInitials'>
                            {(!!firstname && firstname[0]) ? firstname[0] : '.'}
                            {(!!lastname && lastname[0]) ? lastname[0] : '.'}
                        </div>
                    }
                </div>

                <div className='np-header__userInfo'>
                    <div className='np-header__userNameContainer'>
                        {!!firstname && <div className='np-header__userName firstname'>
                            {firstname}
                        </div>}
                        {!!lastname && <div className='np-header__userName lastname'>
                            {lastname}
                        </div>}
                    </div>

                    <div className='np-header__userDetailContainer'>
                        {!!phoneNumber && <a
                            href={`tel: ${phoneNumber}`}
                           className='np-header__userDetail'
                        >
                            <div className='np-header__userDetailText link'>
                                {phoneNumber}
                            </div>
                        </a>}

                        {!!emailAddress && <a
                            href={`mailto: ${emailAddress}`}
                            className='np-header__userDetail'
                        >
                            <div className='np-header__userIcon email'/>
                            <div className='np-header__userDetailText link'>
                                {emailAddress}
                            </div>
                        </a>}

                        {(!!city || !!postalCode) && <div className='np-header__userDetail'>
                            <div className='np-header__userDetailText city'>
                                {city} {city && postalCode && "-"} {postalCode}
                            </div>
                        </div>}
                    </div>

                </div>
            </div>

            <div className='np-header__actionsContainer'>
                <div className='np-header__userExtraInfoGroup'>
                    {workFields && <div className='np-header__userExtraInfo'>
                        <div className='np-header__userExtraInfoText'>{locales.student.workField} :</div>
                        {workFields.map((workField, index) =>
                            <div key={index} className='np-header__userExtraInfoText value'>{workField}</div>
                        )}
                    </div>}
                    {!!socialMedia.length && <div className='np-header__userExtraInfo'>
                        <div className='np-header__userExtraInfoText'>{locales.student.extraInformation} :</div>
                        {socialMedia.map((link, index) =>
                            (!!link.value && <a
                                href={link.value}
                                target='_blank'
                                key={index}
                                className={ClassNames({
                                    'np-header__userExtraInfoIcon': true,
                                    [link.label]: !!link.label && !!link.value
                                })}
                            />)
                        )}
                    </div>}
                </div>

                <div className='np-header__actionSection'>
                    <Button
                        variant="link"
                        size="link-icon"
                        className='np-header__action'
                        onClick={handleCandidateActionClick(HeaderActionsEnum.toCallback)}
                    >
                        <div className='np-header__actionIcon call' />
                        <div className='np-header__actionText'>
                            {locales.student.actions.toCallback}
                        </div>
                    </Button>
                    <Button
                        variant="link"
                        size="link-icon"
                        className='np-header__action'
                        onClick={handleCandidateActionClick(HeaderActionsEnum.interview)}
                    >
                        <div className='np-header__actionIcon chat' />
                        <div className='np-header__actionText'>
                            {locales.student.actions.interview}
                        </div>
                    </Button>
                    <Button
                        variant="link"
                        size="link-icon"
                        className='np-header__action'
                        onClick={handleCandidateActionClick(HeaderActionsEnum.accept)}
                    >
                        <div className='np-header__actionIcon like' />
                        <div className='np-header__actionText'>
                            {locales.student.actions.accept}
                        </div>
                    </Button>
                    <Button
                        variant="link"
                        size="link-icon"
                        className='np-header__action'
                        onClick={handleCandidateActionClick(HeaderActionsEnum.refuse)}
                    >
                        <div className='np-header__actionIcon dislike' />
                        <div className='np-header__actionText'>
                            {locales.student.actions.refuse}
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    </div>;
};

export default memo(HeaderUI);
