import React, { useRef } from "react";
import classNames from 'classnames';

import { Label } from 'components';

import { TFormControl } from './FormControl.types';

import './form-control.scss';

const FormControl: TFormControl = ({
  children,
  id = '',
  label = '',
  className = '',
  elementClassName = '',
  isDisabled = false,
  isRequired = false,
  withBackground = true,
  withRef = false,
  note = '',
  error = '',
}) => {
  const childRef = useRef<any>(null);

  const generalChange = (e: any) => {
    e.preventDefault();
    if (!withRef || !childRef || !childRef.current) return;
    childRef.current?.focus();
  }

  const formControlClasses = classNames({
    'form-control': true,
    'form-control--error': error.length > 0,
    [className]: className.length > 0,
  });

  let childWithRef = children;

  if (withRef) {
    childWithRef = React.Children.map(children, (child: any) =>
      React.cloneElement(child, { ref: childRef })
    );
  }

  return (
    <div className={formControlClasses} id={id}>
      <div
        className={classNames({
          'form-control__element': true,
          'form-control__element--with-background': withBackground,
          [elementClassName]: !!elementClassName,
        })}
      >
        {childWithRef}

        {label && <Label
          id={id}
          className="form-control__label"
          isError={error.length > 0}
          isDisabled={isDisabled}
          isRequired={isRequired}
          children={label}
          onClick={generalChange}
        />}

        <span className="form-control__bottom-line" />
      </div>

      {note.length > 0 && <div className="form-control__note">{note}</div>}

      {error.length > 0 && <div className="form-control__error">{error}</div>}
    </div>
  );
};

export default FormControl;
