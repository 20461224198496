import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import 'dayjs/locale/fr';

import TableExt from "components/Table2/components";

import { TSelectCvsModalColumns } from "./SelectCvsModal.types";

dayjs.extend(relativeTime);

export const tableColumns: TSelectCvsModalColumns = [
    {
        id: 'select-col',
        accessorFn: row => row._id,
        cell: ({ row }) => <TableExt.Checkbox
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
        />,
        size: 0,
        minSize: 62,
        enableSorting: false,
    },
    {
        id: 'info.name',
        accessorFn: row => row.name,
        size: 2,
    }
];
