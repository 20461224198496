import classNames from 'classnames';

import { TChip } from './Chip.types';

import './chip.scss';

const Chip: TChip = ({
  children,
  isChecked = false,
  isRounded = false,
  size = 'regular',
  color = 'wild-sand',
  className = '',
  onClick,
}) => {
  const classes = classNames({
    chip: true,
    'chip--checked': isChecked,
    'chip--rounded': isRounded,
    [`chip--${size}`]: true,
    [`chip--${color}`]: true,
    [className]: className.length > 0,
  });

  return (
    <div className={classes} role="presentation" onClick={onClick}>
      {children}
    </div>
  );
};

export default Chip;
