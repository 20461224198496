import { RESET_FILTER_FILTER, SET_FILTER_FILTER, SET_FILTER_PAGINATION, filterActionTypes } from './action-types';

import { TResetFilterFilter, TSetFilterFilter, TSetFilterPagination, TResetStateAction } from './store.types';

export const setFilterPagination: TSetFilterPagination = ({
  moduleName,
  payload,
}) => ({
  type: SET_FILTER_PAGINATION,
  moduleName,
  payload,
});

export const setFilterFilter: TSetFilterFilter = ({ moduleName, payload }) => ({
  type: SET_FILTER_FILTER,
  moduleName,
  payload,
});

export const resetFilterFilter: TResetFilterFilter = ({ moduleName }) => ({
  type: RESET_FILTER_FILTER,
  moduleName,
});

export const resetStateFilter: TResetStateAction = () => ({
  type: filterActionTypes.RESET,
});
