import { PaymentElement } from '@stripe/react-stripe-js';

import { Button, Loader, FormLayout } from 'components';

import { locales } from 'constants/index';

import { TCreateOfferPaymentUi } from './CreateOfferPayment.types';

const CreateOfferPaymentUi: TCreateOfferPaymentUi = ({
  isLoading,
  handlers: { handlePaymentConfirmation },
}) => {
  return (
    <>
      {isLoading && <Loader />}

      <FormLayout title={locales.createOffer.payment}>
        <PaymentElement id="payment-element" className="payment-element" />
      </FormLayout>

      <div className="create-offer__actions">
        <Button
          variant="primary"
          onClick={handlePaymentConfirmation}
        >
          {locales.createOffer.pay}
        </Button>
      </div>
    </>
  );
};

export default CreateOfferPaymentUi;
