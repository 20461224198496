import { ActionIcon, Checkbox } from '@mantine/core';
import { IconAddressBook } from "@tabler/icons-react";
import { Link } from 'react-router-dom';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import 'dayjs/locale/fr';

import TableExt from "components/Table2/components";
import { locales, routes } from "constants/index";

import type { TCVThequeColumns } from "./types";


dayjs.extend(relativeTime);
export const COLUMNS: TCVThequeColumns = [
    {
        id: 'select-col',
        header: ({ table }) => <Checkbox
            disabled={!!table.getToggleAllRowsSelectedHandler()}
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
        />,
        accessorFn: row => row.candidateId,
        cell: ({ row }) => (
            <TableExt.Checkbox
                isChecked={row.getIsSelected()}
                isDisabled={!row.getCanSelect()}
                onChange={row.getToggleSelectedHandler()}
            />
        ),
        size: 0,
        minSize: 70,
        enableSorting: false,
    },
    {
        id: 'id',
        header: locales.pages.cvTheque.sections.table.head.name,
        accessorFn: row => `${row.firstname} ${row.lastname} ${row.email_address} ${row.phone_number}`,
        cell: info => <TableExt.Profile
            profile={{
                firstname: info.row.original.firstname,
                lastname: info.row.original.lastname,
                email: info.row.original.email_address,
                phone_number: info.row.original.phone_number,
            }}
        />,
        size: 2,
        minSize: 160,
    },
    {
        id: 'row.offer',
        header: locales.pages.cvTheque.sections.table.head.offer,
        accessorFn: row => row.offer,
        size: 3,
    },
    {
        id: 'row.created_date',
        header: locales.pages.cvTheque.sections.table.head.addedDate,
        accessorFn: row => row.created_date,
        cell: info => dayjs(info.getValue<string>()).format("DD/MM/YY"),
        minSize: 160,
        size: 0,
    },
    {
        id: 'view-candidate',
        header: undefined,
        accessorFn: row => row.candidateId,
        cell: info =>
            <ActionIcon
                // aria-disabled
                disabled={ !info.getValue<string>() || !info.row.original.offerId }
                component={ Link }
                to={ `${routes.students.main}/${routes.students.offer}/${info.row.original.offerId}/${info.getValue<string>()}` }
            >
                <IconAddressBook/>
            </ActionIcon>,
        size: 0,
        minSize: 75,
        enableSorting: false,
    },
];
