import {
  SET_OFFERID,
  SET_OPTIONS_OFFERS,
  SET_PIPES,
  SET_PIPES_BY_COLUMN,
  SET_SELECTED_CV_URL,
  SET_SELECTED_CANDIDATES,
  CLEAR_SELECTED_CANDIDATES,
  TRIGGER_REFRESH_PIPES,
  SET_STUDENT_INFO,
  SET_PROFILE,
  SET_DRAGGED_ID,
  RESET_DRAGGED_ID,
  studentsActionTypes,
} from './action-types';

import {
  TClearSelectedCandidatesAction,
  TSetOfferIdAction,
  TSetOptionsOffersAction,
  TSetPipesAction,
  TSetPipesByColumnAction,
  TSetSelectedCandidatesAction,
  TSetSelectedCvUrlAction,
  TTriggerRefreshPipesAction,
  TSetStudentInfoAction,
  TSetProfileAction,
  TResetProfileAction,
  TSetDraggedIdAction,
  TResetDraggedIdAction,
  TResetStateAction,
  TSetProfileActionsAction,
  TResetProfileActionsAction,
  TSetProfileCVAction,
  TSetProfileCommentsAction,
  TEditProfileCommentsAction,
  TSetProfileHistoryAction, TSetProfileEmailsAction,
} from './store.types';

import { setIsLoading } from "modules/App/store/actions";

export {
  setIsLoading
}

export const setPipes: TSetPipesAction = (pipes) => {
  return {
    type: SET_PIPES,
    payload: pipes,
  };
};

export const setOptionsOffers: TSetOptionsOffersAction = (payload) => {
  return {
    type: SET_OPTIONS_OFFERS,
    payload
  };
};

export const setOfferId: TSetOfferIdAction = (payload) => {
  return {
    type: SET_OFFERID,
    payload
  };
};

export const setSelectedCvUrl: TSetSelectedCvUrlAction = (payload) => {
  return {
    type: SET_SELECTED_CV_URL,
    payload
  };
};

export const setPipesByColumn: TSetPipesByColumnAction = (payload) => {
  return {
    type: SET_PIPES_BY_COLUMN,
    payload
  };
};

export const setSelectedCandidates: TSetSelectedCandidatesAction = (selectedCandidates) => {
  return {
    type: SET_SELECTED_CANDIDATES,
    payload: selectedCandidates
  };
};

export const clearSelectedCandidates: TClearSelectedCandidatesAction = () => {
  return {
    type: CLEAR_SELECTED_CANDIDATES,
  };
};

export const triggerRefreshPipes: TTriggerRefreshPipesAction = () => {
  return {
    type: TRIGGER_REFRESH_PIPES,
  };
};

export const setDraggedId: TSetDraggedIdAction = (payload) => {
  return {
    type: SET_DRAGGED_ID,
    payload
  };
};

export const resetDraggedId: TResetDraggedIdAction = () => {
  return {
    type: RESET_DRAGGED_ID,
  };
};

export const setStudentInfo: TSetStudentInfoAction = (payload) => {
  return {
    type: SET_STUDENT_INFO,
    payload
  };
};

export const setProfile: TSetProfileAction = (payload) => {
  return {
    type: SET_PROFILE,
    payload
  };
};

export const setProfileActions: TSetProfileActionsAction = (payload) => {
  return {
    type: studentsActionTypes.SET_PROFILE_ACTIONS,
    payload
  };
};

export const resetProfileActions: TResetProfileActionsAction = () => {
  return {
    type: studentsActionTypes.RESET_PROFILE_ACTIONS
  };
};

export const resetProfile: TResetProfileAction = () => {
  return {
    type: studentsActionTypes.RESET_PROFILE,
  };
};

export const resetStateStudents: TResetStateAction = () => {
  return {
    type: studentsActionTypes.RESET,
  };
};


export const setProfileCV: TSetProfileCVAction = (payload) => {
  return {
    type: studentsActionTypes.SET_PROFILE_CV,
    payload
  }
}
export const setProfileComments: TSetProfileCommentsAction = (payload) => {
  return {
    type: studentsActionTypes.SET_PROFILE_COMMENTS,
    payload
  }
}
export const editProfileComments: TEditProfileCommentsAction = (payload) => {
  return {
    type: studentsActionTypes.EDIT_PROFILE_COMMENTS,
    payload
  }
}
export const setProfileHistory: TSetProfileHistoryAction = (payload) => {
  return {
    type: studentsActionTypes.SET_PROFILE_HISTORY,
    payload
  }
}
export const setProfileEmails: TSetProfileEmailsAction = (payload) => {
  return {
    type: studentsActionTypes.SET_PROFILE_EMAILS,
    payload
  }
}
