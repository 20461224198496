import { memo } from "react";
import classNames from 'classnames';

import { TCheckbox } from './Checkbox.types';

import './checkbox.scss';

const Checkbox: TCheckbox = ({
  id = '',
  name,
  label,
  isChecked,
  error = '',
  className = '',
  labelStyle = '',
  isDisabled = false,
  isError = false,
  onChange,
}) => {
  const checkboxClassNames = classNames({
    checkbox: true,
    [className]: className.length > 0,
  });

  const labelStyles = classNames({
    checkbox__label: true,
    disabled: isDisabled,
    reverse: false, //TODO: Add boolean prop for this.
    [labelStyle]: !!labelStyle,
  });

  const checkStyles = classNames({
    checkbox__check: true,
    error: !!error || isError,
    disabled: isDisabled,
  });

  return (
    <div className={checkboxClassNames}>
      <label
        htmlFor={id}
        className={labelStyles}
        onClick={(e) => {
          e.preventDefault();
          !isDisabled && onChange && onChange(e);
        }}
      >
        {label}
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={isChecked}
          disabled={isDisabled}
          className="checkbox__input"
          onChange={(e) => {
            e.preventDefault();
            !isDisabled && onChange && onChange(e);
          }}
        />
        <span className={checkStyles}/>
      </label>
      {!!error && <div className="checkbox__error">{error}</div>}
    </div>
  );
};

export default memo(Checkbox);
