import { memo, useMemo } from "react";
import ClassNames from "classnames";

import {Badge, Button, Card} from "components";
import { locales } from "constants/index";

import { TEditList } from './EditList.types';

import './edit-list.scss';

const EditList: TEditList = ({
    className = '',
    type = 'courses',
    list = [],
    handlers
}) => {

    const title = useMemo(() => type === 'courses'
        ? locales.settings.customWebsite.course.index
        : locales.settings.myAccount.users.user, [type]);

    const classes = ClassNames({
        'edit-list': true,
        [className]: !!className,
    });


    return (
        <div className={classes}>
            {list.map((item, index) =>
                <Card key={index} isColumns={false}
                    title={`${title} ${index + 1} : ${item.title}`}
                    showHeaderAction={!!item.badge}
                    headerAction={
                        <Badge
                            size='small'
                            value={item.badge}
                            {...!item.isDisabled && {style: { secondary: '#ffc107' }}}
                        />
                    }
                    className='edit-list__listItem__item'
                    cardClassName='edit-list__listItem'
                >
                    <div className='edit-list__listItem__description'>
                        {item.description.map(({label, value}, index) =>
                            <span
                                key={index}
                                className='edit-list__listItem__descriptionItem'
                            >{label} {value}</span>
                        )}
                    </div>

                    <div className='edit-list__buttonGroup'>
                        {handlers.customHandlers?.map((customHandler, index) =>
                            !item.isDisabled && <Button
                                key={index}
                                variant='link'
                                isDisabled={item.isDisabled}
                                children={customHandler.label}
                                onClick={customHandler.handler(item.id)}
                            />
                        )}
                        {handlers.handleEdit &&
                            <Button
                                variant='link'
                                children={locales.general.edit}
                                onClick={handlers.handleEdit(item.id)}
                            />
                        }
                        {handlers.handleDelete &&
                            <Button
                                variant='link'
                                children={locales.general.delete}
                                onClick={handlers.handleDelete(item.id)}
                            />
                        }
                    </div>
                </Card>
            )}
        </div>
    );
};

export default memo(EditList);
