import axios from "axios";
import { memo, useCallback, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useExtractServerError, useToast } from "hooks";
import { IStoreState } from "store";

import { fetchScoringApi } from "../../../api";
import { setIsLoading } from "../../../../App/store/actions";

import ColumnUI from "./Column.ui";
import { TColumn } from "./Column.types";
import { setSelectedCandidates } from "../../../store/actions";

const Column: TColumn = ({ className = "", data: { column }, handlers }) => {
    // * Hook Init
    const dispatch = useDispatch();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();
    const cancelToken = axios.CancelToken.source();

    // * Redux State
    const offer_id = useSelector(({ candidates: { offerId } }: IStoreState) => offerId, shallowEqual);
    const pipesByColumn = useSelector(({ candidates: { pipesByColumn } }: IStoreState) => pipesByColumn, shallowEqual);
    const selectedCandidates = useSelector(
        ({ candidates: { selectedCandidates } }: IStoreState) => selectedCandidates,
        shallowEqual
    );

    // * Memos
    const candidateIds = useMemo( () => pipesByColumn[ column.value ]?.map( item => item._id ) ?? [], [ column.value, pipesByColumn ] );
    const selectedColumnCandidateIds = useMemo( () => candidateIds.filter( item => selectedCandidates.includes( item ) ), [ candidateIds, selectedCandidates ] );
    const pipeInfoId = useMemo(() => column.value ?? [], [offer_id, column.value]);
    const pipeItems = useMemo(() => pipesByColumn[pipeInfoId] ?? [], [offer_id, pipesByColumn, pipeInfoId]);


    // * Side Effects
    // const fetchScoring = async () => {
    //     dispatch(setIsLoading(true, 'thumb'));
    //
    //     try {
    //         const {
    //             data: { data },
    //         } = await fetchScoringApi({ offer_id, pipeInfoId, cancelToken: cancelToken.token });
    //
    //         const pipeInfo: IPipe[] = pipeItems.map((value) => ({
    //             ...value,
    //             score: data.find(e => e._id === value._id)?.score,
    //         }));
    //
    //         dispatch(setPipesByColumn({
    //             ...pipesByColumn,
    //             [pipeInfoId]: pipeInfo
    //         }));
    //     } catch (error) {
    //         if (axios.isCancel(error)) return;
    //         const errorMessage = extractErrorMessage(error);
    //         toast({ type: 'error', message: errorMessage });
    //     }
    //
    //     dispatch(setIsLoading(false, 'thumb'));
    // };


    // * Handlers
    const handleSelectColumn = useCallback( () => () => {
        if ( !candidateIds.length ) return;

        const allCandidatesAreSelected = candidateIds.every((id) => {
            return selectedCandidates.includes(id);
        });

        if (allCandidatesAreSelected) {
            dispatch(setSelectedCandidates(selectedCandidates.filter((item) => !candidateIds.includes(item))));
        } else {
            const concatCandidatesIds = selectedCandidates.concat(candidateIds);
            const selectedIdsInColumn = [...new Set(concatCandidatesIds)];
            dispatch(setSelectedCandidates(selectedIdsInColumn));
        }

    }, [ candidateIds, pipesByColumn, selectedCandidates ] )


    // * Effects
    useEffect(() => {
        if (!offer_id || pipeItems.length == 0) return;
        // fetchScoring();
        return () => {
            cancelToken.cancel("Request canceled.");
            dispatch(setIsLoading(false, "thumb"));
        };
    }, [offer_id]);

    const data = useMemo( () => ({
        column,
        pipeItems,
        selectedColumnCandidateIds,
    }),
    [
        column,
        pipeItems,
        selectedColumnCandidateIds,
    ] );

    const handlersColumn = useMemo( () => ({
        ...handlers,
        handleSelectColumn,
    }), [
        handlers,
        handleSelectColumn,
    ] );

    return <ColumnUI className={className} data={data} handlers={handlersColumn} />;
};

export default memo(Column);
