import {
    Classes,
    Button,
    Dropzone,
    ResumesTable,
    Pagination,
    UploadSuccessModal,
    ConfirmModal,
    UploadPercentageModal,
    Dropdown,
} from 'components';
import { AddCvsModal } from './AddCvsModal';
import { AddClassModal } from './AddClassModal';

import { convertToDropdown } from 'utils';
import { locales } from 'constants/index';
import { Icons } from 'assets';

import { TClassroomsUI } from './Classrooms.types';

import './classrooms.scss';

const ClassroomsUI: TClassroomsUI = ({
    data: {
        isUploading,
        uploadPercentage,
        classes,
        degrees,
        selectedClass,
        newClass,
        selectedDegree,
        uploadedResumes,
        resumes,
        tags,
        selectedResume,
        selectedTag,
        newSelectedDegree,
        newSelectedClass,
        selectedDegrees,
        resumesClasses,
        selectedResumes,
        resumeUrl,
        userInfo,
        isBeingEdited,
        classTextInputRef,
        resumeNameValue,
    },
    options,
    errors,
    pagination: { page },
    totalPages,
    config,
    handlers: {
        handleChangeResumeName,
        handleAddResumesModalShow,
        handleAddResumesModalClose,
        handleAddResumes,
        handleDegreeChange,
        handleClassClick,
        handleUploadFile,
        handleSelectedClassChange,
        handleSelectedDegreeChange,
        handleNewClassChange,
        handleNewClassSubmit,
        handleTagsDropdownShow,
        handleTagsDropdownHide,
        handleSelectedTagChange,
        handleSaveTag,
        handleAddClassDialogHide,
        handleAddClassDialogShow,
        handleResumePageChange,
        handleGenerateResume,
        handleEditResumeNameShow,
        handleEditResumeNameHide,
        handleUploadSuccessModalHide,
        handleClassSettingsMenuShow,
        handleClassSettingsMenuHide,
        handleSelectedDegreesChange,
        handleRemoveTag,
        handleRemoveClass,
        handleRemoveClassCancel,
        handleRemoveClassConfirm,
        handleSelectResume,
        handleRemoveAllResumes,
        handleRemoveSelectedResumes,
        handleRemoveResumesConfirm,
        handleRemoveResumesCancel,
        handleCloseCv,
        handleEditClass,
        handleUpdateClassSubmit,
        handleCloseUploadingPercentage,
    },
    isAddNewResumesModalVisible,
    isClassSettingsMenuVisible,
    isResumeNameInputVisible,
    isTagDropdownVisible,
    isUploadSuccessModalVisible,
    isAddClassesModalVisible,
    isConfirmModalVisible,
    addResumesBodyRef,
    isDeleteResumesConfirmVisible,
}) => {
    return (
        <div className="resumes">
            <div className="page-title">{locales.classroom.title}</div>

            <div className="resumes__filterWrapper">
                <div className="resumes__filterTitle">
                    {locales.general.filter}
                </div>
                <div className="resumes__filterContentGroup">
                    <div className="resumes__filterItem">
                        <Dropdown
                            isWhite
                            placeholder={locales.resumes.degrees ?? "Niveau d’étude"}
                            value={options.degrees.find(({ value }) => value === selectedDegree)}
                            options={options.degrees}
                            onChange={handleDegreeChange}
                            className='resumes__filterDropdown'
                        />
                    </div>

                    <div className="resumes__filterItem"></div>
                </div>
                <div className="resumes__filterButtonGroup">
                    <div className="resumes__filterButton">

                    </div>

                    <div className="resumes__filterButton">
                        <Button
                            className="resumes__add-new"
                            variant="primary"
                            onClick={handleAddResumesModalShow}
                        >
                            <span className="resumes__add-new__text">
                                {locales.resumes.addNewCv}
                            </span>
                            <img alt="add class" src={Icons.AddCircleWhite}/>
                        </Button>
                    </div>
                </div>
            </div>

            <div className="resumes__contentWrapper">
                <Classes
                    classes={classes}
                    className="resumes__classes"
                    selectedClass={selectedClass}
                    isClassSettingsMenuVisible={isClassSettingsMenuVisible}
                    onClick={handleClassClick}
                    onAddClassDialogShow={handleAddClassDialogShow}
                    onSettingsMenuShow={handleClassSettingsMenuShow}
                    onSettingsMenuHide={handleClassSettingsMenuHide}
                    isAddClassVisible={selectedDegree.length > 0}
                    onRemoveClass={handleRemoveClass}
                    onEditClass={handleEditClass}
                    isBeingEdited={isBeingEdited}
                    newClass={newClass}
                    handleUpdateClassSubmit={handleUpdateClassSubmit}
                    handleNewClassChange={handleNewClassChange}
                    classTextInputRef={classTextInputRef}
                />

                {resumes.length === 0
                    && selectedClass.length > 0
                    && selectedDegree.length > 0
                    && (<Dropzone
                        onUploadFile={handleUploadFile}
                        className="resumes__dropzone"
                        description={locales.dropzone.uploadFiles}
                        fileTypes={config?.filesType}
                        maxSize={config?.fileSize}
                        maxFiles={config?.maxFiles}
                    />
                )}

                {selectedClass.length === 0
                    && selectedDegree.length > 0
                    && classes.length > 0
                    && (<div className="resumes__hint flex flex-align-items-end">
                        <img
                            src={Icons.GreenRoundedArrow}
                            alt=""
                            className="resumes__hint-arrow"
                        />
                        {locales.resumes.selectClass}
                    </div>
                )}

                {/* TEMP: let's set default class (check with juliano) */}
                {resumes.length > 0 && selectedClass.length > 0 && (
                    <>
                        <ResumesTable
                            resumes={resumes}
                            selectedResume={selectedResume}
                            selectedResumes={selectedResumes}
                            tags={convertToDropdown({ data: tags, labelKey: 'value', valueKey: '_id' })}
                            selectedTag={selectedTag}
                            onTagsDropdownShow={handleTagsDropdownShow}
                            onTagsDropdownHide={handleTagsDropdownHide}
                            onSelectedTagChange={handleSelectedTagChange}
                            onSaveTag={handleSaveTag}
                            onGenerateResume={handleGenerateResume}
                            onEditResumeNameShow={handleEditResumeNameShow}
                            onEditResumeNameHide={handleEditResumeNameHide}
                            isResumeNameInputVisible={isResumeNameInputVisible}
                            isTagDropdownVisible={isTagDropdownVisible}
                            onRemoveTag={handleRemoveTag}
                            onSelectResume={handleSelectResume}
                            withCheckbox
                            resumeNameValue={resumeNameValue}
                            handleChangeResumeName={handleChangeResumeName}
                            resumeUrl={resumeUrl}
                            onCloseCv={handleCloseCv}
                        />
                        {selectedResumes.length > 0 && (
                            <div className="resumes__delete">
                                <>
                                    <Button
                                        size="small"
                                        variant="danger"
                                        isOutline
                                        onClick={handleRemoveSelectedResumes}
                                    >
                                        {locales.resumes.deleteSelectedResumes} (
                                        {selectedResumes.length})
                                    </Button>

                                    <Button
                                        size="small"
                                        variant="danger"
                                        onClick={handleRemoveAllResumes}
                                    >
                                        {locales.resumes.deleteAllResumes}
                                    </Button>
                                </>
                            </div>
                        )}
                    </>
                )}
            </div>


            {resumes.length > 0 && selectedClass.length > 0 && totalPages > 1 && (
                <div>
                    <Pagination
                        className='resumes__pagination'
                        selectedPage={page}
                        totalPages={totalPages}
                        onPageChange={handleResumePageChange}
                    />
                </div>
            )}

            <>
                {isAddNewResumesModalVisible && (
                    <AddCvsModal
                        selectedClass={ newSelectedClass }
                        selectedDegree={ newSelectedDegree }
                        selectedResume={ selectedResume }
                        classes={ convertToDropdown({ data: resumesClasses, labelKey: 'value', valueKey: '_id' }) }
                        degrees={ convertToDropdown({ data: degrees, labelKey: 'value', valueKey: '_id' }) }
                        tags={ convertToDropdown({ data: tags, labelKey: 'value', valueKey: '_id' }) }
                        uploadedResumes={ uploadedResumes }
                        onClose={ handleAddResumesModalClose }
                        onUploadFile={ handleUploadFile }
                        onAddResumes={ handleAddResumes }
                        onSelectedClassChange={ handleSelectedClassChange }
                        onSelectedDegreeChange={ handleSelectedDegreeChange }
                        config={ config }
                        bodyRef={ addResumesBodyRef }
                    />
                )}

                {isUploadSuccessModalVisible && (
                    <UploadSuccessModal
                        title={ locales.resumes.addResumes }
                        description={
                            locales
                                .formatString(
                                    locales.resumes.successMessage,
                                    {
                                        category: resumesClasses.find(({ _id: id }) => newSelectedClass === id)?.value || ''
                                    })
                                .toString()
                        }
                        fileNames={ uploadedResumes.map(({ name }) => name) }
                        onClose={ handleUploadSuccessModalHide }
                    />
                )}

                {isAddClassesModalVisible && (
                    <AddClassModal
                        newClass={ newClass }
                        selectedDegrees={ selectedDegrees }
                        degrees={ degrees.map(({ _id: id, key, value }) => {

                            if (key === 'bac_1' || key === 'bac_2' || key === 'bac_3')
                                return { category: 'Licence', value: id, label: value };

                            if (key === 'bac_4' || key === 'bac_5' || key === 'bac_6')
                                return { category: 'Master',  value: id,  label: value };

                            return { category: 'Bac',  value: id,  label: value };

                        }) }
                        onClassChange={ handleNewClassChange }
                        onSelectedDegreesChange={ handleSelectedDegreesChange }
                        onAddClass={ handleNewClassSubmit }
                        onClose={ handleAddClassDialogHide }
                    />
                )}

                {isConfirmModalVisible && (
                    <ConfirmModal
                        title={ locales.resumes.deleteClass }
                        description={ `${locales.resumes.deleteClassConfirmMessage} ${ classes.find(({ _id: id }) => selectedClass === id)?.value }?` }
                        onApply={ handleRemoveClassConfirm(selectedClass) }
                        onClose={ handleRemoveClassCancel }
                    />
                )}

                {isDeleteResumesConfirmVisible && (
                    <ConfirmModal
                        title={ selectedResumes.length > 1 ? locales.resumes.deleteAllResumes : locales.resumes.deleteResume }
                        description={ selectedResumes.length > 1 ? locales.resumes.deleteAllResumesConfirmMessage : locales.resumes.deleteResumeConfirmMessage }
                        onApply={ handleRemoveResumesConfirm(selectedResumes.length > 1) }
                        onClose={ handleRemoveResumesCancel }
                    />
                )}

                {isUploading && (
                    <UploadPercentageModal
                        title={ locales.resumes.addResumes }
                        attachToBody={ <div className="resumes__loading flex flex-justify-content-center">{locales.general.loading}</div> }
                        data={{ uploadPercentage }}
                        onClose={ handleCloseUploadingPercentage }
                    />
                )}
            </>
        </div>
    );
};

export default ClassroomsUI;