import React, { memo, useCallback } from 'react';
import classNames from "classnames";
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

import { TRichTextToolbar } from './RichTextToolbar.types';

import '../rich-text-editor.scss';

const MemoizedEmojiPicker = memo(EmojiPicker);

const RichTextToolbar: TRichTextToolbar = ({
    id = '',
    className = '',
    readOnly = true,
    hasEmoji = false,
    isBorderless = false,
    data: {
        showEmoji = false,
    },
    handlers: {
        handleShowEmojiPicker,
        handleInsertEmoji,
    }
}) => {
    const handleEmojiClick = useCallback((emoji: EmojiClickData, event: MouseEvent) => {
        handleInsertEmoji(emoji.emoji);
        handleShowEmojiPicker(false);
    }, [
        handleInsertEmoji,
        handleShowEmojiPicker
    ]);

    const classes = classNames({
        'rich-text-toolbar': true,
        'rich-text-toolbar-hide': readOnly,
        [className]: !!className,
    });

    const toolbarClasses = classNames({
        'ql-snow ql-toolbar': true,
        'ql-toolbar--is-borderless': isBorderless,
        [className]: !!className,
    });

    return <div className={classes}>
        <div id={`react-quill-toolbar-${id}`} className={toolbarClasses}>
            <button className='ql-bold'></button>
            <button className='ql-italic'></button>
            <button className='ql-underline'></button>
            <button className='ql-list' value='ordered'></button>
            <button className='ql-list' value='bullet'></button>
            {hasEmoji && <button
                className='ql-custom'
                onClick={() => handleShowEmojiPicker()}
                children={<div className='ql-emoji'/>}
            />}
        </div>
        {showEmoji && <div className='rich-text-toolbar-absolute'>
            <MemoizedEmojiPicker
                lazyLoadEmojis={true}
                skinTonesDisabled={true}
                previewConfig={{ showPreview: false }}
                onEmojiClick={handleEmojiClick}
            />
        </div>}
    </div>
};

export default memo(RichTextToolbar);
