import { memo } from "react";
import ClassNames from "classnames";

import { locales } from "constants/index";

import { TSection } from './Section.types';

import './section.scss';

const Section: TSection = ({
    title = '',
    children,
    isDisabled = false,
    isColumns = false,
    showSave = false,
    handleSave,
}) => {

    const classes = ClassNames({
        section: true,
        section__full: !title,
    });

    const bodyClasses = ClassNames({
        section__body: true,
        section__body__columns: isColumns,
        disabled: isDisabled,
    });

    return (
        <div className={classes}>
            {title && <div className='section__header'>
                <div className='section__title'>
                    {title}
                </div>
                {showSave && handleSave &&
                    <div
                        className='section__button'
                        onClick={handleSave}
                    >
                        {locales.general.save}
                    </div>
                }
            </div>}

            <div className={bodyClasses}>
                {children}
                {isDisabled && <div className='section__mask'/>}
            </div>
        </div>
    );
};

export default memo(Section);
