import { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { convertToDropdown } from 'utils';

import { setOfferFormStepValuesAction } from 'modules/Offers/store/actions';

import CreateOfferAdditionalInformationUi from './CreateOfferAdditionalInformation.ui';

import {
  TCreateOfferAdditionalInformation,
  TAdditionalInformationForm,
  IStoreState,
  THandleDropdownChange,
  IHandleChange,
  IHandleTextAreaChange,
} from './CreateOfferAdditionalInformation.types';

const CreateOfferAdditionalInformation: TCreateOfferAdditionalInformation = ({
  isUserSubscribed,
  handlePublishOffer,
  handleSaveDraft,
}) => {
  //* Hooks
  const dispatch = useDispatch();

  //* Redux State
  const config = useSelector(
    ({ app: { config } }: IStoreState) => config,
    shallowEqual
  );

  const { values: offerForm } = useSelector(
    ({ offers: { form } }: IStoreState) => form,
    shallowEqual
  );

  //* Local State
  const [keyword, setKeyword] = useState('');
  const [form, setForm] = useState<TAdditionalInformationForm>({
    languages: offerForm.languages,
    keywords: offerForm.keywords,
    pace: offerForm.pace,
    startDate: offerForm.startDate,
    comment: offerForm.comment,
  });

  //* Handlers
  const handleTextAreaChange: IHandleTextAreaChange = ({
    target: { name, value },
  }) => {
    setForm((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    dispatch(
      setOfferFormStepValuesAction({
        ...form,
        [name]: value,
      })
    );
  };

  const handleDropdownChange =
    (fieldName: string): THandleDropdownChange =>
    (option) => {
      if (Array.isArray(option)) {
        setForm((prevValue) => ({
          ...prevValue,
          [fieldName]: option.map(({ value }) => value),
        }));

        return dispatch(
          setOfferFormStepValuesAction({
            ...form,
            [fieldName]: option.map(({ value }) => value),
          })
        );
      }
      dispatch(
        setOfferFormStepValuesAction({
          ...form,
          [fieldName]: option.value,
        })
      );
      setForm((prevValue) => ({
        ...prevValue,
        [fieldName]: option.value,
      }));
    };

  const handleKeywordChange: IHandleChange = ({ target: { value } }) =>
    setKeyword(value);

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      setForm((prevValue) => ({
        ...prevValue,
        keywords: [...prevValue.keywords, keyword],
      }));

      dispatch(
        setOfferFormStepValuesAction({
          ...form,
          keyword: [...form.keywords, keyword],
        })
      );

      setKeyword('');
    }
  };

  const handleSubmitDraft = () => handleSaveDraft({ ...offerForm, ...form });

  const handleSubmit = () => {
    handlePublishOffer({ ...offerForm, ...form });
  };

  const options = {
    languages: convertToDropdown({ data: config.languages }),
    paces: convertToDropdown({ data: config.paces }),
    months: convertToDropdown({ data: config.months }),
  };

  const data = {
    form,
    keyword,
  };

  const handlers = {
    handleTextAreaChange,
    handleDropdownChange,
    handleKeywordChange,
    handleKeyDown,
    handleSubmitDraft,
    handleSubmit,
  };

  return (
    <CreateOfferAdditionalInformationUi
      isUserSubscribed={isUserSubscribed}
      options={options}
      data={data}
      handlers={handlers}
    />
  );
};

export default CreateOfferAdditionalInformation;
