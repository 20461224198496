import { TSocialMediaType } from "../settings.types";

const socials: TSocialMediaType[] = [
    'instagram',
    'tiktok',
    'youtube',
    'linkedin',
    'discord',
    'x',
    'email',
    'website',
    'facebook',
];

export default socials;