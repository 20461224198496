import { memo, useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { IStoreState, TSidebar } from './Sidebar.types';

import { setSidebarState } from "./store/actions";

import { useGetUserInfo } from "hooks";

import SidebarUI from "./Sidebar.ui";

import './sidebar.scss';

const Sidebar: TSidebar = () => {
  //* Hooks Init
  const dispatch = useDispatch();

  //* Redux State
  const userInfo = useGetUserInfo();
  const checkoutUrl = useSelector(({app: {config: { checkout_url: checkoutUrl }}}: IStoreState) => checkoutUrl, shallowEqual);
  const openSidebar = useSelector(({ sidebar: { open } }: IStoreState) => open, shallowEqual);

  //* Handlers
  const toggleSidebarState = useCallback((state?: boolean) => () => {
    if (state === undefined) return dispatch(setSidebarState(!openSidebar));
    dispatch(setSidebarState(state));
  }, [dispatch, openSidebar]);

  //* Wrappers
  const data = useMemo(() => ({
    checkoutUrl,
    profile: {
      title: `${userInfo?.name.firstname} ${userInfo?.name.lastname}`,
      subTitle: userInfo?.info?.school,
      imageUrl: userInfo?.info?.profileUrl,
    }
  }),[
    checkoutUrl,
    userInfo?.name?.firstname,
    userInfo?.name?.lastname,
    userInfo?.info?.school,
    userInfo?.info?.profileUrl,
  ]);

  const handlers = useMemo(() => ({
    toggleSidebarState,
  }),[
    toggleSidebarState,
  ]);

  return <SidebarUI
    openSidebar={openSidebar}
    data={data}
    handlers={handlers}
  />;
};

export default memo(Sidebar);
