import { authenticationActionTypes } from './action-types';

import {
  TAuthenticateUserAction,
  TUnAuthenticateUserAction,
  TSetRegisterAction,
  TResetRegisterAction,
  TResetStateAction,
} from './store.types';

export const authenticateUserAction: TAuthenticateUserAction = ({ accessToken, refreshToken }) => ({
  type: authenticationActionTypes.AUTHENTICATE_USER,
  accessToken,
  refreshToken,
});

export const unAuthenticateUserAction: TUnAuthenticateUserAction = () => ({
  type: authenticationActionTypes.UNAUTHENTICATE_USER,
});

export const setRegister: TSetRegisterAction = (payload) => ({
  type: authenticationActionTypes.SET_REGISTER,
  payload
});

export const resetRegister: TResetRegisterAction = () => ({
  type: authenticationActionTypes.RESET_REGISTER,
});

export const resetStateAuth: TResetStateAction = () => {
  return {
    type: authenticationActionTypes.RESET,
  };
};
