import { memo, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Joi from 'joi';

import { useToast, useExtractServerError, useValidate } from 'hooks';
import { setAccessToken, setRefreshToken } from 'utils';
import { locales, routes } from 'constants/index';
import { authenticateUserAction } from '../store/actions';
import { setIsLoading } from "../../App/store/actions";
import { acceptInviteApi } from '../api';

import { TGuest, IHandleChange } from './Guest.types';

import GuestUi from './Guest.ui';

const validationSchema = {
  firstname: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),
  lastname: Joi.string().required().messages({
    'string.empty': locales.errors.required,
  }),

  password: Joi.string()
    .required()
    .messages({
      'string.empty': locales.errors.required,
      'string.min': locales
        .formatString(locales.errors.minLength, {
          fieldName: locales.general.password,
        })
        .toString(),
      'string.pattern.name': locales.errors.passwordPolicy,
    }),
  passwordConfirmation: Joi.string()
      .required()
      .messages({
        'string.empty': locales.errors.required,
        'string.min': locales
          .formatString(locales.errors.minLength, {
            fieldName: locales.general.password,
          })
          .toString(),
        'string.pattern.name': locales.errors.passwordPolicy,
      }),
};

const Guest: TGuest = () => {
  // * Hooks Init
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { id: guestId = '' } = useParams();
  const { extractErrorMessage } = useExtractServerError();
  const { errors, setErrors, validate } = useValidate();

  //* Local State
  const [loginForm, setLoginForm] = useState({
    firstname: '',
    lastname: '',
    password: '',
    passwordConfirmation: '',
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  //* Handlers
  const handleInputChange: IHandleChange = ({ target: { name, value } }) => {
    setLoginForm((prevInfo) => ({ ...prevInfo, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleInputBlur: IHandleChange = ({ target: { name, value } }) => {
    if (value.length > 0) {
      validate({
        data: {
          [name]: value,
        },
        validationSchema: {
          // @ts-ignore
          [name]: validationSchema[name],
        },
      });
    }
  };

  const handlePasswordVisibilityChange = () => {
    setIsPasswordVisible((prevValue) => !prevValue);
  };

  const handleAcceptTermsChange = () => {
    setAcceptTerms((prevValue) => !prevValue);
  };

  const handleSubmit = () => {
    const isFormValid = validate({ data: loginForm, validationSchema });
    if (!isFormValid) {
      return;
    }

    acceptInvitation();
  };

  //* API Actions
  const acceptInvitation = async () => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: {
          data: { token, refresh_token, user },
        },
      } = await acceptInviteApi({
        params: {
          id: guestId,
        },
        body: {
          ...loginForm,
        }
      });

      setAccessToken({ accessToken: token });
      setRefreshToken({ refreshToken: refresh_token });
      dispatch(
        authenticateUserAction({
          accessToken: token,
          refreshToken: refresh_token,
        })
      );

      navigate(routes.offers.main);

      dispatch(setIsLoading(false));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      dispatch(setIsLoading(false));
    }
  };

  //* Wrappers
  const data = useMemo(() => ({
    ...loginForm,
    isPasswordVisible,
    acceptTerms
  }), [
    loginForm,
    isPasswordVisible,
    acceptTerms,
  ]);

  const handlers = useMemo(() => ({
    handleInputChange,
    handleInputBlur,
    handlePasswordVisibilityChange,
    handleAcceptTermsChange,
    handleSubmit,
  }), [
    handleInputChange,
    handleInputBlur,
    handlePasswordVisibilityChange,
    handleAcceptTermsChange,
    handleSubmit,
  ]);

  return (
    <GuestUi
      data={data}
      errors={errors}
      handlers={handlers}
    />
  );
};

export default memo(Guest);
