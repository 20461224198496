import ReactDOM from 'react-dom';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocumentProxy } from "pdfjs-dist";
import ClassNames from 'classnames';

import {
  ScaleEnum,
  THandleLoadSuccess,
  THandleZoomClick,
  THandleDownloadClick,
  TPdfViewer,
} from './PdfViewer.types';

import './pdf-viewer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer: TPdfViewer = ({
  url,
  isVisible,
  isFixedPosition = false,
  handleDownload,
  onClose,
}) => {
  const container = document.getElementById('pdf-viewer-container')!;

  //* Refs
  const documentRef = useRef<HTMLDivElement>(null);

  //* Local State
  const [scale, _setScale] = useState<ScaleEnum>(ScaleEnum.BASE);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);

  //* Side Effects
  const handleScrollToTopLeft = useCallback(() => {
    if (!documentRef.current) return;
    documentRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [documentRef]);

  //* Handlers
  const onDocumentLoadSuccess: THandleLoadSuccess = useCallback((data: PDFDocumentProxy) => {
    setTotalPages(data.numPages);
  }, [totalPages]);

  // const handleNextPageChange = useCallback(() => {
  //   setPageNumber((prevValue) => prevValue === totalPages ? prevValue : prevValue + 1 );
  //   handleScrollToTopLeft();
  // }, [handleScrollToTopLeft, totalPages, pageNumber]);
  //
  // const handlePreviousPageChange = useCallback(() => {
  //   setPageNumber((prevValue) => (prevValue === 1 ? prevValue : prevValue - 1));
  //   handleScrollToTopLeft();
  // }, [handleScrollToTopLeft, totalPages, pageNumber]);

  const handleZoomClick: THandleZoomClick = useCallback((type) => (event) => {
    event.stopPropagation();
    if (!!type) {
      handleScrollToTopLeft();
      return _setScale(type);
    }
    _setScale(p => p === ScaleEnum.BASE ? ScaleEnum.ZOOMED : ScaleEnum.BASE);
  }, [scale, _setScale]);

  const handleDownloadClick: THandleDownloadClick = useCallback((event) => {
    event.stopPropagation();
    handleDownload && handleDownload();
  }, [scale, _setScale]);

  //* Effects
  useEffect(() => handleScrollToTopLeft(), [handleScrollToTopLeft]);

  const classNames = ClassNames({
    'pdf-viewer': true,
    'pdf-viewer--visible': isVisible,
    'pdf-viewer--fixed-position': isFixedPosition,
  });

  const documentClassNames = ClassNames({
    'zoom-out': scale === ScaleEnum.ZOOMED,
  });

  // const previousButtonClassNames = ClassNames({
  //   'pdf-viewer__actionContainer': true,
  //   'disabled': pageNumber === 1,
  // });
  //
  // const nextButtonClassNames = ClassNames({
  //   'pdf-viewer__actionContainer': true,
  //   'disabled': pageNumber === totalPages,
  // });

  const Component = <div className={classNames}>
    <div className='pdf-viewer__content' onClick={handleZoomClick()}>
      <Document
        className={documentClassNames}
        inputRef={documentRef}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          scale={scale}
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>

      <div className='pdf-viewer__actionsGroup absolute vertical'>
        {onClose && <div className="pdf-viewer__actionContainer" onClick={onClose}>
          <div className="pdf-viewer__action close"/>
        </div>}
        {handleDownload && url && <div className="pdf-viewer__actionContainer" onClick={handleDownloadClick}>
          <div className="pdf-viewer__action download"/>
        </div>}
        {scale !== ScaleEnum.BASE && <div className="pdf-viewer__actionContainer" onClick={handleZoomClick(ScaleEnum.BASE)}>
          <div className="pdf-viewer__action resize"/>
        </div>}
      </div>
    </div>
    {/*<div className='pdf-viewer__actionsGroup'>*/}
    {/*  <div className={previousButtonClassNames} onClick={handlePreviousPageChange}>*/}
    {/*    <div className="pdf-viewer__action previous"/>*/}
    {/*  </div>*/}
    {/*  <div className="pdf-viewer__actionContainer">*/}
    {/*    {pageNumber}*/}
    {/*  </div>*/}
    {/*  <div className={nextButtonClassNames} onClick={handleNextPageChange}>*/}
    {/*    <div className="pdf-viewer__action next" />*/}
    {/*  </div>*/}
    {/*</div>*/}
  </div>;

  if (isFixedPosition) return ReactDOM.createPortal((
    <div className="overlay">{Component}</div>
  ), container);

  return Component;
};

export default memo(PdfViewer);
