import { memo } from 'react';

import { locales } from "constants/index";

import { Badge } from "components";

import { TTableBadge } from './TableBadge.types';

import { ContractEnums, PublicationStatusEnums } from "./TableBadge.enums";

import 'components/Table2/table.scss';

const TableBadge: TTableBadge = ({
    value,
    variant,
    primary,
    secondary,
}) => {

    // TODO: CHANGE THIS TO DYNAMIC SYSTEM
    let prim, sec, val;

    if (!!value) {
        switch (value) {
            case ContractEnums.CDI:
                prim = '#409261'; sec = '#E9FFEF';
                break;

            case ContractEnums.STAGE:
                prim = '#D98634'; sec = '#FFF2DD';
                break;

            case ContractEnums.APPRENTISSAGE:
                prim = '#3F3748'; sec = '#E4E4E4';
                break;

            case ContractEnums.PRO:
                prim = '#3F3748'; sec = '#E4E4E4';
                break;

            case ContractEnums.PEU_IMPORTE:
                prim = '#2EC1AC'; sec = '#E9FFEF';
                break;

            default:
                prim = '#fff'; sec = '#000';
                break;
        }
    }

    if (!!variant) {
        switch (variant) {
            case PublicationStatusEnums.PUBLISHED:
                val = locales.offers.published
                prim = '#409261'; sec = '#E9FFEF';
                break;

            case PublicationStatusEnums.DRAFT:
                val = locales.offers.draft
                prim = '#D98634'; sec = '#FFF2DD';
                break;

            case PublicationStatusEnums.ARCHIVED:
                val = locales.offers.archived
                prim = '#3F3748'; sec = '#E4E4E4';
                break;

            case PublicationStatusEnums.AWAITING_APPROVAL:
                val = locales.offers.awaitingApproval
                prim = '#D98634'; sec = '#FFF2DD';
                break;

            default:
                prim = '#fff'; sec = '#000';
                break;
        }
    }


    return (
        <div className='td__action'>
            <Badge size='small' value={val ?? value} style={{ primary: prim ?? primary, secondary: sec ?? secondary }} />
        </div>
    );
};

export default memo(TableBadge);