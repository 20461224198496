"use client";

import { Dropzone } from "@mantine/dropzone";

import classNames from 'styles/sass/components/Dropzone.module.scss';

export default Dropzone.extend({
	defaultProps: {
		radius: 'md',
	},
	classNames
})