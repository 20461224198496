import { shallowEqual, useSelector } from 'react-redux';

import { IStoreState } from 'store';

const useGetUserInfo = () => {
  const userInfo = useSelector(
    ({ app: { userInfo } }: IStoreState) => userInfo,
    shallowEqual
  );

  return userInfo;
};

export default useGetUserInfo;
