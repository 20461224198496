enum YPareoEnums {
    domain = 'domain',
    token = 'token',
    description = 'description',
    idSite = 'idSite',
    idFormationSouhait1 = 'idFormationSouhait1',
}

enum YPareoActionEnums {
    CREATE = 'CREATE',
    DELETE = 'DELETE',
}

export { YPareoEnums, YPareoActionEnums };