import { memo } from "react";

import AuthenticationLayout from 'modules/Authentication/AuthenticationLayout';

import { TextInput, FormControl, Button } from 'components';

import { locales } from 'constants/index';
import { formSubmit } from 'utils';
import { Images } from 'assets';

import { TPersonalInfoUi } from './PersonalInfo.types';

import { RegisterEnum } from "../Register.types";

import '../register.scss';

const PersonalInfoUi: TPersonalInfoUi = ({
  data: { register },
  errors,
  handlers,
}) => {
  return (
    <AuthenticationLayout mainImage={Images.BusinessDeal}>
      <div className="register flex flex-justify-content-center flex-align-items-center">
        <div className="register__content flex flex-column flex-align-items-center">
          <div className="register__title">
            {locales.registration.createAccount}
          </div>

          <div className="register__description register__description--reset">
            {locales.registration.additionalInfo}
          </div>

          <div className="mobile-icon">
            <div className="mobile-icon__svg mobile-icon__svg__register" />
          </div>

          <form
            onSubmit={formSubmit}
            className="register__form"
          >
            <div className="register__sub-title">
              {locales.registration.getStarted}...
            </div>

            <FormControl
              id={RegisterEnum.lastname}
              label={locales.general.lastName}
              error={errors[RegisterEnum.lastname]}
              className="register__field"
              withRef
            >
              <TextInput
                id={RegisterEnum.lastname}
                name={RegisterEnum.lastname}
                value={register[RegisterEnum.lastname]}
                isError={!!errors[RegisterEnum.lastname]}
                onChange={handlers.handleInputChange}
                onBlur={handlers.handleInputBlur}
              />
            </FormControl>

            <FormControl
              id={RegisterEnum.firstname}
              label={locales.general.firstName}
              error={errors[RegisterEnum.firstname]}
              className="register__field"
              withRef
            >
              <TextInput
                id={RegisterEnum.firstname}
                name={RegisterEnum.firstname}
                value={register[RegisterEnum.firstname]}
                isError={!!errors[RegisterEnum.firstname]}
                onChange={handlers.handleInputChange}
                onBlur={handlers.handleInputBlur}
              />
            </FormControl>
          </form>

          <div className="register__submit">
            <Button
              variant="primary"
              onClick={handlers.handleSubmit}
            >
              {locales.general.next}
            </Button>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default memo(PersonalInfoUi);
