import { SET_SIDEBAR_STATE, sidebarActionTypes } from './action-types';

import { ISidebarGlobalState, TSidebarActions } from './store.types';

const initialState: ISidebarGlobalState = {
    open: false,
};

const reducer = (state: ISidebarGlobalState = initialState, action: TSidebarActions) => {
    switch (action.type) {
        case SET_SIDEBAR_STATE:
            return {
                ...state,
                open: action.payload,
            };

        case sidebarActionTypes.RESET:
            return initialState;

        default:
            return state;
    }
};

export default reducer;
