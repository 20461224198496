import {
  FormControl,
  TextInput,
  Dropdown,
  Chip,
  TextArea,
  Button,
  FormLayout,
  TooltipWrapper,
} from 'components';

import { locales } from 'constants/index';

import { TCreateOfferAdditionalInformationUi } from './CreateOfferAdditionalInformation.types';

const CreateOfferAdditionalInformationUi: TCreateOfferAdditionalInformationUi =
  ({
    isUserSubscribed,
    options,
    data: { form, keyword },
    handlers: {
      handleDropdownChange,
      handleKeywordChange,
      handleKeyDown,
      handleTextAreaChange,
      handleSubmitDraft,
      handleSubmit,
    },
  }) => {
    return (
      <>
        <FormLayout
          title={locales.createOffer.additionalInformation}
          description={locales.createOffer.additionalInformationDescription}
          isOptional
        >
          <TooltipWrapper
            className="create-offer__form-field"
            title={locales.offer.languages}
            content={locales.offer.tooltip.languages}
          >
            <FormControl
              id="languages"
              label={locales.offer.languages}
              withRef
            >
              <Dropdown
                id="languages"
                name="languages"
                isMulti
                options={options.languages}
                value={options.languages.filter((language) =>
                  form.languages.includes(language.value)
                )}
                onChange={handleDropdownChange('languages')}
              />
            </FormControl>
          </TooltipWrapper>

          <TooltipWrapper
            className="create-offer__form-field"
            title={locales.createOffer.keywordsNote}
            content={locales.createOffer.tooltip.keywordsNote}
          >
            <FormControl
              id="keywords"
              label={locales.createOffer.keyWords}
              note={locales.createOffer.keywordsNote}
              withRef
            >
              <TextInput
                id="keywords"
                name="keywords"
                isDark
                value={keyword}
                onChange={handleKeywordChange}
                onKeyDown={handleKeyDown}
              />
            </FormControl>
          </TooltipWrapper>

          {form.keywords?.length > 0 && (
            <div className="create-offer__keywords flex">
              {form.keywords.map((keywordLabel) => (
                <Chip key={keywordLabel}>{keywordLabel}</Chip>
              ))}
            </div>
          )}

          <TooltipWrapper
            className="create-offer__form-field"
            title={locales.createOffer.duration}
            content={locales.createOffer.tooltip.duration}
          >
            <FormControl
              id="pace"
              label={locales.createOffer.duration}
              withRef
            >
              <Dropdown
                id="pace"
                name="pace"
                isMulti
                options={options.paces}
                value={options.paces.filter((pace) =>
                  form.pace.includes(pace.value)
                )}
                onChange={handleDropdownChange('pace')}
              />
            </FormControl>
          </TooltipWrapper>

          <TooltipWrapper
            className="create-offer__form-field"
            title={locales.createOffer.positionStartDate}
            content={locales.createOffer.tooltip.positionStartDate}
          >
            <FormControl
              id="startDate"
              label={locales.createOffer.positionStartDate}
              className="create-offer__form-field"
              withRef
            >
              <Dropdown
                id="startDate"
                name="startDate"
                options={options.months}
                value={options.months.filter((month) =>
                  form.startDate.includes(month.value)
                )}
                onChange={handleDropdownChange('startDate')}
              />
            </FormControl>
          </TooltipWrapper>

          <TooltipWrapper
            className="create-offer__form-field"
            title={locales.createOffer.comments}
            content={locales.createOffer.tooltip.comments}
          >
            <FormControl
              id="comment"
              label={locales.createOffer.comments}
              withRef
            >
              <TextArea
                id="comment"
                name="comment"
                isDark
                value={form.comment}
                onChange={handleTextAreaChange}
              />
            </FormControl>
          </TooltipWrapper>
        </FormLayout>

        <div className="create-offer__actions">
          {isUserSubscribed && (
            <Button
              variant='link'
              onClick={handleSubmitDraft}
            >
              {locales.createOffer.saveAsDraft}
            </Button>
          )}

          <Button
            variant="primary"
            onClick={handleSubmit}
          >
            {isUserSubscribed
              ? locales.createOffer.publishOffer
              : locales.general.submit}
          </Button>
        </div>
      </>
    );
  };

export default CreateOfferAdditionalInformationUi;
