"use client";

import { Select } from "@mantine/core";

import classNames from 'styles/sass/components/Select.module.scss';

export default Select.extend({
	defaultProps: {
		color: 'darkgreen',
		radius: 'md',
		size: 'md',
	},
	classNames,
})