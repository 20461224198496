import {
  CLEAR_OFFER_FORM,
  offersActionTypes,
  SELECT_SUBSCRIPTION_TYPES,
  SET_COUPON_CODE,
  SET_IS_USER_SUBSCRIBED,
  SET_OFFER_FORM_ACTIVE_STEP,
  SET_OFFER_FORM_COMPLETED_STEP,
  SET_OFFER_FORM_STEP_VALUES,
  SET_OFFERS,
  SET_SKILL_CATEGORIES,
  SET_SKILLS,
  SET_SUB_EDUCATION_LEVEL,
  SET_SUBSCRIPTION_TYPES,
} from "./action-types";

import { IOffersGlobalState, TOffersAction } from "./store.types";
import { SkillsTabEnum } from "../CreateOffer/CreateOfferRequiredSkills/CreateOfferRequiredSkills.enums";

const initialState: IOffersGlobalState = {
  chosenCategory: SkillsTabEnum.required,
  skillCategories: [],
  skills: [],
  list: [],
  draft: {
    list: [],
    selected: [],
  },
  subscriptionTypes: [],
  subEducationLevel: [],
  selectedSubscriptionType: '',
  isUserSubscribed: false,
  couponCode: '',
  form: {
    activeStepIndex: 0,
    completedSteps: {
      0: false,
      1: false,
      2: false,
      3: false,
    },
    values: {
      title: "",
      description: "",
      contractType: "",
      postalCode: "",
      city: "",
      lat: "",
      lng: "",
      educationLevel: [],
      workFields: [],
      workPlaces: [],
      profile: "",
      missions: "",
      required: [],
      bonus: [],
      languages: [],
      keywords: [],
      pace: [],
      startDate: "",
      comment: "",
      subEducationLevel: [],
    },
  },
};

const reducer = (
  state: IOffersGlobalState = initialState,
  action: TOffersAction
) => {
  switch (action.type) {
    case SET_OFFERS:
      return {
        ...state,
        list: action.payload,
      };

    case SET_OFFER_FORM_ACTIVE_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          activeStepIndex: action.payload,
        },
      };

    case SET_OFFER_FORM_COMPLETED_STEP:
      return {
        ...state,
        form: {
          ...state.form,
          completedSteps: {
            ...state.form.completedSteps,
            [action.payload.stepIndex]: action.payload.isCompleted,
          },
        },
      };

    case SET_OFFER_FORM_STEP_VALUES:
      return {
        ...state,
        form: {
          ...state.form,
          values: { ...state.form.values, ...action.payload },
        },
      };

    case offersActionTypes.SET_CHOSEN_CATEGORY:
      return {
        ...state,
        chosenCategory: action.payload,
      };

    case SET_SKILL_CATEGORIES:
      return {
        ...state,
        skillCategories: action.payload,
      };

    case SET_SKILLS:
      return {
        ...state,
        skills: action.payload,
      };

    case CLEAR_OFFER_FORM:
      return {
        ...state,
        form: {
          ...initialState.form,
        },
      };

    case SET_SUBSCRIPTION_TYPES:
      return {
        ...state,
        subscriptionTypes: action.payload,
      };

    case SELECT_SUBSCRIPTION_TYPES:
      return {
        ...state,
        selectedSubscriptionType: action.payload,
      };

    case SET_IS_USER_SUBSCRIBED:
      return {
        ...state,
        isUserSubscribed: action.payload,
      };

    case SET_COUPON_CODE:
      return {
        ...state,
        couponCode: action.payload,
      };

    case SET_SUB_EDUCATION_LEVEL:
      return {
        ...state,
        subEducationLevel: action.payload,
      };

    case offersActionTypes.SET_DRAFT:
      return {
        ...state,
        draft: {
          ...state.draft,
          list: action.payload,
        },
      };

    case offersActionTypes.SET_DRAFT_SELECTED:
      return {
        ...state,
        draft: {
          ...state.draft,
          selected: action.payload,
        },
      };

    case offersActionTypes.RESET:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
