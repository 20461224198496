import ReactDOM from 'react-dom';

import classNames from 'classnames';

import { TModal } from './Modal.types';

import './modal.scss';

export const Modal: TModal = ({
  title,
  description,
  className = '',
  bodyClassName = '',
  actionClassName = '',
  children,
  actionButtons,
  onClose,
  attachToBody,
  bodyRef,
}) => {
  const mainClasses = classNames({
    modal: true,
    [className]: className.length > 0,
  });

  const bodyClasses = classNames({
    modal__body: true,
    [bodyClassName]: actionClassName.length > 0,
  });

  const actionClasses = classNames({
    modal__footer: true,
    [actionClassName]: actionClassName.length > 0,
  });

  const container = document.getElementById('app-modal')!;

  return ReactDOM.createPortal(
    <>
      <div className={mainClasses}>
        <div className="modal__header flex flex-column">

          <div
            className="modal__close"
            role="presentation"
            onClick={onClose}
          ></div>

          <div className="modal__title">{title}</div>

          {description && (
            <div className="modal__description"> {description} </div>
          )}

        </div>

        <div ref={bodyRef} className={bodyClasses}>
          {children}
        </div>

        {attachToBody && (
          <div className="modal__extra-body">{attachToBody}</div>
        )}

        <div className={actionClasses}>{actionButtons}</div>

      </div>
      <span className="backdrop"> </span>
    </>,
    container
  );
};
