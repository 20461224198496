import { settingsActionTypes } from './action-types';

import {
  TSetTriggersAction,
  TSetChosenIdentifierAction,
  TSetUserTriggerAction,
  TSetChosenVariablesAction,
  TResetChosenTriggerAction,
  TSetCanResetAction,
  TSetInEditionAction,
  TSetActiveModuleAction,
  TSetEditModeAction,
  TSetWebsiteEditAction,
  TSetWebsiteDesignAction,
  TResetWebsiteDesignAction,
  TSetMyAccountAction,
  TSetYPareoAction,
  TResetYPareoAction,
  TSetModalsAction,
  TResetStateAction,
  TSetUploadAttachmentAction,
  TSetEmailsAction,
  TSetEmailAction, TResetEmailAction, TSetInCreationAction,
} from './store.types';

export const setActiveModule: TSetActiveModuleAction = (payload) => ({
  type: settingsActionTypes.SET_ACTIVE_MODULE,
  payload,
});

export const setWebsiteEdit: TSetWebsiteEditAction = (payload) => ({
  type: settingsActionTypes.SET_WEBSITE_EDIT,
  payload,
});

export const setTriggers: TSetTriggersAction = (payload) => ({
  type: settingsActionTypes.SET_TRIGGERS,
  payload,
});

export const setEmails: TSetEmailsAction = (payload) => ({
  type: settingsActionTypes.SET_EMAILS,
  payload,
});

export const setEmail: TSetEmailAction = (payload) => ({
  type: settingsActionTypes.SET_EMAIL,
  payload,
});

export const resetEmail: TResetEmailAction = () => ({
  type: settingsActionTypes.RESET_EMAIL,
});

export const setChosenIdentifier: TSetChosenIdentifierAction = (payload) => ({
  type: settingsActionTypes.SET_CHOSEN_IDENTIFIER,
  payload,
});

export const setUserTrigger: TSetUserTriggerAction = (payload) => ({
  type: settingsActionTypes.SET_USER_TRIGGER,
  payload,
});

export const setChosenVariables: TSetChosenVariablesAction = (payload) => ({
  type: settingsActionTypes.SET_CHOSEN_VARIABLES,
  payload,
});

export const resetChosenTrigger: TResetChosenTriggerAction = () => ({
  type: settingsActionTypes.RESET_CHOSEN_TRIGGER,
});

export const setCanReset: TSetCanResetAction = (payload) => ({
  type: settingsActionTypes.SET_CAN_RESET,
  payload,
});

export const setInEdition: TSetInEditionAction = (payload) => ({
  type: settingsActionTypes.SET_IN_EDITION,
  payload,
});

export const setInCreation: TSetInCreationAction = (payload) => ({
  type: settingsActionTypes.SET_IN_CREATION,
  payload,
});

export const setEditMode: TSetEditModeAction = (payload) => ({
  type: settingsActionTypes.SET_EDIT_MODE,
  payload
});

export const setMyAccount: TSetMyAccountAction = (payload) => ({
  type: settingsActionTypes.SET_MY_ACCOUNT,
  payload
});

export const setYPareo: TSetYPareoAction = (payload) => ({
  type: settingsActionTypes.SET_YPAREO,
  payload
});

export const resetYPareo: TResetYPareoAction = () => ({
  type: settingsActionTypes.RESET_YPAREO,
});

export const setWebsiteDesign: TSetWebsiteDesignAction = (payload) => ({
  type: settingsActionTypes.SET_WEBSITE_DESIGN,
  payload
});

export const resetWebsiteDesign: TResetWebsiteDesignAction = () => ({
  type: settingsActionTypes.RESET_WEBSITE_DESIGN,
});

export const setModals: TSetModalsAction = (payload) => ({
  type: settingsActionTypes.SET_MODALS,
  payload
});

export const setUploadAttachment: TSetUploadAttachmentAction = (payload) => ({
  type: settingsActionTypes.SET_UPLOAD_ATTACHMENT,
  payload
});

export const resetStateSettings: TResetStateAction = () => {
  return {
    type: settingsActionTypes.RESET,
  };
};
