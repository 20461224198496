import { Modal, Button } from 'components';

import { locales } from 'constants/index';

import { TConfirmModal } from './ConfirmModal.types';

import './confirm-modal.scss';

export const ConfirmModal: TConfirmModal = ({
  title,
  description,
  onClose,
  onApply,
}) => {
  return (
    <Modal
      title={title}
      className="confirm-modal"
      onClose={onClose}
      actionButtons={
        <span className="confirm-modal__actionGroup">
          <Button
            variant="gray"
            onClick={onClose}
          >
            {locales.general.cancel}
          </Button>
          <Button
            variant="danger"
            onClick={onApply}
          >
            {locales.general.delete}
          </Button>
        </span>
      }
    >
      <div className="confirm-modal__description">{description}</div>
    </Modal>
  );
};
