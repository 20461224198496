"use client";

import { Modal } from "@mantine/core";

export default Modal.extend({
	defaultProps: {
		centered: true,
		overlayProps: {
			backgroundOpacity: 0.5,
			blur: 4
		},
		radius: 'sm',
		h: '80%',
		transitionProps: {
			transition: 'fade-up'
		},
	},
})