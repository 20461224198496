import { authenticateUserAction } from 'modules/Authentication/store/actions';

import {
  INIT_APP,
  SET_CONFIG,
  SET_LOCALE,
  SET_USER_INFO,
  SET_IS_APP_LOADED,
  SET_DEGREES,
  SET_CLASSES,
  SET_TAGS,
  SET_IS_LOADING,
  appActionTypes,
} from './action-types';

import {
  TInitAppAction,
  TSetConfigAction,
  TSetLocaleAction,
  TSetUserInfoAction,
  TSetIsAppLoaded,
  TSetDegrees,
  TSetClasses,
  TSetTags,
  TSetIsLoading,
  TResetStateAction,
} from './store.types';

export const initAppAction: TInitAppAction = (locale) => ({
  type: INIT_APP,
  locale,
});

export const setConfigAction: TSetConfigAction = (config) => ({
  type: SET_CONFIG,
  config,
});

export const setLocaleAction: TSetLocaleAction = (locale) => ({
  type: SET_LOCALE,
  locale,
});

export const setUserInfoAction: TSetUserInfoAction = (payload) => ({
  type: SET_USER_INFO,
  payload,
});

export const setIsAppLoaded: TSetIsAppLoaded = (isAppLoaded) => ({
  type: SET_IS_APP_LOADED,
  payload: isAppLoaded,
});

export const setDegrees: TSetDegrees = (degrees) => ({
  type: SET_DEGREES,
  degrees,
});

export const setClasses: TSetClasses = (classes) => ({
  type: SET_CLASSES,
  classes,
});

export const setTags: TSetTags = (tags) => ({
  type: SET_TAGS,
  tags,
});

export const setIsLoading: TSetIsLoading = (isLoading, type = 'normal' ) => ({
  type: SET_IS_LOADING,
  payload: { type, isLoading },
});

export const resetStateApp: TResetStateAction = () => {
  return {
    type: appActionTypes.RESET,
  };
};


export { authenticateUserAction };
