import { memo } from "react";
import classNames from "classnames";
import { Checkbox } from "@mantine/core";

import { DraggableColumn } from "components";

import Item from "../Item";

import { TColumnUI } from "./Column.types";

import "./column.scss";

const ColumnUI: TColumnUI = ({
    className,
    data: { column, pipeItems, selectedColumnCandidateIds, },
    handlers: {
        handleCandidateClick,
        handleSelectCandidate,
        handleCopySocialMedia,
        handleViewCv,
        handleSelectColumn,
    },
}) => {
    const emailColumnValue = "62de937a98c747f4cd8d6b85";
    const archivedColumnValue = "62de938298c747f4cd8d6b89";

    const titleClasses = classNames({
        column__title: true,
        column__title__archive: column.value === archivedColumnValue,
        [className]: !!className,
    });

    return (
        <div className="column">
            <div className="column__title__wrapper">
                <div className={titleClasses}>
                    <Checkbox
                        indeterminate={ !!selectedColumnCandidateIds.length && selectedColumnCandidateIds.length !== pipeItems.length }
                        checked={ selectedColumnCandidateIds.length === pipeItems.length && pipeItems.length > 0 }
                        size="sm"
                        aria-label="Select all column's cards"
                        onChange={ handleSelectColumn(column.value) }
                    />
                    {column.label}

                    <span className="column-items-number">{`(${pipeItems.length})`}</span>

                    {column.value === emailColumnValue && <div className="column__send-email" />}
                </div>
            </div>
            <DraggableColumn className="column__body" key={column.value} id={column.value}>
                {pipeItems.map((item, index: number) => (
                    <Item
                        key={item._id}
                        data={{
                            item,
                            index,
                        }}
                        handlers={{
                            handleCandidateClick,
                            handleSelectCandidate,
                            handleCopySocialMedia,
                            handleViewCv,
                        }}
                    />
                ))}
            </DraggableColumn>
        </div>
    );
};

export default memo(ColumnUI);
