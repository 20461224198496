import Joi from "joi";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { memo, useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { locales } from "constants/index";
import { useExtractServerError, useToast, useValidate } from "hooks";
import { IStoreState } from "store";

import { setIsLoading } from "modules/App/store/actions";
import { setModalData, setModalType, setModalValues } from "../store/actions";

import { createApprenantApi, fetchApprenantApi } from "./api";

import { IDropdownObject } from "common";
import type {
    TAddToYPareoModal,
    THandleTextInputChange,
    THandleTextInputBlur,
    THandleDropdownChange,
    THandleDropdownBlur, THandleCheckboxChange
} from './AddToYPareoModal.types';
import { AddToYPareoModalEnums } from './AddToYPareoModal.enums';
import { ModalsTypesEnum } from "../Modals.enums";
import { ICreateApprenantApiPayload } from "./api/api.types";

import SelectCvsModalUI from "./AddToYPareoModal.ui";


const validationSchema: Record<AddToYPareoModalEnums, Joi.Schema> = {
    student_id: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    firstname: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    lastname: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    city: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    zip_code: Joi.number().required()
        .messages({
            'any.required': locales.errors.required,
            'number.base': locales.errors.number.base,
        }),
    email_address: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    phone_number: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    gender_code: Joi.number().required()
        .messages({
            'any.required': locales.errors.required,
            'number.base': locales.errors.required,
        }),
    nationality_code: Joi.number().required()
        .messages({
            'any.required': locales.errors.required,
            'number.base': locales.errors.required,
        }),
    handicapped: Joi.number(),
    date_of_birth: Joi.string()
        .pattern(/^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/)
        .required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
            'string.pattern.base': locales.errors.pattern.date,
        }),
    force: Joi.boolean(),
};

const AddToYPareoModal: TAddToYPareoModal = ({
    onSubmit,
}) => {
    dayjs.extend(advancedFormat);
    dayjs.extend(customParseFormat);

    const modalType = ModalsTypesEnum.ADD_TO_YPAREO;

    //* Hooks Init
    const toast = useToast();
    const dispatch = useDispatch();
    const { extractErrorMessage } = useExtractServerError();
    const { errors, validate, setErrors } = useValidate();


    //* Redux State
    const modal = useSelector(({ modals: { [modalType]: modals } }: IStoreState) => modals, shallowEqual);


    //* Memos
    const { studentInfo  } = useMemo(() => modal.data, [modal.data]);


    //* Side Effects
    const initRedux = useCallback(() => {
        if (!studentInfo) return;
        const { values } = modal;

        dispatch(setModalValues(modalType, {
            student_id: studentInfo.student_info._id,
            firstname: !!values.firstname ? values.firstname : studentInfo.name.firstname,
            lastname: !!values.lastname ? values.lastname : studentInfo.name.lastname,
            city: !!values.city ? values.city : studentInfo.info.address.city,
            zip_code: !!values.zip_code ? values.zip_code : studentInfo.info.address.postal_code ? `${studentInfo.info.address.postal_code}` : '',
            email_address: !!values.email_address ? values.email_address : studentInfo.contact_info.email_address,
            phone_number: !!values.phone_number ? values.phone_number : studentInfo.contact_info.phone_number ? `${studentInfo.contact_info.phone_number}` : '',
        }));
    }, [modal.values, studentInfo])

    //* API Actions
    const createApprenant = useCallback(async () => {
        try {
            const { values } = modal;
            if (!values.student_id) return;
            dispatch(setIsLoading(true));


            const payload: ICreateApprenantApiPayload = {
                student_id: values.student_id,
                firstname: values.firstname,
                lastname: values.lastname,
                city: values.city,
                zip_code: values.zip_code,
                email_address: values.email_address,
                phone_number: values.phone_number,
                gender_code: values.gender_code,
                nationality_code: values.nationality_code,
                handicapped: values.handicapped ? 1 : 0,
                date_of_birth: values.date_of_birth.trim(),
                force: values.force,
            };

            const isDateValid = dayjs(payload.date_of_birth, 'DD/MM/YYYY', true).isValid();

            if (!isDateValid) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [AddToYPareoModalEnums.date_of_birth]: locales.errors.invalidDate
                }));
            }

            const isValid = validate({ data: payload as any, validationSchema });

            if (!isValid || !isDateValid) return batch(() => {
                dispatch(setIsLoading(false));
            });


            await createApprenantApi(payload);

            toast({ type: 'success', message: locales.modals.yPareoModal.success });

            batch(() => {
                dispatch(setIsLoading(false));
            });

            onSubmit && onSubmit();
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, onSubmit, modal.values, studentInfo]);

    const fetchApprenant = useCallback(async () => {
        try {
            if (!studentInfo?.student_info?._id) return;

            dispatch(setIsLoading(true));

            const payload = Object.assign({
                student_id: studentInfo.student_info._id,
            });

            const { data: { ypareo } } = await fetchApprenantApi(payload);

            batch(() => {
                dispatch(setModalData(modalType, { canForce: ypareo }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, studentInfo?.student_info?._id]);

    const fetchOptions = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const genderCodes: IDropdownObject[] = [
                {
                    label: 'Mme.',
                    value: '2',
                },
                {
                    label: 'Mr.',
                    value: '1',
                },
            ];

            const nationalityCodes: IDropdownObject[] = [
                {
                    label: 'France',
                    value: '1',
                },
                {
                    label: 'Autre',
                    value: '0',
                },
            ];

            batch(() => {
                dispatch(setModalData(modalType, {
                    options: { genderCodes, nationalityCodes }
                }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, studentInfo]);


    //* Handlers
    const handleTextInputChange: THandleTextInputChange = useCallback(({ target: { name, value } }) => {
        batch(() => {
            dispatch(setModalValues(modalType, { [name]: value }));
        });

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }, [dispatch]);

    const handleTextInputBlur: THandleTextInputBlur = useCallback(({ target: { name, value } }) => {
        if (!validationSchema[name as AddToYPareoModalEnums]) return;
        const isValid = validate({
            data: { [name]: value.trim() },
            validationSchema: { [name]: validationSchema[name as AddToYPareoModalEnums] },
        });

        if ( isValid ) return;
        const element = document.getElementById(name);
        setTimeout(() => element?.scrollIntoView(), 0);
    }, [dispatch, validate]);

    const handleDropdownChange: THandleDropdownChange = useCallback((name) => (values) => {
        if (Array.isArray(values)) return;
        dispatch(setModalValues(modalType, { [name]: values.value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }, [dispatch]);

    const handleDropdownBlur: THandleDropdownBlur = useCallback((name) => () => {
        if (!validationSchema[name as AddToYPareoModalEnums]) return;
        validate({
            data: { [name]: modal.values[name] } as any,
            validationSchema: { [name]: validationSchema[name as AddToYPareoModalEnums] },
        });
    }, [modal.values]);

    const handleCheckboxChange: THandleCheckboxChange = useCallback((name) => () => {
        batch(() => {
            dispatch(setModalValues(modalType, { [name]: !modal.values[name] }));
        });

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }, [dispatch, modal.values]);

    const handleSubmitClick = useCallback(async () => {
        await createApprenant();
    }, [dispatch, onSubmit, createApprenant]);

    const handleClose = useCallback(() => {
        dispatch(setModalType(modalType, false));
    }, [dispatch]);


    //* Effects
    useEffect(() => {
        initRedux();
        fetchOptions();
        fetchApprenant();
    }, []);

    useEffect(() => {
        if (!studentInfo?.student_info?._id) return;
        initRedux();
    }, [studentInfo]);


    //* Wrappers
    const values = useMemo(() => modal.values, [modal.values]);

    const data = useMemo(() => modal.data, [modal.data]);

    const handlers = useMemo(() => ({
        handleClose,
        handleSubmitClick,
        handleTextInputChange,
        handleTextInputBlur,
        handleDropdownChange,
        handleDropdownBlur,
        handleCheckboxChange,
    }), [
        handleClose,
        handleSubmitClick,
        handleTextInputChange,
        handleTextInputBlur,
        handleDropdownChange,
        handleDropdownBlur,
        handleCheckboxChange,
    ]);

    if (!modal.isOpen) return null;

    return <SelectCvsModalUI
        values={values}
        data={data}
        handlers={handlers}
        errors={errors}
    />;
};

export default memo(AddToYPareoModal);
