import React from "react";
import { Link } from "react-router-dom";
import { memo } from "react";

import { TBreadcrumbUI } from './Breadcrumb.types';

import './breadcrumb.scss';

const BreadcrumbUI: TBreadcrumbUI = ({
    data: {
        crumbs,
    }
}) => {
    return (
    <div className="breadcrumb">
        {crumbs.map((crumb, index) =>
            <React.Fragment key={index}>
                <div className="breadcrumb__container">
                    <Link className='breadcrumb__content' to={crumb.path} children={crumb.name} />
                </div>
                <div className="breadcrumb__container">
                    <div className="breadcrumb__content separator">/</div>
                </div>
            </React.Fragment>
        )}
    </div>
    );
};

export default memo(BreadcrumbUI);
