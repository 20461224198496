import { baseApi } from 'utils';
import { fetchUserInfoApi, updateUserInfoApi } from 'modules/App/api';
import { fetchOffersApi, fetchOffersStatisticsApi } from "modules/Offers/api";
import {
  TUpdateCompanyProfileApi,
  TFetchAttachmentsApi,
  TUploadAttachmentsApi,
  TDeleteAttachmentApi,
  TFetchTriggersApi,
  TFetchTriggerApi,
  TFetchEmailTemplateApi,
  TFetchEmailTemplateMultiApi,
  TSubmitEmailTemplateApi,
  TCreateDesignApi,
  TUpdateDesignApi,
  TFetchCoursesApi,
  TRemoveCourseApi,
  TUpdateCourseApi,
  TCreateCourseApi,
  TFetchTeachersApi,
  TCreateTeachersApi,
  TRemoveTeachersApi,
  TUpdateTeachersApi,
  TFetchSiteConfigApi,
  TFetchDesignApi,
  TDeleteDesignApi,
  TCreateInviteApi,
  TFetchInvitesApi,
  TUpdateInviteApi,
  TDeleteInviteApi,
  TFetchTokenApi,
  TCreateTokenApi,
  TDeleteTokenApi, TUpdateEmailTemplateApi, TCreateEmailTemplateApi, TDeleteEmailTemplateApi,
} from './api.types';

// TODO: UPDATE TO SCHOOL
export const updateCompanyProfileApi: TUpdateCompanyProfileApi = (payload) => {
  return baseApi().patch('/companies/user/profile', {
    attachment_id: payload.attachmentId,
    company_description: payload.companyDescription,
  });
};

export const fetchAttachmentsApi: TFetchAttachmentsApi = (params) => {
  return baseApi().get('/attachments/private/fetch', { params });
};

export const uploadAttachmentsApi: TUploadAttachmentsApi = (payload) => {
  return baseApi().post('/attachments/private/upload', payload);
};

export const deleteAttachmentApi: TDeleteAttachmentApi = (params) => {
  return baseApi().delete('/attachments/private/upload', { params });
};

export const fetchTriggersApi: TFetchTriggersApi = () => {
  return baseApi().get('/template/triggers');
};

export const fetchTriggerApi: TFetchTriggerApi = (triggerId) => {
  return baseApi().get('/template/trigger', {params: {_id: triggerId}});
};

export const fetchEmailTemplateApi: TFetchEmailTemplateApi = (triggerIdentifier) => {
  return baseApi().get('/template/email', {params: { trigger_identifier: triggerIdentifier }});
};

export const fetchEmailTemplateMultiApi: TFetchEmailTemplateMultiApi = () => {
  return baseApi().get('/template/emails');
};

export const updateEmailTemplateApi: TUpdateEmailTemplateApi = (payload) => {
  return baseApi().patch('/template/email', payload);
};

export const createEmailTemplateApi: TCreateEmailTemplateApi = (payload) => {
  return baseApi().post('/template/email', payload);
};

export const deleteEmailTemplateApi: TDeleteEmailTemplateApi = (params) => {
  return baseApi().delete('/template/email', { params });
};

export const submitEmailTemplateApi: TSubmitEmailTemplateApi = (payload) => {
  return baseApi().post('/template/email', payload);
};

export const fetchSiteConfigApi: TFetchSiteConfigApi = (headers) => {
  return baseApi().get('/config/site', { headers });
};

export const fetchDesignApi: TFetchDesignApi = (params) => {
  return baseApi().get('/config/design', { params });
};

export const createDesignApi: TCreateDesignApi = (payload) => {
  return baseApi().post('/config/design', payload);
};

export const updateDesignApi: TUpdateDesignApi = (payload) => {
  return baseApi().patch('/config/design', payload);
};

export const deleteDesignApi: TDeleteDesignApi = (params) => {
  return baseApi().delete('/config/design', { params });
};

export const fetchCoursesApi: TFetchCoursesApi = (payload) => {
  return baseApi().get('/formations', { ...payload });
};

export const removeCourseApi: TRemoveCourseApi = (params) => {
  return baseApi().delete('/formations', { params });
};

export const updateCourseApi: TUpdateCourseApi = (payload) => {
  return baseApi().patch('/formations', payload);
};

export const createCourseApi: TCreateCourseApi = (payload) => {
  return baseApi().post('/formations', payload);
};

export const fetchTeachersApi: TFetchTeachersApi = (params) => {
  return baseApi().get('/teachers', { params });
};

export const createTeachersApi: TCreateTeachersApi = (payload) => {
  return baseApi().post('/teachers', payload);
};

export const removeTeachersApi: TRemoveTeachersApi = (params) => {
  return baseApi().delete('/teachers', { params });
};

export const updateTeachersApi: TUpdateTeachersApi = (payload) => {
  return baseApi().patch('/teachers', payload);
};

export const createInviteApi: TCreateInviteApi = (payload) => {
  return baseApi().post('/users/invite', payload);
};

export const fetchInvitesApi: TFetchInvitesApi = (params) => {
  return baseApi().get('/users/invitations', { params });
};

export const updateInviteApi: TUpdateInviteApi = (payload) => {
  return baseApi().patch('/users/invitation', payload);
};

export const deleteInviteApi: TDeleteInviteApi = (params) => {
  return baseApi().delete('/users/invitation', { params });
};

export const fetchTokenApi: TFetchTokenApi = (params) => {
  return baseApi().get('/school/ypareo/token', { params });
}

export const createTokenApi: TCreateTokenApi = (payload) => {
  return baseApi().post('/school/ypareo/token', payload);
}

export const deleteTokenApi: TDeleteTokenApi = () => {
  return baseApi().delete('/school/ypareo/token');
}

export {
  fetchUserInfoApi,
  updateUserInfoApi,
  fetchOffersApi,
  fetchOffersStatisticsApi,
};
