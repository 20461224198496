import {
  Modal,
  Dropdown,
  FormControl,
  Dropzone,
  Button,
  ResumesTable,
} from 'components';

import { locales } from 'constants/index';

import { TAddCvsModal } from './AddCvsModal.types';

import styles from './add-cvs-modal.module.scss';

export const AddCvsModal: TAddCvsModal = ({
  selectedClass,
  selectedDegree,
  selectedResume,
  classes,
  degrees,
  tags,
  config,
  uploadedResumes,
  onClose,
  onUploadFile,
  onAddResumes,
  onSelectedClassChange,
  onSelectedDegreeChange,
  bodyRef,
}) => {
  return (
    <Modal
      bodyRef={bodyRef}
      title={locales.resumes.addCVs}
      description={locales.resumes.addCVsDescription}
      className={styles["add-cvs-modal"]}
      onClose={onClose}
      actionClassName={styles["add-cvs-modal__actions"]}
      actionButtons={
        <Button
          variant="primary"
          onClick={onAddResumes}
          isDisabled={
            uploadedResumes.length === 0 ||
            selectedDegree.length === 0 ||
            selectedClass.length === 0
          }
        >
          {locales.resumes.addCVs}
        </Button>
      }
    >
      <div className={styles["add-cvs-modal__content"]}>
        <div className={styles["add-cvs-modal__dropdowns"]}>
          <div className={styles["add-cvs-modal__dropdown"]}>
            <FormControl id="degree" withRef>
              <Dropdown
                isFixedHeight
                placeholder={locales.general.level}
                id="degree"
                options={degrees}
                value={degrees.find(
                  (degree) => degree.value === selectedDegree
                )}
                onChange={onSelectedDegreeChange}
              />
            </FormControl>
          </div>
          <div className={styles["add-cvs-modal__dropdown"]}>
            <FormControl id="degree" withRef>
              <Dropdown
                isFixedHeight
                placeholder={locales.general.promotions}
                id="degree"
                options={classes}
                value={classes.find((cls) => cls.value === selectedClass)}
                onChange={onSelectedClassChange}
              />
            </FormControl>
          </div>
        </div>

        <div className={styles["add-cvs-modal__drag-drop-container"]}>
          <div className={styles["add-cvs-modal__drag-drop"]}>
            <Dropzone
              description={locales
                .formatString(locales.resumes.uploadCvsDropzoneDescription, {
                  maxFileSize: (config.fileSize / 1000000).toFixed(2),
                  fileTypes: config.filesType
                    .map((type) => {
                      return type.split('/')[1];
                    })
                    .join(', '),
                })
                .toString()}
              onUploadFile={onUploadFile}
              fileTypes={config.filesType}
              maxSize={config.fileSize}
              maxFiles={config.maxFiles}
            />
          </div>

          {uploadedResumes.length > 0 && (
            <div className={styles["add-cvs-modal__resumes"]}>
              <ResumesTable
                resumes={uploadedResumes}
                selectedResume={selectedResume}
                className={styles["add-cvs-modal__resumes-table"]}
                tags={tags}
                isSmallRow
                isResumeFileVisible={false}
                isTagsVisible={false}
                isResumeNameEditable={false}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};
