import { ForwardRefRenderFunction } from 'react';

import ReactQuill, { UnprivilegedEditor, Range } from 'react-quill';
import { Delta, DeltaStatic, Sources } from 'quill';

export type TRichTextEditorOnBlur = (
    previousSelection: Range,
    source: Sources,
    editor: UnprivilegedEditor
) => void;

type TRichTextEditorOnChange = (
    content: string,
    delta: Delta,
    source: Sources,
    editor: UnprivilegedEditor
) => void;

export type {
  TRichTextEditorOnChange,
}

interface IProps {
  id: string;
  value: string | Delta;
  className?: string;
  placeholder?: string;
  limit?: number;
  readOnly?: boolean;
  isDark?: boolean;
  isBorderless?: boolean;
  notRounded?: boolean;
  onChange: TRichTextEditorOnChange;
  onBlur?: TRichTextEditorOnBlur;
}

type TRichTextEditor = ForwardRefRenderFunction<ReactQuill, IProps>;

export enum RichTextEditorSourcesEnum {
  api = "api",
  user = "user",
  silent = "silent",
}

export type {
  TRichTextEditor,
  Delta,
  DeltaStatic,
  Sources,
  UnprivilegedEditor,
  IProps as IRichTextEditor,
};
