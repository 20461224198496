"use client";

import { Combobox } from "@mantine/core";

import classNames from 'styles/sass/components/Combobox.module.scss';

export default Combobox.extend({
	defaultProps: {
		size: 'md',
        width: '500px'
	},
    classNames,
})