import ClassNames from "classnames";
import ReactPaginate from 'react-paginate';

import { Icons } from 'assets';
import { TPagination } from './Pagination.types';

import './pagination.scss';

export const Pagination: TPagination = (props) => {
  const {
    className = '',
    selectedPage,
    totalPages,
    marginPagesDisplayed = 1,
    pageRangeDisplayed = 5,
    onPageChange,
  } = props;

  const classNames = ClassNames({
      'pagination flex-align-items-center': true,
      [className]: !!className,
  });

  return (
    <ReactPaginate
      containerClassName={classNames}
      previousClassName="pagination__previous"
      previousLinkClassName="pagination__previous-link flex flex-align-items-center flex-justify-content-center"
      nextClassName="pagination__next"
      nextLinkClassName="pagination__next-link flex flex-align-items-center flex-justify-content-center"
      pageClassName="pagination__page"
      pageLinkClassName="pagination__page-link flex flex-align-items-center flex-justify-content-center"
      breakClassName="pagination__break"
      breakLinkClassName="pagination__break-link flex flex-align-items-center flex-justify-content-center"
      activeClassName={'pagination__active'}
      breakLabel={'...'}
      pageCount={totalPages}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={onPageChange}
      forcePage={selectedPage - 1}
      previousLabel={
        <img
          src={Icons.LeftArrowWhite}
          alt="arrow"
          className="pagination__arrow pagination__arrow--prev"
        />
      }
      nextLabel={
        <img
          src={Icons.RightArrowWhite}
          alt="arrow"
          className="pagination__arrow pagination__arrow--next"
        />
      }
    />
  );
};
