import { memo } from "react";

import {Button, Card, Dropdown, EditList, FormControl, Modal, Section, TextInput} from "components";
import { locales } from "constants/index";

import { TUsersUI } from './Users.types';

const UsersUI: TUsersUI = ({
    data: {
        guests
    },
    modals,
    options,
    handlers,
    errors
}) => {
    return <>
        <Section isColumns title={locales.settings.myAccount.users.users}>
            <EditList
                type='guests'
                list={guests}
                handlers={handlers.editHandlers}
            />

            <div className='flex flex-justify-content-center'>
                <Button
                    variant='primary'
                    children={locales.settings.myAccount.users.add}
                    onClick={handlers.editHandlers.handleCreate}
                />
            </div>
        </Section>

        {(modals.edit || modals.create) && <Modal
            title={
                modals.create
                    ? locales.settings.myAccount.users.add
                    : locales.settings.myAccount.users.update
            }
            onClose={handlers.handleModalClose(modals.create ? 'create' : 'edit')}
        >
            <Card
                isRounded={false}
                hasShadow={false}
                hasPadding={false}
                isColumns={false}
                showAction={true}
                actionHandler={handlers.handleSave(modals.create ? 'guest' : 'guestUpdate')}
            >
                <FormControl
                    id="roleId"
                    className='customize-website__fc-columns'
                    label={locales.settings.myAccount.users.role}
                    withRef
                >
                    <Dropdown
                        options={options.roles}
                        value={options.roles.find(role => role.value === `${modals.data?.roleId}`)}
                        onChange={handlers.handleGuestDropdown('roleId')}
                        isError={!!errors.modal}
                    />
                </FormControl>
                <FormControl
                    className='customize-website__fc-columns'
                    label={locales.general.email}
                    withRef
                >
                    <TextInput
                        onChange={handlers.handleGuestChange()}
                        value={modals.data?.contactInfo?.emailAddress ?? ''}
                        isError={!!errors.modal}
                    />
                </FormControl>
            </Card>
        </Modal>}

        {modals.delete && <Modal
          title={locales.settings.myAccount.users.delete}
          onClose={handlers.handleModalClose('delete')}
        >
            <Card
                isRounded={false}
                hasShadow={false}
                hasPadding={false}
                isColumns={false}
                className='customize-website__card'
            >
            <Button
                variant='danger'
                children={locales.general.delete}
                onClick={handlers.handleSave('guestDelete')}
            />
            </Card>
        </Modal>}
    </>;
};

export default memo(UsersUI);
