import { baseApi } from "utils";

import {
    TFetchPipesApi,
    TFetchPipesByIdApi,
    TPostPipeApi,
    TUpdatePipeApi,
    IFetchPipesApiResponse,
    IFetchPipesByIdApiResponse,
    TFetchOffersOptionsApi,
    IFetchOffersOptionsResponse,
    TFetchStudentInfoApi,
    IFetchStudentInfoResponse,
    TFetchStudentReviewsApi,
    IFetchStudentReviewsResponse,
    TSubmitStudentReviewApi,
    ISubmitStudentReviewApiResponse,
    TFetchStudentCommentsApi,
    IFetchStudentCommentsApiResponse,
    TDownloadCvApi,
    IDownloadCvApiResponse,
    TPostStudentCommentApi,
    TUpdateStatusApi,
    TFetchScoringApi,
    TFetchMatchingApi,
    TFetchHistoryApi,
    TExportCandidatesApi,
} from "./api.types";

// TODO: UPDATE TO SCHOOL
export const fetchPipesApi: TFetchPipesApi = ({ cancelToken, ...params }) => {
    return baseApi().get<IFetchPipesApiResponse>("/company/pipe/fetch", {
        cancelToken,
        params,
    });
};

// TODO: UPDATE TO SCHOOL
export const fetchPipesByIdApi: TFetchPipesByIdApi = (payload) => {
    return baseApi().get<IFetchPipesByIdApiResponse>("/company/pipe/candidates", {
        params: payload,
    });
};

// TODO: UPDATE TO SCHOOL
export const postPipeApi: TPostPipeApi = (payload) => {
    return baseApi().post("/company/pipe/create", payload);
};

// TODO: UPDATE TO SCHOOL
export const updatePipeApi: TUpdatePipeApi = (payload) => {
    return baseApi().put("/company/pipe/update", payload);
};

export const updateStatusApi: TUpdateStatusApi = (payload) => {
    return baseApi().patch("/company/pipe/candidates", payload);
};

export const fetchOffersOptionsApi: TFetchOffersOptionsApi = () => {
    return baseApi().get<IFetchOffersOptionsResponse>("/offers/school/pipe");
};

export const fetchStudentInfoApi: TFetchStudentInfoApi = (params) => {
    return baseApi().get<IFetchStudentInfoResponse>("/school/cvs/parsed", {
        params: { application_id: params.applicationId },
    });
};

export const fetchStudentReviewsApi: TFetchStudentReviewsApi = (userId) => {
    return baseApi().get<IFetchStudentReviewsResponse>("/reviews/fetch", {
        params: { userId },
    });
};

export const submitReviewApi: TSubmitStudentReviewApi = (payload) => {
    return baseApi().post<ISubmitStudentReviewApiResponse>("/reviews/create", payload);
};

export const fetchStudentCommentsApi: TFetchStudentCommentsApi = (id) => {
    return baseApi().get<IFetchStudentCommentsApiResponse>("/comments/school", {
        params: {
            userId: id,
        },
    });
};

export const postStudentCommentApi: TPostStudentCommentApi = (payload) => {
    return baseApi().post("/comments/create", payload);
};

export const downloadCvApi: TDownloadCvApi = (payload) => {
    return baseApi().post<IDownloadCvApiResponse>("/attachments/download", payload);
};

export const fetchScoringApi: TFetchScoringApi = ({ cancelToken, ...params }) => {
    return baseApi().get("/matching/scoring", { params });
};

export const fetchMatchingApi: TFetchMatchingApi = ({ cancelToken, ...params }) => {
    return baseApi().get("/matching/offer", { cancelToken, params });
};

export const fetchHistoryApi: TFetchHistoryApi = (params) => {
    return baseApi().get("/activities/history", { params });
};

export const exportCvApi: TExportCandidatesApi = (payload) => {
    return baseApi().post("/matching/export", payload, { responseType: "blob" });
};
