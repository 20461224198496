import { memo } from "react";

import { TextInput, FormControl, Button, Checkbox } from 'components';

import AuthenticationLayout from '../AuthenticationLayout';

import { locales } from 'constants/index';
import { formSubmit } from 'utils';
import { Icons, Images } from 'assets';

import { TGuestUI } from './Guest.types';

import './guest.scss';

const GuestUi: TGuestUI = ({
  data: { firstname, lastname, password, passwordConfirmation, acceptTerms, isPasswordVisible },
  errors,
  handlers: {
    handleInputChange,
    handleInputBlur,
    handlePasswordVisibilityChange,
    handleAcceptTermsChange,
    handleSubmit,
  },
}) => {
  return (
    <AuthenticationLayout mainImage={Images.BusinessDeal} isLeftContentRounded>
      <div className="login flex flex-justify-content-center flex-align-items-center">
        <div className="login__content flex flex-column">
          <div className="login__title">{locales.guest.guest}</div>

          <div className="login__description">{locales.guest.description}</div>

          <form onSubmit={formSubmit} className="login__form">
            <FormControl
              id="firstname"
              label={locales.general.firstName}
              error={errors.firstname}
              className="login__field"
              isRequired
              withRef
            >
              <TextInput
                id="firstname"
                name="firstname"
                value={firstname}
                isError={!!errors.firstname}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="login__field-input"
              />
            </FormControl>
            <FormControl
              id="lastname"
              label={locales.general.lastName}
              error={errors.lastname}
              className="login__field"
              isRequired
              withRef
            >
              <TextInput
                id="lastname"
                name="lastname"
                value={lastname}
                isError={!!errors.lastname}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="login__field-input"
              />
            </FormControl>

            <FormControl
              id="password"
              label={locales.general.password}
              error={errors.password}
              className="login__field"
              isRequired
              elementClassName='flex'
              withRef
            >
              <TextInput
                id="password"
                name="password"
                type={isPasswordVisible ? 'text' : 'password'}
                value={password}
                isError={!!errors.password}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="login__field-input"
                rightContent={
                  <img
                    src={isPasswordVisible ? Icons.EyeGray : Icons.EyeClosed}
                    alt="eye"
                    className="login__password-icon"
                    onClick={handlePasswordVisibilityChange}
                  />
                }
              />
            </FormControl>

            <FormControl
              id="passwordConfirmation"
              label={locales.registration.passwordConfirmation}
              error={errors.passwordConfirmation}
              className="login__field"
              isRequired
              elementClassName='flex'
              withRef
            >
              <TextInput
                id="passwordConfirmation"
                name="passwordConfirmation"
                type={isPasswordVisible ? 'text' : 'password'}
                value={passwordConfirmation}
                isError={!!errors.passwordConfirmation}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className="login__field-input"
              />
            </FormControl>
          </form>

          <Checkbox
            label={locales.registration.terms}
            isChecked={acceptTerms}
            className="register__terms"
            onChange={handleAcceptTermsChange}
          />

          <div className="login__submit flex flex-justify-content-center">
            <Button
              variant="primary"
              onClick={handleSubmit}
            >
              {locales.general.next}
            </Button>
          </div>

        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default memo(GuestUi);
