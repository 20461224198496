import { useState, useCallback, useMemo, memo, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch, batch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Joi from "joi";

import { useToast, useExtractServerError, useValidate } from "hooks";
import { convertToDropdown } from "utils";
import type { IDropdownObject } from "common";

import { locales, routes } from "constants/index";
import {
    setOfferFormCompletedStepAction,
    setOfferFormStepValuesAction,
    setSubDegreesAction,
} from "modules/Offers/store/actions";
import { fetchCitiesApi, fetchSubEducationLevelApi } from "modules/Offers/api";
import { CreateOfferStepsEnum } from "modules/Offers/CreateOffer/enums";

import CreateOfferGeneralInformationUi from "./CreateOfferGeneralInformation.ui";

import {
    IHandleChange,
    IHandleTextAreaChange,
    IStoreState,
    TCreateOfferGeneralInformation,
    THandleDropdownChange,
    ICityInfo,
    Delta,
    Sources,
    UnprivilegedEditor,
    THandleTextEditorBlur,
    RichTextEditorSourcesEnum,
} from "./CreateOfferGeneralInformation.types";

const validationSchema = {
    title: Joi.string()
        .required()
        .max(45)
        .messages({
            "string.empty": locales.errors.required,
            "string.max": locales
                .formatString(locales.errors.maxLength, {
                    charNum: 45,
                })
                .toString(),
        }),
    description: Joi.string()
        .min(100)
        .required()
        .messages({
            "string.empty": locales.errors.required,
            "string.min": locales
                .formatString(locales.errors.minLength, {
                    charNum: 100,
                })
                .toString(),
        }),
    missions: Joi.string()
        .min(200)
        .required()
        .messages({
            "string.empty": locales.errors.required,
            "string.min": locales
                .formatString(locales.errors.minLength, {
                    charNum: 200,
                })
                .toString(),
        }),
    profile: Joi.string()
        .min(200)
        .required()
        .messages({
            "string.empty": locales.errors.required,
            "string.min": locales
                .formatString(locales.errors.minLength, {
                    charNum: 200,
                })
                .toString(),
        }),
    contractType: Joi.string().required().messages({
        "string.empty": locales.errors.required,
    }),
    postalCode: Joi.string().required().messages({
        "string.empty": locales.errors.required,
    }),
    educationLevel: Joi.array().min(1).required().messages({
        "array.min": locales.errors.required,
    }),
    workFields: Joi.array().min(1).messages({
        "array.min": locales.errors.required,
    }),
    workPlaces: Joi.array().min(1).required().messages({
        "array.min": locales.errors.required,
    }),
};

const CreateOfferGeneralInformation: TCreateOfferGeneralInformation = ({ isUserSubscribed, handleSaveDraft }) => {
    //* Hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();
    const { errors, setErrors, validate } = useValidate();
    const { id } = useParams();

    //* Redux State
    const config = useSelector(({ app: { config } }: IStoreState) => config, shallowEqual);

    const {
        form: { values: offerForm },
        subEducationLevel: reduxSubEducationLevel,
    } = useSelector(({ offers }: IStoreState) => offers, shallowEqual);

    //* Local State
    const [isLoading, setIsLoading] = useState(false);
    const [cities, setCities] = useState<ICityInfo[]>([]);

    //* Memos
    const memoContracts = useMemo(() => convertToDropdown({ data: config.contracts }), [config.contracts]);

    const memoCities: IDropdownObject[] = useMemo(
        () => {
            let result: IDropdownObject[] = [];

            if (cities.length === 0) return [];

            cities.forEach((entry) => {
                result = [
                    ...result,
                    {
                        label: `${entry["nom"]} - ${entry["codesPostaux"][0]}`,
                        value: entry["codesPostaux"][0],
                        lat: entry['centre']['coordinates'][1].toString(),
                        lng: entry['centre']['coordinates'][0].toString(),
                    },
                ];
            });

            return result;
        },
        // () => convertToDropdown({ data: cities, labelKey: "nom", valueKey: "codesPostaux" }),
        [cities]
    );

    const memoWorkFields = useMemo(() => convertToDropdown({ data: config.domaines }), [config.domaines]);

    const memoWorkPlaces = useMemo(() => config.workPlaces, [config.workPlaces]);

    const memoSubEducationLevel = useMemo(
        () => convertToDropdown({ data: reduxSubEducationLevel, valueKey: "_id", labelKey: "value" }),
        [reduxSubEducationLevel]
    );

    //* Handlers
    const handleInputChange: IHandleChange = useCallback(
        ({ target: { name, value } }) => {
            dispatch(setOfferFormStepValuesAction({ [name]: value }));

            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        [offerForm]
    );

    const handleTextAreaChange: IHandleTextAreaChange = useCallback(
        ({ target: { name, value } }) => {
            dispatch(setOfferFormStepValuesAction({ [name]: value }));

            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        [offerForm]
    );

    const handleDropdownChange = useCallback(
        (fieldName: string, multi = false): THandleDropdownChange =>
            (option) => {
                setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: "" }));

                if (Array.isArray(option))
                    return dispatch(
                        setOfferFormStepValuesAction({
                            [fieldName]: option.map(({ value }) => value),
                        })
                    );

                dispatch(
                    setOfferFormStepValuesAction({
                        [fieldName]: multi ? [option.value] : option.value,
                    })
                );
            },
        [offerForm]
    );

    const handleEducationLevelClick = useCallback(
        (id: string) => () => {
            if (offerForm.educationLevel.includes(id))
                return dispatch(
                    setOfferFormStepValuesAction({
                        educationLevel: offerForm.educationLevel.filter((studyLevelId) => studyLevelId !== id),
                    })
                );

            dispatch(
                setOfferFormStepValuesAction({
                    educationLevel: [...offerForm.educationLevel, id],
                })
            );

            setErrors((prevErrors) => ({ ...prevErrors, educationLevel: "" }));
        },
        [offerForm]
    );

    const handleWorkPlaceClick = useCallback(
        (id: string) => () => {
            if (offerForm.workPlaces.includes(id))
                return dispatch(
                    setOfferFormStepValuesAction({
                        workPlaces: offerForm.workPlaces.filter((workPlaceId) => workPlaceId !== id),
                    })
                );

            dispatch(
                setOfferFormStepValuesAction({
                    workPlaces: [...offerForm.workPlaces, id],
                })
            );

            setErrors((prevErrors) => ({ ...prevErrors, workPlaces: "" }));
        },
        [offerForm]
    );

    const handleLocationChange = useCallback((value: string) => {
        if (value.length > 0) fetchCities(value);
    }, []);

    const handleLocationsDropdownChange: THandleDropdownChange = useCallback(
        (option) => {
            setErrors((prevErrors) => ({ ...prevErrors, postalCode: "" }));

            if (Array.isArray(option)) return;

            dispatch(
                setOfferFormStepValuesAction({
                    postalCode: option.value,
                    city: option.label.split(" - ")[0],
                    lat: option.lat,
                    lng: option.lng,
                })
            );
        },
        [dispatch, offerForm]
    );

    const handleGeneralInformationSubmit = useCallback(() => {
        const CAP = "65240cda6b836ac572b11940";
        const hasSubDegrees = offerForm.educationLevel.includes(CAP);

        const data = {
            title: offerForm.title,
            description: offerForm.description,
            missions: offerForm.missions,
            profile: offerForm.profile,
            contractType: offerForm.contractType,
            postalCode: offerForm.postalCode,
            educationLevel: offerForm.educationLevel,
            workFields: offerForm.workFields,
            workPlaces: offerForm.workPlaces,
            ...(hasSubDegrees && { subEducationLevel: offerForm.subEducationLevel }),
        };
        const isFormValid = validate({
            data,
            validationSchema: {
                ...validationSchema,
                ...(hasSubDegrees && {
                    subEducationLevel: Joi.array().min(0).required().messages({
                        "array.min": locales.errors.required,
                    }),
                }),
            },
        });

        if (!isFormValid) return;

        if (!id) navigate(`${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.skills}`);
        else navigate(`${routes.offers.main}/${routes.offers.create.main}/${id}/${routes.offers.create.skills}`);

        batch(() => {
            dispatch(setOfferFormStepValuesAction(offerForm));
            dispatch(
                setOfferFormCompletedStepAction({
                    stepIndex: CreateOfferStepsEnum.generalInformation,
                    isCompleted: true,
                })
            );
        });
    }, [offerForm]);

    const handleTextEditorChange = useCallback(
        (name: string) => (content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor) => {
            // if (source !== RichTextEditorSourcesEnum.user) return;

            dispatch(setOfferFormStepValuesAction({ [name]: content }));

            setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        },
        [offerForm]
    );

    const handleTextEditorBlur: THandleTextEditorBlur = useCallback(
        ( name ) => ( previousSelection, source, editor) => {
            validate({
                data: { [ name ]: editor.getText() },
                validationSchema: { [ name ]: validationSchema[ name ] }
            });
        },
        [offerForm]
    );

    const handleSubmitDraft = useCallback(() => handleSaveDraft(offerForm), [offerForm]);

    const handleUpdateEducationLevel = useCallback(async () => {
        const CAP = "65240cda6b836ac572b11940";
        if (!offerForm.educationLevel.includes(CAP)) return;
        await fetchSubEducationLevel(CAP);
    }, [offerForm.educationLevel]);

    //* Side Effects
    const fetchCities = useCallback(async (location: string) => {
        try {
            const { data } = await fetchCitiesApi(location);

            setCities(data);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: "error", message: errorMessage });
        }
    }, []);

    const fetchSubEducationLevel = useCallback(async (degreeID: string) => {
        try {
            const {
                data: { data },
            } = await fetchSubEducationLevelApi(degreeID);

            dispatch(setSubDegreesAction(data));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: "error", message: errorMessage });
        }
    }, []);

    //* Effects
    useEffect(() => {
        handleUpdateEducationLevel();
    }, [offerForm.educationLevel]);

    const options = useMemo(
        () => ({
            contracts: memoContracts,
            cities: memoCities,
            workFields: memoWorkFields,
            workPlaces: memoWorkPlaces,
            subEducationLevel: memoSubEducationLevel,
        }),
        [memoContracts, memoCities, memoWorkFields, memoWorkPlaces, memoSubEducationLevel]
    );

    const data = useMemo(() => ({ ...offerForm }), [offerForm]);

    const handlers = useMemo(
        () => ({
            handleInputChange,
            handleTextAreaChange,
            handleDropdownChange,
            handleLocationChange,
            handleEducationLevelClick,
            handleWorkPlaceClick,
            handleLocationsDropdownChange,
            handleSubmitDraft,
            handleGeneralInformationSubmit,
            handleTextEditorChange,
            handleTextEditorBlur,
        }),
        [
            handleInputChange,
            handleTextAreaChange,
            handleDropdownChange,
            handleLocationChange,
            handleEducationLevelClick,
            handleWorkPlaceClick,
            handleLocationsDropdownChange,
            handleSubmitDraft,
            handleGeneralInformationSubmit,
            handleTextEditorChange,
            handleTextEditorBlur,
        ]
    );

    return (
        <CreateOfferGeneralInformationUi
            isLoading={isLoading}
            isUserSubscribed={isUserSubscribed}
            config={config}
            errors={errors}
            options={options}
            data={data}
            handlers={handlers}
        />
    );
};

export default memo(CreateOfferGeneralInformation);
