import { memo } from "react";

import {Modal, Button, TextInput, FormControl, Dropdown, Checkbox} from 'components';

import { locales } from 'constants/index';

import { AddToYPareoModalEnums } from "./AddToYPareoModal.enums";
import { TAddToYPareoModalUI } from './AddToYPareoModal.types';

import styles from './add-to-ypareo-modal.module.scss';


const AddToYPareoModalUI: TAddToYPareoModalUI = ({
    values,
    data: { options, canForce },
    handlers,
    errors
}) => {
    return (<>
        <Modal
            className={styles['add-to-ypareo-modal__modal']}
            bodyClassName={styles["add-to-ypareo-modal"]}
            title={locales.modals.yPareoModal.title}
            description={locales.modals.yPareoModal.subTitle}
            onClose={handlers.handleClose}
            actionClassName={styles["add-to-ypareo-modal__actionGroup"]}
            actionButtons={
                <Button
                    variant="secondary"
                    onClick={handlers.handleSubmitClick}
                >
                    {locales.modals.yPareoModal.exportToYPareo}
                </Button>
            }
        >

            <FormControl
                className={styles['add-to-ypareo-modal__fc']}
                id={AddToYPareoModalEnums.firstname}
                label={locales.modals.yPareoModal.firstname}
                error={errors[AddToYPareoModalEnums.firstname]}
                withRef
            >
                <TextInput
                    id={AddToYPareoModalEnums.firstname}
                    name={AddToYPareoModalEnums.firstname}
                    value={values[AddToYPareoModalEnums.firstname]}
                    isError={!!errors[AddToYPareoModalEnums.firstname]}
                    onChange={handlers.handleTextInputChange}
                    onBlur={handlers.handleTextInputBlur}
                />
            </FormControl>

            <FormControl
                className={styles['add-to-ypareo-modal__fc']}
                id={AddToYPareoModalEnums.lastname}
                label={locales.modals.yPareoModal.lastname}
                error={errors[AddToYPareoModalEnums.lastname]}
                withRef
            >
                <TextInput
                    id={AddToYPareoModalEnums.lastname}
                    name={AddToYPareoModalEnums.lastname}
                    value={values[AddToYPareoModalEnums.lastname]}
                    isError={!!errors[AddToYPareoModalEnums.lastname]}
                    onChange={handlers.handleTextInputChange}
                    onBlur={handlers.handleTextInputBlur}
                />
            </FormControl>

            <FormControl
                className={styles['add-to-ypareo-modal__fc']}
                id={AddToYPareoModalEnums.city}
                label={locales.modals.yPareoModal.city}
                error={errors[AddToYPareoModalEnums.city]}
                withRef
            >
                <TextInput
                    id={AddToYPareoModalEnums.city}
                    name={AddToYPareoModalEnums.city}
                    value={values[AddToYPareoModalEnums.city]}
                    isError={!!errors[AddToYPareoModalEnums.city]}
                    onChange={handlers.handleTextInputChange}
                    onBlur={handlers.handleTextInputBlur}
                />
            </FormControl>

            <div className={styles['add-to-ypareo-modal__fcGroup']}>
                <FormControl
                    className={styles['add-to-ypareo-modal__fc']}
                    id={AddToYPareoModalEnums.zip_code}
                    label={locales.modals.yPareoModal.postalCode}
                    error={errors[AddToYPareoModalEnums.zip_code]}
                    withRef
                >
                    <TextInput
                        id={AddToYPareoModalEnums.zip_code}
                        name={AddToYPareoModalEnums.zip_code}
                        value={values[AddToYPareoModalEnums.zip_code]}
                        isError={!!errors[AddToYPareoModalEnums.zip_code]}
                        onChange={handlers.handleTextInputChange}
                        onBlur={handlers.handleTextInputBlur}
                    />
                </FormControl>

                <FormControl
                    className={styles['add-to-ypareo-modal__fc']}
                    id={AddToYPareoModalEnums.phone_number}
                    label={locales.modals.yPareoModal.phoneNumber}
                    error={errors[AddToYPareoModalEnums.phone_number]}
                    withRef
                >
                    <TextInput
                        id={AddToYPareoModalEnums.phone_number}
                        name={AddToYPareoModalEnums.phone_number}
                        value={values[AddToYPareoModalEnums.phone_number]}
                        isError={!!errors[AddToYPareoModalEnums.phone_number]}
                        onChange={handlers.handleTextInputChange}
                        onBlur={handlers.handleTextInputBlur}
                    />
                </FormControl>
            </div>

            <div className={styles['add-to-ypareo-modal__fcGroup']}>
                <FormControl
                    className={styles['add-to-ypareo-modal__fc']}
                    id={AddToYPareoModalEnums.gender_code}
                    label={locales.modals.yPareoModal.genderCode}
                    error={errors[AddToYPareoModalEnums.gender_code]}
                    withRef
                >
                    <Dropdown
                        isFixedHeight
                        id={AddToYPareoModalEnums.gender_code}
                        name={AddToYPareoModalEnums.gender_code}
                        value={options.genderCodes.find(e => e.value === values[AddToYPareoModalEnums.gender_code])}
                        options={options.genderCodes}
                        isError={!!errors[AddToYPareoModalEnums.gender_code]}
                        onChange={handlers.handleDropdownChange(AddToYPareoModalEnums.gender_code)}
                        onBlur={handlers.handleDropdownBlur(AddToYPareoModalEnums.gender_code)}
                        className={styles['add-to-ypareo-modal__fc-Dropdown']}
                    />
                </FormControl>
                <FormControl
                    className={styles['add-to-ypareo-modal__fc']}
                    id={AddToYPareoModalEnums.nationality_code}
                    label={locales.modals.yPareoModal.citizenshipCode}
                    error={errors[AddToYPareoModalEnums.nationality_code]}
                    withRef
                >
                    <Dropdown
                        isFixedHeight
                        id={AddToYPareoModalEnums.nationality_code}
                        name={AddToYPareoModalEnums.nationality_code}
                        value={options.nationalityCodes.find(e => e.value === values[AddToYPareoModalEnums.nationality_code])}
                        options={options.nationalityCodes}
                        isError={!!errors[AddToYPareoModalEnums.nationality_code]}
                        onChange={handlers.handleDropdownChange(AddToYPareoModalEnums.nationality_code)}
                        onBlur={handlers.handleDropdownBlur(AddToYPareoModalEnums.nationality_code)}
                        className={styles['add-to-ypareo-modal__fc-Dropdown']}
                    />
                </FormControl>
            </div>

            <Checkbox
                id={AddToYPareoModalEnums.handicapped}
                name={AddToYPareoModalEnums.handicapped}
                isChecked={values[AddToYPareoModalEnums.handicapped]}
                label={locales.modals.yPareoModal.knownHandicap}
                error={errors[AddToYPareoModalEnums.handicapped]}
                onChange={handlers.handleCheckboxChange(AddToYPareoModalEnums.handicapped)}
                labelStyle={styles['add-to-ypareo-modal__input-checkbox']}
            />

            <FormControl
                className={styles['add-to-ypareo-modal__fc']}
                id={AddToYPareoModalEnums.date_of_birth}
                label={locales.modals.yPareoModal.dateOfBirth}
                error={errors[AddToYPareoModalEnums.date_of_birth]}
                withRef
            >
                <TextInput
                    id={AddToYPareoModalEnums.date_of_birth}
                    name={AddToYPareoModalEnums.date_of_birth}
                    value={values[AddToYPareoModalEnums.date_of_birth]}
                    isError={!!errors[AddToYPareoModalEnums.date_of_birth]}
                    onChange={handlers.handleTextInputChange}
                    onBlur={handlers.handleTextInputBlur}
                />
            </FormControl>


            {canForce && <Checkbox
                id={AddToYPareoModalEnums.force}
                name={AddToYPareoModalEnums.force}
                isChecked={values[AddToYPareoModalEnums.force]}
                label={locales.modals.yPareoModal.force}
                error={errors[AddToYPareoModalEnums.force]}
                onChange={handlers.handleCheckboxChange(AddToYPareoModalEnums.force)}
                labelStyle={styles['add-to-ypareo-modal__input-checkbox']}
            />}

        </Modal>
    </>);
};

export default memo(AddToYPareoModalUI);
