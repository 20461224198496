import {
    ClearIndicatorProps,
    ContainerProps,
    ControlProps,
    CSSObjectWithLabel,
    DropdownIndicatorProps,
    GroupHeadingProps,
    GroupProps,
    IndicatorsContainerProps,
    IndicatorSeparatorProps,
    InputProps,
    LoadingIndicatorProps,
    MenuListProps,
    MenuProps,
    MultiValueProps,
    MultiValueRemoveProps,
    OptionProps,
    PlaceholderProps,
    SingleValueProps,
    StylesConfig,
    ValueContainerProps
} from 'react-select';
import { IDropdownObject } from "common";

const getControlBorder = (
    isFocused: boolean,
    isError: boolean,
    isDisabled: boolean,
) => {
    if (isError) {
        return '#ea3d3d !important';
    }

    if (isDisabled) {
        return '#c1c1c1';
    }

    if (isFocused) {
        return '#2EC1AC';
    }

    return 'transparent';
};

const optionBackgroundColor = (color?: string) => {
    if (color === 'oceanGreen84') {
        return 'rgba(70, 152, 132, 0.1)';
    }

    if (color === 'flamingo43') {
        return 'rgba(240, 85, 67, 0.1)';
    }

    return 'rgba(70, 152, 132, 0.1)';
};

const textStyle: CSSObjectWithLabel = {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    fontWeight: 400,
    fontFamily: '"Manrope"',
    letterSpacing: '.025rem',
};

const textStyleSelected: CSSObjectWithLabel = {
    fontSize: '1.4rem',
    lineHeight: '2.4rem',
    fontWeight: 600,
    fontFamily: '"Manrope"',
    letterSpacing: 0,
};

const textEllipsis: CSSObjectWithLabel = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

interface ITheme {
    isError: boolean;
    isRounded: boolean;
}

const style = ({ isError, isRounded }: ITheme): StylesConfig<IDropdownObject, false> => ({
    clearIndicator: (base: CSSObjectWithLabel, {selectProps: { menuIsOpen }}: ClearIndicatorProps<IDropdownObject, false>) => ({
        ...base,
        margin: 0,
        padding: 0,
        color: isError ? '#d42424' : '#2EC1AC',
    }),
    container: (base: CSSObjectWithLabel, props: ContainerProps<IDropdownObject, false>) => ({
        ...base,
        position: 'relative',
        zIndex: 3,
    }),
    control: (base: CSSObjectWithLabel, { isDisabled, isFocused, menuIsOpen }: ControlProps<IDropdownObject, false>) => ({
        ...base,
        // zIndex: 2,
        width: '100%',
        height: '100%',
        position: 'relative',

        border: 'none',
        borderRadius: menuIsOpen ? '1rem 1rem 0 0' : '1rem',
        padding: 0,
        ...isError && {
            padding: 0,
        },

        backgroundColor: 'none',
        boxShadow: '0 0 1rem #f5f5f5',
    }),
    dropdownIndicator: (base: CSSObjectWithLabel, {selectProps: { menuIsOpen }}: DropdownIndicatorProps<IDropdownObject, false>) => ({
        ...base,
        margin: 0,
        padding: 0,
        color: isError ? '#d42424' : '#2EC1AC',
        transition: 'all .2s ease',
        ...menuIsOpen && { transform: 'rotate(180deg)' },
    }),
    group: (base: CSSObjectWithLabel, props: GroupProps<IDropdownObject, false>) => ({
        ...base,
    }),
    groupHeading: (base: CSSObjectWithLabel, props: GroupHeadingProps<IDropdownObject, false>) => ({
        ...base,
    }),
    indicatorsContainer: (base: CSSObjectWithLabel, props: IndicatorsContainerProps<IDropdownObject, false>) => ({
        ...base,
        padding: '.3rem',
        alignSelf: 'unset',
    }),
    indicatorSeparator: (base: CSSObjectWithLabel, props: IndicatorSeparatorProps<IDropdownObject, false>) => ({
        ...base,
        display: 'none',
    }),
    input: (base: CSSObjectWithLabel, { isDisabled, hasValue }: InputProps<IDropdownObject, false>) => ({
        ...base,
        ...textStyle,
        width: '100%',
        overflow: 'hidden',
    }),
    loadingIndicator: (base: CSSObjectWithLabel, props: LoadingIndicatorProps<IDropdownObject, false>) => ({
        ...base,
    }),
    loadingMessage: (base: CSSObjectWithLabel) => ({
        ...base,
    }),
    menu: (base: CSSObjectWithLabel, props: MenuProps<IDropdownObject, false>) => ({
        ...base,
        borderRadius: '0 0 1rem 1rem',
        overflow: 'hidden',
        borderTop: '0 !important',
        marginTop: 0,
        zIndex: 3,
        boxShadow: '0 .2rem 1rem #f5f5f5',
    }),
    menuList: (base: CSSObjectWithLabel, props: MenuListProps<IDropdownObject, false>) => ({
        ...base,
        padding: '.1rem .5rem',
        boxShadow: 'inset 0px .1rem .1rem #f5f5f5',
    }),
    menuPortal: (base: CSSObjectWithLabel) => ({
        ...base,
        zIndex: '9999999999999999 !important',
    }),
    multiValue: (base: CSSObjectWithLabel, { isDisabled }: MultiValueProps<IDropdownObject, false>) => ({
        ...base,
    }),
    multiValueLabel: (base: CSSObjectWithLabel) => ({
        ...base,
        ...textStyle,
        width: '100%',
        height: '100%',
        padding: '0 !important',
    }),
    multiValueRemove: (base: CSSObjectWithLabel, props: MultiValueRemoveProps<IDropdownObject, false>) => ({
        ...base,
        display: 'none',
    }),
    noOptionsMessage: (base: CSSObjectWithLabel) => ({
        ...base,
        ...textStyle,
    }),
    option: (base: CSSObjectWithLabel , { isSelected, isFocused }: OptionProps<IDropdownObject, false>) => ({
        ...base,
        padding: '.3rem',
        display: 'flex',
        alignItems: 'center',
        gap: '.6rem',

        width: '100%',

        color: '#656F84',

        '&:not(:last-child)': {
            borderBottom: '1px solid #D3E5E1'
        },

        ...textStyle,
        ...(isSelected || isFocused) && textStyleSelected,
        ...(isSelected || isFocused) && { backgroundColor: '#fff' },

        backgroundColor: 'unset',
        '&:hover': { backgroundColor: 'unset' },
        '&:active': { backgroundColor: '#D3E5E1' },
    }),
    placeholder: (base: CSSObjectWithLabel, props: PlaceholderProps<IDropdownObject, false>) => ({
        ...base,
        ...textStyle,
        ...textEllipsis,
        width: '100%',
    }),
    singleValue: (base: CSSObjectWithLabel, { isDisabled, getStyles, hasValue }: SingleValueProps<IDropdownObject, false>) => ({
        ...base,
        ...textStyle,
        ...textEllipsis,
        width: '100%',
    }),
    valueContainer: (base: CSSObjectWithLabel, props: ValueContainerProps<IDropdownObject, false>) => ({
        ...base,
    }),
});

export { style };
