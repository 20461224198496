import { memo } from "react";
import { Link } from "react-router-dom";

import {
  Banner,
  Button,
  Card,
  Checkbox,
  ColorPicker,
  Dropdown,
  LogoPicker,
  EditList,
  FormControl,
  Modal,
  Section,
  TextArea,
  TextInput, RichTextEditor
} from "components";
import { locales } from "constants/index";
import { removeHttpFromString } from "utils";

import { AttachmentsEnum } from "common";

import { TCustomizeWebsiteUI } from './CustomizeWebsite.types';

import socialsArray from './socials';

import './customize-website.scss';

const CustomizeWebsiteUI: TCustomizeWebsiteUI = ({
  errors,
  data : {
    websiteEdit,
    websiteDesign,
    courses,
    editMode,
    uploads,
  },
  modals,
  options,
  handlers,
}) => {
  return (
    <div className="settings__subModule">
      <Section title={locales.settings.customWebsite.domain.domain}>
        <Card isColumns={false}
          title={locales.settings.customWebsite.domain.domain}
          subTitle={locales.settings.customWebsite.domain.domain}
          showAction={!websiteEdit && editMode.domain}
          actionLabel={locales.general.publish}
          actionHandler={handlers.handleSave('domain')}
        >
          {!websiteEdit
            ? <FormControl
              id="domain"
              elementClassName='flex'
              note={locales.settings.customWebsite.domain.warning}
              error={errors.domain}
              withRef
            >
              <TextInput
                id="domain"
                name="domain"
                value={websiteDesign?.domain ?? ''}
                onChange={handlers.handleDomainChange}
                leftContent={'https://www.'}
                rightContent={'.bloom-alternance.fr'}
                isError={!!errors.domain}
              />
            </FormControl>
            : <>
              <Banner className='customize-website__domain'>
                <Link
                  target="_blank"
                  className='customize-website__domain__link'
                  to={`https://${websiteDesign.domain}.bloom-alternance.fr`}
                >
                  {websiteDesign.domain && `https://${websiteDesign.domain}.bloom-alternance.fr`}
                </Link>
              </Banner>
              {websiteDesign.domain && <Button
                variant='danger'
                onClick={handlers.handleDeleteDomain}
                children={locales.general.delete}
              />}
            </>
          }
        </Card>
      </Section>

      <Section title={locales.settings.customWebsite.design.index}>
        <Card
          title={locales.settings.customWebsite.design.color}
          subTitle={locales.settings.customWebsite.design.colorSub}
          isColumns={false}
          showAction={editMode.colors}
          actionHandler={handlers.handleSave('colors')}
        >
          <div className='customize-website__color'>
            {locales.settings.customWebsite.design.colorPrimary}
            <ColorPicker
              color={websiteDesign.colors?.primary}
              onChange={handlers.handleColorsChange('primary')}
            />
          </div>
          <div className='customize-website__color'>
            {locales.settings.customWebsite.design.colorSecondary}
            <ColorPicker
              color={websiteDesign.colors?.secondary}
              onChange={handlers.handleColorsChange('secondary')}
            />
          </div>
        </Card>

        <Card
          title={locales.settings.customWebsite.design.logo}
          subTitle={locales.settings.customWebsite.design.logoSub}
          showAction={editMode.logo}
          actionHandler={handlers.handleSave('logo')}
        >
          <LogoPicker
            isMultiple={false}
            files={uploads.logo?.file}
            attachments={websiteDesign.logo}
            onDeleteFile={handlers.handleDeleteAttachment(AttachmentsEnum.logo)}
            onUploadFile={handlers.handleUploadAttachment(AttachmentsEnum.logo)}
          />
        </Card>
      </Section>

      <Section isColumns title={locales.settings.customWebsite.presentations.index}>
        <Section>
          <Card
            title={locales.settings.customWebsite.presentations.aboutUs}
            subTitle={locales.settings.customWebsite.presentations.aboutUsSub}
            showAction={editMode.aboutUs}
            actionHandler={handlers.handleSave('aboutUs')}
          >
            <FormControl
              className="customize-website__fc-textArea"
              error={errors.aboutUs}
              withRef
            >
              <RichTextEditor
                id='rte-aboutUs'
                value={websiteDesign.aboutUs ?? ''}
                placeholder={locales.settings.customWebsite.presentations.aboutUs}
                readOnly={false}
                onChange={handlers.handleRichTextChange("aboutUs")}
                isBorderless
              />
            </FormControl>
          </Card>
          <Card
            title={locales.settings.customWebsite.presentations.figures}
            subTitle={locales.settings.customWebsite.presentations.figuresSub}
            showAction={editMode.figures}
            actionHandler={handlers.handleSave('figures')}
          >
            <FormControl
              id="foundation_year"
              label={locales.settings.customWebsite.presentations.foundationYear}
              error={errors.foundation_year}
              withRef
            >
              <TextInput
                id="foundation_year"
                name="foundation_year"
                value={websiteDesign.figures?.foundation_year}
                onChange={handlers.handleFiguresChange('foundation_year')}
                isError={!!errors.foundation_year}
              />
            </FormControl>

            {/*<FormControl*/}
            {/*  id="work_force"*/}
            {/*  label={locales.settings.customWebsite.presentations.numberOfEmployees}*/}
            {/*  error={errors.work_force}*/}
            {/*  withRef*/}
            {/*>*/}
            {/*  <TextInput*/}
            {/*    id="work_force"*/}
            {/*    name="work_force"*/}
            {/*    value={websiteDesign.figures?.work_force}*/}
            {/*    onChange={handlers.handleFiguresChange('work_force')}*/}
            {/*    isError={!!errors.work_force}*/}
            {/*  />*/}
            {/*</FormControl>*/}

            {/*<FormControl*/}
            {/*  id="collaborators"*/}
            {/*  label={locales.settings.customWebsite.presentations.numberOfCollaborators}*/}
            {/*  error={errors.collaborators}*/}
            {/*  withRef*/}
            {/*>*/}
            {/*  <TextInput*/}
            {/*    id="collaborators"*/}
            {/*    name="collaborators"*/}
            {/*    value={websiteDesign.figures?.collaborators}*/}
            {/*    onChange={handlers.handleFiguresChange('collaborators')}*/}
            {/*    isError={!!errors.insertion_rate}*/}
            {/*  />*/}
            {/*</FormControl>*/}

            <FormControl
              id="insertion_rate"
              label={locales.settings.customWebsite.presentations.insertion_rate}
              error={errors.insertion_rate}
              withRef
            >
              <TextInput
                id="insertion_rate"
                name="insertion_rate"
                value={websiteDesign.figures?.insertion_rate}
                onChange={handlers.handleFiguresChange('insertion_rate')}
                isError={!!errors.insertion_rate}
              />
            </FormControl>

            <FormControl
              id="success_rate"
              label={locales.settings.customWebsite.presentations.success_rate}
              error={errors.success_rate}
              withRef
            >
              <TextInput
                id="success_rate"
                name="success_rate"
                value={websiteDesign.figures?.success_rate}
                onChange={handlers.handleFiguresChange('success_rate')}
                isError={!!errors.success_rate}
              />
            </FormControl>
          </Card>

        </Section>

        <Section>
          <Card
            title={locales.settings.customWebsite.presentations.joinUs}
            subTitle={locales.settings.customWebsite.presentations.joinUsSub}
            showAction={editMode.joinUs}
            actionHandler={handlers.handleSave('joinUs')}
          >
            <FormControl
              className="customize-website__fc-textArea"
              error={errors.joinUs}
              withRef
            >
              <RichTextEditor
                id='rte-joinUs'
                value={websiteDesign.joinUs ?? ''}
                placeholder={locales.settings.customWebsite.presentations.joinUs}
                readOnly={false}
                onChange={handlers.handleRichTextChange("joinUs")}
                isBorderless
              />
            </FormControl>
          </Card>
          <Card
            title={locales.settings.customWebsite.presentations.publicity}
            subTitle={locales.settings.customWebsite.presentations.publicitySub}
            showAction={editMode.publicity}
            actionHandler={handlers.handleSave('publicity')}
          >
            <LogoPicker
              files={uploads.publicity?.file}
              attachments={websiteDesign.publicity}
              onDeleteFile={handlers.handleDeleteAttachment(AttachmentsEnum.publicity)}
              onUploadFile={handlers.handleUploadAttachment(AttachmentsEnum.publicity)}
            />
          </Card>
        </Section>

        {/*<Section>*/}
        {/*  <Card*/}
        {/*    title={locales.settings.customWebsite.presentations.footerDescription}*/}
        {/*    subTitle={locales.settings.customWebsite.presentations.footerDescriptionSub}*/}
        {/*    showAction={editMode.footerDescription}*/}
        {/*    actionHandler={handlers.handleSave('footerDescription')}*/}
        {/*  >*/}
        {/*    <FormControl*/}
        {/*      className="customize-website__fc-textArea"*/}
        {/*      error={errors.footerDescription}*/}
        {/*      withRef*/}
        {/*    >*/}
        {/*      <RichTextEditor*/}
        {/*        id='rte-footerDescription'*/}
        {/*        value={websiteDesign.footerDescription ?? ''}*/}
        {/*        placeholder={locales.settings.customWebsite.presentations.footerDescription}*/}
        {/*        readOnly={false}*/}
        {/*        onChange={handlers.handleRichTextChange("footerDescription")}*/}
        {/*        isBorderless*/}
        {/*      />*/}
        {/*    </FormControl>*/}
        {/*  </Card>*/}
        {/*</Section>*/}

        <Section>
          <Card
            title={locales.settings.customWebsite.presentations.socials}
            subTitle={locales.settings.customWebsite.presentations.socialsSub}
            showAction={editMode.socials}
            actionHandler={handlers.handleSave('socials')}
          >
            <Section>
              <Card
                isRounded={false}
                hasShadow={false}
                hasPadding={false}
                isColumns={false}
              >
                {socialsArray.map((type, index) => {
                  const social = websiteDesign.socials?.find(media => media.type === type);
                  return(<FormControl
                      key={index}
                      id={type}
                      className='customize-website__fc-columns'
                      elementClassName='customize-website__fc-input'
                      withRef
                    >
                      <Checkbox
                        isChecked={social?.active}
                        onChange={handlers.handleSocialsCheck(type)}
                      />
                      <div className={`customize-website__icon ${type}`}/>
                      <TextInput
                        leftContent={ type !== 'email' && 'https://' }
                        id={type}
                        name={type}
                        value={removeHttpFromString(social?.value ?? "")}
                        placeholder={locales.settings.customWebsite.presentations[type]}
                        onChange={handlers.handleSocialsChange(type)}
                        isError={!!errors[type]}
                      />
                    </FormControl>
                  );
                })}
              </Card>
            </Section>
          </Card>
        </Section>
      </Section>

      <Section isColumns title={locales.settings.customWebsite.course.courses}>
        <EditList
          list={courses}
          handlers={handlers.editHandlers}
        />

        <div className='customize-website__card'>
          <Button
            variant='primary'
            children={locales.settings.customWebsite.course.add}
            onClick={handlers.editHandlers.handleCreate}
          />
        </div>
      </Section>

      {(modals.edit || modals.create) && <Modal
        title={
          modals.create
            ? locales.settings.customWebsite.course.add
            : locales.settings.customWebsite.course.update
        }
        onClose={handlers.handleModalClose(modals.create ? 'create' : 'edit')}
      >
        <Card
          isRounded={false}
          hasShadow={false}
          hasPadding={false}
          isColumns={false}
          showAction={true}
          actionHandler={handlers.handleSave(modals.create ? 'course' : 'courseUpdate')}
        >
          <FormControl
            id="diplomaName"
            className='customize-website__fc-columns'
            label={locales.settings.customWebsite.course.diplomaName}
            withRef
          >
            <TextInput
              id="diplomaName"
              name="diplomaName"
              onChange={handlers.handleCourseChange('title')}
              value={modals.data?.title}
              isError={!!errors.modal}
            />
          </FormControl>
          <FormControl
            id="startDate"
            className='customize-website__fc-columns'
            label={locales.settings.customWebsite.course.startDate}
            withRef
          >
            <TextInput
              id="startDate"
              name="startDate"
              onChange={handlers.handleCourseChange('start_date')}
              value={modals.data?.start_date}
              isError={!!errors.modal}
            />
          </FormControl>
          <FormControl
            id="admissionLevel"
            className='customize-website__fc-columns'
            label={locales.settings.customWebsite.course.admissionLevel}
            withRef
          >
            <Dropdown
              isMulti
              options={options.degrees}
              value={options.degrees?.filter((item) => modals.data?.degree?.includes(item.value))}
              onChange={handlers.handleCourseDropdown('degree')}
              isError={!!errors.modal}
            />
          </FormControl>
          <FormControl
            id="courseLocation"
            className='customize-website__fc-columns'
            label={locales.settings.customWebsite.course.courseLocation}
            withRef
          >
            <TextInput
              id="courseLocation"
              name="courseLocation"
              onChange={handlers.handleCourseChange('city')}
              value={modals.data?.city}
              isError={!!errors.modal}
            />
          </FormControl>
        </Card>
      </Modal>}

      {modals.delete && <Modal
        title={locales.settings.customWebsite.course.confirmDelete}
        onClose={handlers.handleModalClose('delete')}
      >
        <Card
          isRounded={false}
          hasShadow={false}
          hasPadding={false}
          isColumns={false}
          className='customize-website__card'
        >
          <Button
            variant='danger'
            children={locales.general.delete}
            onClick={handlers.handleSave('courseDelete')}
          />
        </Card>
      </Modal>}
    </div>
  );
};

export default memo(CustomizeWebsiteUI);
