import EyeGray from "./eye-gray.svg";
import EyeClosed from "./eye-closed.svg";
import MainLogo from "./main-logo.svg";
import RightArrowWhite from "./right-arrow-white.svg";
import LeftArrowWhite from "./left-arrow-white.svg";
import GreenNotification from "./green-notification.svg";
import CalendarPrimary from "./calendar/dark-green.svg";
import AddCircleWhite from "./add-circle-white.svg";
import GreenRoundedArrow from "./green-rounded-arrow.svg";
import Localisation from "./localisation.png";
import ManAvatar from "./avatar/man-1.svg";
import WomanAvatar from "./avatar/woman-1.svg";

export * from "./utm-sources";
export const Icons = {
    Localisation,
    EyeGray,
    EyeClosed,
    MainLogo,
    RightArrowWhite,
    LeftArrowWhite,
    GreenNotification,
    CalendarPrimary,
    AddCircleWhite,
    GreenRoundedArrow,
    ManAvatar,
    WomanAvatar,
};
