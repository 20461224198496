import { CVThequeActionEnum } from '.';
import type { TCVThequeAction, CVThequeState } from '.';


const initialState: CVThequeState = {
    students: [],
    selectedStudents: [],
    selectedStudentCV: '',
    activeFilters: [],
    filterOptions: {
        offers: [],
    },
    filters: {
        search_string: '',
        exclude_words: [],
        work_field: [],
        study_level: [],
        pipe_status: [],
        offer: '',
        months: '',
        city: '',
        postal_code: '',
        lat: '',
        lng: '',
        range: '',
    },
};


export const CVThequeReducer = ( state = initialState, action: TCVThequeAction ) => {
    switch ( action.type ) {
        case CVThequeActionEnum.SET_STUDENTS:
            return {
                ...state,
                students: action.payload
            };

        case CVThequeActionEnum.SET_SELECTED_STUDENTS:
            return {
                ...state,
                selectedStudents: action.payload
            };

        case CVThequeActionEnum.SET_SELECTED_STUDENT_CV:
            return {
                ...state,
                selectedStudentCV: action.payload
            };

        case CVThequeActionEnum.SET_ACTIVE_FILTERS:
            return {
                ...state,
                activeFilters: action.payload
            };

        case CVThequeActionEnum.SET_FILTERS:
            return {
                ...state,
                filters: action.payload
            };

        case CVThequeActionEnum.EDIT_FILTER_OPTIONS:
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    ...action.payload
                }
            };

        case CVThequeActionEnum.RESET:
            return initialState;

        default:
            return state;
    }
};
