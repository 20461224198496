"use client";

import { ActionIcon } from "@mantine/core";


export default ActionIcon.extend({
	defaultProps: {
		variant: 'subtle',
		size: 'lg',
		radius: 'xl',
	},
})