import { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loader } from 'components';

import { useToast, useExtractServerError } from 'hooks';
import { setStripeSecretKey } from 'utils';
import { routes } from 'constants/index';

import { PaymentSuccessModal } from '../../components';
import { createCheckoutApi } from '../../api';

import CreateOfferPayment from './CreateOfferPayment';
import { TPaymentIntent, IStoreState } from './CreateOfferPayment.types';

const getStripe = async () =>
  await loadStripe(process.env.REACT_APP_STRIPE_API_KEY || '');

const PaymentIntent: TPaymentIntent = ({
  couponCode,
  subscriptionTypes,
  selectedSubscriptionType,
}) => {
  //* Hooks
  const navigate = useNavigate();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();

  const userInfo = useSelector(
    ({ app: { userInfo } }: IStoreState) => userInfo,
    shallowEqual
  );

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [isUserPaid, setIsUserPaid] = useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState('');

  //* Handlers
  const handlePaymentSuccessModalClose = () =>
    navigate(`${routes.offers.main}/${routes.offers.listing}`);

  //* Side Effects
  // const createCheckout = async () => {
  //   try {
  //     if (!userInfo) return;
  //
  //     setIsLoading(true);
  //
  //     const {
  //       data: {
  //         data: { client_secret: clientSecret, paid },
  //       },
  //     } = await createCheckoutApi({
  //       customerId: userInfo.customer_id,
  //       lookupKey: selectedSubscriptionType,
  //       couponCode,
  //     });
  //
  //     setIsUserPaid(paid);
  //     setStripeClientSecret(clientSecret);
  //     setStripeSecretKey(clientSecret);
  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     const errorMessage = extractErrorMessage(error);
  //     toast({ type: 'error', message: errorMessage });
  //     navigate(
  //       `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.plans}`
  //     );
  //   }
  // };

  // useEffect(() => {
  //   if (userInfo && userInfo.customer_id?.length > 0) createCheckout();
  // }, [selectedSubscriptionType, userInfo]);

  if (isUserPaid)
    return <PaymentSuccessModal onClose={handlePaymentSuccessModalClose} />;

  // in case no customer id redirect offers list and show a toast with a proper message.
  if (isLoading) return <Loader />;

  return stripeClientSecret.length > 0 ? (
    <Elements
      stripe={getStripe()}
      options={{ clientSecret: stripeClientSecret }}
    >
      <CreateOfferPayment
        stripeClientSecret={stripeClientSecret}
        subscriptionTypes={subscriptionTypes}
        selectedSubscriptionType={selectedSubscriptionType}
      />
    </Elements>
  ) : null;
};

export default PaymentIntent;
