import { memo } from "react";

import { DraggableContainer } from "components";

import { ExportAffix } from "../../components";
import Column from "../Column";

import { TPipesUi } from "./Pipes.types";

import "./pipes.scss";

const PipesUi: TPipesUi = ({
    isLoading, 
    data: { pipes, pipeColumns, selectedCandidates },
    handlers: {
        handleDragEnd,
        handleDragStart,
        handleCandidateClick,
        handleSelectCandidate,
        handleCopySocialMedia,
        handleViewCv,
        handleToggleSelectAll,
        handleExportCandidatesClick,
    },
}) => {
    return (
        <>
            <DraggableContainer onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                {pipeColumns.map((column) => (
                    <Column
                        key={column.value}
                        data={{
                            column,
                        }}
                        handlers={{
                            handleCandidateClick,
                            handleSelectCandidate,
                            handleCopySocialMedia,
                            handleViewCv,
                        }}
                    />
                ))}
            </DraggableContainer>

            <ExportAffix
                selectedCandidatesLength={selectedCandidates.length}
                checked={pipes.length === selectedCandidates.length}
                mounted={!!selectedCandidates.length}
                indeterminate={selectedCandidates.length > 0 && pipes.length !== selectedCandidates.length}
                onChange={handleToggleSelectAll}
                onClick={handleExportCandidatesClick}
                isLoading={isLoading}
            />
        </>
    );
};

export default memo(PipesUi);
