import { IEnums } from './enums.types';

const enums: IEnums = {
  locales: {
    fr: 'fr',
  },
  app: {
    status: {
      none: 'NONE',
      loaded: 'LOADED',
      authenticated: 'AUTHENTICATED',
      unAuthenticated: 'UNAUTHENTICATED',
    },
  },
  moduleNames: {
    offers: 'offers',
    drafts: 'drafts',
    listing: 'listing',
    classrooms: 'classrooms',
    selectCVs: 'selectCVs',
    archives: 'archives',
  },
};

export { enums };
