import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import 'dayjs/locale/fr';

import { locales, routes } from "constants/index";

import TableExt from "components/Table2/components";

import { TArchivedOffersColumns } from "./ArchivedOffers.types";

dayjs.extend(relativeTime);

export const columns: TArchivedOffersColumns = [
    {
        id: 'select-col',
        header: ({ table }) => <TableExt.Checkbox
            isDisabled={!!table.getToggleAllRowsSelectedHandler()}
            isChecked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
        />,
        accessorFn: row => row._id,
        cell: ({ row }) => <TableExt.Checkbox
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
        />,
        size: 0,
        minSize: 62,
        enableSorting: false,
    },
    {
        id: 'company.name',
        header: locales.general.company,
        accessorFn: row => row.company.name,
        size: 2,
    },
    {
        id: 'info.title',
        header: locales.offer.post,
        accessorFn: row => row.info.title,
        size: 2,
    },
    {
        id: 'info.city',
        header: locales.general.city,
        accessorFn: row => row.info.city,
        size: 1,
    },
    {
        id: 'info.type',
        header: locales.offer.contractType,
        accessorFn: row => row.info.type,
        cell: info => <TableExt.Badge value={info.getValue<string>()} />,
        size: 1,
        minSize: 160,
    },
    {
        id: 'info.work_places',
        header: locales.offer.remoteWork,
        accessorFn: row => row.info.work_places,
        cell: info => info.getValue<string[]>().join(', '),
        size: 1,
    },
    {
        id: 'info.updated_date',
        header: locales.offer.publication,
        accessorFn: row => row.updated_date,
        cell: info => dayjs(info.getValue<string>()).locale('fr').fromNow(),
        size: 1,
    },
    {
        id: 'info._id',
        header: undefined,
        accessorFn: row => row._id,
        cell: info => <TableExt.Link isLink
            path={`${info.getValue<string>()}`}
            children={locales.offers.seeOffer}
        />,
        size: 0,
        minSize: 130,
        enableSorting: false,
    },
];
