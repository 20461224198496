import { memo, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import {
  OffersListing,
  Offer,
  Drafts,
  CreateOffer,
  Logout,
  StudentsDashboard,
  Profile,
  Settings,
  Classrooms,
  CVTheque,
  Listing,
  NewProfile,
} from "modules";

import { Header, MobileBlock, Sidebar } from "components";

import { routes } from "constants/index";
import { TAuthorized } from "./Authorized.types";

import "./authorized.scss";

const AuthorizedRoutes: TAuthorized = () => {
  // * Local State
  const [isMobile, setIsMobile] = useState(false);

  //* Effects
  useEffect(() => {
    if (navigator.userAgent.toLowerCase().match(/(mobile|android)/i)) return setIsMobile(true);
    if (!isMobile) return;
    return setIsMobile(false);
  });

  if (isMobile) return <MobileBlock />;

  return (
    <>
      <div className="auth-content">
        <div className="auth-sidebar">
          <Sidebar />
        </div>
        <div className="auth-routes">
          <div className="auth-header">
            <Header />
          </div>
          <div className="auth-routes-content">
            <Routes>
              <Route path={routes.offers.main}>
                <Route path={routes.offers.listing} element={<OffersListing />} />

                <Route path={routes.offers.drafts} element={<Drafts />} />

              {/*  TODO: KEEP COMMENTED */}
              {/*  /!* <Route*/}
              {/*  path={`${routes.offers.drafts}/:id`}*/}
              {/*  element={<CreateOffer />}*/}
              {/*/> *!/*/}
              {/*  TODO: END */}

                <Route path={routes.offers.details} element={<Offer />} />

                <Route path={routes.offers.create.main}>
                  <Route path={`:step`} element={<CreateOffer />}/>
                  <Route path={`:id/:step`} element={<CreateOffer />}/>
                  <Route
                    index
                    element={
                      <Navigate
                        replace
                        to={`${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.generalInfo}`}
                      />
                    }
                  />
                </Route>

                <Route
                  path={routes.offers.main}
                  element={
                    <Navigate
                      replace
                      to={`${routes.offers.main}/${routes.offers.listing}`}
                    />
                  }
                />
              </Route>

              <Route path={routes.students.main}>
                <Route path={`${routes.students.offer}`} element={<StudentsDashboard />} >

                  <Route path={`:offerId`} element={<StudentsDashboard />} />

                </Route>

                {/*<Route path={routes.students.profile} element={<Profile />} />*/}

                <Route path={routes.students.profile} element={<NewProfile />} />

                <Route
                  path={routes.students.main}
                  element={
                    <Navigate replace
                      to={`${routes.students.main}/${routes.students.offer}`}
                    />
                  }
                />
                <Route path="*" element={<Navigate replace to={routes.students.offer} />} />
              </Route>

              <Route path={routes.classrooms.main}>
                <Route
                  index
                  element={<Classrooms />}
                />

                <Route
                  path="*"
                  element={<Navigate replace to={routes.classrooms.main} />}
                />
              </Route>

              <Route path={routes.listing.main}>
                <Route
                  index
                  element={<Listing />}
                />

                <Route path={routes.listing.details} element={ <Offer
                  moduleName='listing'
                  detailRoute={routes.listing.main}
                  returnRoute={routes.listing.main}
                />} />

                <Route
                  path="*"
                  element={<Navigate replace to={routes.listing.main} />}
                />
              </Route>

                <Route path={ routes.CVTheque.main }>
                    <Route index element={ <CVTheque/> }/>

                    <Route
                        path="*"
                        element={ <Navigate replace to={ routes.CVTheque.main }/> }
                    />
                </Route>

              <Route path={routes.settings.main}>
                <Route path={`:module`} element={<Settings />} />

                <Route
                  path={`${routes.settings.archivedOffers}/:id`}
                  element={<Offer
                    moduleName='archives'
                    detailRoute={`${routes.settings.main}/${routes.settings.archivedOffers}`}
                    returnRoute={`${routes.settings.main}/${routes.settings.archivedOffers}`}
                  />}
                />

                <Route
                  index
                  element={<Navigate replace to={`${routes.settings.main}/${routes.settings.myAccount}`}/>}
                />
                <Route path="*" element={<Navigate replace to={routes.listing.main} />} />
              </Route>

              <Route path={routes.authentication.logout} element={<Logout />} />

              <Route
                  path="*"
                  element={<Navigate replace to={routes.offers.main} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(AuthorizedRoutes);
