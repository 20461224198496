import { memo, useMemo } from "react";
import { components, IndicatorsContainerProps } from "react-select";

import { IDropdownObject } from "common";

import { Badge } from "components";

const IndicatorsContainer = ({ children, ...props }: IndicatorsContainerProps<IDropdownObject, false>) => {
    const values = useMemo(() => props.getValue(), [props]);
    const badgeText = useMemo(() => values.length - 1, [values.length]);

    return <components.IndicatorsContainer {...props}>
        {badgeText > 0 && <Badge hideBall size='extrasmall' value={`+${badgeText}`} />}
        {children}
    </components.IndicatorsContainer>
}

export default memo(IndicatorsContainer);
