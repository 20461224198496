// import { Manrope, Roboto_Mono } from 'next/font/google'
import { createTheme, rem } from "@mantine/core";

// const manrope = Manrope({
// 	subsets: ['latin'],
// 	display: 'swap',
// });
//
// const robotoMono = Roboto_Mono({
// 	subsets: ['latin'],
// 	display: 'swap',
// });

export default createTheme({
	/** TYPOGRAPHY */
	// fontFamily: manrope.style.fontFamily,
	// fontFamilyMonospace: robotoMono.style.fontFamily,
	headings: {
		// fontFamily: manrope.style.fontFamily,
		textWrap: 'balance',
		sizes: {
			h2: {
				fontSize: rem(32),
			},
			h3: {
				fontSize: rem(20),
			},
			h4: {
				fontSize: rem(18),
			},
		}
	},
	fontSizes: {
		xs: rem(10),
		sm: rem(11),
		md: rem(14),
		lg: rem(16),
		xl: rem(20),
	},
});