import { useState, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';

import { useToast } from 'hooks';

import CreateOfferPaymentUi from './CreateOfferPayment.ui';
import { TCreateOfferPayment } from './CreateOfferPayment.types';

const CreateOfferPayment: TCreateOfferPayment = ({
  stripeClientSecret,
  subscriptionTypes,
  selectedSubscriptionType,
}) => {
  //* Hooks
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();

  //* Local State
  const [isLoading, setIsLoading] = useState(false);

  //* Handlers
  const handlePaymentConfirmation = () => confirmPayment();
  //* Side Effects
  const confirmPayment = async () => {
    try {
      if (!stripe || !elements) return;

      setIsLoading(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/offers/listing`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        toast({
          type: 'error',
          message: error.message || '',
        });
      } else {
        toast({
          type: 'error',
          message: 'An unexpected error occurred.',
        });
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // TODO: use the below to show a proper message once the payment is done
  const retrievePaymentIntent = async () => {
    try {
      if (!stripe) return;

      const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
      );

      if (!clientSecret) return;

      const { paymentIntent } = await stripe.retrievePaymentIntent(
        clientSecret
      );

      switch (paymentIntent?.status) {
        case 'succeeded':
          break;
        case 'processing':
          toast({ type: 'info', message: 'Your payment is processing.' });
          break;
        case 'requires_payment_method':
          toast({
            type: 'error',
            message: 'Your payment was not successful, please try again.',
          });
          break;
        default:
          //   toast({ type: 'error', message: 'Something went wrong.' });
          break;
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    retrievePaymentIntent();
  }, [stripe]);

  return (
    <CreateOfferPaymentUi
      isLoading={isLoading}
      subscriptionTypes={subscriptionTypes}
      selectedSubscriptionType={selectedSubscriptionType}
      stripeClientSecret={stripeClientSecret}
      handlers={{
        handlePaymentConfirmation,
      }}
    />
  );
};

export default CreateOfferPayment;
