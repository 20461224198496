"use client";

import { Button } from "@mantine/core";

export default Button.extend({
	defaultProps: {
		color: 'darkgreen',
		variant: 'filled',
		size: 'md',
		radius: 'xl',
	},
})