import { useCallback, useEffect, useMemo, useState } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm } from "@mantine/form";
import { useDebouncedCallback } from "@mantine/hooks";

import { locales } from "constants/index";
import { useExtractServerError, useToast } from "hooks";
import { fetchStudentInfoApi } from "../Students/api";
import { setIsLoading } from "../App/store/actions";
import type { IStoreState } from "store";
import type { TComboboxItem } from "common";
import type { TCVThequeTableItem } from "types/CVTheque";

import { fetchMatchingSearchAPI, fetchOffersAPI, exportToZipAPI } from './api';
import { setStudents, setSelectedStudents, setSelectedStudentCV, setActiveFilters, setFilters, editFiltersOptions } from './store';
import { CVThequeFilterEnum } from "./enums";
import type {
    TOnAddFilter,
    TOnClearClick,
    TOnExportClick,
    TOnSubmit,
    TSetFieldValue,
    THandleSelectionChange,
    THandleShowCV, THandleCloseCv
} from './types';
import type { CVThequeState } from './store';

import CVThequeUI from './CVTheque.ui';


export const CVTheque = () => {
    ///* Hooks
    const dispatch = useDispatch();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();
    // const { options, } = useFilters();


    ///* Redux State
    const [ loading, setLoading ] = useState({ exportCVs: false });


    ///* Redux State
    const config = useSelector( ( { app: { config } }: IStoreState ) => config, shallowEqual );
    const { students, selectedStudents, selectedStudentCV, activeFilters, filterOptions, filters } = useSelector( ( { CVTheque }: IStoreState ) => CVTheque,
        shallowEqual );


    ///* Forms
    const form = useForm<CVThequeState['filters']>( {
        initialValues: { ...filters },
        onValuesChange: ( values ) => {
            onDebouncedValuesChange( values );
        },
    } )


    ///* API Actions
    const fetchOffers = async () => {
        try {
            dispatch( setIsLoading( true ) );

            const { data: { data: offers } } = await fetchOffersAPI( { page: 1, limit: 10000 } );

            batch( () => {
                dispatch( editFiltersOptions( { offers } ) );
                dispatch( setIsLoading( false ) );
            } );
        } catch ( error ) {
            const errorMessage = extractErrorMessage( error );
            toast( { type: 'error', message: errorMessage } );
            dispatch( setIsLoading( false ) );
        }
    }

    const fetchStudents = async ( filters: CVThequeState['filters'], offers: CVThequeState['filterOptions']['offers'] ) => {
        try {
            dispatch( setIsLoading( true ) );

            const { data: { data } } = await fetchMatchingSearchAPI( {
                search_string: filters.search_string,
                limit: '10000',
            } );

            const students: TCVThequeTableItem[] = data.map( student => ( {
                ...student,
                offer: offers.find(
                    element => element._id === student.offerId )?.info?.title
                        ?? locales.pages.cvTheque.sections.table.row.noOffer
            } ) )

            batch( () => {
                dispatch( setStudents( students ) );
                dispatch( setIsLoading( false ) );
            } );
        } catch ( error ) {
            const errorMessage = extractErrorMessage( error );
            toast( { type: 'error', message: errorMessage } );
            dispatch( setIsLoading( false ) );
        }
    }

    const exportToZip = async ( selectedStudents: CVThequeState['selectedStudents'] ) => {
        try {
            setLoading( p => ({ ...p, exportCVs: true  }) );

            const { data } = await exportToZipAPI( { candidatesIds: selectedStudents, excel: false } );


            let url = window.URL.createObjectURL( data );
            let link = document.createElement( "a" );

            link.href = url;
            link.setAttribute( "download", "" );
            document.body.appendChild( link );
            link.click();

            // Clean up
            link.remove();
            window.URL.revokeObjectURL( url );

            setLoading( p => ({ ...p, exportCVs: false  }) );
        } catch ( error ) {
            const errorMessage = extractErrorMessage( error );
            toast( { type: 'error', message: errorMessage } );
            setLoading( p => ({ ...p, exportCVs: false  }) );
        }
    }

    const fetchCandidate = async ( candidateId: string ) => {
        try {
            dispatch( setIsLoading( true ) );

            const { data: { user_info } } = await fetchStudentInfoApi( { applicationId: candidateId } );

            if ( !user_info.cv_info.url ) throw new Error( 'No CV' )

            batch( () => {
                dispatch( setSelectedStudentCV( user_info.cv_info.url ) );
                dispatch( setIsLoading( false ) );
            } );
        } catch ( error ) {
            const errorMessage = extractErrorMessage( error );
            toast( { type: 'error', message: errorMessage } );
            dispatch( setIsLoading( false ) );
        }
    }


    ///* Event Handlers
    const onDebouncedValuesChange = useDebouncedCallback( ( values: CVThequeState['filters'] ) => {
        dispatch( setFilters( values ) );
    }, 500 );

    const onAddFilter: TOnAddFilter = ( value, _ ) => {
        let updatedFilters;

        if ( activeFilters.includes( value ) ) updatedFilters = activeFilters.filter( filter => filter !== value );
        else updatedFilters = [ ...activeFilters, value ]

        dispatch( setActiveFilters( updatedFilters ) );
    }

    const setFieldValue: TSetFieldValue = ( path ) => ( { currentTarget: { value } } ) => {
        form.setFieldValue( path, value );
    }

    const onClearClick: TOnClearClick = ( path ) => () => {
        form.setFieldValue( path, '' );
    }

    const onSubmit: TOnSubmit = ( event ) => {
        event.preventDefault();
    }

    const onExportClick: TOnExportClick = async ( _ ) => {
        await exportToZip( selectedStudents );
    }

    const handleSelectionChange: THandleSelectionChange = ( rowSelection ) => {
        dispatch( setSelectedStudents( Object.keys( rowSelection ) ) );
    }

    const handleShowCV: THandleShowCV = ( candidateId ) => async ( _ ) => {
        await fetchCandidate( candidateId );
    }

    const handleCloseCv: THandleCloseCv = () => {
        dispatch( setSelectedStudentCV( '' ) );
    }


    const convertOffersToComboboxItem = useCallback( ( source: typeof filterOptions.offers ): TComboboxItem[] => {
        return source.map<TComboboxItem>( element => ( {
            label: element.info.title,
            value: element._id,
            original: element
        } ) );
    }, [] );


    ///* Effects
    useEffect( () => {
        fetchOffers();
    }, [] );

    useEffect( () => {
        if ( !filterOptions.offers ) return;
        fetchStudents( filters, filterOptions.offers );
    }, [ filterOptions.offers, filters ] );


    ///* Wrappers
    const data = useMemo( () => ( {
        form,
        students,
        activeFilters,
        selectedStudents,
        selectedStudentCV,
    } ), [
        form,
        students,
        activeFilters,
        selectedStudents,
        selectedStudentCV,
    ] );

    const options = useMemo( () => ( {
        [ CVThequeFilterEnum.OFFERS ]: convertOffersToComboboxItem( filterOptions.offers ),
        [ CVThequeFilterEnum.EXCLUDE_WORDS ]: [],
        [ CVThequeFilterEnum.MONTHS ]: config.months,
        [ CVThequeFilterEnum.LOCATION ]: [],
        [ CVThequeFilterEnum.RANGE ]: config.ranges,
        [ CVThequeFilterEnum.WORK_FIELD ]: config.domaines,
        [ CVThequeFilterEnum.STUDY_LEVEL ]: config.degrees,
        [ CVThequeFilterEnum.PIPE_STATUS ]: config.pipes,
    } ), [
        filterOptions.offers,
        config.months,
        config.ranges,
        config.domaines,
        config.degrees,
        config.pipes,
    ] );

    const handlers = useMemo( () => ( {
        onAddFilter,
        setFieldValue,
        onClearClick,
        onSubmit,
        onExportClick,
        handleSelectionChange,
        handleShowCV,
        handleCloseCv,
    } ), [
        onAddFilter,
        setFieldValue,
        onClearClick,
        onSubmit,
        onExportClick,
        handleSelectionChange,
        handleShowCV,
        handleCloseCv,
    ] );


    return <CVThequeUI loading={loading} data={ data } options={ options } handlers={ handlers }/>;
};
