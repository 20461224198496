import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Joi from 'joi';

import { useToast, useExtractServerError, useValidate } from 'hooks';
import { setAccessToken, setRefreshToken } from 'utils';
import { locales, routes } from 'constants/index';
import { authenticateUserAction } from '../store/actions';
import { loginApi } from '../api';

import { TLogin, IHandleChange } from './Login.types';

import LoginUi from './Login.ui';

const Login: TLogin = () => {
  // * Hooks Init
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();
  const { errors, setErrors, validate } = useValidate();

  const validationSchema = {
    emailAddress: Joi.string()
      .email({
        tlds: false,
      })
      .required()
      .messages({
        'string.empty': locales.errors.required,
        'string.email': locales.errors.invalidEmail,
      }),
    password: Joi.string().required().messages({
      'string.empty': locales.errors.required,
    }),
  };

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    emailAddress: '',
    password: '',
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);

  //* Handlers
  const handleInputChange: IHandleChange = ({ target: { name, value } }) => {
    setLoginForm((prevInfo) => ({ ...prevInfo, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleInputBlur: IHandleChange = ({ target: { name, value } }) => {
    if (value.length > 0) {
      validate({
        data: {
          [name]: value,
        },
        validationSchema: {
          // @ts-ignore
          [name]: validationSchema[name],
        },
      });
    }
  };

  const handlePasswordVisibilityChange = () => {
    setIsPasswordVisible((prevValue) => !prevValue);
  };

  const handleRememberMeChange = () => {
    setIsRememberMe((prevValue) => !prevValue);
  };

  const handleSubmit = () => {
    const isFormValid = validate({ data: loginForm, validationSchema });
    if (!isFormValid) {
      return;
    }
    login();
  };

  //* API Actions
  const login = async () => {
    try {
      setIsLoading(true);
      const { emailAddress, password } = loginForm;

      const {
        data: {
          data: { token, refresh_token, user },
        },
      } = await loginApi({
        username: emailAddress,
        password,
        isRememberMe,
      });

      setAccessToken({ accessToken: token });
      setRefreshToken({ refreshToken: refresh_token });
      dispatch(
        authenticateUserAction({
          accessToken: token,
          refreshToken: refresh_token,
        })
      );

      navigate(routes.offers.main);

      setIsLoading(false);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      setIsLoading(false);
    }
  };

  //* Wrappers
  const data = { ...loginForm, isPasswordVisible, isRememberMe };

  const handlers = {
    handleInputChange,
    handleInputBlur,
    handlePasswordVisibilityChange,
    handleRememberMeChange,
    handleSubmit,
  };

  return (
    <LoginUi
      isLoading={isLoading}
      data={data}
      errors={errors}
      handlers={handlers}
    />
  );
};

export default Login;
