import { memo } from "react";

import { Banner, Card, Section, Switch } from "components";

import { locales } from "constants/index";

import { TNotificationsUI } from './Notifications.types';

import './notifications.scss';

const NotificationsUI: TNotificationsUI = ({
  data : {},
  handlers: {},
}) => {
  return (
    <div className="settings__subModule">
      <Section title={locales.settings.notifications}>
        <Card>
          <Banner>
            <Switch
              id="sendActivated"
              name="sendActivated"
              label={locales.settings.notification.all}
              isChecked={false}
              isInverted
              extraClass=""
              onChange={() => {}}
            />
          </Banner>

          <div className="notifications__switch">
            {locales.settings.notification.recommandation}
            <div className='notifications__switchGroup'>
              <Switch
                id="sendActivated"
                name="sendActivated"
                label={locales.settings.notification.email}
                isChecked={false}
                extraClass=""
                onChange={() => {}}
              />
              <Switch
                id="sendActivated"
                name="sendActivated"
                label={locales.settings.notification.sms}
                isChecked={false}
                extraClass=""
                onChange={() => {}}
              />
            </div>
          </div>

          <div className="">
            <Switch
              id="sendActivated"
              name="sendActivated"
              label={locales.settings.notification.offreApply}
              isChecked={false}
              extraClass="notifications__switch"
              onChange={() => {}}
            />
          </div>
        </Card>
      </Section>
    </div>
  );
};

export default memo(NotificationsUI);
