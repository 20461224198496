import { modalsActionTypes } from './action-types';

import {
  TSetModalTypeAction,
  TSetModalValuesAction,
  TResetModalAction,
  TSetModalDataAction,
  TResetStateAction,
} from './store.types';

export const setModalType: TSetModalTypeAction = (modalType, payload) => {
  return {
    type: modalsActionTypes.SET_MODAL_TYPE,
    modalType,
    payload,
  };
};

export const setModalValues: TSetModalValuesAction = (modalType, payload) => {
  return {
    type: modalsActionTypes.SET_MODAL_VALUES,
    modalType,
    payload,
  };
};

export const setModalData: TSetModalDataAction = (modalType, payload) => {
  return {
    type: modalsActionTypes.SET_MODAL_DATA,
    modalType,
    payload,
  };
};

export const resetModal: TResetModalAction = (modalType, ) => {
  return {
    type: modalsActionTypes.RESET_MODAL,
    modalType,
  };
};


export const resetStateModal: TResetStateAction = () => {
  return {
    type: modalsActionTypes.RESET,
  };
};


export { };
