import { rem } from "@mantine/core";

import type { IconProps } from "assets/tsxIcons/types";


export const ArrowRight = ( { size, style, ...others }: IconProps ) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={ { width: rem( size ), height: rem( size ), ...style } }
        { ...others }
    >
        <path d="M5 12H20M20 12C19.5116 13.3333 17.699 16 15.2569 17M20 12C19.3488 10.6667 17.699 8 15.2569 7"
              stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);