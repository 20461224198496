import classNames from 'classnames';

import { Style } from './Switch.types';

const style: Style = ({ extraClass, isInverted }) =>
  classNames({
    'switch flex flex-align-items-center': true,
    [extraClass]: extraClass.length > 0,
    'switch-inverted': isInverted,
  });

export { style };
