import classNames from 'classnames';
import { TButton } from './Button.types';

import { Link } from 'react-router-dom';

import './button.scss';
import {ButtonVariantEnum} from "./Button.enums";

export const Button: TButton = ({
  children,
  isDisabled,
  variant = 'primary',
  size = 'medium',
  className = '',
  isLink = false,
  isOutline = false,
  isRounded = true,
  borderLess = false,
  path = '',
  onClick = () => {},
}) => {
  const classes = classNames({
    'button': true,
    'button__disabled': isDisabled,
    'button__negate radius': !isRounded,
    'button__negate border': borderLess,
    [`button__size ${size}`]: !!size,
    [`button__variant ${variant}`]: !!variant,
    [`button__variant ${variant} outline`]: isOutline && variant !== ButtonVariantEnum.link,
    [className]: className.length > 0,
  });

  return isLink ? (
    <Link to={isDisabled ? '' : path} className={classes} onClick={onClick} aria-disabled={isDisabled}>
      {children}
    </Link>
  ) : (
    <button className={classes} disabled={isDisabled} onClick={onClick}>
      {children}
    </button>
  );
};
