import { baseApi } from 'utils';

import {
  TRegisterApi,
  TLoginApi,
  TVerifyEmailApi,
  TSendEmailVerificationApi,
  TResetPasswordApi,
  TSendPasswordVerificationApi,
  TValidatePasswordVerificationApi,
  TAcceptInviteApi,
} from './api.types';

export const registerApi: TRegisterApi = (payload) => {
  return baseApi().post('/users/register', payload);
};

export const loginApi: TLoginApi = (payload) => {
  return baseApi().post('/users/auth/login', payload);
};

export const logoutApi = () => {
  return baseApi().put('/users/auth/logout');
};

export const verifyEmailApi: TVerifyEmailApi = (id) => {
  return baseApi().get(`/users/email/verify?id=${id}`);
};

export const sendEmailVerificationApi: TSendEmailVerificationApi = (payload) => {
  return baseApi().post('/schools/email/send', payload);
};

export const resetPasswordApi: TResetPasswordApi = (payload) => {
  return baseApi().put('/schools/user/password', payload);
};

export const sendPasswordVerificationApi: TSendPasswordVerificationApi = (payload) => {
  return baseApi().post('/schools/password/send', payload);
};

export const validatePasswordVerificationApi: TValidatePasswordVerificationApi = (payload) => {
  return baseApi().put('/schools/password/verify', payload);
};

export const acceptInviteApi: TAcceptInviteApi = (payload) => {
  return baseApi().put('/users/accept-invitation', payload.body, { params: payload.params });
};
