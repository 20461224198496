import classNames from 'classnames';
import { TTab } from './Tab.types';

import './tab.scss';

export const Tab: TTab = ({
  title,
  index,
  isActive = false,
  className = '',
  onClick,
  tabColor,
}) => {
  const mainClasses = classNames({
    'tab flex flex-column': true,
    'tab--active': isActive,
    // [`tab--${tabColor}`]: true,
    [className]: className.length > 0,
  });

  return (
    <div className={mainClasses} onClick={onClick(index)}>
      {title}
    </div>
  );
};
