import GreenLine from './green-line.svg';
import OkIllustrationGreen from './ok-illustration-green.svg';
import BusinessDeal from './business-deal.svg';
import Company from './company.svg';
import OfferHero from './offer-hero.png';

export const Images = {
  GreenLine,
  OkIllustrationGreen,
  BusinessDeal,
  Company,
  OfferHero,
};
