export const SET_PIPES = 'SET_PIPES';
export const SET_OPTIONS_OFFERS = 'SET_OPTIONS_OFFERS';
export const SET_OFFERID = 'SET_OFFERID';
export const SET_SELECTED_CV_URL = 'SET_SELECTED_CV_URL';
export const SET_PIPES_BY_COLUMN = 'SET_PIPES_BY_COLUMN';
export const SET_SELECTED_CANDIDATES = 'SET_SELECTED_CANDIDATES';
export const CLEAR_SELECTED_CANDIDATES = 'CLEAR_SELECTED_CANDIDATES';
export const TRIGGER_REFRESH_PIPES = 'TRIGGER_REFRESH_PIPES';
export const SET_DRAGGED_ID = 'SET_DRAGGED_ID';
export const RESET_DRAGGED_ID = 'RESET_DRAGGED_ID';
export const SET_STUDENT_INFO = 'SET_STUDENT_INFO';
export const SET_PROFILE = 'SET_PROFILE';

enum actionTypes {
    SET_PROFILE_ACTIONS = 'SET_PROFILE_ACTIONS',
    RESET_PROFILE_ACTIONS = 'RESET_PROFILE_ACTIONS',

    SET_PROFILE_CV = 'SET_PROFILE_CV',
    SET_PROFILE_DETAILS = 'SET_PROFILE_DETAILS',
    SET_PROFILE_COMMENTS = 'SET_PROFILE_COMMENTS',
    EDIT_PROFILE_COMMENTS = 'EDIT_PROFILE_COMMENTS',
    SET_PROFILE_HISTORY = 'SET_PROFILE_HISTORY',
    SET_PROFILE_EMAILS = 'SET_PROFILE_EMAILS',
    RESET_PROFILE = 'RESET_PROFILE',

    RESET = 'RESET',
}

export { actionTypes as studentsActionTypes };
