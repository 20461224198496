import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  useGetUserInfo,
  useOutsideClick,
  useToast,
  useExtractServerError,
} from 'hooks';
import {
  updateCompanyProfileApi,
  fetchUserInfoApi,
} from 'modules/Settings/api';
import { setUserInfoAction } from 'modules/App/store/actions';

import HeaderUi from './Header.ui';

import { THeader, IAttachment } from './Header.types';

import './header.scss';

const Header: THeader = () => {
  //* Hooks Init
  const dispatch = useDispatch();
  const notificationsRef = useRef<null | HTMLDivElement>(null);
  const userInfo = useGetUserInfo();
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [isNotificationListVisible, setIsNotificationListVisible] =
    useState(false);
  const [isContactSupportModalVisible, setIsContactSupportModalVisible] =
    useState(false);
  const [isAttachmentsLibraryVisible, setIsAttachmentsLibraryVisible] =
    useState(false);
  const [selectedProfileImage, setSelectedProfileImage] = useState('');

  useOutsideClick({
    elementRef: notificationsRef,
    setVisible: setIsNotificationListVisible,
  });

  //* Handlers
  const handleNotificationsClick = () => {
    setIsNotificationListVisible((prevValue) => !prevValue);
  };

  const handleContactSupportClick = () => {
    setIsContactSupportModalVisible(true);
  };

  const handleContactSupportClose = () => {
    setIsContactSupportModalVisible(false);
  };

  const handleProfileImageClick = () => {
    setIsAttachmentsLibraryVisible(true);
  };

  const handleProfileImageSelect = (attachments: IAttachment[]) => {
    setSelectedProfileImage(attachments[0]._id);
  };

  const handleAttachmentsLibraryModalClose = () => {
    setIsAttachmentsLibraryVisible(false);
  };

  const handlerProfileImageUpdate = () => {
    updateCompanyProfile();
  };

  //* Side Effects
  const updateCompanyProfile = async () => {
    try {
      setIsLoading(true);

      await updateCompanyProfileApi({ attachmentId: selectedProfileImage });
      await fetchCompanyInfo();

      setIsLoading(false);
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      setIsLoading(false);
    }
  };

  const fetchCompanyInfo = async () => {
    try {
      const {
        data: { data: userInfo },
      } = await fetchUserInfoApi();

      dispatch(setUserInfoAction(userInfo));
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      setIsLoading(false);
    }
  };

  const data = {
    userInfo,
  };

  const handlers = {
    handleNotificationsClick,
    handleContactSupportClick,
    handleContactSupportClose,
    handleProfileImageClick,
    handleAttachmentsLibraryModalClose,
    handlerProfileImageUpdate,
    handleProfileImageSelect,
  };

  return (
    <HeaderUi
      isLoading={isLoading}
      isNotificationListVisible={isNotificationListVisible}
      isContactSupportModalVisible={isContactSupportModalVisible}
      isAttachmentsLibraryVisible={isAttachmentsLibraryVisible}
      notificationsRef={notificationsRef}
      data={data}
      handlers={handlers}
    />
  );
};

export default Header;
