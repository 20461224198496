"use client";

import { TextInput } from "@mantine/core";

import classNames from 'styles/sass/components/TextInput.module.scss';

export default TextInput.extend({
	defaultProps: {
		size: 'md',
		radius: 'lg',
	},
	classNames,
})