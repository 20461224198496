import { memo } from 'react';

import { TTableInfo } from './TableInfo.types';

import 'components/Table2/table.scss';
import './table-info.scss';

const TableInfo: TTableInfo = ({
    onClick
}) => {
    return (
        <div className='td__action'>
            <div className='table-info' onClick={onClick}/>
        </div>
    );
};

export default memo(TableInfo);