import { memo, useEffect, useMemo, useRef, useState } from "react";
import { getCoreRowModel, getSortedRowModel, RowSelectionState, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual';

// import { columns, makeData } from "./makeData";
import { TTable } from './Table.types';

import TableUI from "./Table.ui";

const Table: TTable = ({
    data = [],
    columns = [],
    rowId = 'index',
    enableRowSelection = false,
    enableMultiRowSelection = false,
    handleSelectionChange,
    withHeader = true,
}) => {
    //* Refs
    const tableContainerRef = useRef<HTMLDivElement>(null);

    //* Local State
    const [rowSelection, onRowSelectionChange] = useState<RowSelectionState>({}) //manage your own row selection state

    //* Effects
    useEffect(() => handleSelectionChange && handleSelectionChange(rowSelection), [rowSelection]);

    //KEEP: for dev purposes
    // const data = useMemo(() => makeData(50), []);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onRowSelectionChange,
        state: {
            rowSelection,
        },
        getRowId: row => row[rowId],
        enableRowSelection,
        enableMultiRowSelection,
        debugTable: true,
        defaultColumn: {
            size: 1,
            minSize: 120,
        },
    })

    const { rows } = table.getRowModel() ?? [];

    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 61, //estimate row height for accurate scrollbar dragging
        getScrollElement: () => tableContainerRef.current,
        //measure dynamic row height, except in firefox because it measures table border height incorrectly
        measureElement:
            typeof window !== 'undefined' &&
            navigator.userAgent.indexOf('Firefox') === -1
                ? element => element?.getBoundingClientRect().height
                : undefined,
        overscan: 5,
    })

    //* Wrappers
    const props = useMemo(() => ({
        table,
        rowVirtualizer,
        rows,
        withHeader,
    }), [ table, rowVirtualizer, rows, withHeader ]);

    return <TableUI ref={tableContainerRef} {...props} />;
};

export default memo(Table);
