import React, { memo, useEffect, useRef, useState } from "react";

import { locales } from "constants/index";
import { InterviewDetailsModal, Badge, Button, Dropdown, ValidateEmailModal } from "components";

import { ModalsTypesEnum } from "components/Modals/Modals.enums";
import { ActionsEnum } from "./Details.enum";
import type { TDetailsUI } from "./Details.type";

import styles from './details.module.scss';


const DetailsUI: TDetailsUI = ({
    data: {
        skills,
        emails,
        config,
        modals,
    },
    handlers,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const [ openSkills, setOpenSkills ] = useState<boolean>(false);
    const [ numberOfVisibleSkills, setNumberOfVisibleSkills ] = useState<number>(10);

    useEffect(() => {
        if (openSkills) return;
        if (!ref || !ref.current) return;
        if (ref.current.offsetHeight > 104) setNumberOfVisibleSkills(p => p - 2);
    }, [ref, numberOfVisibleSkills]);

    return <React.Fragment>
        <div className={styles.details}>
            <div ref={ref} className={styles.skillsContainer}>
                {skills.filter((value, index) => openSkills || (index + 1 < numberOfVisibleSkills))
                .map((value, index) => <Badge
                    key={index}
                    hideBall
                    numberOfLines='one'
                    size='skills'
                    value={value}
                    isOutline
                    style={{ primary: '#2EC1AC', secondary: 'transparent' }}
                />)}

                {skills.length > numberOfVisibleSkills && <Button
                    variant="link"
                    size="link-icon"
                    className={styles.seeMore}
                    onClick={() => setOpenSkills(p => !p)}
                >
                    <div className={styles.seeMore__text}>
                        {openSkills
                            ? locales.student.actions.seeLess
                            : locales.student.actions.seeMore}
                    </div>
                    <div className={`${styles.seeMore__icon} ${openSkills ? styles.arrowUp : styles.arrowDown}`}/>
                </Button>}
            </div>

            <div className={styles.actionsContainer}>
                <div className={styles.action}>
                    <div className={styles.actionTextContainer}>
                        <div className={styles.actionText}>
                            {locales.student.details.callStatus}
                        </div>
                    </div>

                    <div className={styles.actionContentContainer}>
                        <Dropdown
                            placeholder={locales.student.select}
                            className={styles.actionContent}
                            options={emails}
                            onChange={handlers.handleDropdownChangeWithType(ActionsEnum.callStatus)}
                        />
                    </div>
                </div>
            </div>
        </div>

        {/*{modals[ModalsTypesEnum.INTERVIEW_DETAILS].isOpen && <InterviewDetailsModal onSubmit={handlers.handleSubmitInterviewDetails}/>}*/}
        {modals[ModalsTypesEnum.VALIDATE_EMAIL].isOpen && <ValidateEmailModal onSubmit={handlers.handleSubmitInterviewDetails} />}
    </React.Fragment>
};

export default memo(DetailsUI);
