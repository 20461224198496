import { rem } from "@mantine/core";

import type { IconProps } from "assets/tsxIcons/types";


export const ArchiveBox = ( { size, style, ...others }: IconProps ) => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={ { width: rem( size ), height: rem( size ), ...style } }
        { ...others }
    >
        <path
            d="M2.08712 9.92548C2.36569 10.6862 3.13712 11.1191 3.94926 11.1041C11.1814 10.9798 18.5121 11.1083 26.0314 11.1255C26.8521 11.1298 27.6214 10.669 27.9 9.89976C28.213 9.08017 28.3798 8.21201 28.3928 7.33476C28.3928 6.41334 28.2043 5.51334 27.8764 4.64548C27.6085 3.94048 26.9121 3.51619 26.1578 3.48191C18.71 3.12452 11.2493 3.12452 3.8014 3.48191C3.07069 3.51619 2.38926 3.91905 2.12355 4.60262C1.78497 5.48119 1.60712 6.40048 1.60712 7.33476C1.60712 8.22405 1.77855 9.08548 2.08712 9.92548Z"
            fill="#CDEBE7"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.43352 11.1018C9.45423 11.0183 14.5221 11.0547 19.6692 11.089C21.6235 11.104 23.5907 11.1168 25.5664 11.1254C26.0442 13.5854 26.3335 16.2168 26.3335 18.9168C26.3335 21.2418 26.0957 23.4897 25.7399 25.6583C25.6658 26.1046 25.4477 26.5144 25.1188 26.8251C24.7899 27.1358 24.3683 27.3303 23.9185 27.379C20.9577 27.7194 17.9803 27.8954 14.9999 27.9061C12.4864 27.9061 10.0349 27.6811 7.66495 27.4625L6.14995 27.3254C5.68468 27.2906 5.24411 27.1024 4.8974 26.7902C4.55068 26.478 4.31742 26.0595 4.23423 25.6004C3.86049 23.3914 3.67126 21.1551 3.66852 18.9147C3.66852 16.2104 3.93209 13.5618 4.43352 11.1018Z"
              fill="#CDEBE7"/>
        <path
            d="M2.08712 9.92548C2.36569 10.6862 3.13712 11.1191 3.94926 11.1041C11.1814 10.9798 18.5121 11.1083 26.0314 11.1255C26.8521 11.1298 27.6214 10.669 27.9 9.89976C28.213 9.08017 28.3798 8.21201 28.3928 7.33476C28.3928 6.41334 28.2043 5.51334 27.8764 4.64548C27.6085 3.94048 26.9121 3.51619 26.1578 3.48191C18.71 3.12452 11.2493 3.12452 3.8014 3.48191C3.07069 3.51619 2.38926 3.91905 2.12355 4.60262C1.78497 5.48119 1.60712 6.40048 1.60712 7.33476C1.60712 8.22405 1.77855 9.08548 2.08712 9.92548Z"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.91 17.3784H18.09" stroke="#2EC1AC" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M4.40356 11.2432C3.90882 13.7702 3.66265 16.3396 3.66856 18.9146C3.66856 21.2203 3.87213 23.4467 4.23428 25.6003C4.31746 26.0594 4.55072 26.4779 4.89744 26.7901C5.24416 27.1023 5.68472 27.2905 6.14999 27.3253C8.98285 27.5739 11.9464 27.906 15 27.906C17.9803 27.8953 20.9577 27.7193 23.9186 27.3789C24.3684 27.3302 24.7899 27.1357 25.1188 26.825C25.4477 26.5143 25.6659 26.1045 25.74 25.6582C26.0957 23.4896 26.3336 21.2417 26.3336 18.9167C26.3336 16.2596 26.055 13.671 25.59 11.2453"
            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);