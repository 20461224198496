import { memo } from "react";

import { locales } from "constants/index";

import { Button, NextPreviousLinks, PdfViewer, TabPanel, Tabs } from "components";

import type { TNewProfileUI } from "./NewProfile.type";

import styles from './new-profile.module.scss';

import Actions from "./Actions";
import Header from "./Header";
import Details from "./Details";
import Comments from "./Comments";
import History from "./History";

const NewProfileUI: TNewProfileUI = ({
    data,
    handlers,
}) => {

    return <div className={styles.np}>
        <div className={styles.np__navigation}>
            <NextPreviousLinks
                previousLinkLabel={locales.student.previousProfile}
                nextLinkLabel={locales.student.nextProfile}
                previousId={data.previousId}
                nextId={data.nextId}
                onClick={handlers.handleProfileLinkClick}
                variant="white"
            />
        </div>

        <div className={styles.np__content}>
            <Actions />

            <div className={styles.np__profile}>
                <Header />

                <div className={styles.np__profileInfo}>
                    <div className={styles.np__profileInfo__details}>
                        <Details />

                        <div className={styles.np__addNote}>
                            <Button
                                variant='secondary'
                                onClick={handlers.handleAddNoteClick}
                            >
                                {locales.student.newNote}
                            </Button>
                        </div>
                        <Tabs activeTab={ 2 }>
                            <TabPanel title={ locales.student.history.history }>
                                { data?.isSchool && <History/> }
                            </TabPanel>
                            <TabPanel  title={locales.student.notes.notes}>
                                <Comments />
                            </TabPanel>
                        </Tabs>
                    </div>

                    <div className={styles.np__profileInfo__CV}>
                        {!!data.cvUrl && (
                            <PdfViewer
                                url={data.cvUrl}
                                handleDownload={handlers.handleDownloadCvClick}
                                isVisible
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>


    </div>;
};

export default memo(NewProfileUI);
