import { memo } from "react";

import { Filter, Table2 } from 'components';

import { locales, routes, enums } from 'constants/index';

import { columns } from './tableColumns';

import { TArchivedOffersUI } from './ArchivedOffers.types';

import './archived-offers.scss';

const ArchivedOffersUI: TArchivedOffersUI = ( {
    data: { offers },
    handlers,
} ) => {
	return (
		<div className="settings__subModule">
			<div className='archived-offers__filterContainer'>
				<Filter moduleName={enums.moduleNames.archives}>
					<Table2
						data={offers}
						columns={columns}
						rowId='_id'
						// enableRowSelection={true}
						// enableMultiRowSelection={true}
						// handleSelectionChange={handlers.handleSelectionChange}
					/>
				</Filter>
			</div>
		</div>
	);
};

export default memo( ArchivedOffersUI );
