import { memo } from "react";
import { ToastContainer } from 'react-toastify';

import { TToast } from './Toast.types';

import 'react-toastify/dist/ReactToastify.css';

import './toast.scss';

const Toast: TToast = ({
  duration = 3500,
  position = 'top-center',
  isProgressBarHidden = false,
  isNewestOnTop = true,
  isRTL = false,
  isDraggable = true,
  isPauseOnHover = true,
}) => {
  return (
    <ToastContainer
      position={position}
      autoClose={duration}
      hideProgressBar={isProgressBarHidden}
      newestOnTop={isNewestOnTop}
      closeOnClick
      rtl={isRTL}
      pauseOnFocusLoss
      draggable={isDraggable}
      pauseOnHover={isPauseOnHover}
      className="toast"
      toastClassName="class"
      toastStyle={{
        width: '40rem',
      }}
      limit={1}
    />
  );
};

export default memo(Toast);
