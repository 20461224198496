import { memo } from "react";
import { Link } from 'react-router-dom';

import { Modal } from 'components';

import { locales, routes } from 'constants/index';

import { TArchiveSuccessModal } from './ArchiveSuccessModal.types';

import './archive-success-modal.scss';

const ArchiveSuccessModal: TArchiveSuccessModal = ({
  onClose,
  isSingleOffer,
  actionButtons,
}) => {
  return (
    <Modal
      title={locales.offer.archiveOffer}
      className="archive-success-modal"
      onClose={onClose}
      actionButtons={
        actionButtons ?? (
          <span className="flex flex-justify-content-center">
            <Link
              to={`${routes.offers.main}/${routes.offers.listing}`}
              className="archive-success-modal__link"
            >
              {locales.general.returnToHomepage}
            </Link>
          </span>
        )
      }
    >
      <div className="archive-success-modal__image" />

      <div className="archive-success-modal__description">
        {isSingleOffer
          ? locales.offer.successArchiveSubTitle
          : locales.offer.successArchiveSubTitlePlural}
      </div>
      {/*<div className="archive-success-modal__description">*/}
      {/*  {isSingleOffer*/}
      {/*    ? locales.offer.successArchiveSubTitle2*/}
      {/*    : locales.offer.successArchiveSubTitle2Plural}*/}
      {/*</div>*/}
    </Modal>
  );
};

export default memo(ArchiveSuccessModal);
