import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { Modal } from 'components';

import { IStoreState } from "store";

import { ModalsTypesEnum } from "../Modals.enums";
import { TUploadSuccessModal } from './UploadSuccessModal.types';

import './upload-success-modal.scss';

export const UploadSuccessModal: TUploadSuccessModal = ({
  title,
  description,
  fileNames,
  onClose,
}) => {
  const modalType = ModalsTypesEnum.UPLOAD_SUCCESS;

  //* Redux State
  const modals = useSelector(({ modals: { [modalType]: modals } }: IStoreState) => modals, shallowEqual);

  //* Memos
  const reduxFileNames = useMemo(() => modals.data.CVs.filter(item => modals.values.CVs.includes(item._id)).map(item => item.name), [
      modals.data.CVs,
      modals.values.CVs,
  ]);

  if (!fileNames && !modals.isOpen) return null;

  return (
    <Modal title={title} className="upload-success-modal" onClose={onClose}>
      <div className="upload-success-modal__image"></div>

      <div className="upload-success-modal__description">{description}</div>

      <div className="upload-success-modal__files">
        {(fileNames ?? reduxFileNames).map((fileName) => (
          <div
            key={fileName}
            className="upload-success-modal__file flex flex-align-items-center"
          >
            <div className="upload-success-modal__icon"></div>
            <div className="upload-success-modal__filename" title={fileName}>
              {fileName}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};
