export const SET_OFFERS = 'SET_OFFERS';
export const SET_OFFER_FORM_ACTIVE_STEP = 'SET_OFFER_FORM_ACTIVE_STEP';
export const SET_OFFER_FORM_COMPLETED_STEP = 'SET_OFFER_FORM_COMPLETED_STEP';
export const SET_OFFER_FORM_STEP_VALUES = 'SET_OFFER_FORM_STEP_VALUES';
export const SET_SKILL_CATEGORIES = 'SET_SKILL_CATEGORIES';
export const SET_SKILLS = 'SET_SKILLS';
export const CLEAR_OFFER_FORM = 'CLEAR_OFFER_FORM';
export const SET_SUBSCRIPTION_TYPES = 'SET_SUBSCRIPTION_TYPES';
export const SELECT_SUBSCRIPTION_TYPES = 'SELECT_SUBSCRIPTION_TYPES';
export const SET_IS_USER_SUBSCRIBED = 'SET_IS_USER_SUBSCRIBED';
export const SET_COUPON_CODE = 'SET_COUPON_CODE';
export const SET_SUB_EDUCATION_LEVEL = 'SET_SUB_EDUCATION_LEVEL';

enum actionTypes {
    SET_CHOSEN_CATEGORY = 'SET_CHOSEN_CATEGORY',
    SET_DRAFT = 'SET_DRAFT',
    SET_DRAFT_SELECTED = 'SET_DRAFT_SELECTED',
    RESET = 'RESET',
}

export { actionTypes as offersActionTypes };
