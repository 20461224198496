import { memo, useCallback, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { locales } from "constants/index";
import { useToast } from "hooks";
import { IStoreState } from "store";

import { ModalsTypesEnum } from "components/Modals/Modals.enums";
import { setModalType } from "components/Modals/store/actions";

import { TActions } from "./Actions.type";

import ActionsUI from "./Actions.ui";

const Actions: TActions = () => {
    const modalType = ModalsTypesEnum.ADD_TO_YPAREO;

    //* Hooks
    const dispatch = useDispatch();
    const toast = useToast();

    //* Redux State
    const studentInfo = useSelector(({ candidates: { newProfile: { studentInfo } } }: IStoreState) => studentInfo, shallowEqual);
    const modals = useSelector(({ modals }: IStoreState) => modals, shallowEqual);


    //* Handlers
    const handleAddToYPareo = useCallback(async () => {
        dispatch(setModalType(modalType, true));

        if (!!studentInfo?.student_info?._id) return;
        toast({type: 'error', message: locales.errors.cantAddToyPareo});
    },[dispatch, studentInfo]);

    const handleSubmitAddToYPareo = useCallback(async () => {
        dispatch(setModalType(modalType, undefined));
    },[dispatch]);


    //* Wrappers
    const data = useMemo(() => ({
        modals,
    }), [
        modals,
    ]);

    const handlers = useMemo(() => ({
        handleAddToYPareo,
        handleSubmitAddToYPareo,
    }), [
        handleAddToYPareo,
        handleSubmitAddToYPareo,
    ]);

    return <ActionsUI data={data} handlers={handlers} />
};

export default memo(Actions);
