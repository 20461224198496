import { useState, useEffect, memo, useCallback, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch, batch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useToast, useExtractServerError } from "hooks";
import { cleanObject } from "utils";
import { locales, routes } from "constants/index";

import {
    setOfferFormActiveStepAction,
    clearOfferFormAction,
    setOfferFormStepValuesAction,
    setOfferFormCompletedStepAction,
    setSubscriptionTypesAction,
    setIsUserSubscribedAction,
    selectSubscriptionTypeAction,
    setCouponCodeAction,
} from "../store/actions";
import { postOfferApi, updateOfferApi, fetchOfferApi, checkUserSubscriptionApi, fetchSubscriptionsApi } from "../api";

import CreateOfferUi from "./CreateOffer.ui";
import { CreateOfferStepsEnum } from "./enums";

import { TCreateOffer, IStoreState, IHandleChange, IOfferForm } from "./CreateOffer.types";

const CreateOffer: TCreateOffer = () => {
    //* Hooks
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useToast();
    const ExtractServerError = useExtractServerError();
    const params = useParams();
    const Location = useLocation();

    //* Redux State & Memos
    const reduxConfig = useSelector(({ app: { config } }: IStoreState) => config, shallowEqual);

    const reduxUserInfo = useSelector(({ app: { userInfo } }: IStoreState) => userInfo, shallowEqual);

    const reduxOffer = useSelector(({ offers }: IStoreState) => offers, shallowEqual);

    //* Memos
    const location = useMemo(() => Location, [Location]);
    const id = useMemo(() => params.id, [params.id]);
    const step = useMemo(() => params.step ?? "", [params.step]);

    const config = useMemo(() => reduxConfig, [reduxConfig]);

    const userInfo = useMemo(() => reduxUserInfo, [reduxUserInfo]);

    const activeStepIndex = useMemo(() => reduxOffer.form.activeStepIndex, [reduxOffer.form.activeStepIndex]);
    const completedSteps = useMemo(() => reduxOffer.form.completedSteps, [reduxOffer.form.completedSteps]);
    const subscriptionTypes = useMemo(() => reduxOffer.subscriptionTypes, [reduxOffer.subscriptionTypes]);
    const isUserSubscribed = useMemo(() => reduxOffer.isUserSubscribed, [reduxOffer.isUserSubscribed]);
    const selectedSubscriptionType = useMemo(
        () => reduxOffer.selectedSubscriptionType,
        [reduxOffer.selectedSubscriptionType]
    );
    const couponCode = useMemo(() => reduxOffer.couponCode, [reduxOffer.couponCode]);

    //* Callbacks
    const extractErrorMessage = useCallback(ExtractServerError.extractErrorMessage, []);

    //* Local State
    const [isLoading, setIsLoading] = useState(false);

    // * Side Effects
    const fetchOffer = useCallback(async () => {
        try {
            if (!id) return;

            setIsLoading(true);

            const {
                data: { data },
            } = await fetchOfferApi(id);

            dispatch(
                setOfferFormStepValuesAction({
                    title: data.info.title,
                    description: data.company.description || "",
                    contractType: data.info.type || "",
                    city: !!data.info.postal_code ? data.info.city : "",
                    lat: !!data.info.geoloc.lat?.toString() ? data.info.geoloc.lat?.toString() : "",
                    lng: !!data.info.geoloc.lng?.toString() ? data.info.geoloc.lng?.toString() : "",
                    postalCode: data.info.postal_code?.toString() || "",
                    educationLevel: data.info.education_level,
                    workFields: data.info.work_fields,
                    workPlaces: data.info.work_places,
                    profile: data.candidate.profile || "",
                    missions: data.candidate.missions || "",
                    required: data.candidate.skills.required,
                    skillsImportant: data.candidate.skills.important,
                    bonus: data.candidate.skills.bonus,
                    languages: data.candidate.languages,
                    keywords: data.info.keywords,
                    pace: data.info.pace,
                    startDate: data.info.start_date || "",
                    comment: data.info.comment || "",
                    subEducationLevel: data.info.sub_education_level,
                })
            );

            setIsLoading(false);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: "error", message: errorMessage });
            setIsLoading(false);
        }
    }, [id]);

    const postOffer = useCallback(
        async (isDraft: boolean = false, data: Partial<IOfferForm>) => {
            try {
                setIsLoading(true);

                const payload = cleanObject({
                    title: data.title,
                    company_description: data.description,
                    type: data.contractType,
                    city: data.city,
                    lat: data.lat,
                    lng: data.lng,
                    postal_code: data.postalCode || "",
                    education_level: data.educationLevel,
                    work_fields: data.workFields,
                    work_places: data.workPlaces,
                    profile: data.profile,
                    missions: data.missions,
                    skills_required: data.required,
                    skills_bonus: data.bonus,
                    languages: data.languages,
                    keywords: data.keywords,
                    pace: data.pace,
                    comment: data.comment,
                    start_date: data.startDate,
                    sub_education_level: data.subEducationLevel,
                });

                await postOfferApi({
                    ...payload,
                    company_user_id: userInfo?._id || "",
                    draft: isDraft,
                    deleted: false,
                });

                setIsLoading(false);

                handlePaymentJourney(isDraft);
            } catch (error) {
                const errorMessage = extractErrorMessage(error);
                toast({ type: "error", message: errorMessage });
                setIsLoading(false);
            }
        },
        [userInfo]
    );

    const updateOffer = useCallback(
        async (id: string, draft: boolean, data: Partial<IOfferForm>) => {
            try {
                setIsLoading(true);

                const payload = cleanObject({
                    title: data.title,
                    company_description: data.description,
                    type: data.contractType,
                    city: data.city,
                    lat: data.lat,
                    lng: data.lng,
                    postal_code: data.postalCode || "",
                    education_level: data.educationLevel,
                    work_fields: data.workFields,
                    work_places: data.workPlaces,
                    profile: data.profile,
                    missions: data.missions,
                    skills_required: data.required,
                    skills_bonus: data.bonus,
                    languages: data.languages,
                    keywords: data.keywords,
                    pace: data.pace,
                    comment: data.comment,
                    start_date: data.startDate,
                    sub_education_level: data.subEducationLevel,
                });

                await updateOfferApi({
                    ...payload,
                    company_user_id: userInfo?._id || "",
                    offer_id: id,
                    draft,
                    deleted: false,
                });

                setIsLoading(false);

                handlePaymentJourney(draft);
            } catch (error) {
                const errorMessage = extractErrorMessage(error);
                toast({ type: "error", message: errorMessage });
                setIsLoading(false);
            }
        },
        [userInfo]
    );

    const checkUserSubscription = useCallback(async () => {
        try {
            setIsLoading(true);

            // TODO: CHECK THE EQUIVALENT FOR SCHOOL
            // const {
            //   data: { subscription },
            // } = await checkUserSubscriptionApi();

            // --- DISABLED STRIPE
            //
            // dispatch(setIsUserSubscribedAction(subscription))
            dispatch(setIsUserSubscribedAction(true));

            setIsLoading(false);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: "error", message: errorMessage });
            setIsLoading(false);
        }
    }, []);

    const fetchSubscriptions = useCallback(async () => {
        try {
            setIsLoading(true);

            // TODO: CHECK THE EQUIVALENT FOR SCHOOL
            const {
                data: { data },
            } = await fetchSubscriptionsApi();

            batch(() => {
                dispatch(
                    selectSubscriptionTypeAction(
                        data.filter(
                            ({ recurring: { interval, interval_count } }) =>
                                interval === "month" && interval_count === 1
                        )[0].default_price
                    )
                );
                dispatch(setSubscriptionTypesAction(data));
            });

            setIsLoading(false);
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: "error", message: errorMessage });
            setIsLoading(false);
        }
    }, []);

    //* Handlers
    const handleStepClick = useCallback(
        (stepIndex: number) => () => {
            if (completedSteps[stepIndex] || completedSteps[activeStepIndex])
                dispatch(setOfferFormActiveStepAction(stepIndex));
        },
        [completedSteps, activeStepIndex]
    );

    const handleCouponChange: IHandleChange = useCallback(({ target: { value } }) => {
        dispatch(setCouponCodeAction(value));
    }, []);

    const handleSubscriptionTypeClick = useCallback(
        (selectedSubscriptionType: string) => () => {
            dispatch(selectSubscriptionTypeAction(selectedSubscriptionType));
        },
        []
    );

    const handleSaveOfferAsDraft = useCallback(
        (data: Partial<IOfferForm>) => {
            if (!!id) return updateOffer(id, true, data);

            postOffer(true, data);
        },
        [postOffer, updateOffer]
    );

    const handlePublishOffer = useCallback(
        (data: Partial<IOfferForm>) => {
            if (!!id) return updateOffer(id, false, data);

            postOffer(false, data);
        },
        [postOffer, updateOffer]
    );

    const handlePaymentJourney = useCallback(
        (isDraft: boolean) => {
            if (isDraft) return navigate(`${routes.offers.main}/${routes.offers.listing}`);

            // --- DISABLED STRIPE
            //
            // batch(() => {
            //   dispatch(
            //     setOfferFormCompletedStepAction({
            //       stepIndex: CreateOfferStepsEnum.additionalInformation,
            //       isCompleted: true,
            //     })
            //   );
            // });

            // if (!isUserSubscribed) {
            //   if (!id)
            //     navigate(
            //       `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.plans}`
            //     );
            //   else
            //     navigate(
            //       `${routes.offers.main}/${routes.offers.create.main}/${id}/${routes.offers.create.plans}`
            //     );
            // } else {
            navigate(`${routes.offers.main}/${routes.offers.listing}`);
            // }
        },
        [isUserSubscribed]
    );

    //* Effects
    useEffect(() => {
        Promise.all([
            checkUserSubscription(),
            // fetchSubscriptions()
        ]);
    }, []);

    useEffect(() => {
        if (!id && step) {
            let url = `${routes.offers.main}/${routes.offers.create.main}/${routes.offers.create.generalInfo}`;
            return navigate(url);
        }
        let generalUrl = `${routes.offers.main}/${routes.offers.create.main}/${id}/${routes.offers.create.generalInfo}`;
        if (generalUrl !== location.pathname) navigate(generalUrl);
    }, []);

    useEffect(() => {
        return () => {
            dispatch(clearOfferFormAction());
        };
    }, []);

    useEffect(() => {
        if (config.degrees?.length > 0 && !!id) fetchOffer();
    }, [config.degrees, id]);

    useEffect(() => {
        let newActiveStepIndex;
        switch (step) {
            case routes.offers.create.skills:
                newActiveStepIndex = CreateOfferStepsEnum.skills;
                break;
            case routes.offers.create.additionalInfo:
                newActiveStepIndex = CreateOfferStepsEnum.additionalInformation;
                break;
            case routes.offers.create.plans:
                newActiveStepIndex = CreateOfferStepsEnum.plans;
                break;
            case routes.offers.create.checkout:
                newActiveStepIndex = CreateOfferStepsEnum.checkout;
                break;
            default:
                newActiveStepIndex = CreateOfferStepsEnum.generalInformation;
                break;
        }
        if (activeStepIndex === newActiveStepIndex) return;
        dispatch(setOfferFormActiveStepAction(newActiveStepIndex));
    }, [step]);

    const data = useMemo(
        () => ({
            isUserSubscribed,
            completedSteps,
            activeStepIndex,
            couponCode,
            subscriptionTypes,
            selectedSubscriptionType,
        }),
        [isUserSubscribed, completedSteps, activeStepIndex, couponCode, subscriptionTypes, selectedSubscriptionType]
    );

    const handlers = useMemo(
        () => ({
            handlePublishOffer,
            handleSaveOfferAsDraft,
            handleStepClick,
            handleSubscriptionTypeClick,
            handleCouponChange,
        }),
        [handlePublishOffer, handleSaveOfferAsDraft, handleStepClick, handleSubscriptionTypeClick, handleCouponChange]
    );

    // --- DISABLED STRIPE
    //
    // if (!!userInfo && !userInfo.customer_id) {
    //   navigate(routes.offers.listing);
    //
    //   toast({
    //     type: "info",
    //     message: locales.createOffer.noCustomerIdNote,
    //   });
    //
    //   return null;
    // }

    return <CreateOfferUi isLoading={isLoading} data={data} handlers={handlers} />;
};

export default memo(CreateOffer);
