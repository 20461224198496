import { memo, useMemo } from "react";
import { NavLink } from "react-router-dom";
import ClassNames from "classnames";

import { locales, routes } from "constants/index";
import { SettingRoutes } from "./enums";

import CompanyProfile from "./CompanyProfile";
import SettingsCommunication from "./SettingsCommunication";
import CustomizeWebsite from "./CustomizeWebsite";
import Notifications from "./Notifications";
import { ArchivedOffers } from "./ArchivedOffers";

import { TSettingsUI } from "./settings.types";

import './settings.scss';

const SettingsUI: TSettingsUI = ({
    data: {
        isSchool,
        activeModule,
    },
    handlers,
}) => {

    let module;
    const myAccount = useMemo(() =>
        <CompanyProfile />
    , []);

    const customizeWebsite = useMemo(() =>
        <CustomizeWebsite />
    , []);

    const archivedOffers = useMemo(() =>
        <ArchivedOffers />
    , []);

    const communication = useMemo(() =>
        <SettingsCommunication />
    , []);

    const notifications = useMemo(() =>
        <Notifications />
    , []);

    switch (activeModule) {
        case SettingRoutes.CUSTOMIZE_WEBSITE:
            module = customizeWebsite;
            break;

        case SettingRoutes.ARCHIVED_OFFERS:
            module = archivedOffers;
            break;

        case SettingRoutes.COMMUNICATION:
            module = communication;
            break;

        case SettingRoutes.NOTIFICATIONS:
            module = notifications;
            break;

        case SettingRoutes.MY_ACCOUNT:
        default:
            module = myAccount;
            break;
    }

    const settingModules = useMemo(() => [
        {
            title: locales.settings.myAccount.index,
            module: SettingRoutes.MY_ACCOUNT,
            pathname: routes.settings.myAccount,
            disabled: false,
        },
        {
            title: locales.settings.customizeWebsite,
            module: SettingRoutes.CUSTOMIZE_WEBSITE,
            pathname: routes.settings.customizeWebsite,
            disabled: !isSchool,
        },
        {
            title: locales.settings.archivedOffers,
            module: SettingRoutes.ARCHIVED_OFFERS,
            pathname: routes.settings.archivedOffers,
            disabled: true,
        },
        {
            title: locales.settings.communication,
            module: SettingRoutes.COMMUNICATION,
            pathname: routes.settings.communication,
            disabled: false,
        },
        {
            title: locales.settings.notifications,
            module: SettingRoutes.NOTIFICATIONS,
            pathname: routes.settings.notifications,
            disabled: true,
        }
    ], []);

    const bodyClassNames = ClassNames({
        settings__bodyContainer: true,
        overflow: activeModule === SettingRoutes.ARCHIVED_OFFERS,
    })

    return (
        <div className='settings'>
            <div className='settings__headerContainer'>
                <div className='settings__tabBar'>
                    {settingModules.map((value, index) =>
                        <div key={index} className={ClassNames({
                            'settings__tabWrapper': true,
                            'disabled': !!value?.disabled,
                        })}>
                            <NavLink
                                to={`${routes.settings.main}/${value.pathname}`}
                                className={ClassNames({
                                    'settings__tab': true,
                                    'disabled': !!value?.disabled,
                                })}
                                aria-disabled={true}
                            >
                                {value.title}
                            </NavLink>
                        </div>
                    )}
                </div>
            </div>
            <div className={bodyClassNames}>
                {module}
            </div>
        </div>
    );
};

export default memo(SettingsUI);