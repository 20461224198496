import ClassNames from 'classnames';

import { locales } from 'constants/index';

import { TNextPreviousLinks } from './NextPreviousLinks.types';

import './next-previous-links.scss';

const NextPreviousLinks: TNextPreviousLinks = ({
  previousLinkLabel = locales.general.previous,
  nextLinkLabel = locales.general.next,
  previousId,
  nextId,
  onClick,
  className = '',
  variant = 'lightgreen',
}) => {
  const nextPreviousLinksClassName = ClassNames({
    'next-previous-links': true,
    [className]: !!className,
  });

  const linkIconsClassName = ClassNames({
    'next-previous-links__icon': true,
    [`variant__${variant}`]: !!variant,
  });

  return (
    <div className={nextPreviousLinksClassName}>
      <div
        onClick={onClick && onClick(previousId)}
        className="next-previous-links__link"
      >
        <div className={ClassNames(linkIconsClassName, { previous: true })} />

        {previousLinkLabel}
      </div>

      <div
        onClick={onClick && onClick(nextId)}
        className="next-previous-links__link"
      >
        {nextLinkLabel}

        <div className={ClassNames(linkIconsClassName, { next: true })} />
      </div>
    </div>
  );
}

export default NextPreviousLinks;
