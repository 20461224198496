import { memo, useCallback, useEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { useExtractServerError, useToast } from "hooks";

import { fetchStudentCommentsApi, postStudentCommentApi } from "../../api";

import { locales } from "../../../../constants";

import { setIsLoading } from "modules/App/store/actions";
import { editProfileComments, setProfileComments } from "../../store/actions";

import type { IStoreState } from "store";
import type { TComments, THandleCancelNoteClick, THandleSubmitNoteClick } from "./Comments.type";
import type { TRichTextEditorOnChange } from "components/Forms/RichTextEditor/RichTextEditor.types";

import CommentsUI from "./Comments.ui";

const Comments: TComments = () => {
    //* Hooks
    const toast = useToast();
    const dispatch = useDispatch();
    const { extractErrorMessage } = useExtractServerError()

    //* Redux State
    const studentInfo = useSelector(({ candidates: { newProfile: { studentInfo } } }: IStoreState) => studentInfo, shallowEqual);
    const notes = useSelector(({ candidates: { newProfile: { comments } } }: IStoreState) => comments, shallowEqual);


    //* API Actions
    const fetchStudentComments = useCallback(async () => {
        try {
            if (!studentInfo?.student_info?._id) return;

            dispatch(setIsLoading(true));

            const { data: { data: comments } } =
                await fetchStudentCommentsApi(studentInfo?.student_info?._id);

            batch(() => {
                dispatch(setProfileComments({ list: comments }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, studentInfo]);

    const submitComment = useCallback(async () => {
        try {
            if (!studentInfo?.student_info?._id) return toast({ type: 'error', message: locales.errors.general });

            dispatch(setIsLoading(true));

            await postStudentCommentApi({
                userId: studentInfo?.student_info?._id,
                description: notes.edited?.description ?? '',
            });
            await fetchStudentComments();

            batch(() => {
                dispatch(setProfileComments({ isEditing: false, canSubmit: false, edited: {} }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, studentInfo, notes]);


    //* Handlers
    const handleRichTextChange: TRichTextEditorOnChange = useCallback((content, delta, source, editor) => {
        if (source === 'api') return;
        const textLength = editor.getText().trim().length ?? 0;

        console.log(textLength);

        batch(() => {
            if (textLength === 0) {
                console.log("here");
                dispatch(setProfileComments({ canSubmit: false }));
            }
            if (textLength > 1 && !notes.canSubmit) dispatch(setProfileComments({ canSubmit: true }));
            dispatch(editProfileComments({ description: content }));
        });
    }, [dispatch, notes]);

    const handleCancelNoteClick: THandleCancelNoteClick = useCallback(() => {
        dispatch(setProfileComments({ isEditing: false, canSubmit: false, edited: {} }))
    }, [dispatch]);

    const handleSubmitNoteClick: THandleSubmitNoteClick = useCallback(async () => {
        if (!studentInfo?.student_info?._id) return toast({ type: 'error', message: locales.errors.general });
        await submitComment();
    }, [dispatch, submitComment]);


    //* Effects
    useEffect(() => {
        fetchStudentComments();
    }, [studentInfo]);


    const data = useMemo(() => ({
        isEditing: notes.isEditing,
        canSubmit: notes.canSubmit,
        notes: notes.list,
        edited: notes.edited,
    }), [
        notes,
    ]);

    const handlers = useMemo(() => ({
        handleRichTextChange,
        handleCancelNoteClick,
        handleSubmitNoteClick,
    }), [
        handleRichTextChange,
        handleCancelNoteClick,
        handleSubmitNoteClick,
    ]);


    return <CommentsUI data={data} handlers={handlers} />
};

export default memo(Comments);
