import { useState } from 'react';

import { useToast, useExtractServerError } from 'hooks';

import ContactSupportModalUi from './ContactSupportModal.ui';
import { contactSupportApi } from './api';

import {
  TContactSupportModal,
  IHandleTextAreaChange,
} from './ContactSupportModal.types';

import './contact-support-modal.scss';

const ContactSupportModal: TContactSupportModal = ({ onClose }) => {
  //* Hooks Init
  const toast = useToast();
  const { extractErrorMessage } = useExtractServerError();

  //* Local State
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  //* Handlers
  const handleMessageChange: IHandleTextAreaChange = ({
    target: { value },
  }) => {
    setMessage(value);
  };

  const handleClose = () => onClose();

  const handleSubmit = () => contactSupport();

  //* Side Effects
  const contactSupport = async () => {
    try {
      setIsLoading(true);

      const {
        data: { message: serverMessage },
      } = await contactSupportApi(message);

      setIsLoading(false);

      onClose();

      toast({ type: 'success', message: serverMessage });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      toast({ type: 'error', message: errorMessage });
      setIsLoading(false);
    }
  };

  const handlers = {
    handleMessageChange,
    handleClose,
    handleSubmit,
  };

  return (
    <ContactSupportModalUi
      isLoading={isLoading}
      message={message}
      handlers={handlers}
    />
  );
};

export default ContactSupportModal;
