export const INIT_APP = 'INIT_APP';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_IS_APP_LOADED = 'SET_IS_APP_LOADED';
export const SET_DEGREES = 'SET_DEGREES';
export const SET_CLASSES = 'SET_CLASSES';
export const SET_TAGS = 'SET_TAGS';
export const SET_IS_LOADING = 'SET_IS_LOADING';

enum actionTypes {
    RESET = 'RESET',
}

export { actionTypes as appActionTypes };
