import { createTheme, MantineColorsTuple } from "@mantine/core";

const darkgreen: MantineColorsTuple = [
	'#b8cac5',
	'#a1b8b2',
	'#8aa7a0',
	'#73968e',
	'#5d867d',
	'#46766c',
	'#2f665b',

	'#12564b', //evergreen

	'#0e4a40',
	'#0a3e35',
];

const lightgreen: MantineColorsTuple = [
	'#BBE8DF',
	'#AEE4D9',
	'#A1E0D4',
	'#94DCCE',
	'#87D8C8',
	'#7BD3C2',
	'#6ECFBC',

	'#61CBB6', //moderate cyan

	'#54C7B0',
	'#47C3AA',
];

export default createTheme({
	/** COLORS */
	colors: {
		darkgreen,
		lightgreen,
	},
	primaryColor: 'darkgreen',
	primaryShade: 7,

});