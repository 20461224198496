import { memo } from "react";
import AuthenticationLayout from 'modules/Authentication/AuthenticationLayout';

import { Button } from 'components';

import { locales } from 'constants/index';
import { Images } from 'assets';

import { TConfirmationUi } from './Confirmation.types';

import '../register.scss';

const ConfirmationUi: TConfirmationUi = ({
  data: { emailAddress },
  handlers: { handleConfirm, handleResend },
}) => {
  return (
    <AuthenticationLayout mainImage={Images.Company}>
      <div className="register flex flex-justify-content-center flex-align-items-center">
        <div className="register__content register__content--confirmation flex flex-column flex-align-items-center">
          <div className="register__title">
            {locales.registration.confirmation}
          </div>

          <div className="register__description">
            {locales.formatString(locales.registration.confirmationMessage, {
              email: (
                <span className="reset-password__user-email">
                  {emailAddress}
                </span>
              ),
            })}
          </div>

          <a className="register__confirmation-link" onClick={handleResend}>
            {locales.registration.resend}
          </a>

          <div className="register__confirmation-image"></div>

          <div className="register__submit">
            <Button
              variant="primary"
              onClick={handleConfirm}
            >
              {locales.registration.finish}
            </Button>
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
};

export default memo(ConfirmationUi);
