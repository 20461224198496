import Joi from "joi";
import { memo, useCallback, useLayoutEffect, useMemo } from "react";
import { batch, shallowEqual, useDispatch, useSelector } from "react-redux";

import { locales } from "constants/index";

import { useExtractServerError, useToast, useValidate } from "hooks";

import { setIsLoading } from "modules/App/store/actions";
import { setYPareo, resetYPareo } from "modules/Settings/store/actions";

import {deleteTokenApi, createTokenApi, fetchTokenApi} from "modules/Settings/api";

import type { IStoreState } from "modules/Settings/settings.types";
import type { ISetYPareoPayload } from "modules/Settings/store/store.types";
import type { ICreateTokenApiPayload } from "modules/Settings/api/api.types";
import type {
    THandleClipboardClick,
    THandleFetchTokenClick,
    THandleSaveYPareo,
    THandleTextInputBlur,
    THandleTextInputChange,
    TYPareo
} from './YPareo.types';

import { YPareoActionEnums, YPareoEnums } from "./YPareo.enums";

import YPareoUI from "./YPareo.ui";

const validationSchema: Record<YPareoEnums, Joi.Schema> = {
    domain: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    token: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    description: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    idSite: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
    idFormationSouhait1: Joi.string().required()
        .messages({
            'any.required': locales.errors.required,
            'string.empty': locales.errors.required,
        }),
};

const YPareo: TYPareo = () => {
    //* Hooks
    const dispatch = useDispatch();
    const toast = useToast();
    const { extractErrorMessage } = useExtractServerError();
    const { errors, validate, setErrors } = useValidate();

    //* Redux State
    const settings = useSelector(({ settings }: IStoreState) => settings, shallowEqual);


    //* Side Effects
    const fetchYPareo = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));
            dispatch(resetYPareo());

            const { data: { data } } = await fetchTokenApi();

            if (!data) {
                dispatch(setYPareo({ canEdit: true }));
            } else {
                dispatch(setYPareo({
                    canEdit: false,
                    showSubmit: false,
                    [YPareoEnums.domain]: data.endpoint,
                    [YPareoEnums.description]: data.description,
                    [YPareoEnums.idSite]: data.idSite,
                    [YPareoEnums.idFormationSouhait1]: data.idFormationSouhait1,
                }));
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch]);

    const createYPareo = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const payload: Partial<ISetYPareoPayload> = Object.assign(
                {},
                {
                    [YPareoEnums.description]: settings.myAccount.yPareo[YPareoEnums.description],
                    [YPareoEnums.token]: settings.myAccount.yPareo[YPareoEnums.token],
                    [YPareoEnums.domain]: settings.myAccount.yPareo[YPareoEnums.domain],
                    [YPareoEnums.idSite]: settings.myAccount.yPareo[YPareoEnums.idSite],
                    [YPareoEnums.idFormationSouhait1]: settings.myAccount.yPareo[YPareoEnums.idFormationSouhait1],
                }
            );

            const isValid = validate({
                data: payload as any,
                validationSchema: validationSchema,
            });

            if (!isValid) return dispatch(setIsLoading(false));

            const apiPayload: ICreateTokenApiPayload = Object.assign(
                {
                    x_auth_token: settings.myAccount.yPareo[YPareoEnums.token],
                    description: settings.myAccount.yPareo[YPareoEnums.description],
                    endpoint: settings.myAccount.yPareo[YPareoEnums.domain],
                    [YPareoEnums.idSite]: settings.myAccount.yPareo[YPareoEnums.idSite],
                    [YPareoEnums.idFormationSouhait1]: settings.myAccount.yPareo[YPareoEnums.idFormationSouhait1],
                }
            );

            await createTokenApi(apiPayload);

            batch(() => {
                dispatch(setYPareo({
                    canEdit: false,
                    showSubmit: false,
                    [YPareoEnums.domain]: settings.myAccount.yPareo[YPareoEnums.domain],
                    [YPareoEnums.token]: undefined,
                    [YPareoEnums.description]: settings.myAccount.yPareo[YPareoEnums.description],
                    [YPareoEnums.idSite]: settings.myAccount.yPareo[YPareoEnums.idSite],
                    [YPareoEnums.idFormationSouhait1]: settings.myAccount.yPareo[YPareoEnums.idFormationSouhait1],
                }));
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch, settings.myAccount.yPareo]);

    const deleteYPareo = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            await deleteTokenApi();

            setErrors({});
            batch(() => {
                dispatch(resetYPareo());
                dispatch(setIsLoading(false));
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch]);

    const fetchToken = useCallback(async () => {
        try {
            dispatch(setIsLoading(true));

            const payload = Object.assign({ get_token: true })

            const { data: { data } } = await fetchTokenApi(payload);

            if (!data) {
                dispatch(setYPareo({ canEdit: true }));
            } else {
                dispatch(setYPareo({ [YPareoEnums.token]: data.x_auth_token }));
            }

            dispatch(setIsLoading(false));
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
            dispatch(setIsLoading(false));
        }
    }, [dispatch]);

    //* Handlers
    const handleTextInputChange: THandleTextInputChange = useCallback(({ target: { name, value } }) => {
        batch(() => {
            dispatch(setYPareo({ [name]: value }));
            if (!settings.myAccount.yPareo.showSubmit) dispatch(setYPareo({ showSubmit: true }));
        });

        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }, [dispatch, settings.myAccount]);

    const handleTextInputBlur: THandleTextInputBlur = useCallback(({ target: { name, value } }) => {
        if (!validationSchema[name as YPareoEnums]) return;
        validate({
            data: { [name]: value },
            validationSchema: { [name]: validationSchema[name as YPareoEnums] },
        });
    }, [dispatch, validate]);

    const handleFetchTokenClick: THandleFetchTokenClick = useCallback(async () => {
        await fetchToken();
    }, [fetchToken]);

    const handleClipboardClick: THandleClipboardClick = useCallback(async () => {
        try {
            if (!settings.myAccount.yPareo.token) return;

            await navigator.clipboard.writeText(settings.myAccount.yPareo.token.toString());

            toast({
                type: 'success',
                message: locales.formatString(
                    locales.general.copyValueToClipboard,
                    { string: locales.settings.myAccount.yPareo.token }
                ).toString(),
            });
        } catch (error) {
            const errorMessage = extractErrorMessage(error);
            toast({ type: 'error', message: errorMessage });
        }

    }, [settings.myAccount.yPareo.token]);


    const handleSaveYPareo: THandleSaveYPareo = useCallback((type) => async () => {
        switch (type) {
            case YPareoActionEnums.CREATE:
                await createYPareo();
                break;

            case YPareoActionEnums.DELETE:
                await deleteYPareo();
                break;

            default:
                break;
        }
    }, [dispatch, createYPareo, deleteYPareo])

    //* Effects
    useLayoutEffect(() => {
        fetchYPareo();
    }, []);


    //* Component
    const data = useMemo(() => ({
        ...settings.myAccount.yPareo,
    }), [
        settings.myAccount.yPareo,
    ]);

    const handlers = useMemo(() => ({
        handleTextInputChange,
        handleTextInputBlur,
        handleFetchTokenClick,
        handleClipboardClick,
        handleSaveYPareo,
    }), [
        handleTextInputChange,
        handleTextInputBlur,
        handleFetchTokenClick,
        handleClipboardClick,
        handleSaveYPareo,
    ]);


    return <YPareoUI
        data={data}
        handlers={handlers}
        errors={errors}
    />;
};

export default memo(YPareo);
