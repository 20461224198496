import { Button, Filter, Table2 } from 'components';

import { locales, routes, enums } from 'constants/index';

import { columns } from './tableColumns';

import { TOffersListingUi } from './OffersListing.types';

import './offers-listing.scss';

const OffersListingUi: TOffersListingUi = ({
  data: { offers },
  handlers: { handleLinkClick },
}) => {
  return (
    <div className="offers-listing">
      <div className="page-title start">{locales.offers.latestOffer}</div>

      {/*<div className="offers-listing__links">*/}
      {/*  <Button*/}
      {/*    isLink*/}
      {/*    variant='link'*/}
      {/*    className="offers-listing__link"*/}
      {/*    path={`${routes.offers.main}/${routes.offers.drafts}`}*/}
      {/*    onClick={handleLinkClick}*/}
      {/*  >*/}
      {/*    {locales.offers.drafts}*/}
      {/*  </Button>*/}
      {/*</div>*/}

      <div className='offers-listing__filterContainer'>
        <Filter moduleName={enums.moduleNames.offers}>
          <Table2 data={offers} columns={columns} />
        </Filter>
      </div>
    </div>
  );
};

export default OffersListingUi;
