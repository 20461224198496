import { rem } from "@mantine/core";

import type { IconProps } from "assets/tsxIcons/types";


export const DuplicateBox = ( { size, style, ...others }: IconProps ) => (
    <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={ { width: rem( size ), height: rem( size ), ...style } }
        { ...others }
    >
        <g clipPath="url(#clip0_9844_7846)">
            <path
                d="M28.7096 18.639C28.8531 16.3454 28.9286 13.9688 28.9286 11.5337C28.9286 10.5472 28.9161 9.5702 28.8919 8.60448C28.8748 7.92102 28.653 7.25682 28.2484 6.70574C26.7178 4.62085 25.497 3.32317 23.4876 1.77196C22.9314 1.3425 22.2486 1.10956 21.5462 1.09425C20.8473 1.07902 20.1175 1.07129 19.338 1.07129C16.9782 1.07129 15.0734 1.14218 13.1038 1.27728C11.4143 1.39316 10.0724 2.73822 9.9666 4.4284C9.82297 6.72205 9.7475 9.0986 9.7475 11.5337C9.7475 13.9688 9.82297 16.3454 9.9666 18.639C10.0724 20.3292 11.4143 21.6742 13.1038 21.7901C15.0734 21.9251 16.9782 21.9961 19.338 21.9961C21.6979 21.9961 23.6027 21.9251 25.5722 21.7901C27.2619 21.6742 28.6037 20.3292 28.7096 18.639Z"
                fill="#D3E5E1"/>
            <path
                d="M28.7096 18.639C28.8531 16.3454 28.9286 13.9688 28.9286 11.5337C28.9286 10.5472 28.9161 9.5702 28.8919 8.60448C28.8748 7.92102 28.653 7.25682 28.2484 6.70574C26.7178 4.62085 25.497 3.32317 23.4876 1.77196C22.9314 1.3425 22.2486 1.10956 21.5462 1.09425C20.8473 1.07902 20.1175 1.07129 19.338 1.07129C16.9782 1.07129 15.0734 1.14218 13.1038 1.27728C11.4143 1.39316 10.0724 2.73822 9.9666 4.4284C9.82297 6.72205 9.7475 9.0986 9.7475 11.5337C9.7475 13.9688 9.82297 16.3454 9.9666 18.639C10.0724 20.3292 11.4143 21.6742 13.1038 21.7901C15.0734 21.9251 16.9782 21.9961 19.338 21.9961C21.6979 21.9961 23.6027 21.9251 25.5722 21.7901C27.2619 21.6742 28.6037 20.3292 28.7096 18.639Z"
                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.04602 8.11279C6.0139 9.23931 5.99751 10.3803 5.99751 11.5335C5.99751 14.0457 6.07536 16.5004 6.22394 18.8731C6.44783 22.4487 9.28778 25.2869 12.8472 25.5312C14.9034 25.6722 16.8938 25.7459 19.3381 25.7459C19.5686 25.7459 19.7952 25.7452 20.0182 25.7439C19.8351 27.3522 18.5278 28.6104 16.8962 28.7223C14.9266 28.8573 13.0218 28.9282 10.662 28.9282C8.30215 28.9282 6.39734 28.8573 4.42776 28.7223C2.73824 28.6064 1.39638 27.2613 1.29055 25.5712C1.14693 23.2775 1.07144 20.9009 1.07144 18.4658C1.07144 16.0307 1.14693 13.6542 1.29055 11.3605C1.39638 9.67035 2.73824 8.3253 4.42776 8.20941C4.96848 8.17232 5.50431 8.14007 6.04602 8.11279Z"
                  fill="white"/>
            <path
                d="M4.95991 8.1748C4.78278 8.18595 4.60555 8.19763 4.42776 8.20982C2.73824 8.32571 1.39638 9.67076 1.29055 11.3609C1.14693 13.6546 1.07144 16.0311 1.07144 18.4662C1.07144 20.9013 1.14693 23.2778 1.29055 25.5715C1.39638 27.2618 2.73824 28.6069 4.42776 28.7226C6.39734 28.8578 8.30215 28.9287 10.662 28.9287C13.0218 28.9287 14.9266 28.8578 16.8962 28.7226C18.2119 28.6324 19.3167 27.7969 19.7912 26.6333"
                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_9844_7846">
                <rect width="30" height="30" fill="white"/>
            </clipPath>
        </defs>
    </svg>

    );